import Firebase from 'firebase/app'
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import ProjectionViewGrid from './ProjectionViewGrid'

type Props = {
  journeyId: string
  activity: any
  handleOnSelect?: any
  isModeration?: boolean
  setDisplayPromoted?: Dispatch<SetStateAction<boolean>>
  setPromotedPiccle?: Dispatch<SetStateAction<string>>
}

export const ProjectionViewGridContainer = React.memo(
  ({ journeyId, activity, handleOnSelect, isModeration = false }: Props) => {
    const INITIAL_AMOUNT_OF_PICCLES = 70
    const JUMPS = 15

    const visiblePiccleIds = (activity.visiblePiccles as Array<string>) ?? []
    const [visiblePiccles, setVisiblePiccles] = useState([])

    useEffect(() => {
      // get visible piccle data
      const promises = visiblePiccleIds.map(async (piccleId) => {
        const query = await Firebase.firestore()
          .collection('journeys')
          .doc(journeyId)
          .collection('activities')
          .doc(activity.id)
          .collection('piccles')
          .doc(piccleId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              return doc
            }
          })
        return query
      })

      Promise.all(promises).then((piccleData) => {
        piccleData && setVisiblePiccles(piccleData)
      })
      // eslint-disable-next-line
    }, [activity])

    // moderation always sees piccles
    const displayAllPiccles = isModeration || (activity.displayAll ?? true)

    const [lastShownPiccleIndex, setLastShownPiccleIndex] = useState(INITIAL_AMOUNT_OF_PICCLES)
    const [piccles, setPiccles] = useState([])

    useEffect(() => {
      const data: Firebase.firestore.Query = Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .collection('activities')
        .doc(activity.id)
        .collection('piccles')
        .where('hasStrokes', '==', true)
        .where('deleted', '==', false)
        .orderBy('createdOn', activity.continuous ? 'desc' : 'asc')

      const unsubscribe = data.onSnapshot(async () => {
        const ids = await data.get()

        if (activity.displayAll ?? true) {
          setPiccles(ids.docs)
        } else {
          setPiccles(
            ids.docs.filter((piccleDoc) => (piccleDoc.data().done === undefined ? true : piccleDoc.data().done)),
          )
        }
      })

      return () => {
        unsubscribe()
      }
      // eslint-disable-next-line
    }, [activity])

    const loadMorePiccles = async (): Promise<void> => {
      setLastShownPiccleIndex((lastIndex) => lastIndex + JUMPS)
    }

    // when we don't display all piccles, we only show white list and make them appear in reverse order. (desired UX by Chris)
    const shownPiccles = displayAllPiccles
      ? piccles.slice(0, lastShownPiccleIndex)
      : visiblePiccles.slice(0, lastShownPiccleIndex).reverse()

    return (
      <>
        {piccles.length ? (
          <ProjectionViewGrid
            visiblePiccles={visiblePiccles}
            loadMorePiccles={loadMorePiccles}
            journeyId={journeyId}
            isModeration={isModeration}
            handleOnSelect={handleOnSelect}
            activityId={activity.id}
            piccles={shownPiccles}
          />
        ) : null}
      </>
    )
  },
)
