import React, { useState, useEffect } from 'react'
import { Box, Flex } from 'rebass'
import { Input } from '@lightspeed/flame/Input'
import { Text } from '@lightspeed/flame/Text'
import { Switch } from '@lightspeed/flame/Switch'
import { Radio, RadioLabel } from '@lightspeed/flame/Radio'
import { SelectDropdown, CreateSponsorModal, PalettePicker, FormGroup } from 'components'
import { PROMPT_ACTIVITY } from 'appConstants'
import { Activity } from 'Pages/Admin/PageEditJourney/PageEditJourney'
import { Helpbox } from 'components/atoms/Helpbox/Helpbox'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Alert } from '@lightspeed/flame/Alert'
import { Data } from '../../../Pages/Admin/PageEditJourney/PageEditJourney'
import { useTranslation } from 'react-i18next'

export const langValues = {
  zh: 'Cantonese',
  en: 'English',
  fr: 'French',
  ja: 'Japanese',
  ko: 'Korean',
  'zh-hant': 'Mandarin',
  'zh-hans': 'Mandarin(simplified)',
  es: 'Spanish',
  th: 'Thai',
}

type Props = {
  onSave: (data: any) => Promise<void | string>
  onDelete?: (id) => void
  values?: Activity
  sponsors?: any[]
  onCreateSponsor: (data) => Promise<void>
  journey?: Data
  handleUpdateJourney: (journeyData: any) => Promise<void>
}

// ------ CONSTANTS FOR THE FORM ------
// commented out some constants due to some inputs removed to make the prompt more simple as an admin.
// Keeping them as comments if later these are brought back
// const maxDrawingsOptions = [8, 18, 32, 50, 72, 98, 128].map((num) => ({ label: `${num}`, id: `${num}` }))
const timerOptions = [15, 30, 45, 60, 90, 120].map((num) => ({ label: `${num}`, id: `${num}` }))
// const participantGoalOptions = [8, 18, 32, 50, 72, 98, 128, 162, 200].map((num) => ({ label: `${num}`, id: `${num}` }))
// Form input names
const TITLE = 'title'
const PROMPT = 'prompt'
const SPONSOR = 'sponsor'
const MAX_SQUARES = 'maxSquares'
const CONTINUOUS = 'continuous'
const COLORS = 'colors'
const PARTICIPANT_GOAL = 'participantGoal'
const TIMER = 'timer'
const DRAWING_LIMIT = 'drawingLimit'
const PROMOTED_PICCLE = 'promotedPiccle'
const QUESTION = 'question'

export const PromptActivityForm: React.FC<Props> = ({
  onSave,
  onDelete,
  values,
  sponsors = [],
  onCreateSponsor,
  journey,
  handleUpdateJourney,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const [error] = useState<string>(null)

  // Controlled form state and handlers and default values for form inputs.
  const [inputs, setInputs] = useState(
    values || {
      [TITLE]: '',
      [PROMPT]: '',
      [SPONSOR]: '',
      [MAX_SQUARES]: '',
      id: '',
      [CONTINUOUS]: true,
      [COLORS]: [],
      [PARTICIPANT_GOAL]: '',
      [TIMER]: '',
      [DRAWING_LIMIT]: 'infinite',
      [PROMOTED_PICCLE]: '',
      [QUESTION]: { en: '' },
    },
  )

  //update inputs on value prop change
  useEffect(() => {
    values && setInputs(values)
  }, [values])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist()

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    if (event.target.name === QUESTION) {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: { ...inputs.question, [event.target.id]: value } }))
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: value }))
    }
  }

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): Promise<void | string> => {
    event.preventDefault()

    const data = { ...inputs, type: PROMPT_ACTIVITY }
    const result = await onSave(data)
    // onSave for edit gives no result, but gives id if creating new activity
    if (result) return result
  }

  const [isSponsorModalOpen, setIsSponsorModalOpen]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false)

  const [showAdvancedOptions, setShowAdvancedOptions]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false)

  // If we have "values" prop, this means we are in edit mode.
  const isEditMode: boolean = !!values

  // add labels to sponsors
  sponsors = sponsors.map((s) => ({ ...s, label: s.name }))

  return (
    <>
      <CreateSponsorModal
        isOpen={isSponsorModalOpen}
        onClose={() => setIsSponsorModalOpen(false)}
        onCreate={onCreateSponsor}
      />

      {error && (
        <Alert mb={3} type="danger">
          {error}
        </Alert>
      )}

      <Box as="form" id="promptActivityForm" onSubmit={handleSubmit} sx={{ maxWidth: '32rem' }}>
        {isEditMode && (
          <div style={{ display: 'none' }}>
            <Input name="id" value={values.id} readOnly />
          </div>
        )}
        <FormGroup>
          {/* <Input
            name={PROMPT}
            id="en"
            label={
              <>
                {labels.prompt} &#160;
                <Helpbox info={labels.helpboxPrompt} /> &#160;
                <a
                  style={{
                    fontSize: '12px',
                  }}
                  href="https://www.piccles.com/gallery"
                >
                  <em>{labels.promptSuggestion}</em>
                </a>
              </>
            }
            placeholder={labels.questionPlaceholder}
            value={inputs.prompt['en']}
            onChange={handleInputChange}
            autoComplete="off"
            required
          /> */}

          <label htmlFor="languages" style={{ fontWeight: 'bold' }}>
            {t('promptActivityForm.prompt')} &#160;
            <Helpbox info={t('promptActivityForm.helpboxPrompt')} /> &#160;
            <a
              style={{
                fontSize: '12px',
              }}
              href="https://www.piccles.com/halloffame"
            >
              <em>{t('promptActivityForm.promptSuggestion')}</em>
            </a>
          </label>
          {/* <MultiSelect options={langOptions} value={selected} onChange={setSelected} labelledBy="Select" /> */}

          {journey.languages?.map((lang) => {
            return (
              <Input
                key={lang}
                name={QUESTION}
                id={lang}
                value={inputs.question[lang] || ''}
                onChange={handleInputChange}
                placeholder={langValues[lang]}
                autoComplete="off"
                required
              />
            )
          })}

          <PalettePicker colors={inputs.colors} onChange={(colors) => setInputs((inputs) => ({ ...inputs, colors }))} />

          <Flex flexDirection={'column'}>
            <Text fontSize="15px">Show advanced options</Text>
            <Switch checked={showAdvancedOptions} onChange={() => setShowAdvancedOptions(!showAdvancedOptions)} />
          </Flex>

          {showAdvancedOptions && (
            <Flex flexDirection={'column'}>
              <SelectDropdown
                name={TIMER}
                value={inputs.timer}
                onChange={handleInputChange}
                label={t('promptActivityForm.timer')}
                options={timerOptions}
                defaultOption={'2'}
              />

              <Flex
                alignItems="flex-end"
                style={{
                  marginBottom: '10px',
                }}
              >
                <SelectDropdown
                  name={SPONSOR}
                  helpbox={<Helpbox info={t('promptActivityForm.helpboxSponsor')} />}
                  value={inputs.sponsor}
                  onChange={handleInputChange}
                  label={t('promptActivityForm.sponsor')}
                  options={sponsors}
                  defaultOption={'No Sponsor'}
                  required
                />

                <Flex alignItems={'center'} style={{ marginBottom: '10px' }}>
                  <Button ml={2} variant="secondary" onClick={() => setIsSponsorModalOpen(true)}>
                    {t('promptActivityForm.upload')}
                  </Button>
                </Flex>
              </Flex>

              <Box>
                <Flex>
                  <Text fontWeight="bold" size="small" color="textHeading" mb={1}>
                    {t('promptActivityForm.drawingLimit')}
                  </Text>

                  <Helpbox info={t('promptActivityForm.drawLimitHelpbox')} />
                </Flex>

                <RadioLabel htmlFor="radio1">
                  <Radio
                    id="radio1"
                    name={DRAWING_LIMIT}
                    onChange={handleInputChange}
                    checked={inputs.drawingLimit === '1'}
                    value="1"
                  />
                  {'1'}
                </RadioLabel>

                <RadioLabel htmlFor="radio2">
                  <Radio
                    id="radio2"
                    name={DRAWING_LIMIT}
                    onChange={handleInputChange}
                    checked={inputs.drawingLimit === 'infinite'}
                    value="infinite"
                  />
                  {'Infinite'}
                </RadioLabel>
              </Box>
            </Flex>
          )}

          <Box mt={4}>
            {isEditMode && (
              <>
                <Flex>
                  <Button
                    mb={2}
                    mr={2}
                    block
                    variant="primary"
                    type="submit"
                    form="promptActivityForm"
                    onClick={handleSubmit}
                  >
                    {t('promptActivityForm.save')}
                  </Button>

                  <Button
                    mb={2}
                    variant="danger"
                    block
                    fill
                    onClick={(event) => {
                      event.preventDefault()
                      onDelete(values.id)
                    }}
                  >
                    {t('promptActivityForm.delete')}
                  </Button>
                </Flex>
              </>
            )}

            {!isEditMode && (
              <Flex>
                <Button mb={2} type="i" fill variant="primary" form="promptActivityForm" onClick={handleSubmit}>
                  {t('promptActivityForm.add')}
                </Button>

                <Helpbox info={t('promptActivityForm.helpboxAddActivity')} />
              </Flex>
            )}
          </Box>
        </FormGroup>
      </Box>
    </>
  )
}
