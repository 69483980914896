/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from '@lightspeed/flame/Core'
import { Card } from '@lightspeed/flame/Card'
import { FunctionComponent, ReactElement, Fragment } from 'react'
import { JourneyData } from 'hooks/useJourneyData'
import AsynchronousProjectionGridContainer from './AsynchronousProjectionGrid/AsynchronousProjectionGridContainer'

type Props = {
  journey: JourneyData
  journeyId: string
  activity: any
}

export const AsynchronousProjection: FunctionComponent<Props> = ({ journeyId, activity }: Props): ReactElement => {
  return (
    <Fragment>
      <Flex
        bg="background"
        justifyContent="center"
        height="100vh"
        flex={1}
        width="100vw"
        data-testid="AsynchronousProjectionScreen"
        marginTop={1}
        flexDirection="column"
      >
        <Card
          css={css`
            height: 100%;
          `}
        >
          {activity && <AsynchronousProjectionGridContainer journeyId={journeyId} activity={activity} />}
        </Card>
      </Flex>
    </Fragment>
  )
}
