import { CrayonTranslationObjectType, SponsorsData, TemplateJourney } from 'types'
import { Error, Loading } from 'components'

import PageCrayonPickJourney from './PageCrayonPickJourney'
import React from 'react'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import { useSponsorsData } from 'hooks/crayon/useSponsorsData'
import { useNavigate } from 'hooks/useNavigate'

const translations: CrayonTranslationObjectType = {
  en: {
    pickPrompt: 'Please select your journey',
    select: 'Select',
    createdFor: 'Created for',
    withHelp: 'With help from',
  },
  es: {
    pickPrompt: 'Por favor selecciona tu viaje',
    select: 'Selecciona',
    createdFor: 'Creado para',
    withHelp: 'Con la ayuda de',
  },
}

type PageCrayonPickJourneyContainerProps = {
  language: string
  journeyCollectionPath: string
  journeyId: string
}

const PageCrayonPickJourneyContainer: React.FunctionComponent<any> = ({
  language,
  journeyCollectionPath,
  journeyId,
}: PageCrayonPickJourneyContainerProps): JSX.Element => {
  const navigate = useNavigate()
  const labels = translations[language]

  const categoryTranslations: CrayonTranslationObjectType = {
    en: {
      Sibling: 'Sibling',
      Parent: 'Parent',
      Patient: 'Patient',
    },
    es: {
      Sibling: 'Hermanos',
      Parent: 'Padres',
      Patient: 'Paciente',
    },
  }

  const questionCategories: string[] = Object.values(categoryTranslations[language])

  const [journey, journeyLoading, journeyError]: [TemplateJourney, boolean, Error] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  const [sponsors, sponsorsLoading, sponsorsError]: [SponsorsData, boolean, Error] = useSponsorsData({
    journeyCollectionPath,
    journeyId,
  })

  const handleJourneyCategoryPicked = (category: string): void => {
    const categoryKey = Object.entries(categoryTranslations[language]).find(([, value]) => value === category)[0]
    navigate(`${window.location}/${categoryKey}`)
  }

  return (
    <>
      {journeyError && sponsorsError && <Error error={journeyError} />}
      {journeyLoading && sponsorsLoading && <Loading />}
      {journey && (
        <PageCrayonPickJourney
          labels={labels}
          onCategoryPicked={handleJourneyCategoryPicked}
          questionCategories={questionCategories}
          sponsors={sponsors}
        />
      )}
    </>
  )
}

export default PageCrayonPickJourneyContainer
