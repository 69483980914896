import React, { useRef, useState, useEffect } from 'react'
import { Image, Box, Flex } from 'rebass'
import { Heading3 } from '@lightspeed/flame/Text'
import get from 'lodash.get'

import { PiccleCanvasContainer as PiccleCanvas } from 'components'
import useWindowWidth from 'hooks/useWindowWidth'

type Props = {
  journeyId: string
  piccles?: any[]
  activityData: { id: string; imgUrl: string; numSquares: number; title: string }
  onSelect: (index) => void
}

const MosaicPiccleGrid = ({ journeyId, activityData, piccles, onSelect }: Props) => {
  const mosaicSize = Number(activityData.numSquares)

  const piccleGridEl = useRef(null)
  const windowWidth = useWindowWidth()
  const [gridWidth, setGridWidth] = useState(null)

  // find the smallest square number which is bigger or equal to your num of squares.
  // Divide 100 by this square number to get your width for sqaures.
  const squareNumbers = [1, 4, 9, 16, 25, 36, 49, 64, 81, 100]
  // getSquareParent returns the nearest square number which is bigger or equal to your number
  const getSquareParentIndex = (num) => squareNumbers.findIndex((squareNum) => num <= squareNum) + 1
  const getSquareWidth = (num) => 100 / getSquareParentIndex(num)
  const getCanvasWidth = () => Number(gridWidth) / getSquareParentIndex(mosaicSize)

  useEffect(() => {
    setGridWidth(get(piccleGridEl, 'current.clientWidth'))
  }, [windowWidth])

  const boxArray = Array.from(Array(mosaicSize).keys())
  return (
    <Box py={2} px={1} sx={{ textAlign: 'center' }}>
      <Heading3>{activityData.title}</Heading3>
      <Box ref={piccleGridEl} sx={{ position: 'relative' }} width={['100%', '66vh']}>
        <Image src={activityData.imgUrl} width={'100%'} />
        <Flex flexWrap="wrap" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          {boxArray.map((index) => {
            const piccleForSlot = piccles.find((p) => p.mosaicIndex === index)
            if (piccleForSlot) {
              return (
                <Box
                  key={piccleForSlot.id}
                  sx={{
                    backgroundColor: 'rgba(255,255,255,0.8)',
                  }}
                  width={`${getSquareWidth(mosaicSize)}%`}
                  height={`${getSquareWidth(mosaicSize)}%`}
                >
                  <PiccleCanvas
                    journeyId={journeyId}
                    activityId={activityData.id}
                    piccleId={piccleForSlot.id}
                    width={getCanvasWidth()}
                    height={getCanvasWidth()}
                  />
                </Box>
              )
            } else {
              return (
                <Box
                  key={'no-piccle' + index}
                  sx={{
                    backgroundColor: 'rgba(50,80,20,0.1)',
                    boxShadow: 'inset 0 0 3px #000000',
                    cursor: 'pointer',
                  }}
                  width={`${getSquareWidth(mosaicSize)}%`}
                  height={`${getSquareWidth(mosaicSize)}%`}
                  onClick={() => onSelect(index)}
                ></Box>
              )
            }
          })}
        </Flex>
      </Box>
    </Box>
  )
}

export default MosaicPiccleGrid
