import React from 'react'
import { AdminLayout, DashboardHeader, Loading } from 'components'
import { Box, Flex } from '@lightspeed/flame/Core'
import useTranslations from 'hooks/useTranslations'
import { PiccleButton } from 'components/atoms/Button'
import { Card } from '@lightspeed/flame/Card'
import { Heading4, Heading2 } from '@lightspeed/flame/Text'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  singleEvents: Array<any>
  loading: boolean
  error: any
}

const translations = {
  en: {
    title: 'My Single Events',
    back: 'Back to profile',
    noEvents: 'You have no events',
    subscription: 'Subscription: ',
    purchasedOn: 'Purchased on: ',
    scheduledFor: 'Scheduled for: ',
    endsOn: 'Ends on: ',
    purchasedBy: 'Purchased by: ',
  },
  fr: {
    title: 'Mes Évènements',
    back: 'Retour au profile',
    noEvents: 'Vous avez aucun évènements',
    subscription: "Type d'abonnement: ",
    purchasedOn: 'Date de paiement: ',
    scheduledFor: 'Actif le: ',
    endsOn: 'Date de fin: ',
    purchasedBy: 'Achat par: ',
  },
}

type Translations = typeof translations.en

const PageProfileEvents = ({ singleEvents, loading }: Props) => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()

  const SingleEventCard = ({ singleEvent }: SingleEventCardProps) => {
    return (
      <Card m={3} p={2}>
        <Flex my={1} width="100%">
          <Heading2 color="#452768">
            {labels.subscription} {singleEvent?.subscription}
          </Heading2>
        </Flex>

        <Flex my={1} width="100%" flexDirection="column" justifyContent="space-between">
          <Heading4 color="#452768">
            {labels.purchasedOn} {singleEvent?.createdOn?.toDate().toLocaleString('en')}
          </Heading4>
          <Heading4 color="#452768">
            {labels.scheduledFor} {singleEvent?.scheduledFor?.toDate().toLocaleString('en')}
          </Heading4>

          <Heading4 mt={3}>
            {labels.endsOn} {singleEvent?.endTime?.toDate().toLocaleString('en')}
          </Heading4>
          <Heading4>
            {labels.purchasedBy} {singleEvent?.createdBy}
          </Heading4>
        </Flex>
      </Card>
    )
  }

  return (
    <AdminLayout>
      <Box>
        <DashboardHeader title={labels.title} />
      </Box>

      <Card my={1} p={2}>
        <Box width="100%" py={3} pl={2}>
          <PiccleButton
            onClick={() => {
              navigate('/admin/profile')
            }}
          >
            {labels.back}
          </PiccleButton>
        </Box>

        {loading && <Loading />}

        {singleEvents && !!singleEvents.length && !loading ? (
          singleEvents.map((event, index) => <SingleEventCard key={event?.id ?? index} singleEvent={event} />)
        ) : (
          <Heading4>{labels.noEvents}</Heading4>
        )}
      </Card>
    </AdminLayout>
  )
}

export default PageProfileEvents

type SingleEventCardProps = {
  singleEvent?: any
}
