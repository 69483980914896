import React from 'react'
import { Loading, Error } from 'components'
import PagePiccleDraw from './PagePiccleDraw'
import { usePiccleDataOnce } from 'hooks/usePiccleData'
import useActivityDataOnce from 'hooks/useActivityData'
import updatePiccle from 'api/piccles/updatePiccle'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'
import { JourneyType } from '../../../types'
import createPiccle from 'api/piccles/createPiccle'
import { useNavigate } from 'hooks/useNavigate'
import { useLocale } from 'hooks/useChangeLocale'

const PagePiccleDrawContainer = (props) => {
  const navigate = useNavigate()
  const { locale } = useLocale()

  // Data Fetching
  const [strokesData, strokesLoading, strokesError] = usePiccleDataOnce({
    journeyCode: props.journeyCode,
    activityId: props.activityId,
    piccleId: props.piccleId,
  })

  const [journey, journeyLoading, journeyError]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .where('code', '==', props.journeyCode),
    { idField: 'id' },
  )

  const [activityData, activityLoading, activityError] = useActivityDataOnce({
    journeyCode: props.journeyCode,
    activityId: props.activityId,
  })

  // Data Merging
  const error = strokesError || activityError || journeyError
  const loading = strokesLoading || activityLoading || journeyLoading

  // Handlers
  const handleUpdatePiccle = (payload) => {
    return updatePiccle({ journeyId: journey?.id, activityId: props.activityId, piccleId: props.piccleId, payload })
  }

  const goToSharePage = () => {
    navigate(`/${props.journeyCode}/${props.activityId}/${props.piccleId}/share`)
  }

  React.useEffect(() => {
    const goToAsyncFirstPiccle = async (): Promise<void> => {
      // check for async journeys
      if (journey && journey[0].type === JourneyType.ASYNCHRONOUS) {
        // Else we go to draw page
        const activityId = journey[0].sequence.shift()
        const goToDrawPrompt = async () => {
          try {
            const piccleId = await createPiccle({ journeyId: journey[0].id, activityId })
            return piccleId
          } catch (error) {
            console.log(error)
          }
        }
        // Go to the first activity of the async journey
        try {
          const piccleId = await goToDrawPrompt()
          window.location.replace(`/${props.journeyCode}/${journey[0].id}/${activityId}/${piccleId}?locale=${locale}`)
        } catch (error) {
          console.log(error)
        }
      }
    }

    goToAsyncFirstPiccle()
  }, [journey, props.journeyCode, locale])

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journey &&
        (journey[0]?.type === undefined || journey[0]?.type === JourneyType.SYNCHRONOUS) &&
        strokesData &&
        activityData && (
          <>
            <PagePiccleDraw
              journeyId={journey[0].id}
              canvasObjects={strokesData}
              activityId={props.activityId}
              piccleId={props.piccleId}
              colors={activityData.colors}
              prompt={activityData.prompt}
              drawingLimit={activityData.drawingLimit !== 'infinite'}
              goToSharePage={goToSharePage}
              handleUpdatePiccle={handleUpdatePiccle}
              question={activityData.question}
              languages={journey[0].languages}
            />
          </>
        )}
    </>
  )
}

export default PagePiccleDrawContainer
