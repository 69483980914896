import React from 'react'
import get from 'lodash.get'
import { JourneyState } from 'hooks/useJourneyData'
import { ClientLayout, InactiveLayout, TerminalLayout } from 'components'
import { PREGAME, ACTIVE, LIMBO, TERMINAL, PROMPT_ACTIVITY, MOSAIC_ACTIVITY, headerSmall } from 'appConstants'
import createPiccle from 'api/piccles/createPiccle'
import ClientPromptActivity from './ClientPromptActivity'
import ClientMosaicActivity from './ClientMosaicActivity'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  data: JourneyState
  journeyId: string
  asyncJourney?: boolean
  journeyDoc: any
  goToFirstPiccle?: VoidFunction
  journeyCode: string
  labels: {
    instructions: string
    draw: string
  }
}

const PageJourneyStatus = ({
  data,
  journeyId,
  journeyDoc,
  journeyCode,
  labels,
  asyncJourney,
  goToFirstPiccle,
}: Props) => {
  const navigate = useNavigate()
  const handleClickToDrawPrompt = async () => {
    const activityId = data.currentActivity.id
    const piccleId = await createPiccle({ journeyId, activityId })
    navigate(`/${journeyCode}/${activityId}/${piccleId}`)
  }

  const handleClickToDrawMosaic = async (mosaicIndex) => {
    const activityId = data.currentActivity.id
    const piccleId = await createPiccle({ journeyId, activityId, meta: { mosaicIndex } })
    navigate(`/${journeyCode}/${activityId}/${piccleId}`)
  }

  const getCurrentActivityPage = (type) =>
    ({
      [PROMPT_ACTIVITY]: (
        <ClientPromptActivity
          journeyId={journeyId}
          handleClickToDraw={handleClickToDrawPrompt}
          asyncJourney={asyncJourney}
          activity={{
            id: get(data.currentActivity, 'id'),
            continuous: get(data.currentActivity, 'continuous'),
            maxSquares: get(data.currentActivity, 'maxSquares'),
          }}
          prompt={get(data.currentActivity, 'prompt')}
          labels={labels}
        />
      ),
      [MOSAIC_ACTIVITY]: (
        <ClientMosaicActivity
          journeyId={journeyId}
          handleClickToDraw={handleClickToDrawMosaic}
          activityId={get(data.currentActivity, 'id')}
          labels={labels}
        />
      ),
    }[type])

  const getStatusPage = (status) => {
    if (asyncJourney) {
      return (
        <InactiveLayout asyncJourney={asyncJourney} handleStartJourney={goToFirstPiccle} title={journeyDoc['title']} />
      )
    }
    return {
      [PREGAME]: (
        <InactiveLayout asyncJourney={asyncJourney} handleStartJourney={goToFirstPiccle} title={journeyDoc['title']} />
      ),
      [ACTIVE]: getCurrentActivityPage(get(data.currentActivity, 'type')),
      [LIMBO]: (
        <InactiveLayout asyncJourney={asyncJourney} handleStartJourney={goToFirstPiccle} title={journeyDoc['title']} />
      ),
      [TERMINAL]: <TerminalLayout />,
    }[status]
  }

  return <ClientLayout headerStyle={headerSmall}>{getStatusPage(data.status)}</ClientLayout>
}

export default PageJourneyStatus
