import { AdminLayout, CreateSponsorModal, ImageUploader, PalettePicker, SelectDropdown } from 'components'
import { Box, Flex, Image } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Heading2, Heading3, Text } from '@lightspeed/flame/Text'
import React, { FunctionComponent, ReactElement, useState } from 'react'
import { Sponsor, TemplateJourney } from 'types'

import { Button } from '@lightspeed/flame/Button'
import { Checkbox } from '@lightspeed/flame/Checkbox'
import { FaTrash } from 'react-icons/fa'
import { Helpbox } from 'components/atoms/Helpbox/Helpbox'
import { Input } from '@lightspeed/flame/Input'
import uploadImage from 'api/helpers/uploadImage'
import useTranslations from 'hooks/useTranslations'
import { verifyUniqueCode } from 'api/helpers/firestoreHelpers'

const translations = {
  en: {
    createJourney: 'Create Journey',
    title: 'Title',
    code: 'Code',
    edit: 'Edit',
    codeHelp: 'Customize the code that your participants use to join the activity',
    titleHelpbox: 'Choose a name for your meeting or event',
    titlePlaceholder: 'Icebreaker for team bi-weekly meeting',
    QRCode: 'QR code',
    opening: 'Opening',
    closing: 'Closing',
    message: 'Message (maximum characters 24)',
    openingMessagePlaceholder: 'Put your welcome message here',
    image: 'Image',
    deleteImage: 'Delete Image',
    logo: 'Logo',
    primarySponsor: 'Primary sponsor',
    secondarySponsor: 'Secondary sponsor',
    noSponsor: 'No Sponsor',
    hospital: 'Hospital',
    closingMessagePlaceholder: 'Put your closing message here',
    upload: 'Upload',
    done: 'Done',
    recommendedResolution: 'Recommended size: 200 x 200px',
    deleteJourney: (userType) => `Delete this Piccle ${userType === 'free' ? 'Activity' : 'Journey'}?`,
  },
  fr: {
    createJourney: 'Modifier la Journey',
    title: 'Titre',
    code: 'Code',
    edit: 'Modifier',
    codeHelp: "Personnalisez votre code d'accès que vos participants utiliseront",
    titleHelpbox: 'Choisissez un titre pour votre rencontre ou votre évènement',
    titlePlaceholder: 'Brise-glace pour les réunions bi-hebdomadaires',
    QRCode: 'Code QR',
    opening: 'Opening',
    message: 'Message (maximum characters 24)',
    closing: 'Closing',
    image: 'Image',
    deleteImage: 'Delete Image',
    logo: 'Logo',
    primarySponsor: 'Primary sponsor',
    secondarySponsor: 'Secondary sponsor',
    noSponsor: 'No Sponsor',
    hospital: 'Hospital',
    openingMessagePlaceholder: 'Put your welcome message here',
    closingMessagePlaceholder: 'Put your closing message here',
    upload: 'Upload',
    done: 'Fini',
    recommendedResolution: 'Résolution recommandée: 200 x 200px',
    deleteJourney: (userType) => `Supprimer ${userType === 'free' ? "l'Activité Piccle" : 'la Piccle Journey'}?`,
  },
}

type Translations = typeof translations.en

export type Data = {
  code: string
  title: string
  sequence?: []
}

type InputType = Partial<TemplateJourney>

type PageEditTemplateJourneyProps = {
  handleDeleteJourney: () => void
  values: TemplateJourney
  onSubmit: (journeyData) => Promise<void>
  sponsorsData: Sponsor[]
  onCreateSponsor: (data) => Promise<void>
}

const PageEditTemplateJourney: FunctionComponent<PageEditTemplateJourneyProps> = ({
  handleDeleteJourney,
  onSubmit,
  values,
  sponsorsData,
  onCreateSponsor,
}: PageEditTemplateJourneyProps): ReactElement => {
  // Controlled form state and handlers and default values for form inputs.
  const labels: Translations = useTranslations<Translations>(translations)

  const [inputs, setInputs]: [InputType, React.Dispatch<React.SetStateAction<InputType>>] = useState<InputType>(
    values || {
      title: '',
      code: '',
      openingMessage: '',
      closingMessage: '',
      colors: [],
      openingImageUrl: '',
      closingImageUrl: '',
      QRcode: false,
      sponsors: { primary: '', secondary: '', hospital: '' },
    },
  )

  const [codeError, setCodeError] = useState<string>('')

  const [isSponsorModalOpen, setIsSponsorModalOpen]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false)

  const [isUploadingOpeningImage, setisUploadingOpeningImage]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false)

  const [isUploadingClosingImage, setisUploadingClosingImage]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(false)

  const handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // remove error status on code input when typing.
    if (event.target.name === 'code') {
      setCodeError('')
    }
    event.persist()
    setInputs((inputs: InputType): InputType => ({ ...inputs, [event.target.name]: event.target.value }))
  }

  const handleOnTitleBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target
    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, title: values.title }))
      return
    } else {
      const data: { [keyname: string]: string } = { [name]: value }
      await onSubmit(data)
    }
  }

  const handleOnCodeBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target

    // if code input left blank, reset to previous value and get out.
    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, code: values.code }))
      return
    }

    // if code hasn't changed, get out.
    if (value === values.code) {
      return
    }

    // else check if code is unique
    const data: { [keyname: string]: string } = { [name]: value }
    const isUnique: boolean = await verifyUniqueCode(data[name])
    if (isUnique) {
      await onSubmit(data)
    } else {
      setCodeError('code is already in use, must be unique')
    }
  }

  const handleOnOpenMessageBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target
    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, openingMessage: values.openingMessage }))
      return
    } else {
      const data: { [keyname: string]: string } = { [name]: value }
      await onSubmit(data)
    }
  }

  const handleOnClosingMessageBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target
    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, closingMessage: values.closingMessage }))
      return
    } else {
      const data: { [keyname: string]: string } = { [name]: value }
      await onSubmit(data)
    }
  }

  const handleOnColorPaletteChange: (colors: string[]) => Promise<void> = async (colors: string[]): Promise<void> => {
    setInputs((inputs: InputType): InputType => ({ ...inputs, colors }))
    const data: { [keyname: string]: string[] } = { colors }
    await onSubmit(data)
  }

  const handleOnSponsorChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target
    const { sponsors } = inputs
    const newSponsors = { ...sponsors }
    if (value === labels.noSponsor) {
      newSponsors[name] = labels.noSponsor
    } else {
      newSponsors[name] = value
    }
    await onSubmit({ sponsors: newSponsors })
    setInputs((inputs: InputType): InputType => ({ ...inputs, sponsors: newSponsors }))
  }

  const handleUploadImage = async (name, file) => {
    name === 'openingImageUrl' && setisUploadingOpeningImage(true)
    name === 'closingImageUrl' && setisUploadingClosingImage(true)
    const imgUrl = await uploadImage(file)
    await onSubmit({ [name]: imgUrl })
    name === 'openingImageUrl' && setisUploadingOpeningImage(false)
    name === 'closingImageUrl' && setisUploadingClosingImage(false)
  }

  // just removes from firestore, without deleting from storage
  const handleDeleteImage = async (name) => {
    await onSubmit({ [name]: null })
  }

  const onInputRestrictCharacters: (event: React.KeyboardEvent<HTMLInputElement>) => void = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    // REGEX matches lower case alphanumeric and Backspace
    // if user enters anything else, we prevent.
    // eslint-disable-next-line
    if (!event.key.match(/[a-zA-Z0-9]+|[\b]+$/)) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
  }

  const toggleQRCode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    try {
      setInputs((inputs: InputType): InputType => ({ ...inputs, QRcode: checked }))
      await onSubmit({ QRcode: checked })
    } catch (error) {
      console.log(error)
    }
  }

  const sponsors = sponsorsData.map((s) => ({ ...s, label: s.name }))
  return (
    <AdminLayout>
      <CreateSponsorModal
        isOpen={isSponsorModalOpen}
        onClose={() => setIsSponsorModalOpen(false)}
        onCreate={onCreateSponsor}
      />
      <Box mb={3}>
        <Card>
          <CardSection bg="darkGreen">
            <Flex alignItems="center">
              <Heading3 color="white">{labels.createJourney}</Heading3>

              <Box onClick={handleDeleteJourney} sx={{ cursor: 'pointer', marginLeft: 'auto', color: 'background' }}>
                <FaTrash />
              </Box>
            </Flex>
          </CardSection>

          <CardSection large>
            <Flex flexDirection="row" alignItems="center">
              <Box width={1 / 3} px={2}>
                <Input
                  label={
                    <>
                      {labels.title}
                      <Helpbox info={labels.titleHelpbox} />
                    </>
                  }
                  placeholder={labels.titlePlaceholder}
                  id="title"
                  name="title"
                  defaultValue={values.title}
                  onBlur={handleOnTitleBlur}
                />
              </Box>

              <Box width={1 / 3} px={2}>
                <Input
                  label={
                    <>
                      <>{labels.code} </>
                      <Helpbox info={labels.codeHelp} />
                    </>
                  }
                  onKeyDown={onInputRestrictCharacters}
                  id="code"
                  name="code"
                  value={inputs.code.toUpperCase()}
                  onChange={handleInputChange}
                  autoComplete="off"
                  onBlur={handleOnCodeBlur}
                  status={codeError ? 'error' : 'valid'}
                  statusMessage={codeError}
                />
              </Box>
              <Box width={1 / 3} px={2}>
                <Checkbox
                  css={{
                    paddingTop: '0.25rem',
                    paddingRight: '0.75rem',
                    textAlign: 'center',
                  }}
                  label={<>{labels.QRCode}</>}
                  onChange={(event) => {
                    try {
                      toggleQRCode(event)
                    } catch (error) {
                      console.error(error)
                    }
                  }}
                  checked={inputs.QRcode}
                />
              </Box>
            </Flex>
          </CardSection>

          <CardSection m={1}>
            <Flex width={1 / 2}>
              <PalettePicker colors={inputs.colors} onChange={(colors) => handleOnColorPaletteChange(colors)} />
            </Flex>
            <Flex flexDirection={'row'}>
              <Flex flexDirection={'column'} width={1 / 2}>
                <Flex flexDirection={'column'}>
                  <Box m={2}>
                    <Heading2 color={'black'}>{labels.opening}</Heading2>
                  </Box>
                  <Box m={2}>
                    <Input
                      label={labels.message}
                      placeholder={labels.openingMessagePlaceholder}
                      id="openingMessage"
                      name="openingMessage"
                      defaultValue={values.openingMessage}
                      onBlur={handleOnOpenMessageBlur}
                    />
                  </Box>
                  <Flex justifyContent={'center'}>
                    <Box width={1 / 2} m={2}>
                      <Text>{labels.recommendedResolution}</Text>
                      {values.openingImageUrl ? (
                        <Flex flexDirection="column" alignItems={'center'}>
                          <Image src={values.openingImageUrl} />
                          <Button variant="neutral" onClick={() => handleDeleteImage('openingImageUrl')}>
                            {labels.deleteImage}
                          </Button>
                        </Flex>
                      ) : (
                        <ImageUploader
                          loadFileHandler={(file) => handleUploadImage('openingImageUrl', file)}
                          isUploadingImage={isUploadingOpeningImage}
                          required
                        />
                      )}
                    </Box>
                  </Flex>
                </Flex>
                <Flex flexDirection={'column'}>
                  <Box m={1}>
                    <Heading2 color={'black'}>{labels.closing}</Heading2>
                  </Box>
                  <Box m={2}>
                    <Input
                      label={labels.message}
                      placeholder={labels.closingMessagePlaceholder}
                      id="closingMessage"
                      name="closingMessage"
                      defaultValue={values.closingMessage}
                      onBlur={handleOnClosingMessageBlur}
                    />
                  </Box>
                  <Flex justifyContent={'center'}>
                    <Box width={1 / 2} m={2}>
                      <Text>{labels.recommendedResolution}</Text>
                      {values.closingImageUrl ? (
                        <Flex flexDirection="column" alignItems={'center'}>
                          <Image src={values.closingImageUrl} />
                          <Button variant="neutral" onClick={() => handleDeleteImage('closingImageUrl')}>
                            {labels.deleteImage}
                          </Button>
                        </Flex>
                      ) : (
                        <ImageUploader
                          loadFileHandler={(file) => handleUploadImage('closingImageUrl', file)}
                          isUploadingImage={isUploadingClosingImage}
                          required
                        />
                      )}
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDirection={'column'} width={1 / 2}>
                <Flex>
                  <Heading2 color={'black'}>{labels.logo}</Heading2>
                </Flex>
                <Flex m={2}>
                  <Box width={1 / 2} m={2}>
                    <SelectDropdown
                      onChange={handleOnSponsorChange}
                      label={labels.primarySponsor}
                      defaultOption={labels.noSponsor}
                      options={sponsors}
                      value={inputs.sponsors && inputs.sponsors['primary']}
                      name="primary"
                    />
                  </Box>
                  <Box alignSelf="center" width={1 / 2} m={2}>
                    <Button variant="secondary" onClick={() => setIsSponsorModalOpen(true)}>
                      {labels.upload}
                    </Button>
                  </Box>
                </Flex>
                <Flex m={2}>
                  <Box width={1 / 2} m={2}>
                    <SelectDropdown
                      onChange={handleOnSponsorChange}
                      label={labels.secondarySponsor}
                      defaultOption={labels.noSponsor}
                      options={sponsors}
                      value={inputs.sponsors && inputs.sponsors['secondary']}
                      name="secondary"
                    />
                  </Box>
                  <Box alignSelf="center" width={1 / 2} m={2}>
                    <Button variant="secondary" onClick={() => setIsSponsorModalOpen(true)}>
                      {labels.upload}
                    </Button>
                  </Box>
                </Flex>
                <Flex m={2}>
                  <Box width={1 / 2} m={2}>
                    <SelectDropdown
                      onChange={handleOnSponsorChange}
                      label={labels.hospital}
                      defaultOption={labels.noSponsor}
                      options={sponsors}
                      value={inputs.sponsors && inputs.sponsors['hospital']}
                      name="hospital"
                    />
                  </Box>
                  <Box alignSelf="center" width={1 / 2} m={2}>
                    <Button variant="secondary" onClick={() => setIsSponsorModalOpen(true)}>
                      {labels.upload}
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </CardSection>
        </Card>
      </Box>
    </AdminLayout>
  )
}

export default PageEditTemplateJourney
