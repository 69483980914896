import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

export type UpdateJourneyArgs = {
  journeyId: string
  payload: any
}

const updateCrayonJourney = ({ journeyId, payload }: UpdateJourneyArgs): Promise<any> => {
  ReactGA.event({
    category: 'Crayon-Journey',
    action: 'Update crayon journey',
  })

  return Firebase.firestore()
    .collection('crayonJourneys')
    .doc(journeyId)
    .update(prepareDocForUpdate(payload))
    .then(() => payload)
    .catch((error) => {
      alert(`Whoops, couldn't update the journey for crayon: ${error.message}`)
    })
}

export default updateCrayonJourney
