import Firebase from 'firebase/app'
import getJourney from './getJourney'
import getJourneyActivities from './getJourneyActivities'
import { getJourneyState } from '../selectors'

export type ToggleDisplayAllArgs = {
  journeyId: string
  value: boolean
}

/**
 * Will update the visibility mode of list a of activities.
 * @param activitiesList array of ids representing activities
 * @param value boolean reprensenting wether to display all piccles or not
 * @param journeyId id of the journey under which the activities are located
 */
const batchSetDisplayAll = (activitiesList: string[], value, journeyId) => {
  const batch = Firebase.firestore().batch()

  activitiesList.forEach((activity) => {
    const activityRef = Firebase.firestore()
      .collection(`journeys/${journeyId}/activities`)
      .doc(activity)

    batch.set(activityRef, { displayAll: value }, { merge: true })
  })

  return batch.commit()
}

/**
 * returns an ordered list of activities based on the activities sequence
 * @param journeyData document data of a journey
 * @param activities list of documente data of activities
 */
const orderActivities = (journeyData, activities) => {
  return journeyData.sequence.map((id: string) => ({
    id,
    ...activities.find((acivity) => acivity.id === id),
  }))
}

/**
 * returns a list of activities representing the current and all future activities
 * @param journeyData document data of a journey
 * @param currentActivity string representing the id of a activity
 */
const getActivitiesToUpdate = (journeyData, currentActivity): string[] => {
  return journeyData.sequence.slice(journeyData.sequence.indexOf(currentActivity))
}

const toggleDisplayAll = async ({ journeyId, value }: ToggleDisplayAllArgs): Promise<any> => {
  try {
    // grab journey data to have the sequence of activities
    const journeyData = await getJourney({ journeyId })
    // The status of each activity (ended, started etc) is not tracked on the journey. grab the activities
    const journeyActivities = await getJourneyActivities({ journeyId })
    // order them based on the sequence
    const orderedActivities = orderActivities(journeyData, journeyActivities)
    // construct the state the like it is consumed in the projection screen
    const state = getJourneyState(orderedActivities)

    // Nothing to update if we are done
    if (state.status === 'terminal') {
      return
    }

    // Pregame means all activities are affected
    if (state.status === 'pregame') {
      return batchSetDisplayAll(journeyData.sequence, value, journeyId)
    }

    if (state.status === 'limbo') {
      const activitiestoUpdate = getActivitiesToUpdate(journeyData, state.nextActivity.id)
      return batchSetDisplayAll(activitiestoUpdate, value, journeyId)
    }

    if (state.status === 'active') {
      const activitiesToUpdate = getActivitiesToUpdate(journeyData, state.currentActivity.id)
      return batchSetDisplayAll(activitiesToUpdate, value, journeyId)
    }
  } catch (err) {
    console.error(err)
  }
}

export default toggleDisplayAll
