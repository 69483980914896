import React, { useEffect } from 'react'
import { AdminNavbar } from 'components'
import { Box } from 'rebass'
import { CompanyData } from 'api/companies/getCompany'
import picclesApi, { PicclesApiObject } from 'api'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { Loading } from 'components/atoms/Loading'

type Props = {
  children?: React.ReactNode
}

export const AdminLayout: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const [company, setCompany] = React.useState<CompanyData>(null)
  const [loading, setLoading] = React.useState<boolean>(true)
  const {
    user: { email },
  }: AuthenticationContextFields = useAuth()

  const { companyApi }: PicclesApiObject = picclesApi()

  useEffect(() => {
    companyApi
      .getCompanyByUser(email)
      .then(setCompany)
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  return (
    <Box bg="muted" width="100vw" sx={{ minHeight: '100vh' }}>
      <AdminNavbar loading={loading} company={company} />
      {company && (
        <>
          <Box mb={3} />
          <Box px={[2, 8, '13%']}>{children}</Box>
        </>
      )}
      {!company && <Loading />}
    </Box>
  )
}
