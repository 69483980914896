import { useState, useEffect } from 'react'
import getPiccleData from 'api/crayon/getPiccleData'
import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { canvasObjConstantProperties } from 'appConstants'

type Args = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

export const usePiccleDataOnce = ({ journeyCollectionPath, journeyId, piccleId }: Args) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getPiccleData({ journeyCollectionPath, journeyId, piccleId, setData, setLoading, setError })
  }, [piccleId]) // eslint-disable-line
  return [data, loading, error]
}

type Args2 = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

const usePiccleData = ({ journeyCollectionPath, journeyId, piccleId }: Args2) => {
  let [data, loading, error]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(journeyCollectionPath)
      .doc(journeyId)
      .collection('piccles')
      .doc(piccleId)
      .collection('strokes')
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )
  // Rehydrate the cavasObjects
  if (data) {
    data = data.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))
  }
  return [data, loading, error]
}

export default usePiccleData
