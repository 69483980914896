import React, { Fragment } from 'react'
import { Flex } from '@lightspeed/flame/Core'
import CrayonProjectionGridContainer from './CrayonProjectionGrid/CrayonProjectionGridContainer'

type CrayonProjectionProps = {
  journeyCollectionPath: string
  questionId: string
  journeyId: string
  isModeration?: boolean
  handleOnSelect?: (piccleId: string) => void
}

export const CrayonProjection = ({
  journeyCollectionPath,
  questionId,
  journeyId,
  isModeration,
  handleOnSelect,
}: CrayonProjectionProps) => {
  return (
    <Fragment>
      <Flex
        bg="background"
        justifyContent="center"
        backgroundColor="white"
        flex={1}
        width="100%"
        flexDirection="column"
      >
        <CrayonProjectionGridContainer
          journeyCollectionPath={journeyCollectionPath}
          journeyId={journeyId}
          questionId={questionId}
          isModeration={isModeration}
          onClick={handleOnSelect}
        />
      </Flex>
    </Fragment>
  )
}
