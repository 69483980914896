import React, { ReactElement, FunctionComponent } from 'react'
import { Flex, Box } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Text } from '@lightspeed/flame/Text'
import { DashboardHeader } from 'components/molecules/DashboardHeader'
import { SelectDropdown } from 'components'
import useTranslations from 'hooks/useTranslations'

const translations = {
  en: {
    journeys: 'My Piccles',
    create: 'Create New',
    sort: 'Sort By',
  },
  fr: {
    journeys: 'Mes Piccles',
    create: 'Nouveau',
    sort: 'Arranger Selon',
  },
}

type Translations = typeof translations.en

type Props = {
  children?: React.ReactNode
  onClickCreate: () => void
  sortingOptions: { label: string; id: string }[]
  sortBy: string
  onChangeSort: (option) => void
}

export const JourneyList: FunctionComponent<Props> = ({
  children,
  onClickCreate,
  sortBy,
  onChangeSort,
  sortingOptions,
}: Props): ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)

  return (
    <Box>
      <DashboardHeader title={labels.journeys}>
        <Flex ml="auto" sx={{ minWidth: 'max-content' }} alignItems="center">
          <Text style={{ minWidth: 'max-content' }} pr={2} color="white">
            {labels.sort}
          </Text>
          <SelectDropdown options={sortingOptions} onChange={onChangeSort} value={sortBy} name="sortBy" />
        </Flex>
      </DashboardHeader>

      <Box my={1}>
        <Card>
          <CardSection mx={-2} p={2} large>
            <Flex width="100%" alignItems="center" justifyContent="center">
              <Button variant="primary" fill p={1} onClick={onClickCreate} style={{ minWidth: 'max-content' }}>
                {labels.create}
              </Button>
            </Flex>
          </CardSection>
        </Card>
      </Box>

      <Flex flexDirection="column" pt={2}>
        {children}
      </Flex>
    </Box>
  )
}
