/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box } from 'rebass'

type Props = {
  brushSize: number
  onChange: (arg) => void
  color: string
}

const getSliderStyles = (color) =>
  `
  height: 26px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
  }

  &::-webkit-slider-thumb {
    -webkit-box-shadow: 0px 0px 2px #000000, white 0px 0px 0px 2px inset;
    box-shadow: 0px 0px 2px #000000, white 0px 0px 0px 2px inset;
    border: 0px solid #000000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: ${color};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8.5px;
  }

  &::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 0px 2px #000000, white 0px 0px 0px 2px inset;
    border: 0px solid #000000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: ${color};
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: transparent;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-fill-upper {
    background: transparent;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }

  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 2px #000000, white 0px 0px 0px 2px inset;
    border: 0px solid #000000;
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background: ${color};
    cursor: pointer;
  }

  &:focus::-ms-fill-lower {
    background: transparent;
  }

  &:focus::-ms-fill-upper {
    background: transparent;
  }
`

export const BrushSizeSlider = ({ brushSize, onChange, color }: Props) => {
  const handleOnChange = (e) => {
    const width = parseInt(e.target.value, 10)
    onChange(width)
  }

  return (
    <Box
      data-testid="BrushSizeSlider"
      css={css`
        height: 40px;
        width: 100%;
        position: relative;
      `}
    >
      <input
        css={css`
          ${getSliderStyles(color)}
          z-index: 3;
          position: relative;
          padding-top: 2px;
        `}
        type="range"
        step={2}
        min={4}
        max={70}
        value={brushSize}
        onChange={handleOnChange}
      />
      <div
        css={css`
          position: absolute;
          top: 10px;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 88vw 10px 0;
          border-color: transparent ${color} transparent transparent;
          z-index: 2;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 8px;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12px 88vw 12px 1px;
          border-color: transparent rgba(0, 0, 0, 0.1) transparent transparent;
          z-index: 1;
        `}
      />
    </Box>
  )
}
