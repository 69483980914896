import React from 'react'

import { MosaicPiccleGridContainer as MosaicPiccleGrid } from 'components'
import { Heading2 } from '@lightspeed/flame/Text'
import { Box } from 'rebass'

type Props = {
  handleClickToDraw: (arg) => void
  activityId: string
  journeyId: string
  labels: {
    instructions: string
  }
}

const ClientMosaicActivity = ({ handleClickToDraw, activityId, journeyId, labels }: Props) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box width="100%">
        <MosaicPiccleGrid journeyId={journeyId} activityId={activityId} onSelect={handleClickToDraw} />
      </Box>
      <Heading2>{labels.instructions}</Heading2>
    </Box>
  )
}

export default ClientMosaicActivity
