import React from 'react'
import { useJourneyData } from 'hooks/useJourneyData'
import { Loading, Error, NotAccessible } from 'components'
import { Data } from 'Pages/Client/PageProjectionView/PageProjectionViewFree'
import Firebase from 'firebase/app'
import { AsynchronousProjection } from './AsynchronousProjection'

type AsyncProjectionProps = {
  journeyId: string
  activityId: string
}

export const AsynchronousProjectionViewContainer = ({ journeyId, activityId }: AsyncProjectionProps) => {
  const [journeyData, journeyLoading, journeyError]: [Data, boolean, Error] = useJourneyData({
    journeyId: journeyId,
  })

  const [activity, setActivity] = React.useState(null)

  // need to keep backwards compatibility so we place a default value here.
  const { accessible = true } = journeyData || {}

  const getActivity = async () => {
    try {
      const activityDocument = await Firebase.firestore()
        .collection(`journeys/${journeyId}/activities`)
        .doc(activityId)
        .get()

      return { ...activityDocument.data(), id: activityDocument.id }
    } catch (error) {
      console.log(error)
    }
  }

  const loading = journeyLoading
  const error = journeyError

  React.useEffect(() => {
    getActivity().then(setActivity)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!accessible && <NotAccessible />}
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journeyData && accessible && (
        <AsynchronousProjection journey={journeyData} journeyId={journeyId} activity={activity} />
      )}
    </>
  )
}
