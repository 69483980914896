import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import generateRandomCode from '../helpers/uniqueCode'
import { prepareDocForCreate, verifyUniqueCode } from '../helpers/firestoreHelpers'

const createCrayonJourney = async (data) => {
  ReactGA.event({
    category: 'Crayon-Journey',
    action: 'create Crayon journey',
  })

  if (data.title) {
    data.slug = slugify(data.title, { lower: true })
  } else {
    data.title = ''
  }

  // try a first code
  let code = generateRandomCode(5)

  // regenerate code until it is unique
  while (!(await verifyUniqueCode(code))) {
    code = generateRandomCode(5)
  }

  // code is unique, assign it to creation
  data.code = code
  data.isDraft = true

  data.isDeleted = false
  data.colors = []

  return Firebase.firestore()
    .collection('crayonJourneys')
    .add(prepareDocForCreate(data))
    .then((res) => res.id)
    .catch((error) => {
      alert(`Whoops, couldn't create the journey for crayon: ${error.message}`)
    })
}

export default createCrayonJourney
