import React, { CSSProperties } from 'react'
import { Card } from '@lightspeed/flame/Card'
import usePiccleSVG from 'hooks/crayon/usePiccleSVG'
import { Error } from 'components'
import PiccleCanvas from './CrayonPiccleCanvas'

type Props = {
  height?: number
  width?: number
  style?: CSSProperties
  journeyCollectionPath: string
  piccleId: string
  journeyId: string
}

export const CrayonPiccleCanvasContainer: React.FunctionComponent<Props> = ({
  journeyCollectionPath,
  piccleId,
  journeyId,
  height,
  width,
}: Props) => {
  const [svg, loading, error] = usePiccleSVG({ journeyCollectionPath, journeyId, piccleId, height, width })

  return (
    <>
      {error && !loading && <Error error={error} />}
      {svg && (
        <Card style={{ margin: 1, borderRadius: 10 }}>
          <PiccleCanvas height={height} width={width} svg={svg} />
        </Card>
      )}
    </>
  )
}
