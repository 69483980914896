import { Error, Loading } from 'components'
import { Question, SponsorsData, TemplateJourney } from 'types'

import PageCrayonJourneyEnd from './PageCrayonJourneyEnd'
import React from 'react'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import { useSponsorsData } from 'hooks/crayon/useSponsorsData'
import useRandomQuestion from 'hooks/crayon/useRandomQuestion'
import { CrayonTranslationObjectType } from 'types'

type Props = {
  journeyCollectionPath: string
  category: string
  templateId: string
  journeyId: string
  language: string
}

const translations: CrayonTranslationObjectType = {
  en: {
    weldone: 'Well Done!',
    startOver: 'Start Over',
    forMore: 'For more fun ideas,',
    visit: 'visit',
    poweredBy: 'Powered by Piccles',
  },
  es: {
    weldone: 'Well Done!',
    startOver: '¡Comienza de nuevo!',
    forMore: 'Para más ideas divertidas,',
    visit: 'visitas',
    poweredBy: 'Energizado por Piccles',
  },
}

const PageCrayonJourneyEndContainer: React.FunctionComponent<any> = ({
  journeyCollectionPath,
  category,
  templateId,
  journeyId,
  language,
}: Props): JSX.Element => {
  const labels = translations[language]
  const [journey, journeyLoading, journeyError]: [TemplateJourney, boolean, Error] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  const [randomQuestion, randomQuestionLoading, randomQuestionError]: [Question, boolean, Error] = useRandomQuestion({
    templateId,
    category,
  })

  const [sponsors, sponsorsLoading, sponsorsError]: [SponsorsData, boolean, Error] = useSponsorsData({
    journeyCollectionPath,
    journeyId,
  })

  return (
    <>
      {journeyError && randomQuestionError && sponsorsError && <Error error={journeyError} />}
      {journeyLoading && randomQuestionLoading && sponsorsLoading && <Loading />}
      {journey && randomQuestion && (
        <PageCrayonJourneyEnd
          labels={labels}
          closingImage={journey.closingImageUrl}
          closingMessage={journey.closingMessage}
          journeyCollectionPath={journeyCollectionPath}
          randomQuestion={randomQuestion}
          templateId={templateId}
          journeyId={journeyId}
          language={language}
          sponsors={sponsors}
        />
      )}
    </>
  )
}

export default PageCrayonJourneyEndContainer
