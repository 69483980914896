import { Error, Loading } from 'components'
import { Question, SponsorsData } from 'types'

import PageCrayonDrawingShare from './PageCrayonDrawingShare'
import React from 'react'
import useQuestion from 'hooks/crayon/useQuestion'
import useRandomQuestion from 'hooks/crayon/useRandomQuestion'
import { useSponsorsData } from 'hooks/crayon/useSponsorsData'
import { CrayonTranslationObjectType } from 'types'

type Props = {
  journeyCollectionPath: string
  templateId: string
  journeyId: string
  currentQuestionIndex: string
  questionId: string
  piccleId: string
  category: string
  language: string
}

const translations: CrayonTranslationObjectType = {
  en: {
    next: 'Next',
    again: 'Again',
    finish: 'Finish',
    poweredBy: 'Powered by Piccles',
  },
  es: {
    next: 'Proximo',
    again: 'Otra vez',
    finish: 'Hecho',
    poweredBy: 'Energizado por Piccles',
  },
}

const PageCrayonDrawingShareContainer: React.FC<any> = ({
  journeyCollectionPath,
  templateId,
  journeyId,
  currentQuestionIndex,
  questionId,
  piccleId,
  category,
  language,
}: Props) => {
  const labels = translations[language]

  const [question, questionLoading, questionError]: [Question, boolean, Error] = useQuestion({
    templateId,
    questionId,
  })

  const [randomQuestion, randomQuestionLoading, randomquestionError]: [Question, boolean, Error] = useRandomQuestion({
    templateId,
    category,
  })

  const [sponsors, sponsorsLoading, sponsorsError]: [SponsorsData, boolean, Error] = useSponsorsData({
    journeyCollectionPath,
    journeyId,
  })

  return (
    <>
      {questionError && randomquestionError && sponsorsError && <Error />}
      {questionLoading && randomQuestionLoading && sponsorsLoading && <Loading />}
      {question && (
        <PageCrayonDrawingShare
          labels={labels}
          journeyCollectionPath={journeyCollectionPath}
          templateId={templateId}
          journeyId={journeyId}
          category={category}
          question={question}
          randomQuestion={randomQuestion}
          currentQuestionIndex={currentQuestionIndex}
          piccleId={piccleId}
          language={language}
          sponsors={sponsors}
        />
      )}
    </>
  )
}

export default PageCrayonDrawingShareContainer
