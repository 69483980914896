import Firebase from 'firebase/app'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'

type Args = {
  journeyCode: string
}

/**
 * Returns the journeyId in the form of a 3-tuple hook based on the journey code
 *
 * @param journeyCode The journey code
 */
export default ({ journeyCode }: Args): [string, boolean, Error] => {
  const [data, loading, error]: [any, boolean, Error] = useCollectionDataOnce(
    Firebase.firestore()
      .collection('journeys')
      .where('code', '==', journeyCode),
    { idField: 'id' },
  )
  return [data ? data[0]?.id : undefined, loading, error]
}
