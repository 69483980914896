import Firebase from 'firebase/app'

type IsPiccleDoneArgs = {
  piccleId: string
  activityId: string
  journeyId: string
}

/**
 * returns a boolean indicating is a user has submitted his piccle.
 * @param IsPiccleDoneArgs: { piccleId: string, activityId: string, journeyId: string }
 * @returns boolean
 */
export const isPiccleDone = async ({ piccleId, activityId, journeyId }: IsPiccleDoneArgs): Promise<boolean> => {
  const piccle = await Firebase.firestore()
    .doc(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}`)
    .get()

  return piccle.data().done ?? false
}
