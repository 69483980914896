import React, { ReactElement, FunctionComponent } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'

import { Loading, Error } from 'components'
import { getUserId } from 'api/helpers/firestoreHelpers'
import { TemplateJourneyList } from './TemplateJourneyList'
import { Template } from 'types'

const TemplateJourneyListContainer: FunctionComponent<{}> = (): ReactElement => {
  const userUid: string = getUserId()

  // fetch templetes that the user is allowed to access.
  // allowed if the current authenticated user's id is in the `users` property
  const [templatesData, templatesLoading, templatesError]: [Template[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('templates')
      .where('users', 'array-contains', userUid),
    { idField: 'id' },
  )

  return (
    <>
      {templatesLoading && <Loading />}
      {templatesError && <Error error={templatesError} />}
      {templatesData &&
        templatesData.map((template: Template) => {
          return <TemplateJourneyList key={template.id} template={template} />
        })}
    </>
  )
}
export default TemplateJourneyListContainer
