/** @jsx jsx */
import { memo, useEffect } from 'react'
import { jsx } from '@emotion/core'
import { PiccleCanvasContainer as PiccleCanvas } from 'components'
import { useInView } from 'react-intersection-observer'

type Props = {
  journeyId: string
  activityId: string
  piccleIds?: string[]
  loadMorePiccles?: () => Promise<void>
  isModeration?: boolean
}

const ProjectionViewGrid: React.FC<Props> = ({ journeyId, piccleIds, activityId, loadMorePiccles }) => {
  const [ref, inView] = useInView()

  const calculatePiccleSize = () => {
    if (piccleIds.length <= 2) return 350
    if (piccleIds.length <= 4) return 325
    if (piccleIds.length <= 6) return 250
    if (piccleIds.length <= 12) return 200
    if (piccleIds.length <= 16) return 150
    if (piccleIds.length <= 28) return 115
    if (piccleIds.length <= 45) return 90
    return 90
  }

  useEffect(() => {
    if (inView) {
      loadMorePiccles()
    } // eslint-disable-next-line
  }, [inView])

  return (
    <div className="async-projection" data-testid="AsynchronousProjectionViewGrid">
      {piccleIds.map((piccleId) => (
        <PiccleCanvas
          key={piccleId}
          journeyId={journeyId}
          activityId={activityId}
          piccleId={piccleId}
          height={calculatePiccleSize()}
          width={calculatePiccleSize()}
        />
      ))}
      <div ref={ref}>.</div>
    </div>
  )
}

export default memo(ProjectionViewGrid)
