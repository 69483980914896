// Constants for the status of a journey
/** @jsx jsx */
import { keyframes } from '@emotion/core'

export const PREGAME = 'pregame'
export const ACTIVE = 'active'
export const LIMBO = 'limbo'
export const TERMINAL = 'terminal'

// Types of activities
export const PROMPT_ACTIVITY = 'prompt'
export const MOSAIC_ACTIVITY = 'mosaic'

export const validEmailRegex = /^(([^<>()[\],;:\s@"/]+(\.[^<>()\\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * These properties are constant for all cavnas objects.
 * We extract them from the canvas objects prior to sending them object to firebase.
 * We then rehydrate the objects with this data prior to loading into cavnas.
 * This helps to make the canvas object smaller and therefore lowers bandwidth use per request.
 * With millions of strokes, every byte counts.
 */
export const canvasObjConstantProperties = {
  angle: 0,
  fill: null,
  fillRule: 'nonzero',
  flipX: false,
  flipY: false,
  globalCompositeOperation: 'source-over',
  opacity: 1,
  originX: 'left',
  originY: 'top',
  paintFirst: 'fill',
  scaleX: 1,
  scaleY: 1,
  skewX: 0,
  skewY: 0,
  strokeDashOffset: 0,
  strokeLineCap: 'round',
  strokeLineJoin: 'round',
  strokeMiterLimit: 10,
  type: 'path',
  version: '2.7.0',
  visible: true,
  selectable: false,
}

// Styles applied to ClientNavBar depending on the page

export const headerLarge = {
  height: '3.5rem',
  align: 'flex-end',
  marginTop: '35px',
}

export const headerSmall = {
  height: '2rem',
  align: 'center',
  marginTop: '4px',
}

export const flipStyles = {
  drawShow: {
    zIndex: 0,
    opacity: 1,
    transition: 'opacity 1.5s ease',
  },
  drawHide: {
    opacity: -1,
    transition: 'opacity 0.5s ease',
  },
  writeShow: {
    zIndex: 1,
    opacity: 1,
    transition: 'opacity 0.5s ease',
  },
  writehide: {
    zIndex: -1,
    opacity: 0,
  },
}

export const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(0, -15px, 0);
  }

  70% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0,-4px,0);
  }
`

export const flip = keyframes`
  0% {
        transform:  rotateY(180deg);
    }
    50% {
        transform:  rotateY(120deg);
    }
    100% {
        transform: rotateY(0deg);
    }
  `
