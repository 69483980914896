import picclesApi from 'api'
import { JourneyState } from 'hooks/useJourneyData'
import restartJourney from 'api/journeys/restartJourney'

export type MoveForwardJourneyActivity = {
  journeyId: string
  state: JourneyState
  firstActivityId?: string
}

const moveForwardJourneyActivity = async ({
  journeyId,
  state,
  firstActivityId,
}: MoveForwardJourneyActivity): Promise<any> => {
  try {
    const { activityApi } = picclesApi()
    if (state.currentActivity && state.nextActivity) {
      await activityApi.endActivity({ journeyId, activityId: state.currentActivity.id })
      await activityApi.startActivity({ journeyId, activityId: state.nextActivity.id })
    } else if (!state.nextActivity) {
      //terminate the last activity
      await activityApi.endActivity({ journeyId, activityId: state.currentActivity.id })
      //activate the first activity in a sequence arr after restarting a journey
      await restartJourney({ journeyId })
      await activityApi.startActivity({ journeyId, activityId: firstActivityId })
    }
  } catch (error) {
    console.log(error)
  }
}

export default moveForwardJourneyActivity
