import React from 'react'
import Firebase from 'firebase/app'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import useTranslations from 'hooks/useTranslations'
import picclesApi from '../../../api'
import createSponsor from 'api/journeys/createSponsor'
import { verifyUniqueCodeForJourney } from 'api/helpers/firestoreHelpers'
import { Error, Loading } from 'components'
import PageEditJourney, { Data } from './PageEditJourney'
import { getUserId } from 'api/helpers/firestoreHelpers'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'hooks/useNavigate'
import { useTranslation } from 'react-i18next'

const translations = {
  en: {
    editJourney: 'Edit Journey',
    editActivity: 'Edit Activity',
    done: 'Done',
    deleteJourney: (userType) => `Delete this Piccle ${userType === 'free' ? 'Activity' : 'Journey'}?`,
  },
  fr: {
    editJourney: 'Modifier la Journey',
    editActivity: "Modifier l'activité",
    done: 'Fini',
    deleteJourney: (userType) => `Supprimer ${userType === 'free' ? "l'Activité Piccle" : 'la Piccle Journey'}?`,
  },
}

const PageEditJourneyContainer = (props) => {
  const navigate = useNavigate()
  const { journeyApi, activityApi } = picclesApi()
  const userUid = getUserId()
  const labels = useTranslations<typeof translations.en>(translations)
  const { userType } = useAuth()
  const { t } = useTranslation()

  // Journey Data fetch
  const [journeyData, journeyLoading, journeyError]: [Data, boolean, Error] = useDocumentData(
    Firebase.firestore().doc(`journeys/${props.journeyId}`),
  )
  // Activity data fetch
  const [activityData, activityLoading, activityError]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .doc(props.journeyId)
      .collection('activities'),
    {
      idField: 'id',
    },
  )
  // Sponsors data fetch
  const [sponsorsData, sponsorsLoading, sponsorsError]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(`sponsors`)
      .where('createdBy', '==', userUid)
      .orderBy('createdOn', 'desc'),
    {
      idField: 'id',
    },
  )

  // HANDLERS
  const handleCreateActivity = async (activityData) => {
    return await activityApi.createActivity({
      journeyId: props.journeyId,
      payload: activityData,
      sequence: journeyData.sequence,
    })
  }
  const handleUpdateJourney = (payload) => {
    return journeyApi.updateJourney({ journeyId: props.journeyId, payload })
  }
  const handleUpdateActivity = async (activityData) => {
    return activityApi.updateActivity({
      journeyId: props.journeyId,
      activityId: activityData.id,
      payload: activityData,
    })
  }
  const handleDeleteActivity = async (activityId: string, sequence: string[]) => {
    await activityApi.deleteActivity({ journeyId: props.journeyId, activityId, sequence })
  }
  const handleCreateSponsor = async (sponsorData) => {
    return createSponsor(sponsorData)
  }
  const handleValidateUniqueCode = async (code: string) => {
    return verifyUniqueCodeForJourney(code)
  }
  const handleDeleteJourney = async () => {
    const confirmDelete = window.confirm(labels.deleteJourney(userType))
    if (confirmDelete === true) {
      try {
        await journeyApi.deleteJourney({ journeyId: props.journeyId })
        navigate('/admin')
      } catch (e) {
        alert(e.message)
        console.error(e)
      }
    }
  }

  // Merge all Loading and Error
  const loading = journeyLoading || activityLoading || sponsorsLoading
  const error = journeyError || activityError || sponsorsError

  return (
    <DndProvider backend={HTML5Backend}>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journeyData && activityData && sponsorsData && (
        <PageEditJourney
          labels={t('pageEditJourneyContainer')}
          journeyData={journeyData}
          activitiesData={activityData}
          sponsorsData={sponsorsData}
          handleValidateUniqueCode={handleValidateUniqueCode}
          handleCreateActivity={handleCreateActivity}
          handleUpdateActivity={handleUpdateActivity}
          handleUpdateJourney={handleUpdateJourney}
          handleDeleteActivity={handleDeleteActivity}
          handleCreateSponsor={handleCreateSponsor}
          handleDeleteJourney={handleDeleteJourney}
        />
      )}
    </DndProvider>
  )
}

export default PageEditJourneyContainer
