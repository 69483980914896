import { CrayonText } from 'components'
import { Dropdown } from '@lightspeed/flame/Dropdown'
import { Flex } from 'rebass'
import React from 'react'
/** @jsx jsx */
import { jsx } from '@emotion/core'

type Props = {
  name: string
  items: string[]
  onItemClicked: (item: string) => void
}

export const CrayonDropDown: React.FunctionComponent<Props> = ({ name, items, onItemClicked }: Props) => {
  return (
    <Dropdown
      buttonContent={name}
      placement="start"
      style={{
        width: 260,
        height: 75,
        fontSize: 22,
        fontFamily: 'RivalSlabRegular',
        backgroundColor: 'white',
        borderColor: '#b3b3b3',
        borderWidth: 2,
        borderRadius: 20,
      }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        css={{
          width: 260,
          backgroundColor: '#e6e6e6',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {items.map((item) => (
          <Flex
            key={item}
            onClick={() => onItemClicked(item)}
            css={{
              height: 70,
              borderWidth: 0,
              width: 260,
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'white',
              },
            }}
          >
            <CrayonText
              text={item}
              styles={{
                fontSize: '22',
                textAlign: 'center',
              }}
            />
          </Flex>
        ))}
      </Flex>
    </Dropdown>
  )
}
