import Firebase from 'firebase/app'
import 'firebase/storage'
import ReactGA from 'react-ga'

const uploadImage = async (file): Promise<any> => {
  ReactGA.event({
    category: 'Upload',
    action: 'upload image',
  })
  const storageRef: Firebase.storage.Reference = await Firebase.storage().ref()
  const imageRef: Firebase.storage.Reference = storageRef.child(`${file.name}-${new Date().valueOf()}`)

  try {
    const snapshot: Firebase.storage.UploadTaskSnapshot = await imageRef.put(file, { contentType: file.type })
    return snapshot.ref.getDownloadURL()
  } catch (error) {
    return console.log('error', error)
  }
}

export default uploadImage

//add BASE64 image string
// @ts-ignore
export const uploadImageString = async (fileName, journeyId, activityId, dataUrl): Promise<void> => {
  ReactGA.event({
    category: 'Upload',
    action: 'upload image',
  })
  const storageRef: Firebase.storage.Reference = await Firebase.storage().ref()
  const imageRef: Firebase.storage.Reference = storageRef.child(
    `piccles/${journeyId}/${activityId}/${fileName}-${new Date().valueOf()}`,
  )

  try {
    const snapshot: Firebase.storage.UploadTaskSnapshot = await imageRef.putString(dataUrl, 'data_url')
    return snapshot.ref.getDownloadURL()
  } catch (error) {
    return console.log('error', error)
  }
}
