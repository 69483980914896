import React from 'react'
import { useParams } from '@reach/router'
import get from 'lodash.get'

// components
import { Error, Loading } from 'components'
import PageJourneyStatus from './PageJourneyStatus'

// contants and types
import { PROMPT_ACTIVITY } from 'appConstants'
import { JourneyType } from '../../../types'

// hooks
import { useJourneyData, useJourneyState } from 'hooks/useJourneyData'
import useTranslations from 'hooks/useTranslations'

// api actions
import createPiccle from 'api/piccles/createPiccle'
import { getJourneyIdFromCode } from 'api/journeys/getJourneyIdFromCode'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    draw: 'Draw',
    instructions: 'Select a square to draw',
  },
  fr: {
    draw: 'Dessinez',
    instructions: 'Sélectionnez un carré à dessiner',
  },
}

const PageJourneyStatusContainer: React.FC = () => {
  const labels = useTranslations<typeof translations.en>(translations)
  const navigate = useNavigate()
  // grabing journeyid from the code
  const { journeyCode } = useParams()
  const journeyCodeUppercase = journeyCode.toUpperCase()

  const [journeyId, setJourneyId] = React.useState<string>(null)

  // fetch journeyState and journey doc
  const [data, stateLoading, stateError] = useJourneyState({ journeyId })
  const [journeyDoc, journeyLoading, journeyError] = useJourneyData({ journeyId })

  const loading = stateLoading || journeyLoading
  const error = stateError || journeyError
  const isActivePromptActivity = data && get(data.currentActivity, 'type') === PROMPT_ACTIVITY

  React.useEffect(() => {
    getJourneyIdFromCode(journeyCodeUppercase).then(setJourneyId)
  }, [journeyCodeUppercase])

  React.useEffect(() => {
    if (isActivePromptActivity) {
      handleGoToDrawPrompt()
    }
    // eslint-disable-next-line
  }, [isActivePromptActivity])

  const handleGoToDrawPrompt = async () => {
    const activityId = data.currentActivity.id
    const piccleId = await createPiccle({ journeyId, activityId })
    setTimeout(() => navigate(`/${journeyCodeUppercase}/${activityId}/${piccleId}`), 1000)
  }

  /**
   * handles going to the piccle page from the inactive layout. Needs refactor
   */
  const handleGoToAsyncFirstPiccle = async (): Promise<void> => {
    if (journeyDoc?.type === JourneyType.ASYNCHRONOUS) {
      // Else we go to draw page
      const activityId = journeyDoc.sequence.shift()
      const goToDrawPrompt = async () => {
        try {
          const piccleId = await createPiccle({ journeyId, activityId })
          return piccleId
        } catch (error) {
          console.log(error)
        }
      }
      // Go to the first activity of the async journey
      try {
        const piccleId = await goToDrawPrompt()
        navigate(`/${journeyCodeUppercase}/${journeyId}/${activityId}/${piccleId}`, { replace: true })
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      {error && <Error error={error} />}
      {(loading || !journeyDoc) && <Loading />}
      {!loading && !error && data && !isActivePromptActivity && (
        <PageJourneyStatus
          asyncJourney={journeyDoc?.['type'] === JourneyType.ASYNCHRONOUS}
          journeyId={journeyId}
          journeyDoc={journeyDoc}
          data={data}
          goToFirstPiccle={handleGoToAsyncFirstPiccle}
          journeyCode={journeyCodeUppercase}
          labels={labels}
        />
      )}
    </>
  )
}

export default PageJourneyStatusContainer
