import Firebase from 'firebase/app'
import { canvasObjConstantProperties } from 'appConstants'

type Args = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
  setError: (arg) => void
  setData: (arg) => void
  setLoading: (arg) => void
}

const rehydrateCanvasObjects = (canvasObjects) =>
  canvasObjects.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))

const getPiccleData = async ({
  journeyCollectionPath,
  journeyId,
  piccleId,
  setError,
  setData,
  setLoading,
}: Args): Promise<void> => {
  setLoading(true)
  try {
    const data = rehydrateCanvasObjects(
      await Firebase.firestore()
        .collection(journeyCollectionPath)
        .doc(journeyId)
        .collection(`piccles/${piccleId}/strokes`)
        .orderBy('createdOn', 'asc')
        .get()
        .then((snap: Firebase.firestore.QuerySnapshot) => snap.docs)
        .then((docs: Firebase.firestore.QueryDocumentSnapshot[]) =>
          docs.map((doc: Firebase.firestore.QueryDocumentSnapshot): {
            id: string
          } => ({ ...doc.data(), id: doc.id })),
        ),
    )
    setData(data)
    setLoading(false)
  } catch (error) {
    setError(error)
  }
}

export default getPiccleData
