import { Question } from 'types'

export class QuestionMemoize {
  static readonly key = 'piccles_memoized_questions'

  static get(): { [key: string]: Question } {
    return JSON.parse(window.localStorage.getItem(this.key))
  }

  static getQuestion(id: string): Question {
    return JSON.parse(window.localStorage.getItem(this.key))[id] as Question
  }

  static set(question: Question): void {
    const temp = this.get()
    window.localStorage.setItem(this.key, JSON.stringify({ ...temp, [question.id]: question }))
  }

  static clear(): void {
    window.localStorage.removeItem(this.key)
  }
}
