import { Box, Image } from 'rebass'
import { CrayonButton, CrayonLayout, CrayonText } from 'components'
import { SponsorsData } from 'types'

import { Fragment } from 'react'
import React from 'react'
import baloonsImage from 'assets/images/crayon/baloons.png'
/** @jsx jsx */
import { jsx } from '@emotion/core'

type Props = {
  labels: {
    picked: string
    prompt: string
    start: string
    back: string
    poweredBy: string
  }
  category: string
  openingImage: string
  openingMessage: string
  goToFirstPiccle: () => Promise<void>
  handleGoBack: () => void
  language: string
  sponsors: SponsorsData
}

const PageCrayonJourneyStart: React.FunctionComponent<Props> = ({
  labels,
  category,
  openingImage,
  openingMessage,
  goToFirstPiccle,
  handleGoBack,
  sponsors = {},
}: Props): JSX.Element => {
  return (
    <CrayonLayout
      showHeader
      showFooter
      primaryLogo={sponsors['primarySponsor'] && sponsors['primarySponsor'].imgUrl}
      secondaryLogo={sponsors['secondarySponsor'] && sponsors['secondarySponsor'].imgUrl}
      footerText={labels.poweredBy}
    >
      <Fragment>
        {openingImage ? (
          <Image mt={25} src={openingImage} width="50%" />
        ) : (
          <Image mt={25} src={baloonsImage} height="200px" width="200" />
        )}
        <Box mt={25} mb={25}>
          <CrayonText text={labels.picked} />
        </Box>
        <CrayonText
          text={category.toLocaleLowerCase()}
          styles={{
            fontFamily: 'RivalSlab-Medium',
            fontSize: '25px',
          }}
        />
        <Box mt={25} mb={35}>
          <CrayonText text={openingMessage ? openingMessage : labels.prompt} />
        </Box>
        <CrayonButton onClick={() => goToFirstPiccle()}>
          <CrayonText
            text={labels.start}
            styles={{
              color: '#FFF',
              fontSize: '22px',
              fontWeight: 500,
            }}
          />
        </CrayonButton>
        <CrayonText
          text={labels.back}
          onClick={() => handleGoBack()}
          styles={{
            fontSize: 17,
            marginTop: 25,
          }}
        />
      </Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonJourneyStart
