import createActivity, { CreateActivityArguments } from './createActivity'
import deleteActivity, { DeleteActivityArgs } from './deleteActivity'
import endActivity, { EndActivityArgs } from './endActivity'
import startActivity, { StartActivityArgs } from './startActivity'
import updateActivity, { UpdateActivityArgs } from './updateActivity'
import getActivity, { GetActivityArgs } from './getActivity'
import getIsActivityEditable, { GetIsActivityEditableArgs } from './getIsActivityEditable'

export type ActivityActions = {
  createActivity: (createActivityArguments: CreateActivityArguments) => Promise<string>
  deleteActivity: (deleteActivityArguments: DeleteActivityArgs) => Promise<any>
  endActivity: (endActivityArguments: EndActivityArgs) => Promise<any>
  startActivity: (startActivityArguments: StartActivityArgs) => Promise<any>
  updateActivity: (updateActivityArguments: UpdateActivityArgs) => Promise<any>
  getActivity: (getActivityArgs: GetActivityArgs) => Promise<any>
  getIsActivityEditable: (getIsActivityEditableArgs: GetIsActivityEditableArgs) => Promise<boolean>
}

const activityActions: ActivityActions = {
  createActivity,
  deleteActivity,
  endActivity,
  startActivity,
  updateActivity,
  getActivity,
  getIsActivityEditable,
}

export default activityActions
