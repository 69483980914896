import React from 'react'
import { Box, Flex } from '@lightspeed/flame/Core'
import { Image } from 'rebass'
import { Heading3, Text } from '@lightspeed/flame/Text'
import { SubscriptionUi, EventType, Subscription } from './Plans'

type PlanCardProps = {
  subscription: SubscriptionUi
  isCurrentSubscription: boolean
  currentSubscription: Subscription
  period: EventType
  pickSubscription: () => void
}

const PlanCard = ({
  subscription,
  isCurrentSubscription,
  currentSubscription,
  period,
  pickSubscription,
}: PlanCardProps) => {
  const {
    name,
    image,
    description,
    participants,
    price,
    branding,
    data,
    support,
    btnText,
    btnSubText,
    color,
    btnTextColor,
  } = subscription

  const isRecurringSubscription: boolean = period === 'ANNUAL' && name !== Subscription.BIGDILL
  const cannotDowngrade: boolean =
    period === 'ANNUAL' &&
    name === Subscription.PRIME &&
    (currentSubscription === Subscription.PRO || currentSubscription === Subscription.BIGDILL)

  return (
    <Box
      width={[1, 1 / 2, 1 / 4]}
      marginBottom={[5, 5, 0]}
      paddingRight={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box style={{ width: '100%', border: `8px solid ${color}`, padding: 5 }}>
        <Box display="flex" width={1} flexDirection="row" justifyContent="space-between">
          <Box style={{ paddingTop: 2 }}>
            <Heading3 style={{ color: '#3A1256', letterSpacing: 8, fontWeight: 800 }}>{name}</Heading3>
            <Flex flexDirection="row" alignItems="center">
              <Heading3 style={{ color: '#3A1256', fontWeight: 800 }}>{price[period]}</Heading3>
              {isRecurringSubscription && (
                <Text fontWeight={800} fontSize="large" style={{ color: '#3A1256', marginLeft: 12 }}>
                  /Month
                </Text>
              )}
            </Flex>
            {isRecurringSubscription && <Text style={{ fontSize: 14, color: '#4F2A68' }}>(paid annually)</Text>}
          </Box>
          {image && <Image src={image} sx={{ posision: 'absolute', height: name === Subscription.PRO ? 75 : 50 }} />}
        </Box>
        <Text fontSize="small" style={{ fontWeight: 600, color: '#3A1256', marginTop: 16 }}>
          {description}
        </Text>

        <Text fontWeight={800} style={{ color: '#3A1256', marginTop: 16 }}>
          {participants}
        </Text>

        <Text fontSize="small" fontWeight={800} style={{ color: '#3A1256', marginTop: 16 }}>
          Branding
        </Text>
        <Text fontSize="small" style={{ color: '#4F2A68' }}>
          {branding}
        </Text>

        <Text fontSize="small" fontWeight={800} style={{ color: '#3A1256', marginTop: 16 }}>
          Data
        </Text>

        <Text fontSize="small" style={{ color: '#4F2A68' }}>
          {data}
        </Text>

        <Text fontSize="small" fontWeight={800} style={{ color: '#3A1256', marginTop: 16 }}>
          Support
        </Text>

        <Text fontSize="small" style={{ color: '#4F2A68' }}>
          {support}
        </Text>
      </Box>
      <button
        onClick={() => {
          if (name === Subscription.BIGDILL) {
            window.open('mailto:Hello@piccles.com')
          } else if (name !== Subscription.TASTER) {
            pickSubscription()
          }
        }}
        disabled={isCurrentSubscription || cannotDowngrade}
        style={{
          width: 150,
          height: 32,
          backgroundColor: color,
          padding: 5,
          color: btnTextColor,
          fontWeight: 700,
          border: 0,
          borderRadius: 5,
          marginTop: 20,
          textDecoration: 'none',
          cursor: name !== Subscription.TASTER && 'pointer',
        }}
      >
        {isCurrentSubscription ? 'Current Plan' : btnText}
      </button>
      <Text style={{ fontSize: 12, color: '#3A1256' }}>{btnSubText}</Text>
    </Box>
  )
}

export default PlanCard
