import Firebase from 'firebase/app'

export const getJourneyFields = async (journeyId: string): Promise<Firebase.firestore.DocumentData | null> => {
  try {
    return (
      (await Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .get()
        .then((journey: Firebase.firestore.DocumentSnapshot) => journey.data())) || null
    )
  } catch (error) {
    console.log('journeys.ts getJourneyFields error: ', error)
  }
}

export const getJourneyFromCode = async (code: string): Promise<Firebase.firestore.DocumentData | null> => {
  try {
    return (
      (await Firebase.firestore()
        .collection('journeys')
        .where('code', '==', code)
        .limit(1)
        .get()
        .then((journey: Firebase.firestore.QuerySnapshot) =>
          journey.docs
            .map((journey: Firebase.firestore.QueryDocumentSnapshot) => ({
              ...journey.data(),
              documentId: journey.id,
            }))
            .pop(),
        )) || null
    )
  } catch (error) {
    console.log('journeys.ts getJourneyFields error: ', error)
  }
}

export const setJourneyFields = async (journeyId: string, fields: any) => {
  try {
    await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .set({ ...fields }, { merge: true })
  } catch (error) {
    console.log('journeys.ts setJourneyFields error: ', error)
  }
}
