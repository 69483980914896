import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { Error } from 'components'
import PageWelcome from './PageWelcome'
import { TemplateJourney } from 'types'
import { useNavigate } from 'hooks/useNavigate'

const PageWelcomeContainer: React.FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [validation, setValidation] = useState('')

  const scanTemplateJourneysForCode = async (journeyCollectionPath: string, code: string): Promise<TemplateJourney> => {
    try {
      const templateJourneyCollection = await Firebase.firestore()
        .collection(journeyCollectionPath)
        .where('code', '==', code)
        .limit(1)
        .get()

      if (templateJourneyCollection.size > 0) {
        const journey = templateJourneyCollection.docs[0]
        return { ...journey.data(), id: journey.id } as TemplateJourney
      }
    } catch (error) {
      console.log('Error scanning journey collections', error)
    }
  }

  const handleGoToExperience = async (experienceCode: string): Promise<void> => {
    if (experienceCode.length === 0) {
      setValidation('noCodeEntered')
      return
    }

    try {
      const experienceCollection = await Firebase.firestore()
        .collection('journeys')
        .where('code', '==', experienceCode)
        .limit(1)
        .get()

      // some experiences do not have this property, do not remove for back wards compatibility
      if (experienceCollection.size <= 0) {
        // No code found in normal journeys. let's check templates!
        const templatesCollection = await Firebase.firestore()
          .collection('templates')
          .get()

        if (templatesCollection.size > 0) {
          templatesCollection.docs.forEach(async (doc) => {
            const journeyCollectionPath = doc.data().journeyCollectionPath
            const templateId = doc.id
            const journey: TemplateJourney = await scanTemplateJourneysForCode(journeyCollectionPath, experienceCode)
            if (journey) {
              navigate(`/t/${journeyCollectionPath}/${templateId}/${journey.id}`)
            } else {
              setValidation('codeNotFound')
            }
          })
        }
      } else {
        const { accessible = true } = experienceCollection.docs[0].data()
        if (accessible) {
          navigate(`/${experienceCode}`)
        } else {
          setValidation('codeNotFound')
        }
      }
    } catch (error) {
      setError(error)
    }
  }

  if (error) return <Error error={error} />

  return (
    <PageWelcome handleGoToExperience={handleGoToExperience} validation={validation} setValidation={setValidation} />
  )
}

export default PageWelcomeContainer
