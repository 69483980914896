/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex, Box } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Text, Heading3 } from '@lightspeed/flame/Text'
import { useBreakPoint } from 'hooks/useBreakPoint'
import useTranslations from 'hooks/useTranslations'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { markJourneyAsRecentlyEdited } from 'api/journeys/markJourneyAsRecentlyEdited'
import { openLinkInNewTab } from 'api/helpers/openLinkInNewTab'
import { downloadJourney } from 'api/journeys/downloadJourney'
import { Journey } from 'hooks/useJourneyData'
import { Activity } from 'Pages/Admin/PageEditJourney/PageEditJourney'
import { bounce } from 'appConstants'
import { useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'hooks/useNavigate'
import downloadJourneyParticipants from 'api/journeys/downloadJourneyParticipants'

const translations = {
  en: {
    edit: 'Edit',
    projection: 'Projection',
    download: 'Download',
    start: 'Start',
    moderation: 'Moderation',
    code: 'Code',
    error: 'Something went wrong.',
    downloadingMessage: 'This might take a while...',
  },
  fr: {
    edit: 'Modifier',
    projection: 'Projection',
    download: 'Télécharger',
    start: 'Commencer',
    moderation: 'Modération',
    code: 'Code',
    error: 'Something went wrong.',
    downloadingMessage: 'This might take a while...',
  },
}

type Translations = typeof translations.en

type Props = {
  data: Journey
  activities: Array<Activity>
}

export const JourneyCard: React.FC<Props> = ({ data, activities }: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const breakPoint: string = useBreakPoint()
  const [downloadActive, setDownloadActive] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleDownload = async () => {
    setDownloadActive(true)
    toast.info(labels.downloadingMessage, {
      toastId: 'downloading',
      autoClose: false,
    })
    downloadJourney(data, activities).then(({ type, goal, completed }) => {
      toast.dismiss('downloading')
      const text = type === 'success' ? `${completed} out of ${goal} piccles successfully downloaded.` : labels[type]
      toast[type](text, {
        progress: undefined,
      })
      setDownloadActive(false)
    })
  }

  const { userType }: AuthenticationContextFields = useAuth()

  return (
    <Box mb={2}>
      <Card>
        <CardSection mx={-2} p={2}>
          <Flex>
            <Flex
              width={breakPoint !== 'sm' ? 1 / 4 : 2 / 4}
              px={4}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Heading3
                css={css`
                  line-height: 1.3rem;
                  padding-bottom: 0.8rem;
                `}
              >
                {data.title ? data.title : 'Untitled'}
              </Heading3>

              <Button
                variant="secondary"
                onClick={() => {
                  markJourneyAsRecentlyEdited(data.id)
                  navigate(`/admin/edit/${data.id}`)
                }}
              >
                {labels.edit}
              </Button>
            </Flex>

            {breakPoint !== 'sm' && (
              <Flex width={1 / 4} px={[1, 2]} justifyContent="flex-end" flexDirection="column">
                <Flex alignItems="center">
                  <Text color="textDim" pr={1}>
                    {labels.code}:{' '}
                  </Text>

                  <Text color="textDim" fontWeight="bold">
                    {data.code}
                  </Text>
                </Flex>

                <Text color="textDim" mt={2}>
                  {data.sequence && `${data.sequence.length} Activities`}
                </Text>
              </Flex>
            )}

            <Flex width={1 / 4} px={[1, 2]} justifyContent="flex-end" flexDirection="column">
              {data.participants && (
                <select
                  style={{
                    padding: '5px 20px',
                    background: '#ddd',
                    border: 'none',
                    borderRadius: '5px',
                  }}
                  defaultValue="default"
                >
                  <option value="default">{data.participants.length} Participants</option>
                  <option
                    onClick={() => {
                      downloadJourneyParticipants(data)
                    }}
                  >
                    Download contact info
                  </option>
                </select>
              )}
            </Flex>

            <Flex width={2 / 4} px={[1, 2]} flexDirection="column" alignItems="flex-end">
              <Button
                variant="secondary"
                m={1}
                onClick={() => {
                  markJourneyAsRecentlyEdited(data.id)
                  openLinkInNewTab(`/projection/${data.id}`)
                }}
              >
                {userType === 'enterprise' ? labels.projection : labels.start}
              </Button>

              <Button
                variant="secondary"
                m={1}
                onClick={() => {
                  markJourneyAsRecentlyEdited(data.id)
                  navigate(`/admin/moderate/${data.id}`)
                }}
              >
                {labels.moderation}
              </Button>

              <Button
                variant="secondary"
                m={1}
                onClick={handleDownload}
                css={() => css`
                  position: relative;
                  text-align: center;
                  margin-left: auto;
                  display: flex;
                  flex-direction: row;
                  .dot {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin-top: 12px;
                    margin-left: 3px;
                    margin-right: 3px;
                    background: #452768;
                    animation: ${downloadActive ? bounce : null} 1s ease infinite;

                    &:nth-of-type(2) {
                      animation-delay: -1.1s;
                    }

                    &:nth-of-type(3) {
                      animation-delay: -0.9s;
                    }
                  }
                `}
              >
                {downloadActive ? (
                  <div style={{ display: 'inline-flex' }}>
                    <Text>Downloading</Text>
                    <Text className="dot"></Text>
                    <Text className="dot"></Text>
                    <Text className="dot"></Text>
                  </div>
                ) : (
                  <Text>Download</Text>
                )}
              </Button>
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </Box>
  )
}
