import React from 'react'

import Firebase from 'firebase/app'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'

import { Error, Loading } from 'components'
import MosaicPiccleGrid from './MosaicPiccleGrid'

type Props = {
  onSelect?: (any) => void
  activityId: string
  journeyId: string
}

export const MosaicPiccleGridContainer = ({ journeyId, onSelect, activityId }: Props) => {
  const [activityData, activityLoading, activityError]: [
    any,
    boolean,
    Error,
  ] = useDocumentData(Firebase.firestore().doc(`activities/${activityId}`), { idField: 'id' })

  const [picclesData, picclesLoading, picclesError]: [
    any,
    boolean,
    Error,
  ] = useCollectionData(Firebase.firestore().collection(`activities/${activityId}/piccles`), { idField: 'id' })

  const error = activityError || picclesError
  const loading = activityLoading || picclesLoading

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {activityData && picclesData && (
        <MosaicPiccleGrid journeyId={journeyId} activityData={activityData} piccles={picclesData} onSelect={onSelect} />
      )}
    </>
  )
}
