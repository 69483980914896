import React, { ReactNode } from 'react'
import useTranslations from 'hooks/useTranslations'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lightspeed/flame/Modal'
import { Switch } from '@lightspeed/flame/Switch'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Heading3, Heading2, Text } from '@lightspeed/flame/Text'
import { Flex, Box } from '@lightspeed/flame/Core'
import picclesApi from 'api'

const translations = {
  en: {
    close: 'Close',
    delete: 'Delete',
    promote: 'Promote',
    description: 'Piccle Description',
    actions: 'Actions',
    piccleModalPrompt: 'You Selected The Following Piccle',
    noDescription: 'No Description Available For This Piccle',
    visible: 'Visible',
    moderation: 'Moderation',
  },
  fr: {
    close: 'Fermer',
    delete: 'Effacer',
    promote: 'Promouvoir',
    description: 'Description du Piccle',
    actions: 'Actions',
    piccleModalPrompt: 'Vous avez choisi le piccle ci-dessous',
    noDescription: 'Aucune description disponible pour ce Piccle',
    visible: 'Visible',
    moderation: 'Modération',
  },
}

type Translations = typeof translations.en

type Activity = {
  id: string
  type: string
  title: string
  isStarted: boolean
  isComplete: boolean
  journeyId: string
  continuous?: boolean
  promotedPiccle: string
  visiblePiccles?: []
}

type Props = {
  isModalOpen: boolean
  onClose: () => void
  onDelete: () => void
  children: ReactNode
  currentJourneyId: string
  currentPiccleId: string
  data: Activity
  description: string
  isPiccleVisible?: boolean
}

export const ModerationActionModal: React.FunctionComponent<Props> = ({
  isModalOpen,
  onClose,
  children,
  onDelete,
  data,
  currentJourneyId,
  currentPiccleId,
  description,
}: Props): React.ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)

  const isPicclePromoted = data.promotedPiccle === currentPiccleId

  // not all activities have the visible piccle set this keeps thing backwards compatible
  const visiblePicclesList: string[] = data.visiblePiccles ?? []

  const isPiccleVisible = visiblePicclesList.includes(currentPiccleId)

  const togglePromotePiccle: (currentJourneyId: string, activityData: Activity) => Promise<any> = async (
    currentJourneyId: string,
    activityData: Activity,
  ) => {
    const { activityApi } = picclesApi()
    onClose()
    // when the piccle is already promoted we unpromote it else we set it to promoted
    const promotedPiccle = data.promotedPiccle === currentPiccleId ? '' : currentPiccleId

    return activityApi.updateActivity({
      journeyId: currentJourneyId,
      activityId: activityData.id,
      payload: { ...activityData, promotedPiccle },
    })
  }

  const togglePiccleVisibility = async () => {
    const { activityApi } = picclesApi()

    // if the piccle is visible we remove it else we add it to the list
    const visiblePiccles = isPiccleVisible
      ? visiblePicclesList.filter((id) => id !== currentPiccleId)
      : [...visiblePicclesList, currentPiccleId]

    try {
      // Aggregating all the visible piccles in a list
      await activityApi.updateActivity({
        journeyId: currentJourneyId,
        activityId: data.id,
        payload: {
          ...data,
          visiblePiccles,
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      onClose()
    }
  }

  return (
    <Modal isOpen={isModalOpen} onRequestClose={onClose}>
      <ModalHeader onCloseClicked={onClose}>
        <Heading2>{labels.piccleModalPrompt}</Heading2>
      </ModalHeader>

      <ModalBody>
        <Flex maxHeight={300} data-testid="ModerationActionModal">
          {children}
          <Box px={2}>
            <Heading3 mb={2}>{labels.actions}</Heading3>
            <Flex flexDirection="column">
              <Flex justifyContent="space-between" mb={3}>
                <Text marginRight={2}>{labels.promote}</Text>
                <Switch checked={isPicclePromoted} onChange={() => togglePromotePiccle(currentJourneyId, data)} />
              </Flex>
              <Flex justifyContent="space-between" mb={3}>
                <Text marginRight={2}>{labels.visible}</Text>
                <Switch checked={isPiccleVisible} onChange={() => togglePiccleVisibility()} />
              </Flex>
            </Flex>
            <Heading3 mb={2}>{labels.moderation}</Heading3>

            <Button variant="danger" block onClick={onDelete}>
              {labels.delete}
            </Button>

            {
              <Box px={0} maxWidth={173.217} height={'100%'}>
                <Heading3 mt={2}>{description ? labels.description : labels.noDescription}</Heading3>
                {description && (
                  <Box height={'45%'} overflow={'auto'}>
                    {description}
                  </Box>
                )}
              </Box>
            }
          </Box>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>{labels.close}</Button>
      </ModalFooter>
    </Modal>
  )
}
