import React, { ReactElement, FunctionComponent } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'
import { getUserId } from 'api/helpers/firestoreHelpers'
import TemplateList from './TemplateList'
import { Loading, Error, TemplateCard } from 'components'
import { Template } from 'types'
import { crayonPicclesApi } from 'api'
import { useNavigate } from 'hooks/useNavigate'

const TemplateListContainer: FunctionComponent<{}> = (): ReactElement => {
  const navigate = useNavigate()
  const { crayonJourneyApi } = crayonPicclesApi()
  const userUid: string = getUserId()

  // fetch templetes that the user is allowed to access.
  // allowed if the current authenticated user's id is in the `users` property
  const [data, loading, error]: [Template[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('templates')
      .where('users', 'array-contains', userUid),
    { idField: 'id' },
  )

  const handleOnCreateTemplateJourney = async (journeyCollectionPath: string) => {
    try {
      const journeyId = await crayonJourneyApi.createCrayonJourney({})
      navigate(`admin/${journeyCollectionPath}/journey/${journeyId}`)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <TemplateList>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {data &&
        data.map((template: Template) => {
          return (
            <TemplateCard
              key={template.id}
              onClickCreate={() => handleOnCreateTemplateJourney(template.journeyCollectionPath)}
              template={template}
            />
          )
        })}
    </TemplateList>
  )
}
export default TemplateListContainer
