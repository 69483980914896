import React from 'react'
import useTranslations from 'hooks/useTranslations'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lightspeed/flame/Modal'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Heading3, Heading2, Text } from '@lightspeed/flame/Text'
import { Flex, Box } from '@lightspeed/flame/Core'
import { TemplateJourney, TemplatePromotedPiccle } from 'types'
import { CrayonPiccleCanvasContainer } from 'components'
import { Switch } from '@lightspeed/flame/Switch'
import { crayonPicclesApi } from 'api'

const translations = {
  en: {
    close: 'Close',
    delete: 'Delete',
    promote: 'Promote',
    actions: 'Actions',
    piccleModalPrompt: 'You Selected The Following Piccle',
    visible: 'Visible',
    moderation: 'Moderation',
  },
  fr: {
    close: 'Fermer',
    delete: 'Effacer',
    promote: 'Promouvoir',
    actions: 'Actions',
    piccleModalPrompt: 'Vous avez choisi le piccle ci-dessous',
    visible: 'Visible',
    moderation: 'Modération',
  },
}

type Translations = typeof translations.en

type Props = {
  isModalOpen: boolean
  onClose: () => void
  onDelete: () => void
  journey: TemplateJourney
  journeyCollectionPath: string
  questionId: string
  piccleId: string
}

export const TemplateModerationActionModal: React.FunctionComponent<Props> = ({
  isModalOpen,
  onClose,
  onDelete,
  journey,
  journeyCollectionPath,
  questionId,
  piccleId,
}: Props): React.ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)

  const { promotedPiccles = [] } = journey

  const isPicclePromoted =
    journey.promotedPiccles &&
    promotedPiccles.findIndex((p) => p.questionId === questionId && p.piccleId === piccleId) >= 0

  const togglePromotePiccle: () => Promise<any> = async () => {
    const { crayonJourneyApi } = crayonPicclesApi()
    onClose()
    let newPromotedPiccles: TemplatePromotedPiccle[]

    if (isPicclePromoted) {
      newPromotedPiccles = promotedPiccles.filter((p) => p.piccleId !== piccleId)
    } else {
      const currentQuestionPromotedPiccle: number = promotedPiccles.findIndex((p) => p.questionId === questionId)
      if (currentQuestionPromotedPiccle < 0) {
        newPromotedPiccles = [...promotedPiccles, { questionId, piccleId }]
      } else {
        newPromotedPiccles = [...promotedPiccles]
        newPromotedPiccles[currentQuestionPromotedPiccle].piccleId = piccleId
      }
    }

    return crayonJourneyApi.updateCrayonJourney({
      journeyId: journey.id,
      payload: { ...journey, promotedPiccles: newPromotedPiccles },
    })
  }

  return (
    <Modal isOpen={isModalOpen} onRequestClose={onClose}>
      <ModalHeader onCloseClicked={onClose}>
        <Heading2>{labels.piccleModalPrompt}</Heading2>
      </ModalHeader>

      <ModalBody>
        <Flex maxHeight={300}>
          <div style={{ width: 300, height: 300 }}>
            <CrayonPiccleCanvasContainer
              journeyCollectionPath={journeyCollectionPath}
              piccleId={piccleId}
              journeyId={journey.id}
              height={200}
              width={200}
            />
          </div>
          <Box px={2}>
            <Heading3 mb={2}>{labels.actions}</Heading3>
            <Flex flexDirection="column">
              <Flex justifyContent="space-between" mb={3}>
                <Text marginRight={2}>{labels.promote}</Text>
                <Switch checked={isPicclePromoted} onChange={() => togglePromotePiccle()} />
              </Flex>
            </Flex>
            <Heading3 mb={2}>{labels.moderation}</Heading3>

            <Button variant="danger" block onClick={onDelete}>
              {labels.delete}
            </Button>
          </Box>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>{labels.close}</Button>
      </ModalFooter>
    </Modal>
  )
}
