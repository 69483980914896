import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

export type UpdateActivityArgs = {
  journeyId: string
  activityId: string
  payload: any
}

const updateActivity = ({ journeyId, activityId, payload }: UpdateActivityArgs) => {
  ReactGA.event({
    category: 'Activity',
    action: 'Update activity',
  })
  if (payload.title) {
    payload.slug = slugify(payload.title, { lower: true })
  }
  return Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .doc(activityId)
    .update(prepareDocForUpdate(payload))
    .then(() => payload)
    .catch((error) => {
      alert(`Whoops, couldn't update the activity: ${error.message}`)
    })
}

export default updateActivity
