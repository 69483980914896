import { useLocale } from './useChangeLocale'

type PropertyFunction<T> = (arg: string) => T

type TranslationMap = { [key: string]: string | PropertyFunction<string> }

type Translations<T extends TranslationMap> = {
  fr: T
  en: T
}

const useTranslations = <T extends TranslationMap>(languageObject: Translations<T>): T => {
  const { locale } = useLocale()
  return languageObject[locale]
}

export default useTranslations
