/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { JourneyData, MosaicActivity, PromptActivity } from 'hooks/useJourneyData'
import { Flex, Box } from '@lightspeed/flame/Core'
import { Text, Image } from 'rebass'
import { ProjectionViewGridContainer } from 'components/molecules/ProjectionViewGrid'
import { PiccleCanvasContainer as PiccleCanvas } from 'components/molecules/PiccleCanvas'
import { Card } from '@lightspeed/flame/Card'
import { SidebarEnterprise } from '../../../components/molecules/SidebarEnterprise'
import picclesLogo from 'assets/images/piccles-logo.png'
import { PREGAME, LIMBO, TERMINAL, ACTIVE } from 'appConstants'
import { useBreakPoint } from 'hooks/useBreakPoint'
import { FunctionComponent, ReactElement, Fragment } from 'react'
import { useQrModalState } from 'hooks/useQrModalState'
import { Modal, ModalBody, ModalHeader } from '@lightspeed/flame/Modal'
import QRCode from 'qrcode.react'
import { QrModalStateCloseAction } from 'Contexts/QrContext/actions'
import { Loading } from 'components'
import picclesApi from 'api'
import { useTranslation } from 'react-i18next'
import { useLocale } from 'hooks/useChangeLocale'

export type Data = JourneyData
type Props = {
  data: Data
  journeyId: string
  logo: string
  sponsorsLoading: boolean
  QRenabled: boolean
}

const PageProjectionViewEnterprise: FunctionComponent<Props> = ({
  data,
  journeyId,
  logo,
  sponsorsLoading,
  QRenabled = false,
}: Props): ReactElement => {
  const { state }: JourneyData = data

  const breakPoint: string = useBreakPoint()
  const { t } = useTranslation()
  const { locale, changeLocale } = useLocale()

  const windowHeight: number = window.innerHeight
  const height = window.innerWidth

  const journeySequence = data.sequence

  const [qrModalState, qrModalDispatch] = useQrModalState()
  //const [displayPromoted, setDisplayPromoted] = useState<boolean>(false)
  //const [promotedPiccle, setPromotedPiccle] = useState<string | null>(null)

  // Set the activity according to state
  let activity: MosaicActivity | PromptActivity = state.currentActivity || state.nextActivity
  if (state.status === PREGAME) {
    activity = state.nextActivity
  }
  if (state.status === ACTIVE) {
    activity = state.currentActivity
  }
  if (state.status === LIMBO) {
    activity = state.previousActivity
  }
  if (state.status === TERMINAL) {
    activity = state.previousActivity
  }

  const displayPromoted: boolean = activity && activity?.promotedPiccle?.length > 0
  const { activityApi } = picclesApi()

  const [languages, setLanguages] = React.useState<string[]>()

  let languageIndex = 0

  React.useEffect(() => {
    setLanguages(data.languages)
  }, [data])

  return (
    <Fragment>
      {!activity && <Loading />}
      {activity && (
        <Fragment>
          <Flex bg="background" height="100vh" flex={1} width="100vw" data-testid="PageProjectionViewEnterprise" p={2}>
            {breakPoint === 'lg' ? (
              <SidebarEnterprise
                status={state.status}
                activity={activity}
                code={data.code}
                logo={logo}
                sponsorsLoading={sponsorsLoading}
                QRenabled={QRenabled}
                journeyId={journeyId}
                state={state}
                journeySequence={journeySequence}
              />
            ) : null}

            <Card
              css={(theme) => css`
                background: ${theme.colors.white};
                width: ${breakPoint === 'lg' ? '83.333%' : '100%'};
                height: 100%;
                text-align: center;
                display: flex;
                flex-flow: column;
                position: relative;
              `}
            >
              <Flex justifyContent="center" style={{ display: '-webkit-box' }}>
                <Image src={picclesLogo} width="8vw" mt={2} display={['inline', 'inline', 'none']} />
              </Flex>

              {activity.question && (
                <Text
                  fontSize={['large', 'xlarge']}
                  pt={[0, 3]}
                  pb={[2, 3]}
                  mx={2}
                  css={(theme) => css`
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    font-weight: 700;
                    border-bottom: 1px solid ${theme.colors.text};
                  `}
                  data-cy="ActivityPrompt"
                >
                  <span
                    css={() => css`
                      font-weight: 700;
                      font-size: 40px;
                    `}
                    onClick={() => {
                      languageIndex = languages.indexOf(locale) - 1

                      if (languageIndex < 0) {
                        languageIndex = languages.length - 1
                      }

                      changeLocale(languages[languageIndex])
                    }}
                  >
                    ←
                  </span>
                  {activity.question[locale] || `This activity is missing question in ${locale}`}
                  <span
                    css={() => css`
                      font-weight: 700;
                      font-size: 40px;
                    `}
                    onClick={() => {
                      languageIndex = languages.indexOf(locale) + 1

                      if (languageIndex > languages.length - 1) {
                        languageIndex = 0
                      }

                      changeLocale(languages[languageIndex])
                    }}
                  >
                    →
                  </span>
                </Text>
              )}

              {displayPromoted && (
                <Box className="promoted" width="50%">
                  <Box
                    css={() => css`
                      position: absolute;
                      top: 0px;
                      right: 10px;
                      font-size: 2rem;
                      font-weight: bold;
                      &:hover {
                        cursor: pointer;
                      }
                    `}
                    onClick={() => {
                      return activityApi.updateActivity({
                        journeyId,
                        activityId: activity.id,
                        payload: { promotedPiccle: '' },
                      })
                    }}
                  >
                    X
                  </Box>

                  <PiccleCanvas
                    height={windowHeight / 2}
                    width={windowHeight / 2}
                    journeyId={journeyId}
                    piccleId={activity.promotedPiccle}
                    activityId={activity.id}
                  />
                </Box>
              )}

              {activity && (
                <ProjectionViewGridContainer
                  journeyId={journeyId}
                  activity={activity}
                  //setDisplayPromoted={setDisplayPromoted}
                  //setPromotedPiccle={setPromotedPiccle}
                />
              )}
            </Card>
          </Flex>
          {QRenabled && (
            <Modal
              data-cy="QRmodal"
              isOpen={qrModalState}
              onRequestClose={() => qrModalDispatch(new QrModalStateCloseAction())}
            >
              <ModalHeader
                style={{ textAlign: 'center' }}
                onCloseClicked={() => qrModalDispatch(new QrModalStateCloseAction())}
              >
                {t('pageProjectViewEnterprise.scanPrompt')}
              </ModalHeader>

              <ModalBody
                css={{
                  overflow: 'auto',
                  marginBottom: '1.5vw',
                }}
              >
                <Flex flexDirection="row" justifyContent="center">
                  <QRCode
                    size={height / 3}
                    value={`${window.location.protocol}//${window.location.host}/${data?.code}?locale=${locale}`}
                  />
                </Flex>
              </ModalBody>
            </Modal>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
export default PageProjectionViewEnterprise
