/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box } from '@lightspeed/flame/Core'
import { Image } from 'rebass'
import { Text } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { useState } from 'react'
import useTranslations from 'hooks/useTranslations'
import picclesLogo from 'assets/images/piccles-logo.png'

const translations = {
  en: {
    toJoin: 'Join at:',
    copyLink: 'Copy this link',
    copied: 'Copied to clipboard',
    enterCode: 'and enter code',
  },
  fr: {
    toJoin: 'Rejoindre à :',
    copyLink: 'Copier ce lien',
    copied: 'Copié dans le presse-papiers',
    enterCode: 'et entrez le code',
  },
}

type Props = {
  code: string
}

export const SidebarFree = ({ code }: Props) => {
  const labels = useTranslations<typeof translations.en>(translations)
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    const path = `${window.location.protocol}//${window.location.host}/${code}`
    navigator.clipboard.writeText(path).then(() => setCopied(true))
  }

  return (
    <Box
      data-testid="SidebarFree"
      css={(theme) => css`
        background-image: linear-gradient(to top right, ${theme.colors.secondary}, ${theme.colors.secondarydark});
        width: 16.6667%;
        height: 100%;
        margin-right: ${theme.space[2]};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-radius: 5px;
        min-height: min-content;
      `}
    >
      {/* Only display the Piccles Logo on the Free Sidebar */}
      <Box
        height="9rem"
        width="9rem"
        bg="background"
        css={(theme) => css`
          border: 10px solid ${theme.colors.text};
          border-radius: 50% !important;
          display: flex;
          align-items: center;
          justify-content: space-around;
        `}
      >
        <Image height="5.4rem" width="5.4rem" src={picclesLogo} />
      </Box>

      {/* Instructions for joining the experience, will rotate from french to english. */}
      <Box
        p={1}
        css={css`
          width: 100%;
          margin-top: 40%;
          text-align: center;
        `}
      >
        <Text
          key={labels.toJoin}
          my={2}
          fontSize="large"
          color="white"
          css={css`
            font-weight: 400;
            font-size: 2vw;
          `}
        >
          {labels.toJoin}
        </Text>
        <Text
          my={2}
          fontSize={28}
          color="primary"
          css={css`
            font-weight: 900;
            font-size: 2vw;
          `}
        >
          <b>app.piccles.com</b>
        </Text>
        <Text
          my={2}
          fontSize={28}
          color="white"
          css={css`
            font-size: 1.5vw;
          `}
        >
          {labels.enterCode}
        </Text>
        <Text
          my={2}
          fontSize={28}
          color="primary"
          css={css`
            font-weight: 700;
            font-size: 2.4vw;
          `}
        >
          {code}
        </Text>
      </Box>
      <Box
        css={css`
          width: 100%;
          padding: 0 10%;
        `}
      >
        {/* Button that copies the app url so user can send to friends to participate and draw */}
        <Button block fill size="large" variant="primary" onClick={copyToClipboard}>
          {copied ? labels.copied : labels.copyLink}
        </Button>
      </Box>
    </Box>
  )
}
