import Firebase from 'firebase/app'
import firebase from 'firebase'

const collectEmail = async ({ journeyCode, email }): Promise<any> => {
  return await Firebase.firestore()
    .collection('journeys')
    .where('code', '==', journeyCode)
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => {
        doc.ref.update({ participants: firebase.firestore.FieldValue.arrayUnion(email) })
      })
    })
}

export default collectEmail
