import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'
import journeyApi from '../journeys'
import { prepareDocForCreate } from '../helpers/firestoreHelpers'

export type CreateActivityArguments = {
  journeyId: string
  payload: any
  sequence: string[]
}

const createActivity = async ({ journeyId, payload, sequence }: CreateActivityArguments): Promise<string> => {
  ReactGA.event({
    category: 'Activity',
    action: 'create activity',
  })

  payload.slug = slugify(payload.title, { lower: true })
  payload.isStarted = false
  payload.isComplete = false

  // As described in CreateJourney action, we need to set the journey.draft === false as we create the first activity.
  // This is the first activity IF sequence.length === 0
  if (!sequence || sequence.length === 0) {
    await Firebase.firestore()
      .collection(`journeys`)
      .doc(journeyId)
      .update({ isDraft: false })
  }

  const activityId: string = await Firebase.firestore()
    .collection(`journeys`)
    .doc(journeyId)
    .collection('activities')
    .add(prepareDocForCreate(payload))
    .then((res: Firebase.firestore.DocumentReference): string => res.id)

  // Update the journey sequence with the new activity
  const newSequence: string[] = [...(sequence ? sequence : []), activityId]
  await journeyApi.updateJourney({ journeyId, payload: { sequence: newSequence } })
  return activityId
}

export default createActivity
