import Firebase from 'firebase/app'
import { getUserId } from 'api/helpers/firestoreHelpers'

export type UpdateCompanyUsageArgs = {
  journeyId: string
}

const updateCompanyUsage = async ({ journeyId }: UpdateCompanyUsageArgs) => {
  try {
    const userUid: string = getUserId()
    const db = Firebase.firestore()
    // grab journey by id
    const journeyDoc = await db
      .collection('journeys')
      .doc(journeyId)
      .get()

    // grab company by journey created by
    const companyCollection = await db
      .collection('companies')
      .where('createdBy', '==', journeyDoc.data().createdBy)
      .limit(1)
      .get()

    const company = companyCollection.docs[0]

    // increment acts similarlu to transactions and queues up when heavy load
    const incrementCompanyUsage = Firebase.firestore.FieldValue.increment(1)
    const incrementTodayUsage = Firebase.firestore.FieldValue.increment(1)
    const incrementCompanyVisitors = Firebase.firestore.FieldValue.increment(1)
    const incrementTodaysVisitors = Firebase.firestore.FieldValue.increment(1)

    // batch to ensure today's usage and total usage updated at the same time
    const batch = db.batch()

    // Getting Refs on all to potentially be updated
    const companuyUsageRef = db.collection('usage').doc(company.id)

    const todayUsageRef = db
      .collection('usage')
      .doc(company.id)
      .collection('dates')
      .doc(new Date().toDateString())

    const userVisitsCompanyRef = db
      .collection('usage')
      .doc(company.id)
      .collection('uniqueVisitors')
      .doc(userUid)

    const userVisitsTodayRef = db
      .collection('usage')
      .doc(company.id)
      .collection('dates')
      .doc(new Date().toDateString())
      .collection('uniqueVisitors')
      .doc(userUid)

    const { exists: userVisitedCompany } = await userVisitsCompanyRef.get()
    const { exists: userVisitedToday } = await userVisitsTodayRef.get()

    // we need to track which users have already visited to not double count
    if (!userVisitedCompany) {
      batch.set(userVisitsCompanyRef, {})
    }

    if (!userVisitedToday) {
      batch.set(userVisitsTodayRef, {})
    }

    // merge true to make sure we don't recreate doc

    batch.set(
      companuyUsageRef,
      {
        totalPiccles: incrementCompanyUsage,
        companyName: company.data().company,
        // conditionnaly increment the visited visitors if the user never visited before company
        ...(!userVisitedCompany && { totalVisitors: incrementCompanyVisitors }),
      },
      { merge: true },
    )
    batch.set(
      todayUsageRef,
      // conditionnaly increment the visited visitors if the user never visited before company
      { totalPiccles: incrementTodayUsage, ...(!userVisitedToday && { totalVisitors: incrementTodaysVisitors }) },
      { merge: true },
    )

    // batch both updates
    await batch.commit()
  } catch (error) {
    console.log(error)
    throw error
  }

  return true
}

export default updateCompanyUsage
