import { Error, Loading } from 'components'
import { Question, SponsorsData, TemplateJourney } from 'types'

import PageCrayonJourneyStart from './PageCrayonJourneyStart'
import React from 'react'
import createPiccle from 'api/crayon/createPiccle'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import useRandomQuestion from 'hooks/crayon/useRandomQuestion'
import { useSponsorsData } from 'hooks/crayon/useSponsorsData'
import { CrayonTranslationObjectType } from 'types'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  journeyCollectionPath: string
  templateId: string
  journeyId: string
  category: string
  language: string
}

const translations: CrayonTranslationObjectType = {
  en: {
    picked: 'You have selected',
    prompt: 'Happy Coloring',
    start: 'Start',
    back: 'Back',
    poweredBy: 'Powered by Piccles',
  },
  es: {
    picked: 'Has seleccionado',
    prompt: 'Happy Coloring',
    start: '¡Comienza!',
    back: '¡Vuelve a la página!',
    poweredBy: 'Energizado por Piccles',
  },
}

const PageCrayonJourneyStartContainer: React.FunctionComponent<any> = ({
  journeyCollectionPath,
  templateId,
  journeyId,
  category,
  language,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const labels = translations[language]

  const [randomQuestion, questionLoading, questionError]: [Question, boolean, Error] = useRandomQuestion({
    templateId,
    category,
  })

  const [journey, journeyLoading, journeyError]: [TemplateJourney, boolean, Error] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  const [sponsors, sponsorsLoading, sponsorsError]: [SponsorsData, boolean, Error] = useSponsorsData({
    journeyCollectionPath,
    journeyId,
  })

  const goToFirstPiccle = async (): Promise<void> => {
    if (!randomQuestion) {
      alert('No questions in this category!')
      return
    }
    const goToDrawPrompt = async () => {
      try {
        const piccleId = await createPiccle({
          journeyCollectionPath,
          journeyId,
          questionId: randomQuestion.id,
        })
        return piccleId
      } catch (error) {
        console.log(error)
      }
    }
    try {
      const piccleId = await goToDrawPrompt()
      navigate(`${window.location}/1/${randomQuestion.id}/${piccleId}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGoBack = (): void => {
    navigate(
      window.location.pathname
        .split('/')
        .slice(0, -1)
        .join('/'),
    )
  }

  const categoryTranslations: CrayonTranslationObjectType = {
    en: {
      Sibling: 'Sibling',
      Parent: 'Parent',
      Patient: 'Patient',
    },
    es: {
      Sibling: 'Hermanos',
      Parent: 'Padres',
      Patient: 'Paciente',
    },
  }

  const loading = journeyLoading || questionLoading || sponsorsLoading
  const error = journeyError || questionError || sponsorsError

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journey && randomQuestion && (
        <PageCrayonJourneyStart
          labels={labels}
          category={categoryTranslations[language][category]}
          openingImage={journey.openingImageUrl}
          openingMessage={journey.openingMessage}
          goToFirstPiccle={goToFirstPiccle}
          handleGoBack={handleGoBack}
          language={language}
          sponsors={sponsors}
        />
      )}
    </>
  )
}

export default PageCrayonJourneyStartContainer
