import { QrModalStateAction, QrModalActionType } from 'Contexts/QrContext/actions'
import { QrModalState } from '../types'

export const qrModalStateReducer: React.Reducer<QrModalState, QrModalStateAction> = (
  _state: QrModalState,
  action: QrModalStateAction,
): QrModalState => {
  switch (action.type) {
    case QrModalActionType.OPEN:
      return true
    case QrModalActionType.CLOSE:
      return false
    default:
      return false
  }
}
