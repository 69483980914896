/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Card } from '@lightspeed/flame/Card'
import { Flex } from '@lightspeed/flame/Core'
import { Input } from '@lightspeed/flame/Input'
import { useTranslation } from 'react-i18next'

type Props = {
  boxSize: number
  handleUpdatePiccle: (payload) => Promise<void>
}

export const DrawingDescription = ({ boxSize, handleUpdatePiccle }: Props) => {
  const { t } = useTranslation()

  const handleInput = (event) => {
    handleUpdatePiccle({ description: event.target.value })
  }

  return (
    <Card
      data-testid="DrawingDescription"
      css={() => css`
        height: ${boxSize}px;
        width: ${boxSize}px;
        padding: 50px 20px;
      `}
    >
      <Flex flexDirection="column" alignItems="center">
        <Input
          type="text"
          size="large"
          label={t('drawingDescription.addDescription')}
          placeholder={t('drawingDescription.placeholder')}
          onChange={handleInput}
          css={() => css`
            flex: 1;
          `}
        />
      </Flex>
    </Card>
  )
}
