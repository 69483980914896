import React, { useState } from 'react'
import { Label } from '@rebass/forms'
import { Box, Image } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'

import { SelectDropdown, ImageUploader } from 'components'
import uploadImage from 'api/helpers/uploadImage'
import useTranslations from 'hooks/useTranslations'
import { Activity } from 'Pages/Admin/PageEditJourney/PageEditJourney'

const translations = {
  en: {
    title: 'Title',
    numSquares: 'Number of mosaic squares',
    image: 'Background Image',
    palette: 'Color Palette',
    create: 'Create New',
    save: 'Save',
    delete: 'Delete',
    add: 'Add to Journey',
  },
  fr: {
    title: 'Titre',
    numSquares: 'Nombre de carrés mosaïques',
    image: 'Image de Fond',
    palette: 'Palette de Couleurs',
    create: 'Créer',
    save: 'Sauvegarder',
    delete: 'Effacer',
    add: 'Ajouter',
  },
}

type Props = {
  onSave: (data: any) => void
  onDelete?: (id) => void
  values?: Activity
}

const numberOptions = [4, 9, 16, 25, 36, 49, 64, 81, 100].map((num) => ({ label: `${num}`, id: `${num}` }))

const MOSAIC_ACTIVITY = 'mosaic'

export const MosaicActivityForm = ({ onSave, onDelete, values }: Props) => {
  const labels = useTranslations<typeof translations.en>(translations)

  // Controlled form state and handlers. "values" prop will be undefined if we are creating a NEW activity,
  // in which case we use the initial state defined:
  const [inputs, setInputs] = useState(values || { title: '', numSquares: '', imgUrl: null })
  const handleInputChange = (event) => {
    event.persist()
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }))
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = { ...inputs, type: MOSAIC_ACTIVITY }
    onSave(data)
  }

  // Image upload state and handler
  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const handleUploadImage = async (file) => {
    setIsUploadingImage(true)
    const imgUrl = await uploadImage(file)
    setInputs({ ...inputs, imgUrl })
    setIsUploadingImage(false)
  }

  // If we have "values" prop, this means we are in edit mode.
  const isEditMode = !!values
  return (
    <Box as="form" id="mosaicActivityForm" onSubmit={handleSubmit} sx={{ maxWidth: '32rem' }}>
      {isEditMode && (
        <div style={{ display: 'none' }}>
          <Input name="id" value={values.id} readOnly />
        </div>
      )}
      <Input
        label={labels.title}
        name="title"
        value={inputs.title}
        onChange={handleInputChange}
        autoComplete="off"
        required
      />
      <SelectDropdown
        value={inputs.numSquares}
        onChange={handleInputChange}
        label={labels.numSquares}
        options={numberOptions}
        name="numSquares"
        required
      />
      <Label>{labels.image}</Label>
      {inputs.imgUrl ? (
        <Image src={inputs.imgUrl} width="300px" height="300px" sx={{ maxWidth: '300px' }} />
      ) : (
        <ImageUploader loadFileHandler={handleUploadImage} isUploadingImage={isUploadingImage} required />
      )}

      <Box mt={4}>
        {isEditMode ? (
          <>
            <Button block mb={2} variant="primary" type="submit" form="mosaicActivityForm" value="Submit">
              {labels.save}
            </Button>
            <Button
              block
              mb={2}
              variant="danger"
              onClick={(event) => {
                event.preventDefault()
                onDelete(values.id)
              }}
            >
              {labels.delete}
            </Button>
          </>
        ) : (
          <Button block mb={2} variant="primary" type="submit" form="mosaicActivityForm" value="Submit">
            {labels.add}
          </Button>
        )}
      </Box>
    </Box>
  )
}
