import { Box, Image } from 'rebass'
import { CrayonDropDown, CrayonLayout, CrayonText } from 'components'

import { Fragment } from 'react'
import { Language } from 'types'
import React from 'react'
import crayonHi from 'assets/images/crayon/crayon-hi.png'
import crayonLogo from 'assets/images/crayon/crayon-logo.png'

type Props = {
  onLanguagePicked: (language: string) => void
}

const PageCrayonWelcome: React.FunctionComponent<Props> = ({ onLanguagePicked }: Props): JSX.Element => {
  const languages = Object.keys(Language)

  return (
    <CrayonLayout showFooter>
      <Fragment>
        <Image mt={25} src={crayonLogo} height="75px" sx={{ maxWidth: '300px' }} />
        <Box mt={40}>
          <Image src={crayonHi} height="90px" sx={{ maxWidth: '300px' }} />
        </Box>
        <Box mt={40} width={[3 / 5]}>
          <CrayonText
            text="Your friends at 
            The Crayon Initiative made 
            this drawing tool just for 
            you-because we believe in
            expressing yourself in all
            kinds of creative ways"
          />
        </Box>
        <Box mt={20}>
          <CrayonText text="Enjoy!" />
        </Box>
        <Box mb={1}>
          <CrayonText text="Please select your language" styles={{ marginTop: 4 }} />
        </Box>
        <Box>
          <CrayonDropDown name="Select" items={languages} onItemClicked={onLanguagePicked} />
        </Box>
      </Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonWelcome
