import React from 'react'
import { AdminLayout, BillingForm, Loading } from 'components'
import { Flex, Box } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { CompanyData } from 'api/companies/getCompany'
import useTranslations from 'hooks/useTranslations'
import { DashboardHeader } from 'components/molecules/DashboardHeader'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@lightspeed/flame/Modal'
import { PiccleButton as Button, PiccleButton } from 'components/atoms/Button'
import { Heading3, Text } from '@lightspeed/flame/Text'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import config from 'config'
import { Elements } from '@stripe/react-stripe-js'
import { PaymentMethodDisplay } from 'components/molecules/PaymentMethods'
import picclesApi, { PicclesApiObject } from 'api'
import { UpgradeConfirmation } from 'api/stripe/upgradeCompanyPlan'
import Plans, { Subscription, EventType } from './Plans'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    billing: 'Billing information for',
    profile: 'Profile Details',
    confirmUpgrade: 'Confirm upgrade',
    cancel: 'Cancel',
    upgrade: 'Yes, I want to upgrade my plan',
    add: 'Add a card',
    error: 'There was a error creating the subscription',
    myEvents: 'My events',
    youAreSubscribing: (plan) => `You are subscribing to the ${plan} plan`,
    packagePaymentIntention: (plan) => `You are making a single payment for the package ${plan}`,
    paidAnnually: `Paid annually`,
    cardEnding: (lastDigits) => `Card ending in ${lastDigits} will be charged`,
    company: 'Company',
    eventStartDate: (eventStartDate) => `Your active event will start on 
    ${eventStartDate} and it will last
    for 7 days so you may test before the event day.`,
  },
  fr: {
    profile: 'Détails du profil',
    billing: 'Détails de facturation pour',
    confirmUpgrade: 'Confirm upgrade',
    cancel: 'Cancel',
    upgrade: 'Yes, I want to upgrade my plan',
    add: 'Ajouter une carte',
    error: 'There was a error creating the subscription',
    myEvents: 'Mes évènements',
    youAreSubscribing: (plan) => `Vous allez être abonné(e) au plan ${plan}`,
    packagePaymentIntention: (plan) => `Vous vous apprêtez à faire un paiement pour le plan ${plan}`,
    paidAnnually: `Payé annuellement`,
    cardEnding: (lastDigits) =>
      `Des frais sur la carte qui contient les 4 derniers chiffres ${lastDigits} seront appliqués.`,
    company: 'Compagnie',
    eventStartDate: (eventStartDate) => `Votre évènement sera en mesure le  
    ${eventStartDate} et il dure 7 jours afin que vous puissiez tester avant votre évènement.`,
  },
}

type Translations = typeof translations.en

const stripePromise: Promise<Stripe> = loadStripe(config.stripe.publicKey)
// temporary hiding to allow for deploys
const showBilling = true

type PageProfileProps = {
  companyData: CompanyData
  paymentMethods: any[]
  currentSubscription: any
}

const PageProfile = ({ companyData, paymentMethods, currentSubscription }: PageProfileProps) => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()
  const {
    user: { email },
  }: AuthenticationContextFields = useAuth()

  const { stripeApi }: PicclesApiObject = picclesApi()

  const [intentToChangePaymentMethod, setIntentToChangePaymentMethod] = React.useState<boolean>(false)
  const [eventType, setEventType] = React.useState<EventType>(EventType.ANNUAL)
  const [subscriptionSelection, setSubscriptionSelection] = React.useState<Subscription>(Subscription.TASTER)
  const [eventStartDate, setEventStartDate] = React.useState<Date>(new Date())
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string | null>(null)
  const [selectedCard, setSelectedCard] = React.useState<any>(null)

  React.useEffect(() => {
    if (paymentMethods?.length > 0) {
      setSelectedCard(paymentMethods[0])
    }
  }, [paymentMethods])

  const handleSubscriptionPicked = (subscription: Subscription) => {
    setSubscriptionSelection(subscription)
    setModalOpen(true)
  }

  const onConfirmUpgrade = async (): Promise<UpgradeConfirmation> => {
    setError(null)

    if (paymentMethods.length === 0) {
      setError('Please add your card information first!')
      return
    }
    const [{ id: paymentMethodId }] = paymentMethods
    try {
      const upgradeResult: UpgradeConfirmation = await stripeApi.upgradeCompanyPlan({
        paymentMethodId,
        email,
        subscription: subscriptionSelection,
        eventType,
        eventStartDate: eventType === EventType.SINGLE && Math.floor(eventStartDate.valueOf() / 1000),
      })
      setModalOpen(false)
      window.location.reload()
      return upgradeResult
    } catch (error) {
      setError(null)
      console.log('error', error)
    }
  }

  const onChangePlan = async () => {
    setError(null)

    const [{ id: paymentMethodId }] = paymentMethods
    if (paymentMethods.length === 0) {
      setError('Please add your card information first!')
      return
    }

    try {
      const upgradeResult = await stripeApi.changePlan({
        paymentMethodId,
        email,
        subscription: subscriptionSelection,
      })
      setModalOpen(false)
      window.location.reload()
      return upgradeResult
    } catch (error) {
      setError(null)
      console.log('error', error)
    }
  }

  return (
    <>
      <Modal isOpen={modalOpen}>
        <ModalHeader
          onCloseClicked={() => {
            setModalOpen(false)
            setSubscriptionSelection(Subscription.TASTER)
          }}
        >
          {labels.confirmUpgrade}
        </ModalHeader>

        <ModalBody>
          {eventType === EventType.SINGLE ? (
            <Flex flexDirection="column">
              <Text>
                {labels.packagePaymentIntention(subscriptionSelection)}{' '}
                {subscriptionSelection === Subscription.PRIME &&
                  Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    currencyDisplay: 'symbol',
                  }).format(99)}
                {subscriptionSelection === Subscription.PRO &&
                  Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    currencyDisplay: 'symbol',
                  }).format(499)}{' '}
              </Text>

              {!!paymentMethods?.length && (
                <Text>{labels.cardEnding(paymentMethods && selectedCard?.card?.last4)} </Text>
              )}

              <Text my={3}>
                {labels.eventStartDate(
                  eventStartDate?.toISOString()?.slice(0, 10) || new Date().toISOString().slice(0, 10),
                )}
              </Text>

              <input
                type="date"
                min={new Date().toISOString().slice(0, 10)}
                max={new Date(
                  Date.now() +
                    29 * 24 * 60 * 60 * 1000 +
                    new Date().getUTCHours() * 60 * 60 * 1000 +
                    new Date().getUTCMinutes() * 60 * 1000 +
                    new Date().getUTCSeconds() * 1000,
                )
                  .toISOString()
                  .slice(0, 10)}
                onChange={(event) => {
                  const currentDate = new Date()

                  const timeOffset =
                    currentDate.getUTCHours() * 60 * 60 * 1000 +
                    currentDate.getUTCMinutes() * 60 * 1000 +
                    currentDate.getUTCSeconds() * 1000

                  const date = new Date(
                    event.target.value ? Date.parse(event.target.value) + timeOffset : Date.now() + timeOffset,
                  )
                  setEventStartDate(date)
                }}
              />
            </Flex>
          ) : (
            <Flex flexDirection="column">
              <Text>
                {labels.youAreSubscribing(subscriptionSelection !== Subscription.TASTER && subscriptionSelection)}(
                {subscriptionSelection === Subscription.PRIME &&
                  Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    currencyDisplay: 'symbol',
                  }).format(228)}
                {subscriptionSelection === Subscription.PRO &&
                  Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    currencyDisplay: 'symbol',
                  }).format(1500)}{' '}
                {labels.paidAnnually})
              </Text>

              {!!paymentMethods?.length && (
                <Text>{labels.cardEnding(paymentMethods && selectedCard?.card?.last4)} </Text>
              )}
            </Flex>
          )}
          {error && <Text color="red">{error}</Text>}
        </ModalBody>

        <ModalFooter style={{ textAlign: 'right' }}>
          <Flex justifyContent="space-between">
            <Button
              variant="danger"
              mr={2}
              onClick={() => {
                setSubscriptionSelection(Subscription.TASTER)
                setModalOpen(false)
              }}
            >
              {labels.cancel}
            </Button>

            <Button
              variant="primary"
              onClick={() => {
                if (companyData.stripeSubscriptionId && eventType === EventType.ANNUAL) {
                  onChangePlan()
                } else {
                  onConfirmUpgrade()
                }
              }}
            >
              {labels.upgrade}
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>

      <AdminLayout>
        <Box my={3}>
          <DashboardHeader title={labels.profile} />
        </Box>

        <Card>
          <CardSection>
            <Flex flexDirection="column">
              <PiccleButton
                width={['30%', '20%', '15%']}
                style={{ borderRadius: '0' }}
                onClick={() => navigate('/admin/profile/events')}
              >
                {labels.myEvents}
              </PiccleButton>
              <Flex width={1} flexWrap="wrap" style={{ borderBottom: '5px solid #CFCBD1' }}>
                <Box
                  width={[1, 1, 0.6]}
                  padding={5}
                  sx={{ border: 0, borderRightWidth: [0, 0, 5], borderStyle: 'solid', borderColor: '#CFCBD1' }}
                >
                  <Heading3 color="#452768" fontWeight="800" mb={4}>
                    {labels.billing} {companyData.admin}
                  </Heading3>
                  {paymentMethods ? (
                    <>
                      {intentToChangePaymentMethod ? (
                        <Elements stripe={stripePromise}>
                          <BillingForm
                            onCancelChangePaymentMethod={() => {
                              setIntentToChangePaymentMethod(false)
                            }}
                            onChangePaymentMethod={() => {
                              window.location.reload()
                              setIntentToChangePaymentMethod(false)
                            }}
                          />
                        </Elements>
                      ) : (
                        <>
                          {paymentMethods.map((paymentMethod, key) => (
                            <PaymentMethodDisplay
                              key={key}
                              paymentMethod={paymentMethod}
                              selected={selectedCard?.id === paymentMethod.id}
                              onClick={() => setSelectedCard(paymentMethod)}
                            />
                          ))}
                          <Button onClick={() => setIntentToChangePaymentMethod(true)}>{labels.add}</Button>
                        </>
                      )}
                    </>
                  ) : (
                    <Loading />
                  )}
                </Box>
                <Box width={[1, 1, 0.4]} padding={5}>
                  <Heading3 color="#452768" fontWeight="800" mb={4}>
                    Account info
                  </Heading3>
                  <Flex flexDirection="row" justifyContent="space-between">
                    <Text style={{ fontSize: 14, color: '#3A1256', fontWeight: 800 }}>{labels.company}</Text>
                    <Text style={{ fontSize: 14, color: '#3A1256' }}>{companyData.company ?? companyData.admin}</Text>
                  </Flex>
                  <Flex flexDirection="row" justifyContent="space-between" style={{ marginTop: 20 }}>
                    <Text style={{ fontSize: 14, color: '#3A1256', fontWeight: 800 }}>Plan</Text>

                    <Flex flexDirection="column">
                      <Text style={{ fontSize: 14, color: '#3A1256' }}>{companyData.subscription || 'TASTER'}</Text>
                      <Text style={{ fontSize: 12, color: '#3A1256' }}>
                        {currentSubscription && currentSubscription.status}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
              <Box paddingX={5} paddingTop={40}>
                {showBilling && (
                  <Plans
                    eventType={eventType}
                    currentSubscription={companyData.subscription}
                    subscriptionSelection={subscriptionSelection}
                    setEventType={setEventType}
                    setSubscriptionSelection={handleSubscriptionPicked}
                  />
                )}
              </Box>
            </Flex>
          </CardSection>
        </Card>
      </AdminLayout>
    </>
  )
}

export default PageProfile
