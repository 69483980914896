import { Subscription } from 'Pages/Admin/PageProfile/Plans'

export type GetCompanyArguments = {
  companyName: string
}

export enum SubscriptionStatus {
  TRIAL = 'trial',
  NOT_SUBSCRIBED = 'unsubscribed',
  SUBSCRIPTION = 'subscription',
}

export type PicclesSubscription = {
  status: SubscriptionStatus
  subscription?: Subscription
}

export type CompanyData = {
  admin: string
  createdBy: string
  createdOn: {
    _seconds: number
    _nanoseconds: number
  }
  company: string
  trialEndsOn?: {
    _seconds: number
    _nanoseconds: number
  }
  plan: SubscriptionStatus
  subscription?: Subscription
  stripeSubscriptionId?: string
  stripeCustomerId: string
  singleEventType?: Subscription
}

const getCompany = async ({ companyName }: GetCompanyArguments, endpointUrl: string): Promise<CompanyData> => {
  try {
    const request = await fetch(`${endpointUrl}/name/${companyName}`, { mode: 'cors' })
    const data = await request.json()
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getCompany
