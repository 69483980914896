import { Box, Flex } from 'rebass'
import { css } from '@emotion/core'
import { CrayonButtonSmall, CrayonLayout, CrayonText } from 'components'
import { Question, SponsorsData } from 'types'
import React, { Fragment } from 'react'

import { CrayonProjection } from 'components/molecules/CrayonProjection'
import { CrayonPiccleCanvasContainer as PiccleCanvas } from 'components'
import createPiccle from 'api/crayon/createPiccle'
import { useNavigate } from 'hooks/useNavigate'

type PageNextAsyncDrawingProps = {
  labels: {
    next: string
    again: string
    finish: string
    poweredBy: string
  }
  journeyCollectionPath: string
  category: string
  currentQuestionIndex: string
  templateId: string
  journeyId: string
  randomQuestion: Question
  question: Question
  piccleId: string
  language: string
  sponsors: SponsorsData
}

const PageCrayonDrawingShare = ({
  labels,
  journeyCollectionPath,
  templateId,
  currentQuestionIndex,
  category,
  journeyId,
  randomQuestion,
  question,
  piccleId,
  language,
  sponsors = {},
}: PageNextAsyncDrawingProps) => {
  const navigate = useNavigate()
  const hasNextQuestion: boolean = parseInt(currentQuestionIndex) < 3

  const handleDrawAgain = async () => {
    try {
      const newPiccleId = await createPiccle({
        journeyCollectionPath,
        journeyId,
        questionId: question.id,
      })

      navigate(
        window.location.pathname
          .split('/')
          .slice(0, -2)
          .join('/') + `/${newPiccleId}`,
        { replace: true },
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleNextDrawing = async () => {
    try {
      if (hasNextQuestion) {
        const newPiccleId = await createPiccle({
          journeyCollectionPath,
          journeyId,
          questionId: randomQuestion.id,
        })
        navigate(
          window.location.pathname
            .split('/')
            .slice(0, -4)
            .join('/') + `/${parseInt(currentQuestionIndex) + 1}/${randomQuestion.id}/${newPiccleId}`,
          { replace: true },
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  const goToJourneyEndPage = () => {
    navigate(`/t/${journeyCollectionPath}/${templateId}/${journeyId}/${language}/${category}/end`)
  }

  return (
    <CrayonLayout
      showHeader
      showFooter
      primaryLogo={sponsors['primarySponsor'] && sponsors['primarySponsor'].imgUrl}
      secondaryLogo={sponsors['secondarySponsor'] && sponsors['secondarySponsor'].imgUrl}
      footerText={labels.poweredBy}
    >
      <Fragment>
        <Flex flexDirection="column" justifyContent="center" mb={25}>
          <CrayonText
            text={question.title[language]}
            styles={{
              color: '#000',
              fontSize: '17px',
              margin: '25px',
            }}
          />
          <PiccleCanvas
            journeyCollectionPath={journeyCollectionPath}
            journeyId={journeyId}
            piccleId={piccleId}
            height={150}
            width={150}
          />
        </Flex>
        <Flex data-testid="PageShare" width="100%" flexDirection="column" alignItems="center" marginBottom={25}>
          {hasNextQuestion ? (
            <Flex width={['80%', '65%', '50%']} justifyContent="space-around">
              <CrayonButtonSmall height="45px" onClick={handleDrawAgain} size="small">
                <CrayonText
                  text={labels.again}
                  styles={{
                    color: '#FFF',
                    fontSize: '17px',
                    fontWeight: 500,
                  }}
                />
              </CrayonButtonSmall>
              <CrayonButtonSmall onClick={handleNextDrawing}>
                <CrayonText
                  text={labels.next}
                  styles={{
                    color: '#FFF',
                    fontSize: '17px',
                    fontWeight: 500,
                  }}
                />
              </CrayonButtonSmall>
            </Flex>
          ) : (
            <Flex width="50vw" justifyContent="space-around">
              <CrayonButtonSmall onClick={handleDrawAgain} size="small">
                <CrayonText
                  text={labels.again}
                  styles={{
                    color: '#FFF',
                    fontSize: '17px',
                  }}
                />
              </CrayonButtonSmall>
              <CrayonButtonSmall
                onClick={goToJourneyEndPage}
                size="small"
                style={{ marginLeft: 10, background: '#1a508f' }}
              >
                <CrayonText
                  text={labels.finish}
                  styles={{
                    color: '#FFF',
                    fontSize: '17px',
                  }}
                />
              </CrayonButtonSmall>
            </Flex>
          )}
        </Flex>
        <Box
          css={css`
            width: 100vw;
            overflow: auto;
            height: 40vh;
          `}
        >
          <CrayonProjection
            journeyCollectionPath={journeyCollectionPath}
            questionId={question.id}
            journeyId={journeyId}
          />
        </Box>
      </Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonDrawingShare
