import React from 'react'
import { Box } from 'rebass'

export const FormGroup = ({ children }) => (
  <>
    {children.map((child, i: number) => (
      <Box mb={4} key={child.name || child.label || child.value || i}>
        {child}
      </Box>
    ))}
  </>
)
