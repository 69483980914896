/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useRef } from 'react'
import { fabric } from 'fabric'
import { Flex } from '@lightspeed/flame/Core'
import { Card } from '@lightspeed/flame/Card'
import pick from 'lodash.pick'
import updatePiccle from 'api/piccles/createPiccleStroke'
import { canvasObjConstantProperties } from 'appConstants'
import picclesApi, { isPiccleDone } from 'api'

type Props = {
  journeyId: string
  activityId: string
  piccleId: string
  canvasObjects?: any
  brushSize: number
  currentColor: string
  onStroke: (arg) => void
}
const CANVAS_SIZE = 320

export const DrawingCanvas = ({
  journeyId,
  activityId,
  piccleId,
  canvasObjects,
  currentColor,
  brushSize,
  onStroke,
}: Props) => {
  // Set references necessary for changing canvas properties as props change.
  const canvas = useRef(null)
  const canvasSize = useRef(null)

  const boxRef = useRef(null)

  // Necessary to avoid stale reference to onStroke
  const onStrokeRef = useRef<typeof onStroke>()
  onStrokeRef.current = onStroke

  const reSizeCanvas = () => {
    // calculate the new canvas size and set the dimensions of the canvas

    const reSizeCanvasSize = Math.min(boxRef.current.clientHeight, boxRef.current.clientWidth)
    canvas.current.setDimensions({ width: reSizeCanvasSize, height: reSizeCanvasSize })

    // get the previous canvas size and calcualte the difference in scale to the size. Use this to
    // scale the brush strokes by this value
    const prevCanvasSize = canvasSize.current
    const delta = reSizeCanvasSize / prevCanvasSize
    canvas.current.setZoom(canvas.current.getZoom() * delta)

    // set the current canvas size to the new size for future resize calculations
    canvasSize.current = reSizeCanvasSize

    // render both the top canvas and the secondary canvas
    canvas.current.renderAll()
  }

  const loadObjectsToCanvas = (canvasObjects) => {
    canvas.current.loadFromJSON({ objects: canvasObjects, backgroundColor: 'white' })
  }

  const initializeCanvas = async () => {
    const initialCanvasSize = Math.min(boxRef.current.clientHeight, boxRef.current.clientWidth)
    canvasSize.current = initialCanvasSize

    // Initialize the canvas element
    canvas.current = new fabric.Canvas(piccleId, {
      width: initialCanvasSize,
      height: initialCanvasSize,
      isDrawingMode: true,
      selection: false,
    })
    canvas.current.setZoom(initialCanvasSize / CANVAS_SIZE)

    // Set some defaults on the canvas
    fabric.Object.prototype.transparentCorners = false

    canvas.current.freeDrawingBrush.width = brushSize
    canvas.current.freeDrawingBrush.color = currentColor

    // add the pre-existing canvas elements
    if (canvasObjects) {
      loadObjectsToCanvas(canvasObjects)
    }

    const { activityApi } = picclesApi()
    // // checking if editing after done is on.
    const allowEditAfterDone = await activityApi.getIsActivityEditable({ activityId, journeyId })
    // checking if piccle was submited already
    const isDone = await isPiccleDone({ activityId, journeyId, piccleId })

    if (allowEditAfterDone) {
      canvas.current.on('path:created', onBrushStroke)
    } else if (!isDone) {
      canvas.current.on('path:created', onBrushStroke)
    }

    // setup callback to be triggered on every path creation event
  }

  const onBrushStroke = async (newObj) => {
    /**
     * First we extract ONLY the properties of canvas objects that aren't constant.
     * The rest will be rehydrated upon reception from the firebase.
     *  */
    const trimmedCanvasObject = pick(newObj.path, ['height', 'left', 'stroke', 'strokeWidth', 'top', 'width', 'path'])
    try {
      const svg = canvas.current.toSVG().replace(new RegExp(/(<svg\s.+)width="\d+"\sheight="\d+"(.+)/), '$1$2')

      const strokeId = await updatePiccle({ payload: trimmedCanvasObject, journeyId, activityId, piccleId, svg })
      onStrokeRef.current({ ...trimmedCanvasObject, ...canvasObjConstantProperties, id: strokeId })
    } catch (e) {
      console.error(e)
    }
  }

  // Inititialize canvas and set resize listeners on mount
  useEffect(() => {
    initializeCanvas()
    window.addEventListener('resize', reSizeCanvas)
    return () => {
      window.removeEventListener('resize', reSizeCanvas)
    }
  }, []) // eslint-disable-line

  // When canvasObjects change length, reload (ie: to reflect an undo action)
  useEffect(() => {
    loadObjectsToCanvas(canvasObjects)
  }, [canvasObjects.length]) // eslint-disable-line

  // Set canvas brush color and size when they change
  useEffect(() => {
    if (canvas.current) {
      canvas.current.freeDrawingBrush.width = brushSize
      canvas.current.freeDrawingBrush.color = currentColor
    }
  }, [brushSize, currentColor]) // eslint-disable-line
  return (
    <Flex alignItems="center" justifyContent="center" ref={boxRef} flex="1" height="100%">
      <Card>
        <canvas id={piccleId} />
      </Card>
    </Flex>
  )
}
