import React from 'react'
import { Button, ButtonProps } from '@lightspeed/flame/Button'

export const CrayonButton: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const buttonProps: ButtonProps = {
    ...props,
    style: {
      height: '75px',
      width: '260px',
      backgroundColor: '#1a508f',
      borderRadius: '15px',
      border: 0,
    },
  }
  return <Button fill variant="#1a508f" data-testid="Crayon-Button" {...buttonProps} />
}
