/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { FunctionComponent, ReactElement, Fragment } from 'react'
import { Flex, Box } from '@lightspeed/flame/Core'
import { Text, Image } from 'rebass'
import { CrayonProjection, CrayonPiccleCanvasContainer } from 'components'
import { Card } from '@lightspeed/flame/Card'
import picclesLogo from 'assets/images/piccles-logo.png'
import { useBreakPoint } from 'hooks/useBreakPoint'
import { useQrModalState } from 'hooks/useQrModalState'
import { Modal, ModalBody, ModalHeader } from '@lightspeed/flame/Modal'
import QRCode from 'qrcode.react'
import useTranslations from 'hooks/useTranslations'
import { QrModalStateCloseAction } from 'Contexts/QrContext/actions'
import { SidebarCrayonProjection } from 'components'
import { Question, TemplateJourney, TemplatePromotedPiccle } from 'types'

const translations = {
  en: {
    toParticipate: 'To participate go to:',
    useCode: 'and use code:',
    scanPrompt: 'Scan this QR code to start drawing!',
  },
  fr: {
    toParticipate: 'Pour participer va sur:',
    useCode: 'et entre le code:',
    scanPrompt: 'Scan ce code QR pour dessiner!!',
  },
}

type Props = {
  questions: Question[]
  journey: TemplateJourney
  templateId: string
  journeyId: string
  journeyCollectionPath: string
  QRenabled: boolean
}

const PageCrayonProjectionView: FunctionComponent<Props> = ({
  questions,
  journey,
  templateId,
  journeyId,
  journeyCollectionPath,
  QRenabled = false,
}: Props): ReactElement => {
  const breakPoint: string = useBreakPoint()
  const labels = useTranslations(translations)

  const windowHeight: number = window.innerHeight
  const height = window.innerWidth

  const [qrModalState, qrModalDispatch] = useQrModalState()

  const getPromotedPiccle = (): TemplatePromotedPiccle => {
    return journey.promotedPiccles && journey.promotedPiccles.find((p) => p.questionId === journey.currentQuestion)
  }

  const getCurrentQuestion = (): Question => {
    const currentQuestion = questions.find((q) => q.id === journey.currentQuestion)
    if (currentQuestion) {
      return currentQuestion
    } else {
      return questions[0]
    }
  }

  const journeyStartUrl = `${window.location.protocol}/${window.location.host}/t/${journeyCollectionPath}/${templateId}/${journeyId}`
  return (
    <Fragment>
      <Flex bg="background" height="100vh" flex={1} width="100vw" data-testid="PageProjectionViewEnterprise" p={2}>
        {breakPoint === 'lg' ? (
          <SidebarCrayonProjection url={journeyStartUrl} code={journey.code} QRenabled={QRenabled} />
        ) : null}

        <Card
          css={(theme) => css`
            background: ${theme.colors.white};
            width: ${breakPoint === 'lg' ? '83.333%' : '100%'};
            height: 100%;
            text-align: center;
            display: flex;
            flex-flow: column;
            position: relative;
          `}
        >
          <Flex justifyContent="center" style={{ display: '-webkit-box' }}>
            <Image src={picclesLogo} width="8vw" mt={2} display={['inline', 'inline', 'none']} />
          </Flex>
          <Text
            fontSize={['large', 'xlarge']}
            pt={[0, 3]}
            pb={[2, 3]}
            mx={2}
            css={(theme) => css`
              text-align: center;
              font-weight: 700;
              border-bottom: 1px solid ${theme.colors.text};
            `}
            data-cy="ActivityPrompt"
          >
            {getCurrentQuestion().title.en}
          </Text>
          {getPromotedPiccle() && (
            <Box className="promoted" width="50%">
              <CrayonPiccleCanvasContainer
                journeyCollectionPath={journeyCollectionPath}
                piccleId={getPromotedPiccle().piccleId}
                journeyId={journey.id}
                height={windowHeight / 2}
                width={windowHeight / 2}
              />
            </Box>
          )}
          <Box p={2} height="105vh">
            <CrayonProjection
              journeyCollectionPath={journeyCollectionPath}
              journeyId={journey.id}
              questionId={getCurrentQuestion().id}
            />
          </Box>
        </Card>
      </Flex>
      {QRenabled && (
        <Modal
          data-cy="QRmodal"
          isOpen={qrModalState}
          onRequestClose={() => qrModalDispatch(new QrModalStateCloseAction())}
        >
          <ModalHeader
            style={{ textAlign: 'center' }}
            onCloseClicked={() => qrModalDispatch(new QrModalStateCloseAction())}
          >
            {labels.scanPrompt}
          </ModalHeader>

          <ModalBody
            css={{
              overflow: 'auto',
              marginBottom: '1.5vw',
            }}
          >
            <Flex flexDirection="row" justifyContent="center">
              <QRCode size={height / 3} value={journeyStartUrl} />
            </Flex>
          </ModalBody>
        </Modal>
      )}
    </Fragment>
  )
}
export default PageCrayonProjectionView
