export default {
  primary: '#8FD61D', // validation
  primarydark: '#6AAD12', // links
  secondary: '#452768', // buttons, loadingbar
  secondarydark: '#27173A', // Titles
  white: '#FFF', // Use for boxes, header, text on coloured backgrounds
  background: '#F1F1F2', // screen background and borders
  text: '#27173A',
  overlay: 'rgba(39, 23, 58, 95)', // Under pop-up box
  textfill: 'rgba(39, 23, 58, 50)', // for text-are default text and borders
  primarydisabled: 'rgba(143, 214, 29, 50)', // disabled button,
  textdisabled: 'rgba(191, 191, 193, 50)', // disabled button,
  textDim: '#929292',
}
