import Firebase from 'firebase/app'

type Args = {
  journeyCode: string
  activityId: string
  setError: (arg) => void
  setData: (arg) => void
  setLoading: (arg) => void
}

const getActivityData = async ({ journeyCode, activityId, setError, setData, setLoading }: Args): Promise<void> => {
  setLoading(true)

  try {
    // First get the journeyId from the code.
    const journeyId: any = await Firebase.firestore()
      .collection('journeys')
      .where('code', '==', journeyCode)
      .get()
      .then(
        (snap: Firebase.firestore.QuerySnapshot) =>
          snap.docs.map((doc: Firebase.firestore.QueryDocumentSnapshot) => doc.id)[0],
      )

    const activityData = await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .doc(activityId)
      .get()
      .then((doc: Firebase.firestore.DocumentSnapshot) => ({ ...doc.data(), id: doc.id }))
    setData(activityData)
    setLoading(false)
  } catch (error) {
    setError(error)
    setLoading(false)
  }
}

export default getActivityData
