/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useLocale } from 'hooks/useChangeLocale'
import { langValues } from '../../molecules/PromptActivityForm/PromptActivityForm'
// ../PromptActivityForm/PromptActivityForm
type Props = {
  languages?: string[]
}

// language toggler is conditional
export const LanguageToggle: React.FunctionComponent<Props> = ({ languages }: Props): JSX.Element => {
  const { locale, changeLocale } = useLocale()

  const handleSwitch = () => {
    changeLocale(locale === 'en' ? 'fr' : 'en')
  }

  const customizedHandleSwitch = (value) => {
    changeLocale(value)
  }
  // customized toggler for client-side
  if (languages) {
    return (
      <div
        data-testid="LanguageToggle"
        css={css`
          position: absolute;
          left: 10px;
        `}
      >
        <select
          name="language"
          id="language"
          onChange={(event) => {
            event.preventDefault()
            customizedHandleSwitch(event.target.value)
          }}
          value={locale}
          // style={{ marginTop: '5px', padding: '8px 30px' }}
        >
          {languages.map((lang) => {
            return (
              <option key={lang} value={lang}>
                {langValues[lang]}
              </option>
            )
          })}
        </select>
      </div>
    )
  }
  // for general usage e.g. welcome page
  return (
    <div
      data-testid="LanguageToggle"
      css={css`
        position: absolute;
        left: 10px;
      `}
    >
      <label
        css={css`
          position: relative;
          display: inline-block;
          width: 60px;
          height: 34px;
        `}
      >
        <input
          type="checkbox"
          onChange={handleSwitch}
          checked={locale === 'fr'}
          css={css`
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + span:before {
              transform: translateX(26px);
            }
            &:checked ~ div > p:nth-of-type(1) {
              color: #bebebe;
              transition: color 0.4s ease;
            }
            &:checked ~ div > p:last-child {
              color: #27173a;
              transition: color 0.4s ease;
            }
          `}
        ></input>
        <span
          css={css`
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #f1f1f2;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 34px;
            &:before {
              position: absolute;
              content: '';
              height: 26px;
              width: 26px;
              left: 4px;
              bottom: 4px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              border-radius: 50%;
            }
          `}
        ></span>
        <div
          css={css`
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            z-index: 2;
            align-items: center;
            justify-content: space-evenly;
            top: 0;
          `}
        >
          <p
            css={css`
              color: #27173a;
              transition: color 0.4s ease;
            `}
          >
            EN
          </p>
          <p
            css={css`
              color: #bebebe;
              transition: color 0.4s ease;
            `}
          >
            FR
          </p>
        </div>
      </label>
    </div>
  )
}
