import React from 'react'
import { useJourneyData } from 'hooks/useJourneyData'
import { Loading, Error, NotAccessible } from 'components'
import { Data } from 'Pages/Client/PageProjectionView/PageProjectionViewFree'
import PageProjectionViewEnterprise from 'Pages/Client/PageProjectionView/PageProjectionViewEnterprise'
import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import picclesLogo from 'assets/images/piccles-logo.png'

const PageProjectionViewContainer = (props) => {
  const [journeyData, journeyLoading, journeyError]: [Data, boolean, Error] = useJourneyData({
    journeyId: props.journeyId,
  })

  // needed keep backwards compatibility we need a default value here.
  const { accessible = true } = journeyData || {}

  // Gets the list of activities of a certain journeyID
  const [activities, activitiesLoading, activitiesError]: [any[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .doc(props.journeyId)
      .collection('activities'),
    { idField: 'id' },
  )

  // Get the currently active activity's sponsorID
  const currentActivitySponsorId: string = activities
    ? activities.filter((activity) => (activity.isStarted && !activity.isComplete) || activity)[0]?.sponsor
    : null

  // Gets list of sponsors
  const [sponsorsData, sponsorsLoading]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore().collection(`sponsors`),
    {
      idField: 'id',
    },
  )
  // Finds the current activity's sponsor based on the sponsor id, and get the logo. Else use the piccles logo
  const logo = currentActivitySponsorId
    ? sponsorsData?.filter((sponsor) => sponsor.id === currentActivitySponsorId)[0]?.imgUrl
    : picclesLogo

  const loading = journeyLoading || activitiesLoading
  const error = journeyError || activitiesError

  return (
    <>
      {!accessible && <NotAccessible />}
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journeyData && accessible && (
        <PageProjectionViewEnterprise
          data={journeyData}
          journeyId={props.journeyId}
          logo={logo}
          sponsorsLoading={sponsorsLoading}
          QRenabled={journeyData.QRcode}
        />
      )}
    </>
  )
}

export default PageProjectionViewContainer
