import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

export type UpdateQuestionArgs = {
  templateId: string
  questionId: string
  payload: any
}

const updateQuestion = ({ templateId, questionId, payload }: UpdateQuestionArgs): Promise<any> => {
  ReactGA.event({
    category: 'Template-Question',
    action: 'Update template question',
  })

  return Firebase.firestore()
    .collection(`templates/${templateId}/questions`)
    .doc(questionId)
    .update(prepareDocForUpdate(payload))
    .then(() => payload)
    .catch((error) => {
      alert(`Whoops, couldn't update the journey for crayon: ${error.message}`)
    })
}

export default updateQuestion
