import Firebase from 'firebase/app'
import { fabric } from 'fabric'
import { canvasObjConstantProperties } from 'appConstants'
import memoize from 'micro-memoize'
import { deepEqual } from 'fast-equals'
import 'firebase/storage'

const getJourney = async (journeyId: string) => {
  return Firebase.firestore()
    .doc(`journeys/${journeyId}`)
    .get()
    .then((doc: Firebase.firestore.DocumentSnapshot): { id: string; title: string } => ({
      id: doc.id,
      title: doc.data().title,
    }))
}

const canvasToImage = ({ piccleId, height, width, canvasObjects }) => {
  const canvasId = `${piccleId}-${height}-${width}`

  const initialCanvasSize = width
  // Initialize the canvas element
  const canvas = new fabric.StaticCanvas(canvasId, {
    width: width,
    height: height,
  })
  canvas.setZoom(initialCanvasSize / 320)

  // Set some defaults on the canvas
  fabric.Object.prototype.transparentCorners = false

  // add the pre-existing canvas elements
  if (canvasObjects) {
    canvas.loadFromJSON({ objects: canvasObjects, backgroundColor: 'white' }, null)
  }
  const image = canvas.toDataURL({ format: 'png' })
  canvas.dispose()

  return image
}

const memoizedCanvasToImage = memoize(canvasToImage, { isEqual: deepEqual, maxSize: 128 })

const getPiccleStrokes = async (journeyId, activityId, piccleId) => {
  return Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection(`activities/${activityId}/piccles/${piccleId}/strokes`)
    .orderBy('createdOn', 'asc')
    .get()
    .then((snap: Firebase.firestore.QuerySnapshot) => snap.docs)
    .then((docs: Firebase.firestore.QueryDocumentSnapshot[]) =>
      docs.map((doc: Firebase.firestore.QueryDocumentSnapshot): {
        doc: Firebase.firestore.DocumentData
      } => ({ ...doc.data(), doc: doc.metadata })),
    )
}

export const downloadJourneyOnDone: Function = async (
  journeyId: string,
  activityId: string,
  piccleId: string,
): Promise<{ type: string; goal?: number; completed?: number }> => {
  try {
    const journey = await getJourney(journeyId)

    let piccleStrokes = await getPiccleStrokes(journey.id, activityId, piccleId)

    if (piccleStrokes.length > 0) {
      piccleStrokes = piccleStrokes.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))
      const image = memoizedCanvasToImage({
        piccleId,
        height: 250,
        width: 250,
        canvasObjects: piccleStrokes,
      })

      const imageName = `${piccleId}.png`

      const storageRef: Firebase.storage.Reference = await Firebase.storage().ref()
      const imageRef: Firebase.storage.Reference = storageRef.child(`${journeyId}/${activityId}/${imageName}`)

      await imageRef.putString(image, 'data_url')
    }

    return { type: 'success' }
  } catch (err) {
    console.log(err)
    return { type: 'error' }
  }
}
