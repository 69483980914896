import React, { memo } from 'react'
import './PiccleCanvas'
import picclesApi from 'api'

type Props = {
  piccleId: string
  journeyId?: string
  activityId?: string
  svg: string
  width?: number
  height?: number
}

export default memo(({ piccleId, journeyId, activityId, svg }: Props) => (
  <div
    onDoubleClick={() => {
      console.log(piccleId)
      const { activityApi } = picclesApi()
      return activityApi.updateActivity({
        journeyId,
        activityId: activityId,
        payload: { promotedPiccle: piccleId },
      })
    }}
    dangerouslySetInnerHTML={{ __html: svg }}
  />
))
