import React from 'react'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import { Loading, Error } from 'components'
import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Question, QuestionCategory, TemplateJourney } from 'types'
import PageCrayonProjectionView from './PageCrayonProjectionView'

const PageCrayonProjectionViewContainer = (props) => {
  const [allQuestions, questionsLoading, questionsError]: [Question[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('templates')
      .doc(props.templateId && props.templateId)
      .collection('questions')
      // eslint-disable-next-line
      //@ts-ignore
      .where('categories', 'array-contains-any', Object.keys(QuestionCategory))
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )

  const { journeyCollectionPath, templateId, journeyId } = props
  const [templateJourney, templateJourneyLoading, templateJourneyError]: [
    TemplateJourney,
    boolean,
    Error,
  ] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  //get sponsers

  const loading = questionsLoading || templateJourneyLoading
  const error = questionsError || templateJourneyError

  return (
    <>
      {error && questionsError && <Error error={error} />}
      {loading && questionsLoading && <Loading />}
      {allQuestions && templateJourney && (
        <PageCrayonProjectionView
          journeyCollectionPath={journeyCollectionPath}
          questions={allQuestions}
          journey={templateJourney}
          QRenabled={templateJourney.QRcode}
          templateId={templateId}
          journeyId={journeyId}
        />
      )}
    </>
  )
}

export default PageCrayonProjectionViewContainer
