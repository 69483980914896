import React, { CSSProperties } from 'react'
import usePiccleSVG from 'hooks/usePiccleSVG'
import { Error } from 'components'
import PiccleCanvas from './PiccleCanvas'

type Props = {
  height?: number
  width?: number
  style?: CSSProperties
  activityId: string
  piccleId: string
  journeyId: string
}

export const PiccleCanvasContainer: React.FunctionComponent<Props> = ({
  journeyId,
  piccleId,
  height,
  width,
  activityId,
}: Props) => {
  const [svg, loading, error] = usePiccleSVG({ journeyId, activityId, piccleId, height, width })

  return (
    <>
      {error && !loading && <Error error={error} />}
      {svg && <PiccleCanvas piccleId={piccleId} height={height} width={width} svg={svg} />}
    </>
  )
}
