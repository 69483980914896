import Firebase from 'firebase/app'
import { saveAs } from 'file-saver'
import { Journey } from 'hooks/useJourneyData'
import 'firebase/storage'

const downloadJourneyParticipants: Function = async (
  journey: Journey,
): Promise<{ type: string; goal?: number; completed?: number }> => {
  try {
    // get all activities
    await Firebase.firestore()
      .collection('journeys')
      .doc(journey.id)
      .get()
      .then((doc) => {
        const { participants } = doc.data()
        const file = new File([participants.join(',')], `${journey.title}_participant_contacts.txt`, {
          type: 'text/plain;charset=utf-8',
        })
        saveAs(file)
      })
    return { type: 'success' }
  } catch (err) {
    console.log(err)
    return { type: 'error' }
  }
}

export default downloadJourneyParticipants
