import Firebase from 'firebase/app'

export type GetJourneyArgs = {
  journeyId: string
}

const getCrayonJourney = async ({ journeyId }: GetJourneyArgs): Promise<any> => {
  const journeyRef = await Firebase.firestore()
    .collection('crayonJourneys')
    .doc(journeyId)
    .get()
  return journeyRef.data()
}

export default getCrayonJourney
