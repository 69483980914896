const env = 'production' // Fall back on dev incase the app is built and run somehow without setting NODE_ENV
/**
 *  Currently we use the same firebase db for all environments.
 *  For the sake of completeness and configurability, all 3 environments are configured
 *  so as make change easy in the future when/if we do spool separate dbs for different envs.
 *
 *  Sentry is also configured as 1 environment. Again changing this should be as straightforward as
 *  changing the production sentry dsn when we spool one.
 *
 *  API KEYS should NOT be written directly into this file.
 *  1. Add REACT_APP_YOUR_KEY for dev env in your local `.env` file (which is .gitignored)
 *     Add REACT_APP_YOUR_KEY for prod/staging envs in sempahore (or w/e the CI for deployments currently is)
 *  2. Access REACT_APP_YOUR_KEY with `process.enc.REACT_APP_YOUR_KEY`
 *
 *  Note: create-react-app requires that .env file env_vars start with "REACT_APP_"
 */

const config = {
  development: {
    env,
    firebase: {
      apiKey: 'AIzaSyAKiKliH66mBTJywGCOmv5DvV1Ibzokq_U',
      authDomain: 'app.piccles.com',
      databaseURL: 'https://piccles-dev.firebaseio.com/',
      projectId: 'piccles-dev',
      storageBucket: 'gs://piccles-dev.appspot.com/',
    },
    api: {
      url: 'http://localhost:5002/piccles-dev/us-central1/webApi',
    },
    sentry: {
      dsn: 'https://ca641fcbb23644e48ace58411afddd1b@sentry.io/1425211',
    },
    google: {
      analytics: {
        trackingId: 'NA',
      },
    },
    stripe: {
      publicKey: 'pk_test_dLJVrzdbn2e1tBhYEXc2VODe008QBT1eox',
    },
  },
  staging: {
    env,
    firebase: {
      apiKey: 'AIzaSyAKiKliH66mBTJywGCOmv5DvV1Ibzokq_U',
      authDomain: 'piccles-v2-staging.web.app/',
      databaseURL: 'https://piccles-dev.firebaseio.com/',
      projectId: 'piccles-dev',
      storageBucket: 'gs://piccles-dev.appspot.com/',
    },
    api: {
      url: 'https://us-central1-piccles-dev.cloudfunctions.net/webApi',
    },
    sentry: {
      dsn: 'https://ca641fcbb23644e48ace58411afddd1b@sentry.io/1425211',
    },
    google: {
      analytics: {
        trackingId: 'NA',
      },
    },
    stripe: {
      publicKey: 'pk_test_dLJVrzdbn2e1tBhYEXc2VODe008QBT1eox',
    },
  },
  production: {
    env,
    firebase: {
      apiKey: 'AIzaSyAKiKliH66mBTJywGCOmv5DvV1Ibzokq_U',
      authDomain: 'app.piccles.com',
      databaseURL: 'https://piccles-dev.firebaseio.com/',
      projectId: 'piccles-dev',
      storageBucket: 'gs://piccles-dev.appspot.com/',
    },
    api: {
      url: 'https://us-central1-piccles-dev.cloudfunctions.net/webApi',
    },
    sentry: {
      dsn: 'https://ca641fcbb23644e48ace58411afddd1b@sentry.io/1425211',
    },
    google: {
      analytics: {
        trackingId: 'NA',
      },
    },
    stripe: {
      publicKey: 'pk_live_XdUxnyVbMBA0iaySH7LVlsPA00V8GITLGM',
    },
  },
}

export default config[env]
