import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import generateRandomCode from '../helpers/uniqueCode'
import { prepareDocForCreate, verifyUniqueCode } from '../helpers/firestoreHelpers'
import { JourneyType } from '../../types'

const createJourney = async (data) => {
  ReactGA.event({
    category: 'Journey',
    action: 'create journey',
  })

  if (data.title) {
    data.slug = slugify(data.title, { lower: true })
  } else {
    data.title = ''
  }

  // try a first code
  let code = generateRandomCode(5)

  // regenerate code until it is unique
  while (!(await verifyUniqueCode(code))) {
    code = generateRandomCode(5)
  }

  // code is unique, assign it to creation
  data.code = code

  // Add draft boolean = true to journey as it's created.
  // This allows us to forgo displaying it on dashboard if user creates 0 activities.
  // This gets switched to true when the first activity is created.
  data.isDraft = true

  // Add the isDeleted = false property. This changes to true when user deletes a journey.
  data.isDeleted = false

  // Journeys are synchronous by default
  data.type = JourneyType.SYNCHRONOUS

  // English is default
  data.languages = ['en']

  return Firebase.firestore()
    .collection('journeys')
    .add(prepareDocForCreate(data))
    .then((res) => res.id)
    .catch((error) => {
      alert(`Whoops, couldn't create the journey: ${error.message}`)
    })
}

export default createJourney
