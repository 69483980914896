/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { memo, useEffect } from 'react'
import { CrayonPiccleCanvasContainer as PiccleCanvas } from 'components'
import { useInView } from 'react-intersection-observer'

type Props = {
  journeyCollectionPath: string
  journeyId: string
  piccleIds?: string[]
  loadMorePiccles?: () => Promise<void>
  isModeration?: boolean
  onClick?: (piccleId: string) => void
}

const CrayonProjectionGrid: React.FC<Props> = ({
  journeyCollectionPath,
  journeyId,
  piccleIds,
  loadMorePiccles,
  isModeration = false,
  onClick,
}) => {
  const [ref, inView] = useInView()

  const calculatePiccleSize = () => {
    if (piccleIds.length <= 2) return 350
    if (piccleIds.length <= 4) return 325
    if (piccleIds.length <= 6) return 250
    if (piccleIds.length <= 12) return 200
    if (piccleIds.length <= 16) return 150
    if (piccleIds.length <= 28) return 115
    if (piccleIds.length <= 45) return 90
    return 90
  }

  useEffect(() => {
    if (inView) {
      loadMorePiccles()
    } // eslint-disable-next-line
  }, [inView])

  return (
    <div
      className="async-projection"
      css={css`
        backgroundcolor: 'white';
      `}
      data-testid="CrayonProjectionViewGrid"
    >
      {piccleIds.map((piccleId) => (
        <div
          key={piccleId}
          css={css`
            backgroundcolor: 'white';
            ${isModeration ? '&:hover { opacity: 0.6 }' : ''};
            cursor: ${isModeration ? 'pointer' : ''};
            heigth: ${calculatePiccleSize()};
            width: ${calculatePiccleSize()};
          `}
          onClick={isModeration ? () => onClick(piccleId) : null}
        >
          <PiccleCanvas
            journeyCollectionPath={journeyCollectionPath}
            journeyId={journeyId}
            piccleId={piccleId}
            height={calculatePiccleSize()}
            width={calculatePiccleSize()}
          />
        </div>
      ))}
      <div ref={ref}>.</div>
    </div>
  )
}

export default memo(CrayonProjectionGrid)
