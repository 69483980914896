import { lighten, darken } from 'polished'
import colors from './colors'

const createButtonStyles = (color: string, fill = false) => ({
  backgroundImage: fill ? `linear-gradient(to bottom, ${lighten(0.05, color)}, ${color})` : undefined,
  backgroundColor: fill ? color : 'transparent',
  borderColor: fill ? darken(0.05, color) : color,
  color: fill ? colors.white : color,
  borderRadius: '30px',
  fontSize: '18px',
  fontWeight: '400',
  transition: 'all 1s ease',
  '&:hover': {
    backgroundImage: 'none',
    color: colors.white,
    backgroundColor: fill ? darken(0.05, color) : color,
    borderColor: fill ? 'rgba(12,13,13,0.5)' : color,
    transition: 'all .5s ease',
  },
  '&:active': {
    backgroundImage: 'none',
    color: fill ? colors.white : color,
    backgroundColor: fill ? darken(0.05, color) : 'none',
    boxShadow: `inset 0 1px 0 0 ${darken(0.1, color)}`,
    borderColor: fill ? 'rgba(12,13,13,0.5)' : color,
  },
})

// Button Variants
export default {
  outline: {
    primary: createButtonStyles(colors.primary, false),
    secondary: createButtonStyles(colors.secondary, false),
  },
  fill: {
    primary: createButtonStyles(colors.primary, true),
    secondary: createButtonStyles(colors.secondary, true),
  },
}
