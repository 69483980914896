export type SetupIntent = {
  clientSecret: string
  stripeCustomerId: string
}

const getSetupIntent = async (email: string, endpointUrl: string): Promise<SetupIntent> => {
  try {
    const response = await fetch(`${endpointUrl}/${email}/setup-intent`, { mode: 'cors' })
    if (response.status >= 400 && response.status <= 499) throw new Error('API Client Error')
    const data = response.json()
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getSetupIntent
