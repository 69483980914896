import 'firebase/firestore'
import Firebase from 'firebase'

export type WriteCompanyData = {
  companyName: string
  createdBy: string
  admin: string
  role?: string
  firstName: string
  lastName: string
  associatedUser: string
}

const createCompany = async (
  { companyName, createdBy, admin, role, firstName, lastName, associatedUser }: WriteCompanyData,
  endpointUrl: string,
): Promise<string> => {
  try {
    const resp = await fetch(endpointUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        company: companyName,
        createdBy,
        admin,
        createdOn: Firebase.firestore.Timestamp.fromDate(new Date()),
        role: role ? role : '',
        firstName,
        lastName,
        associatedUser,
      }),
    })
    const { data } = await resp.json()
    return data.docId
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default createCompany
