import React from 'react'
import { QrModalStateAction } from '../Contexts/QrContext/actions'
import { qrModalStateReducer } from '../Contexts/QrContext/reducers'
import {
  QrModalState,
  QrModalStateDispatch,
  QrModalStateProviderProps,
} from '../Contexts/QrContext/types/qrModalStateTypes'

const initialQrModalState = false as QrModalState

const QrModalStateContext: React.Context<QrModalState> = React.createContext(initialQrModalState)

const QrModalDispatchContext: React.Context<QrModalStateDispatch> = React.createContext(
  (_action: QrModalStateAction) => {}, // eslint-disable-line
)

export const QrModalStateProvider = ({ children }: QrModalStateProviderProps): JSX.Element => {
  const [state, dispatch] = React.useReducer(qrModalStateReducer, initialQrModalState)

  return (
    <QrModalStateContext.Provider value={state}>
      <QrModalDispatchContext.Provider value={dispatch}>{children}</QrModalDispatchContext.Provider>
    </QrModalStateContext.Provider>
  )
}

const useQrModalStateValue = (): QrModalState => {
  const qrModalContext = React.useContext(QrModalStateContext)

  if (qrModalContext === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }

  return qrModalContext
}

const useQrModalDispatch = (): QrModalStateDispatch => {
  const qrModalDispatchContext = React.useContext(QrModalDispatchContext)

  if (qrModalDispatchContext === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }

  return qrModalDispatchContext
}

export const useQrModalState: () => [QrModalState, QrModalStateDispatch] = () => [
  useQrModalStateValue(),
  useQrModalDispatch(),
]
