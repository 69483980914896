import { Box, Image } from 'rebass'
import { CrayonDropDown, CrayonLayout, CrayonText } from 'components'

import { Fragment } from 'react'
import React from 'react'
import { SponsorsData } from 'types'
/** @jsx jsx */
import { jsx } from '@emotion/core'

type Props = {
  labels: {
    pickPrompt: string
    select: string
    createdFor: string
    withHelp: string
  }
  questionCategories: string[]
  onCategoryPicked: (category: string) => void
  sponsors: SponsorsData
}

const PageCrayonPickJourney: React.FunctionComponent<Props> = ({
  labels,
  questionCategories,
  onCategoryPicked,
  sponsors = {},
}: Props): JSX.Element => {
  const getSponsorLogo = (type: string) => {
    return sponsors[type]?.imgUrl
  }

  return (
    <CrayonLayout showHeader showFooter primaryLogo={getSponsorLogo('primarySponsor')}>
      <Fragment>
        <Box mt={25}>
          <CrayonText text={labels.pickPrompt} />
        </Box>
        <Box mt={25} mb={40}>
          <CrayonDropDown name={labels.select} items={questionCategories} onItemClicked={onCategoryPicked} />
        </Box>
        {sponsors['hospitalSponsor'] && (
          <Box>
            <CrayonText text={labels.createdFor} />
            <Box mt={20} mb={20}>
              <Image mt={25} src={getSponsorLogo('hospitalSponsor')} width="260px" sx={{ maxHeight: '200px' }} />
            </Box>
          </Box>
        )}
        {sponsors['secondarySponsor'] && (
          <Box>
            <CrayonText text={labels.withHelp} />
            <Box mt={20}>
              <Image mt={25} src={getSponsorLogo('secondarySponsor')} width="260px" sx={{ maxHeight: '200px' }} />
            </Box>
          </Box>
        )}
      </Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonPickJourney
