import Firebase from 'firebase/app'

export type GetJourneyArgs = {
  journeyId: string
}

const getJourney = async ({ journeyId }: GetJourneyArgs): Promise<any> => {
  const journeyRef = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .get()
  return journeyRef.data()
}

export default getJourney
