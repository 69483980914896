import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

export type DeleteJourneyArgs = {
  journeyId: string
}

const deleteCrayonJourney = async ({ journeyId }: DeleteJourneyArgs) => {
  // Google analytics
  ReactGA.event({
    category: 'Crayon-Journey',
    action: 'Delete Crayon journey',
  })

  // Here is where we do the deed. Set `isDeleted === true` and remove code.
  try {
    return await Firebase.firestore()
      .collection('crayonJourneys')
      .doc(journeyId)
      .update({ isDeleted: true, code: '' })
  } catch (e) {
    console.log(e)
  }
}

export default deleteCrayonJourney
