import { Error, Loading } from 'components'
import Firebase from 'firebase/app'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Question, TemplateJourney, QuestionCategory } from 'types'
import PageTemplateModeration from './PageTemplateModeration'

const PageTemplateModerationContainer = (props) => {
  const [allQuestions, questionsLoading, questionsError]: [Question[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('templates')
      .doc(props.templateId)
      .collection('questions')
      // eslint-disable-next-line
      //@ts-ignore
      .where('categories', 'array-contains-any', Object.keys(QuestionCategory))
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )

  const { journeyCollectionPath, journeyId } = props

  const [templateJourney, templateJourneyLoading, templateJourneyError]: [
    TemplateJourney,
    boolean,
    Error,
  ] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  // Merge all Loading and Error
  const loading = questionsLoading || templateJourneyLoading
  const error = questionsError || templateJourneyError

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {allQuestions && templateJourney && (
        <PageTemplateModeration
          journeyCollectionPath={journeyCollectionPath}
          questions={allQuestions}
          journey={templateJourney}
        />
      )}
    </>
  )
}

export default PageTemplateModerationContainer
