import React from 'react'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Box } from 'rebass'
import { FluidPiccleGridContainer as FluidPiccleGrid } from 'components'
import { Heading2 } from '@lightspeed/flame/Text'

type Props = {
  handleClickToDraw: () => void
  activity: { id: string; continuous?: boolean; maxSquares?: string }
  prompt: string
  asyncJourney?: boolean
  journeyId: string
  labels: {
    draw: string
  }
}

const ClientPromptActivity = ({ journeyId, handleClickToDraw, activity, prompt, labels }: Props) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Heading2>{prompt}</Heading2>
      <Box height="75vh" width="75vh">
        <FluidPiccleGrid journeyId={journeyId} activity={activity} />
      </Box>
      <Button onClick={handleClickToDraw}>{labels.draw}</Button>
    </Box>
  )
}

export default ClientPromptActivity
