import { useState, useEffect } from 'react'
import getActivityData from 'api/activities/getActivityData'

type Args = {
  journeyCode: string
  activityId: string
}

const useActivityDataOnce = ({ journeyCode, activityId }: Args) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getActivityData({ journeyCode, activityId, setData, setLoading, setError })
  }, [activityId, journeyCode])

  return [data, loading, error]
}

export default useActivityDataOnce
