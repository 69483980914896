import React, { useState } from 'react'
import { Box, Flex } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'
import { Alert } from '@lightspeed/flame/Alert'
import useTranslations from 'hooks/useTranslations'
import { useInput, Input as InputValue } from 'hooks/useInput'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import picclesApi, { PicclesApiObject } from 'api'

import 'firebase/firestore'
import { Icon } from '@lightspeed/flame/Icon'
import { isValidFirebaseDocumentId } from 'api/helpers/firestoreHelpers'
import { Helpbox } from 'components/atoms/Helpbox/Helpbox'
import { validEmailRegex } from 'appConstants'
import { SelectDropdown } from 'components/atoms/SelectDropdown'
import { useLocale } from 'hooks/useChangeLocale'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    email: 'Email',
    password: 'Password',
    signup: 'Sign Up',
    company: 'Company Name',
    confirmPassword: 'Confirm Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    dropdown: 'Role',
    role: 'Please specify your role',
    invalidPasswordMatch: 'Your password entries do not match',
    validPasswordMatch: 'Your password matches!',
    companyNameNotUnique: 'This company name exists',
    ForwardSlashException: 'Company name cannot contain a forward slash.',
    CharacterLimitException:
      'Character limit exceeded. Please enter an input below 1500 characters for the company name.',
    IllegalDocumentNamesException: 'Company name cannot be . or ..',
    ReservedUnderscorePatternException: 'Company name cannot start with two underscores and end with two underscores.',
    accept: 'By clicking on Sign Up, you agree to',
    agreement: "Piccles' terms of use.",
    companyNameRestrictions: 'Company name cannot be . or .. and cannot contain a forward slash.',
    invalidEmailFormat: 'Invalid email format.',
  },
  fr: {
    email: 'Courriel',
    password: 'Mot de passe',
    signup: 'Enregistrer',
    company: 'Nom de compagnie',
    confirmPassword: 'Retapez votre mot de Passe',
    firstName: 'Prénom',
    lastName: 'Nom',
    dropdown: 'Rôle',
    role: 'Veuillez écrire votre rôle ci-dessous',
    invalidPasswordMatch: "Votre mot de passe n'a pas été retapé correctement",
    validPasswordMatch: 'Votre mot de passe a bien été retapé!',
    companyNameNotUnique: 'Ce nom de compagnie existe',
    ForwardSlashException: 'Le nom de la compagnie ne peut pas contenir de barres obliques.',
    CharacterLimitException:
      'Limite de charactères atteinte. Veuillez entrer une valeur inférieure à 1500 charactères pour le nom de la compagnie.',
    IllegalDocumentNamesException: 'Le nom de la compagnie ne peut pas être . ou ..',
    ReservedUnderscorePatternException:
      'Le nom de la compagnie ne peut pas commencer pas deux barres en bas, ni se terminer par deux barres en bas.',
    accept: 'En cliquant sur Enregistrer, vous acceptez les',
    agreement: 'termes et conditions de Piccles.',
    companyNameRestrictions:
      'Le nom de la compagnie ne peut pas être . ou .. et il ne peut pas contenir de barres obliques.',
    invalidEmailFormat: "Format d'adresse courriel invalide.",
  },
}

const OTHER = 'other'
const DEFAULT_DROPDOWN_SELECTION = 'default'

type Translations = typeof translations.en

type DropdownOption = {
  label: string
  id: string
}

export type ReactHook<T> = [T, React.Dispatch<React.SetStateAction<T>>]

enum PasswordMatch {
  MATCH = 'MATCH',
  NO_MATCH = 'INVALID',
}

export const SignupForm: React.FunctionComponent = (): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const { locale } = useLocale()
  const navigate = useNavigate()

  const dropdownSelections: DropdownOption[] =
    locale === 'en'
      ? [
          { label: "What's your role?", id: DEFAULT_DROPDOWN_SELECTION },
          { label: 'Event Professional', id: 'event professional' },
          { label: 'Trainer', id: 'trainer' },
          { label: 'Executive', id: 'executive' },
          { label: 'Speaker', id: 'speaker' },
          { label: 'Researcher', id: 'researcher' },
          { label: 'Marketing Manager', id: 'marketing manager' },
          { label: 'Human Resources', id: 'human resources' },
          { label: 'Teacher', id: 'teacher' },
          { label: 'Other', id: OTHER },
        ]
      : [
          { label: 'Quel est votre rôle?', id: DEFAULT_DROPDOWN_SELECTION },
          { label: "Professionnel de l'événementiel", id: 'event professional' },
          { label: 'Coach', id: 'trainer' },
          { label: 'Gestionnaire', id: 'executive' },
          { label: 'Conférencier(ière)', id: 'speaker' },
          { label: 'Chercheur(euse)', id: 'researcher' },
          { label: 'Directeur(trice) marketing', id: 'marketing manager' },
          { label: 'Ressources humaines', id: 'human resources' },
          { label: 'Professeur(e)', id: 'teacher' },
          { label: 'Autre', id: OTHER },
        ]

  const { signup, error, loading }: AuthenticationContextFields = useAuth()

  // boolean passwordMatched returns true if both passwords are equal
  const [passwordMatched, setPasswordMatched]: ReactHook<PasswordMatch> = React.useState<PasswordMatch>(null)

  const [companyNameException, setCompanyNameException]: ReactHook<string> = React.useState<string>(null)

  const [isValidEmailFormat, setIsValidEmailFormat]: ReactHook<boolean> = React.useState<boolean>(null)

  const [isRoleValid, setIsRoleValid]: ReactHook<boolean> = React.useState<boolean>(null)

  const [dropdownSelectedOption, setDropdownSelectedOption] = useState(dropdownSelections[0])

  const [showOtherInputField, setShowOtherInputField] = useState(false)

  // Form state and handler
  const email: InputValue = useInput('')
  const password: InputValue = useInput('')
  const confirmPassword: InputValue = useInput('')
  const company: InputValue = useInput('')
  const firstName: InputValue = useInput('')
  const lastName: InputValue = useInput('')
  const role: InputValue = useInput('')
  const isSignupDisabled: boolean =
    passwordMatched !== PasswordMatch.MATCH || companyNameException !== null || !isValidEmailFormat || !isRoleValid

  const handleSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    signup({
      email: email.value,
      password: password.value,
      firstName: firstName.value,
      lastName: lastName.value,
      companyName: company.value.length ? company.value : email.value,
      role: role.value === '' ? dropdownSelectedOption.label : role.value,
    })
      .then(async () => {
        const { companyApi }: PicclesApiObject = picclesApi()
        try {
          await companyApi.getCompanyByUser(email.value)
        } catch (error) {
          throw error
        }
      })
      .then(() => navigate('/admin'))
      .then(() => window.location.replace('/admin'))
      .catch((error) => {
        console.log(error)
      })
  }

  const handleEmailFormat = (event) => {
    const email: string = event.target.value
    setIsValidEmailFormat(validEmailRegex.test(email))
  }

  const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newDropdownSelection = dropdownSelections.find((o) => o.id === event.target.value)
    setDropdownSelectedOption(newDropdownSelection)
    const validRoleCheck =
      newDropdownSelection.id === DEFAULT_DROPDOWN_SELECTION || (newDropdownSelection.id === OTHER && role.value === '')
    setIsRoleValid(!validRoleCheck)
    setShowOtherInputField(event.target.value === OTHER)
  }

  const verifyRole = (): void => {
    setIsRoleValid(role.value !== '')
  }

  const verifyPassword = (): void => {
    if (!password.value) return
    if (password.value === confirmPassword.value) {
      setPasswordMatched(PasswordMatch.MATCH)
    } else {
      setPasswordMatched(PasswordMatch.NO_MATCH)
    }
  }

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const emailInputElement = event.target as HTMLInputElement
    email.setValue(emailInputElement.value.toLowerCase())
  }

  return (
    <Box as="form" id="signup" onSubmit={handleSubmit}>
      {error && (
        <Alert mb={3} type="warning">
          <Icon name="cross" />
          {error}
        </Alert>
      )}

      {passwordMatched === PasswordMatch.NO_MATCH && (
        <Alert noCloseBtn mb={3} type="danger">
          <Icon name="cross" />
          {labels.invalidPasswordMatch}
        </Alert>
      )}

      {passwordMatched === PasswordMatch.MATCH && (
        <Alert mb={3} type="success">
          <Icon name="checkmark" />
          {labels.validPasswordMatch}
        </Alert>
      )}

      {companyNameException !== null && (
        <Alert mb={3} type="danger">
          <Icon name="cross" />
          {labels[companyNameException]}
        </Alert>
      )}

      {!isValidEmailFormat && isValidEmailFormat !== null && email.value !== '' && (
        <Alert mb={3} type="danger">
          <Icon name="cross" />
          {labels.invalidEmailFormat}
        </Alert>
      )}

      {!isRoleValid && isRoleValid !== null && (
        <Alert mb={3} type="danger">
          <Icon name="cross" />
          {labels.role}
        </Alert>
      )}

      <Flex mx={-2} mb={3}>
        <Box width={1 / 2} px={2}>
          <Input {...firstName.bind} label={labels.firstName} name="firstName" required autoComplete="off" />
        </Box>

        <Box width={1 / 2} px={2}>
          <Input {...lastName.bind} label={labels.lastName} name="lastName" autoComplete="off" required />
        </Box>
      </Flex>

      <Flex mx={-2} mb={3}>
        <Box width={1 / 2} px={2}>
          <Input
            {...email.bind}
            label={labels.email}
            name="email"
            onChange={handleEmailInput}
            onBlur={handleEmailFormat}
            value={email.value}
            required
            autoComplete="off"
          />
        </Box>

        <Box width={1 / 2} px={2}>
          <Input
            {...company.bind}
            label={
              <>
                {labels.company}
                <Helpbox info={labels.companyNameRestrictions} />
              </>
            }
            name="company"
            autoComplete="off"
            onBlur={async () => {
              try {
                // DO NOT REMOVE THIS UNLESS YOU WILL REPLACE ITS FUNCTIONALITY (PE-402 Bug)
                isValidFirebaseDocumentId(company.value)
              } catch (error) {
                setCompanyNameException(error.name)
              }
            }}
            onFocus={() => {
              setCompanyNameException(null)
            }}
            required
          />
        </Box>
      </Flex>

      <Flex mx={-2} mb={3}>
        <Box width={1 / 2} px={2}>
          <Input
            {...password.bind}
            label={labels.password}
            name="password"
            type="password"
            autoComplete="off"
            required
            onBlur={verifyPassword}
            onFocus={() => {
              setPasswordMatched(null)
            }}
          />
        </Box>

        <Box width={1 / 2} px={2}>
          <Input
            {...confirmPassword.bind}
            label={labels.confirmPassword}
            name="confirmPassword"
            type="password"
            autoComplete="off"
            required
            onKeyUp={verifyPassword}
            onFocus={() => {
              setPasswordMatched(null)
            }}
          />
        </Box>
      </Flex>

      <SelectDropdown
        label={labels.dropdown}
        name="Identification Dropdown"
        value={dropdownSelectedOption.id}
        onChange={handleDropdownChange}
        options={dropdownSelections}
      />
      {showOtherInputField ? (
        <Box style={{ marginBottom: '10px' }}>
          <Input {...role.bind} onKeyUp={verifyRole} label={labels.role} name="Other" required autoComplete="off" />
        </Box>
      ) : null}

      <Flex mx={2} mb={3}>
        <small>
          <label style={{ marginLeft: '5px' }}>{labels.accept}</label>
          <a
            style={{ marginLeft: '5px' }}
            href="https://www.piccles.com/subscription-agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels.agreement}
          </a>
        </small>
      </Flex>

      <Button
        block
        fill
        variant="primary"
        type="submit"
        form="signup"
        value="Submit"
        loading={loading}
        disabled={isSignupDisabled}
      >
        {labels.signup}
      </Button>
    </Box>
  )
}
