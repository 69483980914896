export type PaymentMethods = {
  paymentMethods: {
    id: string
    card: {
      brand: string
      last4: string
      exp_month: string
      exp_year: string
    }
  }[]
  customer: {
    invoice_settings: {
      default_payment_method: string
    }
  }
}

const getPaymentMethods = async (email: string, endpointUrl: string): Promise<PaymentMethods> => {
  try {
    const response = await fetch(`${endpointUrl}/${email}/payment-methods`, { mode: 'cors' })
    if (response.status >= 400 && response.status <= 499) throw new Error('API Client Error')
    const data = response.json()
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getPaymentMethods
