import React, { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'
import PageProfileEvents from './PageProfileEvents'
import Firebase from 'firebase/app'
import { useState } from 'react'

export const PageProfileEventsContainer = () => {
  const { user } = useAuth()

  const [singleEvents, setSingleEvents] = useState<Array<any>>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>(null)

  useEffect(() => {
    Firebase.firestore()
      .collection('companies')
      .where('admin', '==', user.email)
      .limit(1)
      .get()
      .then(({ docs: [company] }) => company)
      .then((company) =>
        Firebase.firestore()
          .collection(`companies/${company.id}/singleEvents`)
          .orderBy('createdOn', 'desc')
          .get(),
      )
      .then(({ docs: singleEvents }) => {
        setSingleEvents(singleEvents.map((singleEventRef) => ({ id: singleEventRef.id, ...singleEventRef.data() })))
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }, [user.email])

  return <PageProfileEvents singleEvents={singleEvents} loading={loading} error={error} />
}
