import React, { useState, ReactElement, FunctionComponent } from 'react'
import { Flex, Box } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Alert } from '@lightspeed/flame/Alert'
import { Heading3, Text } from '@lightspeed/flame/Text'
import useTranslations from 'hooks/useTranslations'

import { AdminLayout, QuestionItem } from 'components'
import { Button } from '@lightspeed/flame/Button'
import { Question, QuestionCategory, UpdateQuestionInputType } from 'types'

const translations = {
  en: {
    questions: 'Questions',
    english: 'English',
    spanish: 'Spanish',
    placeholder: 'Sibling/ playroom/ patient questions go here.',
    sibling: 'Sibling',
    parent: 'Parent',
    patient: 'Patient',
    add: 'Add',
    save: 'Save',
    emptyTitle: 'There are questions with empty titles. Kindly update or remove the question',
    emptyCategories: 'There are questions without any category. Kindly update',
  },
  fr: {
    questions: 'Questions',
    english: 'English',
    spanish: 'Spanish',
    placeholder: 'Sibling/ playroom/ patient questions go here.',
    sibling: 'Sibling',
    parent: 'Parent',
    patient: 'Patient',
    add: 'Add',
    save: 'Save',
    emptyTitle: 'There are questions with empty titles. Kindly update or remove the question',
    emptyCategories: 'There are questions without any category. Kindly update',
  },
}

type PageEditTemplateProps = {
  existingQuestions: Question[]
  onNewQuestionCreate: (payload: Pick<Question, 'categories' | 'title'>) => Promise<string | void>
  onUpdateQuestion: ({ questionId, payload }: UpdateQuestionInputType) => Promise<void>
  onDeleteQuestion: (questionId: string) => Promise<void>
}

const PageEditTemplate: FunctionComponent<PageEditTemplateProps> = ({
  existingQuestions,
  onNewQuestionCreate,
  onUpdateQuestion,
  onDeleteQuestion,
}: PageEditTemplateProps): ReactElement => {
  const labels = useTranslations<typeof translations.en>(translations)
  const [isUpdaingdata, setIsUpdaingdata] = useState<boolean>(false)
  const [isEmptyQuestion, setIsEmptyQuestion] = useState<boolean>(false)
  const [isEmptyCategories, setIsEmptyCategories] = useState<boolean>(false)

  // To collect questions that are beign updated.
  const [updatedQuestions, setUpdatedQuestions] = useState<UpdateQuestionInputType[]>([])

  // convert enum to array
  const questionCategories = Object.keys(QuestionCategory) as (keyof typeof QuestionCategory)[]

  // returns true is all languages fields in a given quesiton is empty
  const checkIsTitleEmpty = ({ title }: Pick<Question, 'title'>) => {
    return Object.values(title).every((lang) => lang === '')
  }

  const updateQuestions = (args: UpdateQuestionInputType): void => {
    const index: number = updatedQuestions.findIndex((q) => q.questionId === args.questionId)
    setIsEmptyQuestion(false)
    setIsEmptyCategories(false)
    // updating this question for the first time
    if (index < 0) {
      setUpdatedQuestions([...updatedQuestions, args])
      return
    }
    const newQuestions = [...updatedQuestions]
    newQuestions[index] = { ...newQuestions[index], ...args }
    setUpdatedQuestions(newQuestions)
  }

  const saveQuestions = async (): Promise<void> => {
    if (updatedQuestions.length === 0) {
      // no questions were changed
      return
    }

    // is there any question with empty fields for all languages
    const isAnyQuestionEmpty: boolean = updatedQuestions.every((question) => {
      const { title } = question.payload
      return checkIsTitleEmpty({ title })
    })

    const isAnyQuestionHasNoCategory: boolean = updatedQuestions.every((question) => {
      const { categories } = question.payload
      return categories.length <= 0
    })

    if (isAnyQuestionEmpty) {
      setIsEmptyQuestion(true)
      return
    }

    if (isAnyQuestionHasNoCategory) {
      setIsEmptyCategories(true)
      return
    }

    setIsUpdaingdata(true)

    // move in to a firebase batch is required
    try {
      await Promise.all(
        updatedQuestions.map(async (q) => {
          await onUpdateQuestion(q)
        }),
      )

      setIsUpdaingdata(false)
      setUpdatedQuestions([])
    } catch (error) {
      console.log('Error updating questions', error)
    }
  }

  const deleteQuestion = (questionId: string): void => {
    onDeleteQuestion(questionId)
    // remove from updated quesstions. in case the question was updated before belete
    const newUpdatedQuestions = [...updatedQuestions].filter((q) => q.questionId !== questionId)
    setUpdatedQuestions(newUpdatedQuestions)
  }

  return (
    <AdminLayout>
      <Box mb={3}>
        {isEmptyQuestion && (
          <Alert noCloseBtn mb={3} type="danger">
            {labels.emptyTitle}
          </Alert>
        )}
        {isEmptyCategories && (
          <Alert noCloseBtn mb={3} type="danger">
            {labels.emptyCategories}
          </Alert>
        )}
        <Card>
          <CardSection bg="darkGreen">
            <Flex alignItems="center">
              <Heading3 color="white">{labels.questions}</Heading3>
            </Flex>
          </CardSection>
          <CardSection>
            <Flex>
              <Flex ml={5} width={1 / 2}>
                <Button
                  width={1 / 2}
                  mb={2}
                  mr={2}
                  block
                  fill
                  variant="primary"
                  type="submit"
                  form="promptActivityForm"
                  onClick={() => {
                    const questionId = onNewQuestionCreate({ categories: [], title: { en: '', es: '' } })
                    // add newly created question to the updatedQuestions. so that it can be submitted with update
                    questionId.then((questionId) => {
                      if (questionId) {
                        setUpdatedQuestions([
                          ...updatedQuestions,
                          {
                            questionId,
                            payload: {
                              categories: [],
                              title: { en: '', es: '' },
                            },
                          },
                        ])
                      }
                    })
                  }}
                >
                  {labels.add}
                </Button>
              </Flex>
              <Flex mr={5} justifyContent={'flex-end'} width={1 / 2}>
                <Button
                  loading={isUpdaingdata}
                  width={1 / 2}
                  mb={2}
                  mr={2}
                  style={{ float: 'right' }}
                  fill
                  block
                  variant="primary"
                  type="submit"
                  form="promptActivityForm"
                  onClick={() => saveQuestions()}
                >
                  {labels.save}
                </Button>
              </Flex>
            </Flex>
            <Flex>
              <Flex justifyContent={'center'} mr={5} width={1 / 3}>
                <Text>{labels.english}</Text>
              </Flex>
              <Flex justifyContent={'center'} mr={5} width={1 / 3}>
                <Text>{labels.spanish}</Text>
              </Flex>

              <Flex justifyContent="center" ml={5} width={1 / 3}>
                <Flex width={1 / 3}>
                  <Text>{labels.sibling}</Text>
                </Flex>
                <Flex width={1 / 3}>
                  <Text>{labels.parent}</Text>
                </Flex>
                <Flex width={1 / 3}>
                  <Text>{labels.patient}</Text>
                </Flex>
              </Flex>
            </Flex>
          </CardSection>
          {existingQuestions &&
            existingQuestions.map((q, index) => {
              return (
                <CardSection key={q.id}>
                  <QuestionItem
                    index={index}
                    question={q}
                    questionCategories={questionCategories}
                    onDeleteQuestion={deleteQuestion}
                    updateQuestions={updateQuestions}
                  />
                </CardSection>
              )
            })}
        </Card>
      </Box>
    </AdminLayout>
  )
}

export default PageEditTemplate
