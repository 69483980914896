import Firebase from 'firebase/app'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { TemplateJourney } from 'types'

type Args = {
  journeyCollectionPath: string
  journeyId: string
}

export const useJourneyData = ({ journeyCollectionPath, journeyId }: Args): [TemplateJourney, boolean, Error] => {
  const [data, loading, error]: [TemplateJourney, boolean, Error] = useDocumentData(
    Firebase.firestore().doc(`${journeyCollectionPath}/${journeyId}`),
    { idField: 'id' },
  )

  return [data, loading, error]
}
