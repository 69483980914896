/** @jsx jsx */
import { memo, useEffect } from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { PillBadge } from '@lightspeed/flame/Badge'
import { useInView } from 'react-intersection-observer'
import { Box } from '@lightspeed/flame/Core'
import PiccleCanvas from '../PiccleCanvas/PiccleCanvas'

type Props = {
  journeyId: string
  activityId: string
  piccles?: any[]
  loadMorePiccles?: () => Promise<void>
  isModeration?: boolean
  handleOnSelect: any
  visiblePiccles: string[]
}

const ProjectionViewGrid: React.FC<Props> = ({
  journeyId,
  activityId,
  piccles,
  loadMorePiccles,
  isModeration,
  handleOnSelect,
  visiblePiccles,
}) => {
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      loadMorePiccles()
    } // eslint-disable-next-line
  }, [inView])

  return (
    <div className="projection" data-testid="ProjectionViewGrid">
      {piccles.map((piccle) => {
        const showVisibleBadge = visiblePiccles.includes(piccle.id) && isModeration

        return (
          <PicclesWrapper
            isModeration={isModeration}
            key={`grid:${piccle.id}`}
            {...(isModeration && { onClick: () => handleOnSelect(piccle.id) })}
          >
            <PiccleCanvas piccleId={piccle.id} journeyId={journeyId} activityId={activityId} svg={piccle.data().svg} />
            {showVisibleBadge ? (
              <Box marginTop={-7}>
                <PillBadge type="success">✓</PillBadge>
              </Box>
            ) : (
              ''
            )}
          </PicclesWrapper>
        )
      })}
      <div ref={ref}>.</div>
    </div>
  )
}

const PicclesWrapper = styled.div<{ isModeration }>`
  ${({ isModeration }) =>
    isModeration
      ? `  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }`
      : `height: max-content;`}
`

export default memo(ProjectionViewGrid)
