import React, { SyntheticEvent } from 'react'
import { Box, Flex } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'
import { Alert } from '@lightspeed/flame/Alert'
import { Divider } from '@lightspeed/flame/Divider'
import { Heading2, Heading3 } from '@lightspeed/flame/Text'
import { Input as InputValue } from 'hooks/useInput'
import useTranslations from 'hooks/useTranslations'
import { useInput } from 'hooks/useInput'
import { useAuth } from 'hooks/useAuth'
import { Location } from '@reach/router'
import GoogleButton from 'react-google-button'

const translations = {
  en: {
    email: 'Email',
    password: 'Password',
    login: 'Log in',
    enterprise: 'Thanks for siging up!',
    continue: 'Please login to continue',
    divider: 'Or',
    googleAuth: 'Sign in with Google',
  },
  fr: {
    email: 'Courriel',
    password: 'Mot de passe',
    login: 'Connection',
    enterprise: 'Merci de vous être inscrit !',
    continue: 'Veuillez vous connecter pour continuer',
    divider: 'Ou',
    googleAuth: 'Connection avec Google',
  },
}

type Translations = typeof translations.en

export const LoginForm: React.FunctionComponent = (): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)

  const { signin, googleSignup, error, loading } = useAuth()

  // Form state and handler
  const email: InputValue = useInput('')
  const password: InputValue = useInput('')
  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault()
    await signin({ email: email.value, password: password.value })
  }

  return (
    <Box as="form" id="login" onSubmit={handleSubmit}>
      <Location>
        {({ location }) => (
          <>
            <Flex justifyContent="center" mt={4} mb={4}>
              <GoogleButton label={labels.googleAuth} onClick={googleSignup} />
            </Flex>

            <Divider mb={6}>{labels.divider}</Divider>

            {location.search === '?enterprise' && (
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                {' '}
                <Heading2 mb={1}>{labels.enterprise}</Heading2>
                <Heading3 mb={3}>{labels.continue}</Heading3>
              </Box>
            )}
            {error && (
              <Alert noCloseBtn mb={3} type="warning">
                {error}
              </Alert>
            )}
            <Flex mx={-2} mb={3}>
              <Box width={1 / 2} px={2}>
                <Input {...email.bind} label={labels.email} name="email" required autoComplete="off" />
              </Box>
              <Box width={1 / 2} px={2}>
                <Input {...password.bind} label={labels.password} name="password" type="password" required />
              </Box>
            </Flex>

            <Box mb={6}>
              <Button block fill variant="secondary" type="submit" form="login" value="Submit" loading={loading}>
                {labels.login}
              </Button>
            </Box>
          </>
        )}
      </Location>
    </Box>
  )
}
