import React from 'react'
import { Flex } from 'rebass'
import { Heading3 } from '@lightspeed/flame/Text'
import useTranslations from 'hooks/useTranslations'
import { PROMPT_ACTIVITY } from 'appConstants'
import { ActivityType } from 'Pages/Admin/PageEditJourney/PageEditJourney'
import { PiccleButton } from 'components/atoms/Button'

const translations = {
  en: {
    type: 'Create New Activity',
    activityCreation: 'Activity',
  },
  fr: {
    type: 'Créé une nouvelle activité',
    activityCreation: 'Activité',
  },
}

type Translations = typeof translations.en

type Props = {
  onSelectType: (type) => void
  activityType: ActivityType
}

export const CreateActivity: React.FC<Props> = ({ onSelectType, activityType }: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)

  return (
    <Flex alignItems="center">
      <Heading3 color="white">{labels.activityCreation}</Heading3>
      <Flex ml="auto" sx={{ minWidth: 'max-content' }} alignItems="center">
        {activityType !== PROMPT_ACTIVITY && (
          <PiccleButton
            onClick={() => {
              onSelectType(PROMPT_ACTIVITY)
            }}
          >
            {labels.type}
          </PiccleButton>
        )}
      </Flex>
    </Flex>
  )
}
