import createUser, { WriteUserData } from './createUser'
import getUser, { GetUserArguments, UserData } from './getUser'

export type UserActions = {
  createUser: (data: WriteUserData) => Promise<string | void>
  getUser: (data: GetUserArguments) => Promise<UserData>
}

const userActions = (url: string): UserActions => ({
  createUser: (data: WriteUserData) => createUser(data, url),
  getUser: (data: GetUserArguments) => getUser(data),
})

export default userActions
