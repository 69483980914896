import Firebase from 'firebase/app'

export const getJourneyIdFromCode = async (code: string): Promise<string> => {
  try {
    const journeys = await Firebase.firestore()
      .collection('journeys')
      .where('code', '==', code)
      .get()

    if (journeys.size === 0) {
      throw new Error('Journey not found')
    }

    return journeys.docs[0].id
  } catch (error) {
    throw error
  }
}
