import config from '../config'

import userApi, { UserActions } from './user'
import journeyApi, { JourneyActions } from './journeys'
import activityApi, { ActivityActions } from './activities'
import companyApi, { CompanyActions } from './companies'
import stripeApi, { StripeActions } from './stripe'

// Crayon methods
import crayonJourneyApi, { CrayonJourneyActions } from './crayonJourneys'

export type PicclesApiObject = {
  userApi: UserActions
  journeyApi: JourneyActions
  activityApi: ActivityActions
  companyApi: CompanyActions
  stripeApi: StripeActions
}

export type CrayonPicclesApiObject = {
  crayonJourneyApi: CrayonJourneyActions
}

const picclesApi = (): PicclesApiObject => ({
  userApi: userApi(`${config.api.url}/users`),
  journeyApi,
  activityApi,
  companyApi: companyApi(`${config.api.url}/companies`),
  stripeApi: stripeApi(`${config.api.url}/stripe`),
})

//specific api for crayon
export const crayonPicclesApi = (): CrayonPicclesApiObject => ({
  crayonJourneyApi,
})

export default picclesApi
export * from './piccles'
