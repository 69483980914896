import React from 'react'
import { Flex } from 'rebass'
import { Heading3 } from '@lightspeed/flame/Text'
import { Card, CardProps, CardSection } from '@lightspeed/flame/Card'

interface Props extends CardProps {
  children?: React.ReactNode
  title: string
}

export const DashboardHeader = ({ title, children, ...rest }: Props) => {
  return (
    <Card {...rest}>
      <CardSection bg="secondary" data-testid="DashboardHeader">
        <Flex alignItems="center">
          <Heading3 color="white" fontWeight="bold">
            {title}
          </Heading3>
          <Flex ml="auto" sx={{ minWidth: 'max-content' }} alignItems="center">
            {children}
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  )
}
