import { useState, useEffect } from 'react'

const useWindowHeight: () => number = (): number => {
  const [height, setHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    const handleResize: () => void = () => setHeight(window.innerHeight)

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return height
}

export default useWindowHeight
