import React, { useState } from 'react'
import { Flex } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Text, TextLink } from '@lightspeed/flame/Text'

import { LoginForm, PasswordResetForm } from 'components'
import useTranslations from 'hooks/useTranslations'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { AuthLayout } from 'components/molecules/AuthLayout'
import { Box } from '@lightspeed/flame/Core'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    signup: 'Sign Up',
    login: 'I already have an account',
    forgot: 'Forgot password?',
    tagline: 'Great to see you again!',
    noAccount: 'No account yet? ',
  },
  fr: {
    signup: "S'enregistrer",
    login: "J'ai déjà un compte",
    forgot: 'Mot de passe oublié?',
    tagline: 'Ravi de vous revoir!',
    noAccount: 'Pas encore de compte? ',
  },
}

type Translations = typeof translations.en

const LOGIN = 'login'
const FORGOTPASSWORD = 'forgot'

const CenterLinksBox = ({ children }): JSX.Element => (
  <Flex flexDirection="column" mt={3} mb={3}>
    {children}
  </Flex>
)

const PageWelcome: React.FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate()
  const labels: Translations = useTranslations<Translations>(translations)

  const { setError }: AuthenticationContextFields = useAuth()
  const [mode, setMode]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>(LOGIN)

  const handleModeSet: (mode: string) => void = (mode: string): void => {
    setError(null)
    setMode(mode)
  }

  const ForgotLink = (): JSX.Element => (
    <TextLink onClick={() => handleModeSet(FORGOTPASSWORD)}>{labels.forgot}</TextLink>
  )
  const SignupLink = (): JSX.Element => (
    <>
      <Text>
        {labels.noAccount}
        <TextLink onClick={() => navigate('/admin/register')}>{labels.signup}</TextLink>
      </Text>
    </>
  )
  const LoginLink = (): JSX.Element => <TextLink onClick={() => handleModeSet(LOGIN)}>{labels.login}</TextLink>

  const modeSwitch = (mode: string) =>
    ({
      [LOGIN]: (
        <>
          <LoginForm />
          <CenterLinksBox>
            <ForgotLink />
            <Box mt={2} />
            <SignupLink />
          </CenterLinksBox>
        </>
      ),
      [FORGOTPASSWORD]: (
        <>
          <PasswordResetForm />
          <CenterLinksBox>
            <SignupLink />
            <Box mt={2} />
            <LoginLink />
          </CenterLinksBox>
        </>
      ),
    }[mode])

  return (
    <AuthLayout overwrite={translations}>
      <Card large style={{ zIndex: 1 }}>
        <CardSection>{modeSwitch(mode)}</CardSection>
      </Card>
    </AuthLayout>
  )
}

export default PageWelcome
