import { PREGAME, ACTIVE, LIMBO, TERMINAL } from 'appConstants'

/**
 * Four states are possible for a journey:
 *    1. pregame: All activities are "unstarted"
 *    2. active: 1 of the activities is "started" and "incomplete"
 *    3. limbo: some activitie(s) are "started" and "complete" while other(s) are "unstarted" and "incomplete"
 *    4. terminal: All activities are "started and "complete"
 */
type Activity = {
  id: string
  type: string
  title: string
  isStarted: boolean
  isComplete: boolean
}
export function getJourneyState(activities: Activity[]) {
  if (activities.every((a) => !a.isStarted)) {
    return {
      status: PREGAME,
      previousActivity: null, // no previousActivity
      currentActivity: null, // no currentActivity
      nextActivity: activities[0], // nextActivity is the first Activity in this case
    }
  }
  if (activities.every((a) => a.isStarted && a.isComplete)) {
    return {
      status: TERMINAL,
      previousActivity: activities[activities.length - 1], // previousActivity is the last Activity in this case
      currentActivity: null, // no currentActivity
      nextActivity: null, // no nextActivity
    }
  }
  if (activities.some((a) => a.isStarted && !a.isComplete)) {
    // Current activity in this case is the first one that is "started" but "incomplete"
    const currentIndex = activities.findIndex((a) => a.isStarted && !a.isComplete)
    return {
      status: ACTIVE,
      previousActivity: activities[currentIndex - 1] || null, // could be null if currentIndex is 0
      currentActivity: activities[currentIndex],
      nextActivity: activities[currentIndex + 1] || null, // could be null if currentIndex is last
    }
  }
  if (activities.some((a) => a.isStarted && a.isComplete) && activities.some((a) => !a.isStarted)) {
    // Next activity in this case is the first one that is "not started"
    const nextIndex = activities.findIndex((a) => !a.isStarted)
    return {
      status: LIMBO,
      previousActivity: activities[nextIndex - 1],
      currentActivity: null, // No currentActivity in this case
      nextActivity: activities[nextIndex],
    }
  }
}
