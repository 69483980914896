import { useState } from 'react'

export type Input = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  reset: () => void
  bind: { value: string; onChange: (event: React.ChangeEvent<HTMLInputElement>) => void }
}

export const useInput = (initialValue: string): Input => {
  const [value, setValue]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>(initialValue)

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
      },
    },
  }
}
