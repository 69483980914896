import Firebase from 'firebase/app'
import React, { useState, useEffect } from 'react'
import AsynchronousProjectionGrid from './AsynchronousProjectionGrid'

type Props = {
  journeyId: string
  activity: any
}

const ProjectionViewGridContainer = ({ journeyId, activity }: Props) => {
  const initialAmountOfPiccles = 70
  const jumps = 15
  const [lastShownPiccleIndex, setLastShownPiccleIndex] = useState(initialAmountOfPiccles)
  const [picclesIds, setPicclesIds] = useState([])

  useEffect(() => {
    const data: Firebase.firestore.Query = Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .doc(activity.id)
      .collection('piccles')
      .where('done', '==', true)
      .where('hasStrokes', '==', true)
      .where('deleted', '==', false)
      .orderBy('createdOn', activity.continuous ? 'desc' : 'asc')

    const unsubscribe = data.onSnapshot(async () => {
      const ids = await data.get()
      setPicclesIds(ids.docs.filter((activityDoc) => activityDoc.data().done ?? true).map(({ id }) => id))
    })

    return () => {
      unsubscribe()
    }

    // eslint-disable-next-line
  }, [])

  const loadMorePiccles = async (): Promise<void> => {
    setLastShownPiccleIndex((lastIndex) => lastIndex + jumps)
  }

  return (
    <>
      {picclesIds.length ? (
        <AsynchronousProjectionGrid
          loadMorePiccles={loadMorePiccles}
          journeyId={journeyId}
          activityId={activity.id}
          piccleIds={picclesIds.slice(0, lastShownPiccleIndex)}
        />
      ) : null}
    </>
  )
}

export default React.memo(ProjectionViewGridContainer)
