/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from 'rebass'
import { CirclePicker, ColorResult } from 'react-color'

type Props = {
  colors: string[]
  onChangeComplete: (color: ColorResult) => any
}

export const Palette = (props: Props) => {
  return (
    <Flex justifyContent="center">
      <CirclePicker
        {...props}
        css={css`
          &.circle-picker {
            max-height: 75px;
            max-width: 98%;
            width: auto !important;
            margin-top: 0px;
            justify-content: center;
            position: relative;
            z-index: 1;
            overflow-y: hidden;
            transform: scale(1);
            div {
              transform: scale(1) !important;
              span {
                div {
                  border: 1px solid rgba(0, 0, 0, 0.1);
                }
              }
            }
          }
        `}
      />
    </Flex>
  )
}
