import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

export type UpdateJourneyArgs = {
  journeyId: string
  payload: any
}

const updateJourney = ({ journeyId, payload }: UpdateJourneyArgs): Promise<any> => {
  ReactGA.event({
    category: 'Journey',
    action: 'Update journey',
  })

  return Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .update(prepareDocForUpdate(payload))
    .then(() => payload)
    .catch((error) => {
      alert(`Whoops, couldn't update the journey: ${error.message}`)
    })
}

export default updateJourney
