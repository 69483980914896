import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Question } from 'types'

type Args = {
  templateId: string
  category: string
}

const useQuestion = ({ templateId, category }: Args): [Question, boolean, Error] => {
  const [data, loading, error]: [Question[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('templates')
      .doc(templateId)
      .collection('questions')
      .where('categories', 'array-contains', category)
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )

  let randomQuestion: Question
  if (data) {
    //pick a random question out of all
    randomQuestion = data[Math.floor(Math.random() * data.length)]
  }

  return [randomQuestion, loading, error]
}

export default useQuestion
