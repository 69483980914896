import { Input } from '@lightspeed/flame/Input'
import React, { useState } from 'react'
import { Box, Flex } from '@lightspeed/flame/Core'
import useTranslations from 'hooks/useTranslations'
import { Text } from '@lightspeed/flame/Text'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import picclesAPI, { PicclesApiObject } from 'api'
import { PiccleButton as Button } from 'components/atoms/Button'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { useInput } from 'hooks/useInput'
import { Stripe, StripeElements } from '@stripe/stripe-js'

type Translations = typeof translations.en

const translations = {
  en: {
    address: 'Billing address',
    cardInfo: 'Credit Card Information',
    city: 'City',
    province: 'Province/State/Region',
    zip: 'Postal Code',
    cancel: 'Cancel',
    save: 'Save payment method',
  },
  fr: {
    address: 'Addresse de facturation',
    cardInfo: 'Information de la carte de crédit',
    city: 'Ville',
    province: 'Province/État/Région',
    zip: 'Code Postal',
    cancel: 'Annuler',
    save: 'Ajouter la carte',
  },
}

type Props = {
  onChangePaymentMethod: () => void
  onCancelChangePaymentMethod: () => void
}

export const BillingForm = ({ onChangePaymentMethod, onCancelChangePaymentMethod }: Props) => {
  const { user }: AuthenticationContextFields = useAuth()
  const stripe: Stripe = useStripe()
  const elements: StripeElements = useElements()
  const labels: Translations = useTranslations<Translations>(translations)
  const { stripeApi }: PicclesApiObject = picclesAPI()
  const [loading, setLoading] = useState<boolean>(false)
  const address = useInput('')
  const city = useInput('')
  const province = useInput('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    try {
      setLoading(true)
      // First we fetch create a setup intent and retrieve the clientSecret it generates.
      const { clientSecret, stripeCustomerId } = await stripeApi.getSetupIntent(user.email)

      // Next we can create the payment method on the client
      const result = await stripe.confirmCardSetup(clientSecret, {
        /* eslint-disable */
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            address: {
              line1: address.value,
              state: province.value,
              city: city.value,
            },
          },
        },
        /* eslint-enable */
      })

      if (result.error) {
        console.log(result.error)
      } else {
        // If successful we set this new payment method as default
        const paymentMethodId = result.setupIntent.payment_method
        await stripeApi.setDefaultPaymentMethod({ stripeCustomerId, paymentMethodId })

        // Then we trigger the onChangePaymentMethod from our parent component
        onChangePaymentMethod()
      }
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} id="billing_form">
      <Box my={5}>
        <Text color="textHeading" size="small" fontWeight="700" marginBottom={2}>
          {labels.cardInfo}
        </Text>

        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
            hidePostalCode: true,
          }}
        />
      </Box>

      <Box marginBottom={2}>
        <Input label={labels.address} {...address.bind} />
      </Box>

      <Flex marginBottom={2}>
        <Box marginRight={2} width={0.5}>
          <Input label={labels.city} {...city.bind} />
        </Box>

        <Box width={0.5}>
          <Input label={labels.province} {...province.bind} />
        </Box>
      </Flex>

      <Flex mt={8} width="100%" justifyContent="space-between">
        <Button onClick={onCancelChangePaymentMethod} variant="danger" disabled={!stripe || loading}>
          {labels.cancel}
        </Button>

        <Button type="submit" form="billing_form" loading={loading} disabled={!stripe || loading}>
          {labels.save}
        </Button>
      </Flex>
    </form>
  )
}
