import { AuthenticationContextFields, useAuth } from 'hooks/useAuth'
import { useNavigate } from 'hooks/useNavigate'
import React from 'react'

import picclesApi from '../../../api'
import PageWelcome from '../PageWelcome'
import PageDashboard from './PageDashboard'

const PageDashboardContainer = () => {
  const { user }: AuthenticationContextFields = useAuth()
  const { journeyApi } = picclesApi()
  const navigate = useNavigate()

  const handleOnCreate = async () => {
    try {
      const journeyId = await journeyApi.createJourney({})
      navigate(`admin/edit/${journeyId}`)
    } catch (error) {
      console.log(error)
    }
  }

  // prevent anonymousUsers from accessing the admin app
  return !user || user.isAnonymous ? <PageWelcome /> : <PageDashboard onCreateNewJourney={handleOnCreate} />
}
export default PageDashboardContainer
