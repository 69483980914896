import React, { ReactElement } from 'react'

import { Text } from 'rebass'

type CrayonTextProps = {
  text: string | ReactElement
  styles?: {}
  onClick?: () => void
}

export const CrayonText = ({ text, styles, onClick }: CrayonTextProps) => {
  return (
    <Text
      lineHeight={1.4}
      textAlign="center"
      fontSize={['1em', '20px']}
      fontFamily="RivalSlab-Regular"
      color="#000"
      {...styles}
      onClick={onClick}
    >
      {text}
    </Text>
  )
}
