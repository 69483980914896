import React from 'react'
import useTranslations from 'hooks/useTranslations'

const translations = {
  en: {
    title: 'Whoops',
    notAccessible: 'We are sorry, this page is not accessible at the moment',
  },
  fr: {
    title: 'Ouf',
    notAccessible: "Cette page n'est pas disponible à cet instant",
  },
}

export const NotAccessible: React.FC = () => {
  const labels = useTranslations<typeof translations.en>(translations)
  return (
    <div>
      <h1>{labels.title}</h1>
      <div style={{ fontFamily: 'monospace' }}>{labels.notAccessible}</div>
    </div>
  )
}
