import { Loading, Error } from 'components'
import Firebase from 'firebase/app'
import React, { useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { TemplatePiccle } from 'types'
import CrayonProjectionGrid from './CrayonProjectionGrid'

type Props = {
  journeyCollectionPath: string
  journeyId: string
  questionId: string
  isModeration?: boolean
  onClick?: (piccleId: string) => void
}

const CrayonProjectionGridContainer = ({
  journeyCollectionPath,
  journeyId,
  questionId,
  isModeration,
  onClick,
}: Props) => {
  const initialAmountOfPiccles = 70
  const jumps = 15
  const [lastShownPiccleIndex, setLastShownPiccleIndex] = useState(initialAmountOfPiccles)

  const [piccleData, loading, error]: [TemplatePiccle[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(journeyCollectionPath)
      .doc(journeyId)
      .collection('piccles')
      .where('done', '==', true)
      .where('questionId', '==', questionId)
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )

  const getPiccleIds = (): string[] => {
    return piccleData.map(({ id }) => id).reverse()
  }

  const loadMorePiccles = async (): Promise<void> => {
    setLastShownPiccleIndex((lastIndex) => lastIndex + jumps)
  }

  return (
    <>
      {loading && (
        <div style={{ backgroundColor: 'white' }}>
          <Loading />
        </div>
      )}
      {error && <Error />}
      {piccleData && piccleData.length > 0 && (
        <CrayonProjectionGrid
          journeyCollectionPath={journeyCollectionPath}
          loadMorePiccles={loadMorePiccles}
          journeyId={journeyId}
          piccleIds={getPiccleIds().slice(0, lastShownPiccleIndex)}
          isModeration={isModeration}
          onClick={onClick}
        />
      )}
    </>
  )
}

export default React.memo(CrayonProjectionGridContainer)
