import React, { ReactElement, FunctionComponent } from 'react'
import Firebase from 'firebase/app'

import { Template, TemplateJourney } from 'types'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Loading, Error, TemplateJourneyCard } from 'components'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  template: Template
}

export const TemplateJourneyList: FunctionComponent<Props> = ({ template }: Props): ReactElement => {
  const navigate = useNavigate()
  const [data, loading, error]: [TemplateJourney[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(template.journeyCollectionPath)
      .where('isDeleted', '==', false),
    { idField: 'id' },
  )

  const handleOnEditTemplateJourney = (journeyId) => {
    navigate(`admin/${template.journeyCollectionPath}/journey/${journeyId}`)
  }

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {data &&
        data.map((journey: TemplateJourney) => {
          return (
            <TemplateJourneyCard
              key={journey.id}
              template={template}
              journey={journey}
              onEdit={() => handleOnEditTemplateJourney(journey.id)}
            />
          )
        })}
    </>
  )
}
