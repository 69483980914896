import React, { useState } from 'react'
import Firebase from 'firebase/app'
import { Input } from '@lightspeed/flame/Input'
import { Checkbox } from '@lightspeed/flame/Checkbox'

import { verifyUniqueCode } from 'api/helpers/firestoreHelpers'
import { Flex, Box } from 'rebass'
import { Helpbox } from 'components/atoms/Helpbox/Helpbox'
import { getJourneyFields, setJourneyFields } from 'api/journeys/journeys'
import { ReactHook } from '../SignupForm/SignupForm'
import { Radio, RadioLabel } from '@lightspeed/flame/Radio'
import { JourneyType } from '../../../types'
import { langValues } from '../PromptActivityForm/PromptActivityForm'
import { useTranslation } from 'react-i18next'
import { MultiSelect } from 'react-multi-select-component'

type InputType = {
  title?: string
  code?: string
  language?: string
}

type Props = {
  children?: React.ReactNode
  onSubmit: (journeyData) => Promise<void>
  values: { title?: string; code?: string; languages?: string[] }
}

//for dropdown lib
const langOptions = [
  { label: 'Cantonese', value: 'zh' },
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Mandarin', value: 'zh-hant' },
  { label: 'Mandarin(simplified)', value: 'zh-hans' },
  { label: 'Spanish', value: 'es' },
  { label: 'Thai', value: 'th' },
]

export const JourneyForm: React.FunctionComponent<Props> = ({ onSubmit, values }: Props): JSX.Element => {
  const { t } = useTranslation()

  const journeyId = window.location.pathname.replace('/admin/edit/', '')

  const [codeError, setCodeError]: ReactHook<string> = useState<string>('')
  const [qrEnable, setQrEnabled]: ReactHook<boolean> = useState<boolean>(false)
  const [journeyType, setJourneyType]: ReactHook<JourneyType> = useState<JourneyType>()
  const [collectInfo, setCollectInfo] = useState<boolean>(false)

  // Controlled form state and handlers. "values" prop will be undefined if we are creating a NEW activity,
  // in which case we use the initial state defined:
  const { title, code, languages } = values
  const [inputs, setInputs]: [InputType, React.Dispatch<React.SetStateAction<InputType>>] = useState<InputType>(
    { title, code, language: languages[0] } || { title: '', code: '', language: 'en' },
  )
  const initialLang = languages.map((lang) => ({ label: langValues[lang], value: lang }))
  const [selected, setSelected] = useState(initialLang)

  React.useEffect(() => {
    // update languages array on journey docs
    const updateLanguages = async () => {
      const updatedLanguages = selected.map(({ value }) => value)
      await onSubmit({ languages: updatedLanguages })
    }
    updateLanguages()
  }, [selected]) // eslint-disable-line

  React.useEffect(() => {
    getJourneyFields(journeyId).then((journey: Firebase.firestore.DocumentData) => {
      setQrEnabled(journey.QRcode || false)
      setJourneyType(journey.type)
      setCollectInfo(journey.collectInfo || false)
    })
  }, [journeyId])

  const handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    // remove error status on code input when typing.
    if (event.target.name === 'code') {
      setCodeError('')
    }
    event.persist()
    setInputs((inputs: InputType): InputType => ({ ...inputs, [event.target.name]: event.target.value }))
  }

  const handleOnTitleBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target

    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, title: values.title }))
      return
    } else {
      const data: { [keyname: string]: string } = { [name]: value }
      await onSubmit(data)
    }
  }

  const handleOnCodeBlur: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target

    // if code input left blank, reset to previous value and get out.
    if (!value) {
      setInputs((inputs: InputType): InputType => ({ ...inputs, code: values.code }))
      return
    }

    // if code hasn't changed, get out.
    if (value === values.code) {
      return
    }

    // else check if code is unique
    const data: { [keyname: string]: string } = { [name]: value }
    const isUnique: boolean = await verifyUniqueCode(data[name])
    if (isUnique) {
      await onSubmit(data)
    } else {
      setCodeError('code is already in use, must be unique')
    }
  }

  //leaving it for the future reference
  // const handleOnLanguageSelect: (event: React.ChangeEvent<HTMLSelectElement>) => Promise<void> = async (
  //   event: React.ChangeEvent<HTMLSelectElement>,
  // ): Promise<void> => {
  //   event.preventDefault()
  //   const { value }: EventTarget & HTMLSelectElement = event.target

  //   const index = languages.indexOf(value)

  //   let newLanguageArr: string[] = []
  //   if (index !== -1) {
  //     //replace the selected language in the languages array in the first place
  //     languages.splice(index, 1)
  //     newLanguageArr = [value, ...languages]
  //   } else {
  //     // simply add a new language as a default
  //     newLanguageArr = [value, ...languages]
  //   }

  //   const data = { languages: newLanguageArr }
  //   await onSubmit(data)
  // }

  const onInputRestrictCharacters: (event: React.KeyboardEvent<HTMLInputElement>) => void = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    // REGEX matches lower case alphanumeric and Backspace
    // if user enters anything else, we prevent.
    // eslint-disable-next-line
    if (!event.key.match(/[a-zA-Z0-9]+|[\b]+$/)) {
      event.preventDefault()
      event.stopPropagation()
      return
    }
  }

  const toggleQRCode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const journeyId = window.location.pathname.replace('/admin/edit/', '')

    try {
      await setJourneyFields(journeyId, { QRcode: event.target.checked })
    } catch (error) {
      console.log(error)
    }
  }

  const toggleCollectInfo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const journeyId = window.location.pathname.replace('/admin/edit/', '')

    try {
      await setJourneyFields(journeyId, { collectInfo: event.target.checked })
    } catch (error) {
      console.log(error)
    }
  }

  const journeyTypeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as JourneyType

    try {
      await setJourneyFields(journeyId, { type })
    } catch (error) {
      console.log(error)
    } finally {
      setJourneyType(type)
    }
  }

  return (
    <>
      <Flex flexDirection="row" alignItems="center">
        <Box width={1 / 5} px={2}>
          <Input
            label={
              <>
                {t('journeyForm.title')}
                <Helpbox info={t('journeyForm.titleHelpbox')} />
              </>
            }
            placeholder={t('journeyForm.titlePlaceholder')}
            id="title"
            name="title"
            defaultValue={values.title}
            onBlur={handleOnTitleBlur}
          />
        </Box>

        <Box width={1 / 5} px={2}>
          <Input
            label={
              <>
                <>{t('journeyForm.code')} </>
                <Helpbox info={t('journeyForm.codeHelp')} />
              </>
            }
            onKeyDown={onInputRestrictCharacters}
            id="code"
            name="code"
            value={inputs.code.toUpperCase()}
            onChange={handleInputChange}
            autoComplete="off"
            onBlur={handleOnCodeBlur}
            status={codeError ? 'error' : 'valid'}
            statusMessage={codeError}
          />
        </Box>
        <Box width={2 / 5} px={2}>
          <label htmlFor="language" style={{ fontWeight: 'bold' }}>
            {t('journeyForm.language')} <Helpbox info={t('journeyForm.languageHelp')} />
          </label>

          {/* 
          <select
            name="language"
            id="language"
            onChange={(event) => {
              handleInputChange(event)
              handleOnLanguageSelect(event)
            }}
            value={values.languages[0]}
            style={{ marginTop: '5px', padding: '8px 30px' }}
          >
            {languages.map((lang) => {
              return (
                <option key={lang} value={lang}>
                  {langValues[lang]}
                </option>
              )
            })}
          </select> */}
          <MultiSelect
            options={langOptions}
            value={selected}
            onChange={setSelected}
            ClearSelectedIcon={<React.Fragment />}
            labelledBy="Select"
          />
        </Box>

        <Box
          width={1 / 5}
          px={2}
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <RadioLabel>
            <Radio
              name="journeyType"
              value={JourneyType.SYNCHRONOUS}
              label="Synchronous"
              checked={!journeyType || journeyType === JourneyType.SYNCHRONOUS}
              onChange={journeyTypeChange}
            />
          </RadioLabel>

          <RadioLabel>
            <Radio
              name="journeyType"
              value={JourneyType.ASYNCHRONOUS}
              label="Asynchronous"
              checked={journeyType === JourneyType.ASYNCHRONOUS}
              onChange={journeyTypeChange}
            />
          </RadioLabel>

          <Checkbox
            css={{
              paddingTop: '0.25rem',
              paddingRight: '0.75rem',
              textAlign: 'center',
            }}
            label={<>{t('journeyForm.QRCode')}</>}
            onChange={(event) => {
              try {
                toggleQRCode(event)
              } catch (error) {
                console.error(error)
              } finally {
                setQrEnabled(!qrEnable)
              }
            }}
            checked={qrEnable}
          />

          <Checkbox
            css={{
              paddingTop: '0.25rem',
              paddingRight: '0.75rem',
              textAlign: 'center',
            }}
            label={<>{t('journeyForm.collectContactInfo')}</>}
            onChange={(event) => {
              try {
                toggleCollectInfo(event)
              } catch (error) {
                console.error(error)
              } finally {
                setCollectInfo(!collectInfo)
              }
            }}
            checked={collectInfo}
          />
        </Box>
      </Flex>
    </>
  )
}
