/** @jsx jsx */
import { jsx, css } from '@emotion/core'

type Props = {
  children: React.ReactNode
  messageBody: string
}

export const ShareSms = ({ children, messageBody }: Props) => (
  <div data-testid="ShareSms">
    <a
      css={css`
        text-decoration: none;
      `}
      href={`sms://&body=${encodeURIComponent(messageBody)}`}
    >
      {children}
    </a>
  </div>
)
