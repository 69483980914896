import React from 'react'
import Firebase from 'firebase/app'

import { AsynchronousPicclesCanvas } from './AsynchronousPicclesCanvas'
import { Loading } from 'components/atoms/Loading'
import { canvasObjConstantProperties } from 'appConstants'
import { JourneyType } from '../../../types'
import updatePiccle from 'api/piccles/updatePiccle'
import createPiccle from 'api/piccles/createPiccle'
import { useNavigate } from 'hooks/useNavigate'
import { useLocale } from 'hooks/useChangeLocale'

export const AsynchronousPicclesContainer: React.FC<any> = ({ journeyCode, journeyId, activityId, piccleId }) => {
  const navigate = useNavigate()
  const { locale } = useLocale()

  const [journey, setJourney] = React.useState(null)
  const [activities, setActivities] = React.useState(null)
  const [currentActivity, setCurrentActivity] = React.useState(null)
  const [strokes, setStrokes] = React.useState(null)

  const nextActivityId = journey && journey.sequence[journey.sequence.indexOf(activityId) + 1]

  const getJourney = async (journeyId: string): Promise<void> => {
    try {
      const journeySnapshot = await Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .get()
      setJourney(journeySnapshot.data())
    } catch (error) {
      console.log(error)
    }
  }

  const getActivity = async (journeyId: string): Promise<void> => {
    try {
      const activityCollectionSnapshot = await Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .collection('activities')
        .get()

      const activity = activityCollectionSnapshot.docs.map((activity) => ({ ...activity.data(), id: activity.id }))
      setActivities(activity)
    } catch (error) {
      console.log(error)
    }
  }

  const getCurrentPiccleStroke = async (journeyId: string, piccleId: string): Promise<void> => {
    const rehydrateCanvasObjects = (canvasObjects) =>
      canvasObjects.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))

    try {
      const piccleStrokes = rehydrateCanvasObjects(
        await Firebase.firestore()
          .collection('journeys')
          .doc(journeyId)
          .collection(`activities/${activityId}/piccles/${piccleId}/strokes`)
          .orderBy('createdOn', 'asc')
          .get()
          .then((snap: Firebase.firestore.QuerySnapshot) => snap.docs)
          .then((docs: Firebase.firestore.QueryDocumentSnapshot[]) =>
            docs.map((doc: Firebase.firestore.QueryDocumentSnapshot): {
              id: string
            } => ({ ...doc.data(), id: doc.id })),
          ),
      )

      setStrokes(piccleStrokes)
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdatePiccle = (payload) => {
    return updatePiccle({ journeyId, activityId, piccleId, payload })
  }

  const goToNextActivity = async () => {
    const nextActivityId = journey && journey.sequence[journey.sequence.indexOf(activityId) + 1]
    const isFinalActivity = currentActivity.id === nextActivityId

    if (isFinalActivity || !nextActivityId) {
      return
    }

    if (nextActivityId)
      navigate(`${window.location.origin}/${journey.code}/${journeyId}/${nextActivityId}?locale=${locale}`)
  }

  const goToSharePage = async () => {
    if (currentActivity)
      navigate(`${window.location.origin}/${journey.code}/${journeyId}/${currentActivity.id}/${piccleId}/share`)
  }

  const generateCanvasForNextActivity = async () => {
    try {
      const newPiccleId = await createPiccle({ journeyId, activityId })
      navigate(`${window.location.origin}/${journeyCode}/${journeyId}/${activityId}/${newPiccleId}?locale=${locale}`)
    } catch (error) {
      console.log(error)
    }
  }

  const loading = !(journey && activities && strokes && currentActivity)

  React.useEffect(() => {
    getJourney(journeyId)
    getActivity(journeyId)
    getCurrentPiccleStroke(journeyId, piccleId) // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (journey && journey.type === JourneyType.SYNCHRONOUS)
      navigate(`${window.location.origin}/${journey.code}?locale=${locale}`)
    // eslint-disable-next-line
  }, [journey])

  React.useEffect(() => {
    if (activities) setCurrentActivity(activities.find((activity) => activity.id === activityId))
    // eslint-disable-next-line
  }, [activities])

  React.useEffect(() => {
    if (piccleId === undefined) generateCanvasForNextActivity()
    // eslint-disable-next-line
  }, [piccleId])

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <AsynchronousPicclesCanvas
          journeyId={journeyId}
          canvasObjects={strokes}
          activityId={currentActivity.id}
          currentActivity={currentActivity}
          piccleId={piccleId}
          goToSharePage={goToSharePage}
          colors={currentActivity.colors}
          prompt={currentActivity.prompt}
          drawingLimit={false}
          handleUpdatePiccle={handleUpdatePiccle}
          journey={journey}
          goToNextActivity={goToNextActivity}
          nextActivityId={nextActivityId}
        />
      )}
    </>
  )
}
