// Helper functions for working with Firebase Firestore

import Firebase from 'firebase/app'
import 'firebase/auth'

const getUserId = (): string => (Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null)

const prepareDocForCreate = (doc) => {
  // timestamps
  doc.createdBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.createdOn = Firebase.firestore.Timestamp.now()
  doc.deleted = false
  return doc
}

const prepareDocForUpdate = (doc) => {
  // timestamps
  doc.updatedBy = Firebase.auth().currentUser ? Firebase.auth().currentUser.uid : null
  doc.updatedOn = Firebase.firestore.Timestamp.now()

  // don't save the id as part of the document
  delete doc.id

  // don't save values that start with an underscore (these are calculated by the backend)
  Object.keys(doc).forEach((key: string) => {
    if (key.indexOf('_') === 0) {
      delete doc[key]
    }
  })

  return doc
}

// Used to verify the uniqueness of a code before journey creation.
const verifyUniqueCode = async (code: string): Promise<boolean> => {
  const experiences: Firebase.firestore.QuerySnapshot = await Firebase.firestore()
    .collection('journeys')
    .where('code', '==', code)
    .get()

  return experiences.size === 0
}

// Used to verify the uniqueness of a code when saving a new code for pre-exsiting journey
const verifyUniqueCodeForJourney = async (code: string): Promise<boolean> => {
  const experiences: Firebase.firestore.QuerySnapshot = await Firebase.firestore()
    .collection('journeys')
    .where('code', '==', code)
    .get()

  return experiences.size === 1
}

const isValidFirebaseDocumentId = (documentId: string): void => {
  const firebaseDocumentIdCharLimit = 1500
  const illegalDocumentId = ['.', '..']

  if (documentId.length > firebaseDocumentIdCharLimit) {
    const characterLimitException = new Error('Character limit exceeded. Please enter an input below 1500 characters.')
    characterLimitException.name = 'CharacterLimitException'
    throw characterLimitException
  }

  if (documentId === illegalDocumentId[0] || documentId === illegalDocumentId[1]) {
    const illegalDocumentNamesException = new Error(
      `Company name cannot be ${illegalDocumentId[0]} or ${illegalDocumentId[1]}`,
    )
    illegalDocumentNamesException.name = 'IllegalDocumentNamesException'
    throw illegalDocumentNamesException
  }

  if (documentId.match(/\//)) {
    const forwardSlashError = new Error('Company name cannot contain a forward slash.')
    forwardSlashError.name = 'ForwardSlashException'
    throw forwardSlashError
  }

  if (documentId.match(/__.*__/)) {
    const reservedUnderscorePatternException = new Error(
      'Company name cannot start with two underscores and end with two underscores.',
    )
    reservedUnderscorePatternException.name = 'ReservedUnderscorePatternException'
    throw reservedUnderscorePatternException
  }
}

export {
  prepareDocForCreate,
  prepareDocForUpdate,
  getUserId,
  verifyUniqueCode,
  verifyUniqueCodeForJourney,
  isValidFirebaseDocumentId,
}
