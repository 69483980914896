import Firebase from 'firebase/app'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { Journey } from 'hooks/useJourneyData'
import 'firebase/storage'

export const downloadJourney: Function = async (
  journey: Journey,
): Promise<{ type: string; goal?: number; completed?: number }> => {
  try {
    // get all activities
    const activityData = []
    await Firebase.firestore()
      .collection('journeys')
      .doc(journey.id)
      .collection(`activities`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            activityData.push({ ...doc.data(), id: doc.id })
          }
        })
      })

    const zip = new JSZip()
    const zipFolder = zip.folder(journey.title)
    let numberOfPiccles = 0
    let downloadCount = 0
    await Promise.all(
      activityData.map(async (activity) => {
        const actFolder = zipFolder.folder(activity.question.en.replace(/\?/g, ''))

        // now fetching the list of pngs in storage directly instead of checking firebase docs
        const list = await Firebase.storage()
          .ref(`${journey.id}/${activity.id}`)
          .listAll()

        await Promise.all(
          list.items.map(async (itemRef) => {
            numberOfPiccles++
            const url = await itemRef.getDownloadURL()
            const imageName = await itemRef.name

            const blob = await fetch(url).then((response) => response.blob())
            actFolder.file(`${imageName}`, blob, { binary: true })
            downloadCount++
          }),
        )
      }),
    )
    await zip.generateAsync({ type: 'blob' }).then(function(content) {
      saveAs(content, journey.title + '.zip')
    })
    return { type: 'success', goal: numberOfPiccles, completed: downloadCount }
  } catch (err) {
    console.log(err)
    return { type: 'error' }
  }
}
