import React, { useState, ReactElement, FunctionComponent } from 'react'
import { Flex, Box } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Heading3 } from '@lightspeed/flame/Text'
import { Alert } from '@lightspeed/flame/Alert'
import { PROMPT_ACTIVITY, MOSAIC_ACTIVITY } from 'appConstants'
import { FaTrash } from 'react-icons/fa'

import {
  JourneyForm,
  ActivityStack,
  AdminLayout,
  CreateActivity,
  PromptActivityForm,
  MosaicActivityForm,
} from 'components'

export type Data = {
  code: string
  title: string
  languages: string[]
  sequence?: []
}

export type Activity = {
  id: string
  type: string
  title: string
  prompt?: string
  isStarted: boolean
  isComplete: boolean
  numSquares?: string
  imgUrl?: string
  maxSquares?: string
  sponsor?: string
  continuous?: boolean
  colors?: string[]
  timer?: string
  participantGoal?: string
  drawingLimit?: '1' | 'infinite'
  promotedPiccle?: string
  question: { [key: string]: string }
}

type Props = {
  handleCreateActivity: (activityData: any) => Promise<string>
  handleUpdateJourney: (journeyData) => Promise<void>
  handleUpdateActivity: (activityData) => Promise<void>
  handleDeleteActivity: (activityId: string, sequence: string[]) => Promise<void>
  handleCreateSponsor: (sponsorData) => Promise<void>
  handleValidateUniqueCode: (code) => Promise<boolean>
  handleDeleteJourney: () => void
  journeyData: Data
  activitiesData: Activity[]
  sponsorsData: { name: string; slug: string }[]

  labels: {
    editJourney: string
  }
}

export enum ActivityType {
  PROMPT = 'prompt',
  IMAGE_PROMPT = 'Prompt and Image',
  MOSAIC = 'Mosaic',
}

const PageEditJourney: FunctionComponent<Props> = ({
  journeyData,
  activitiesData,
  sponsorsData,
  handleCreateActivity,
  handleUpdateActivity,
  handleUpdateJourney,
  handleDeleteActivity,
  handleCreateSponsor,
  // handleValidateUniqueCode, (Commented because never used)
  labels,
  handleDeleteJourney,
}: Props): ReactElement => {
  const [activityForEdit, setActivityForEdit] = useState<Activity>(null)
  const [activityTypeForCreate, setActivityTypeForCreate] = useState<ActivityType>(null)
  const [validation, setValidation] = useState<string>('')

  // const validate: Function = async (): Promise<boolean> => {
  //   let message = 'Missing:'
  //   let isValid = true

  //   if (!journeyData.title) {
  //     message += ' title,'
  //     isValid = false
  //   }

  //   if (!journeyData.code) {
  //     message += ' code,'
  //     isValid = false
  //   }
  //   // run unique code check at the end since it requires api call.
  //   if (journeyData.code && !(await handleValidateUniqueCode(journeyData.code))) {
  //     message = `Code ${journeyData.code} is already taken`
  //     isValid = false
  //   }

  //   if (!isValid) setValidation(message)
  //   return isValid
  // }

  const onClickEditActivity: (activityId: string) => void = (activityId: string): void => {
    setActivityTypeForCreate(null)
    setActivityForEdit(activitiesData.find((a) => activityId === a.id))
  }

  const onUpdateActivity = async (data): Promise<void> => {
    try {
      await handleUpdateActivity(data)
    } catch (e) {
      alert(e)
    }
    setActivityForEdit(null)
  }

  const onCreateActivity = async (data): Promise<string> => {
    const activityId = await handleCreateActivity(data)
    setActivityTypeForCreate(null)
    return activityId
  }

  const onDeleteActivity = async (id: string): Promise<void> => {
    try {
      await handleDeleteActivity(id, journeyData.sequence)
    } catch (e) {
      alert(e)
    }
    setActivityForEdit(null)
    setActivityTypeForCreate(null)
  }

  const onSelectActivityType = (type: ActivityType): void => {
    setActivityForEdit(null)
    setActivityTypeForCreate(type)
  }

  const onActivityReorder = (sequence): void => {
    handleUpdateJourney({ sequence })
  }

  const activityFormForEditSwitcher: Function = (type: ActivityType): ReactElement =>
    ({
      [PROMPT_ACTIVITY]: (
        <PromptActivityForm
          onSave={onUpdateActivity}
          values={activityForEdit}
          onDelete={onDeleteActivity}
          onCreateSponsor={handleCreateSponsor}
          sponsors={sponsorsData}
          journey={journeyData}
          handleUpdateJourney={handleUpdateJourney}
        />
      ),
      [MOSAIC_ACTIVITY]: (
        <MosaicActivityForm onSave={onUpdateActivity} values={activityForEdit} onDelete={onDeleteActivity} />
      ),
    }[type])

  const activityFormForCreateSwitcher: Function = (type: ActivityType): ReactElement =>
    ({
      [PROMPT_ACTIVITY]: (
        <PromptActivityForm
          onSave={onCreateActivity}
          onCreateSponsor={handleCreateSponsor}
          sponsors={sponsorsData}
          journey={journeyData}
          handleUpdateJourney={handleUpdateJourney}
        />
      ),
      [MOSAIC_ACTIVITY]: <MosaicActivityForm onSave={onCreateActivity} />,
    }[type])

  // Order the activities before passing to stack
  const orderedActivities: Activity[] = journeyData.sequence
    ? journeyData.sequence.map((id) => activitiesData.find((a) => a.id === id)).filter((act) => act !== undefined) // Filter out undefined elements which may result from orphaned activities.
    : []

  return (
    <AdminLayout>
      <Flex justifyContent="flex-end" width="100%" mb={2}>
        {validation && (
          <Alert mb={0} py={1} mx="auto" type="warning" title="" onClose={() => setValidation('')}>
            {validation}
          </Alert>
        )}
      </Flex>

      <Box mb={3}>
        <Card>
          <CardSection bg="darkGreen">
            <Flex alignItems="center">
              <Heading3 color="white">{labels.editJourney}</Heading3>

              <Box onClick={handleDeleteJourney} sx={{ cursor: 'pointer', marginLeft: 'auto', color: 'background' }}>
                <FaTrash />
              </Box>
            </Flex>
          </CardSection>

          <CardSection large>
            <JourneyForm
              onSubmit={handleUpdateJourney}
              values={{ title: journeyData.title, code: journeyData.code, languages: journeyData.languages }}
            />
          </CardSection>
        </Card>
      </Box>

      <Flex>
        <Box width={[2 / 3]} mb={3} mr={[3]}>
          <Card>
            <CardSection bg="DarkGreen">
              <CreateActivity activityType={activityTypeForCreate} onSelectType={onSelectActivityType} />
            </CardSection>

            <CardSection large style={{ minHeight: '6rem' }}>
              {activityForEdit && activityFormForEditSwitcher(activityForEdit.type)}
              {activityTypeForCreate && activityFormForCreateSwitcher(activityTypeForCreate)}
            </CardSection>
          </Card>
        </Box>

        <Box width={[1 / 3]} mb={3}>
          <Card>
            <CardSection bg="DarkGreen">
              <Flex alignItems="center">
                <Heading3 color="white">Activity Stack</Heading3>
              </Flex>
            </CardSection>

            <CardSection large style={{ minHeight: '6rem' }}>
              <ActivityStack
                activities={orderedActivities}
                onReorder={onActivityReorder}
                onClickEditActivity={onClickEditActivity}
                activityIdForEdit={activityForEdit && activityForEdit.id}
              />
            </CardSection>
          </Card>
        </Box>
      </Flex>
    </AdminLayout>
  )
}

export default PageEditJourney
