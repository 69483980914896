import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import { Question } from 'types'
import { prepareDocForCreate } from '../helpers/firestoreHelpers'

export type CreateQuestionArguments = {
  templateId: string
  payload: Pick<Question, 'categories' | 'title'>
}

const createQuestion = async ({ templateId, payload }: CreateQuestionArguments): Promise<string> => {
  ReactGA.event({
    category: 'Questions',
    action: 'create template question',
  })

  const questionId: string = await Firebase.firestore()
    .collection('templates')
    .doc(templateId)
    .collection('questions')
    .add(prepareDocForCreate(payload))
    .then((res: Firebase.firestore.DocumentReference): string => res.id)

  return questionId
}

export default createQuestion
