import deleteJourney, { DeleteJourneyArgs } from './deleteJourney'
import updateJourney, { UpdateJourneyArgs } from './updateJourney'
import getJourney, { GetJourneyArgs } from './getJourney'
import createJourney from './createJourney'
import getJourneyActivities, { GetJourneyActivitiesArgs } from './getJourneyActivities'
import toggleDisplayAll, { ToggleDisplayAllArgs } from './toggleDisplayAll'
import moveForwardJourneyActivity, { MoveForwardJourneyActivity } from './moveForwardJourneyActivity'
import moveBackwardJourneyActivity, { MoveBackwardJourneyActivity } from './moveBackwardJourneyActivity'

export type JourneyActions = {
  getJourney: (getJourneyArgs: GetJourneyArgs) => Promise<any>
  deleteJourney: (deleteJourneyArguments: DeleteJourneyArgs) => Promise<void>
  updateJourney: (updateJourneyArguments: UpdateJourneyArgs) => Promise<any>
  createJourney: (data: any) => Promise<string | void>
  toggleDisplayAll: (toggleDisplayAllArgs: ToggleDisplayAllArgs) => Promise<any>
  getJourneyActivities: (args: GetJourneyActivitiesArgs) => Promise<any>
  moveForwardJourneyActivity: (moveForwardJourneyActivityArgs: MoveForwardJourneyActivity) => Promise<void>
  moveBackwardJourneyActivity: (moveBackwardJourneyActivityArgs: MoveBackwardJourneyActivity) => Promise<void>
}

const journeyActions: JourneyActions = {
  getJourney,
  deleteJourney,
  updateJourney,
  createJourney,
  toggleDisplayAll,
  getJourneyActivities,
  moveForwardJourneyActivity,
  moveBackwardJourneyActivity,
}

export default journeyActions
