import React from 'react'
import { Flex } from '@lightspeed/flame/Core'
import { Heading3 } from '@lightspeed/flame/Text'
import PlanCard from './PlanCard'
import PlanSwitch from 'components/atoms/PlanSwitch/PlanSwitch'

// product images
import tasterImg from 'assets/images/products/taster.png'
import primeImg from 'assets/images/products/prime.png'
import proImg from 'assets/images/products/pro.png'
import bigDillImg from 'assets/images/products/big_dill.png'

export enum EventType {
  SINGLE = 'SINGLE',
  ANNUAL = 'ANNUAL',
}

export enum Subscription {
  TASTER = 'TASTER',
  PRIME = 'PRIME',
  PRO = 'PRO',
  BIGDILL = 'BIGDILL',
}

type Price = {
  [EventType.ANNUAL]: string
  [EventType.SINGLE]: string
}

export type SubscriptionUi = {
  name: Subscription
  price: Price
  image?: string
  description: string
  descriptionSubText?: string
  participants?: string
  branding: string
  data: string
  support: string
  btnText: string
  btnSubText?: string
  color: string
  btnTextColor: string
}

const subscriptionsUi: SubscriptionUi[] = [
  {
    name: Subscription.TASTER,
    description: 'Free fun with your family and friends',
    image: tasterImg,
    price: { ANNUAL: '$0', SINGLE: '$0' },
    participants: 'Up to 10 concurrent participants',
    branding: 'Piccles',
    data: 'none',
    support: 'Online support (Videos/FAQs)',
    btnText: 'Free!',
    btnSubText: 'No credit card required',
    color: '#9573AC',
    btnTextColor: '#FFF',
  },
  {
    name: Subscription.PRIME,
    description: 'All the features for teachers, speakers & trainers to keep audiences engaged.',
    descriptionSubText: '* Add more users at $11/per month',
    image: primeImg,
    price: { ANNUAL: '$19', SINGLE: '$99' },
    participants: 'Up to 50 concurrent participants',
    branding: 'Piccles',
    data: 'Basic analytics\n Download of drawing',
    support: 'Support tickets (72 hr response)',
    btnText: 'Buy Prime',
    btnSubText: '30 day money-back guarantee',
    color: '#51831A',
    btnTextColor: '#FFF',
  },
  {
    name: Subscription.PRO,
    description: 'Custom branding for your company to own the experiance',
    descriptionSubText: '* Add more users at $99/per month',
    image: proImg,
    price: { ANNUAL: '$125', SINGLE: '$499' },
    participants: 'Up to 500 concurrent participants',
    branding: 'Upload your logo\n Customize access codes',
    data: 'Basic analytics\n Download of drawing',
    support: 'Email support ( <24 hr response) \n Phone Support',
    btnText: 'Buy Pro',
    btnSubText: '30 day money-back guarantee',
    color: '#3A1256',
    btnTextColor: '#FFF',
  },
  {
    name: Subscription.BIGDILL,
    description: 'Cook up a custom package specially designed for your organization',
    descriptionSubText: '* Add more users at $99/per month',
    image: bigDillImg,
    price: { ANNUAL: 'STARTING AT $5000', SINGLE: 'STARTING AT $999' },
    participants: 'Organization-wide licenses up to 50,000 participants',
    branding: 'Every element customizable',
    data:
      'Advance analytics and information capture capabilities. \n *contact us to talk about custome feature requests. Wild ideas welcome.',
    support: 'Dedicated support manager plus priority phone and email support \n Custom onboarding included',
    btnText: 'Contact Sales',
    color: '#FF904D',
    btnTextColor: '#000',
  },
]

//type Translations = typeof translations.en

type PageProfileProps = {
  eventType: EventType
  currentSubscription: Subscription
  subscriptionSelection: Subscription
  setEventType: React.Dispatch<React.SetStateAction<EventType>>
  setSubscriptionSelection: (subscription: Subscription) => void
}

const Plans = ({ eventType, currentSubscription, setEventType, setSubscriptionSelection }: PageProfileProps) => {
  const handleEventType = (type: EventType) => {
    setEventType(type)
  }

  const handleSubscriptionPicked = (subscription: Subscription) => {
    setSubscriptionSelection(subscription)
  }

  const options = [
    { label: 'Annual', value: EventType.ANNUAL },
    { label: 'Single Event', value: EventType.SINGLE },
  ]

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" mb={32} mx={2}>
        <Heading3 color="#452768" fontWeight="800">
          Choose a plan to upgrade
        </Heading3>

        <PlanSwitch
          options={options}
          selected={options.find((o) => o.value === eventType)}
          onPlanTypePicked={(type) => handleEventType(type.value)}
        />
      </Flex>

      <Flex flexWrap="wrap">
        {subscriptionsUi.map((subscription) => (
          <PlanCard
            key={subscription.name}
            subscription={subscription}
            isCurrentSubscription={currentSubscription === subscription.name && eventType === EventType.ANNUAL}
            currentSubscription={currentSubscription}
            period={eventType}
            pickSubscription={() => handleSubscriptionPicked(subscription.name)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default Plans
