import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'
import { Error } from 'components/atoms/Error'
import { Loading } from 'components/atoms/Loading'
import { JourneyData } from 'hooks/useJourneyData'
import { GlobalActivities } from './GlobalActivities'

export const GlobalActivitiesContainer = () => {
  const [data, loading, error]: [JourneyData[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .where('isGlobal', '==', true)
      .orderBy('createdOn', 'desc'),
    { idField: 'id' },
  )

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {data && <GlobalActivities data={data} />}
    </>
  )
}
