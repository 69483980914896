import React from 'react'
import useTranslations from 'hooks/useTranslations'
import { Label } from '@rebass/forms'
import { Flex, Text, Box } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'
import { Loading } from 'components'

const translations = {
  en: {
    drawing: 'Drawing image',
    drag: 'Drag & Drop image here or',
    load: 'Load image file',
  },
  fr: {
    drawing: 'Image pour dessin',
    drag: 'Drag & Drop image here or',
    load: 'Telecharger fichier image',
  },
}

type Props = {
  loadFileHandler: (files: any) => void
  isUploadingImage: boolean
  required?: boolean
}

export const ImageUploader = ({ loadFileHandler, isUploadingImage, required }: Props) => {
  const labels = useTranslations<typeof translations.en>(translations)

  const blockEventDefaults = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onDropHandler = (event) => {
    blockEventDefaults(event)
    console.log('\n%cevent.dataTransfer', 'color:orange;font-weight:bold;', event.dataTransfer, '\n\n')
    const { files } = event.dataTransfer
    loadFileHandler(files[0])
  }

  const onFileSelect = (event) => {
    const { files } = event.target
    loadFileHandler(files[0])
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mt={2}
      sx={{
        width: '100%',
        maxWidth: '300px',
        height: '300px',
        background: '#FFFFFF',
        border: '3px dashed #EBEBEB',
        textAlign: 'center',
      }}
      onDragEnter={blockEventDefaults}
      onDragOver={blockEventDefaults}
      onDrop={onDropHandler}
    >
      {isUploadingImage ? (
        <Loading />
      ) : (
        <Box sx={{ zIndex: 1 }} bg="white">
          <Text fontSize={3}>{labels.drawing}</Text>
          <Text fontSize={1}>{labels.drag}</Text>
          <Button>
            <Label for="ImageLoad">{labels.load}</Label>
          </Button>
          <Input
            style={{ zIndex: -1, position: 'absolute' }}
            type="file"
            name="file"
            id="ImageLoad"
            onChange={onFileSelect}
            accept=".jpg, .jpeg, .png"
            required={required}
          />
        </Box>
      )}
    </Flex>
  )
}
