import createReducerContext from 'reducer-context'
import picclesAPI, { PicclesApiObject } from 'api'
// import { EventType } from './Plans'

// Define some typescript shennanigans
type State = {
  status: PageStatus
  paymentMethod: PaymentMethod
  upgradeIntent: boolean
  plan: PlanStatus
}

type PageStatus = 'idle' | 'change_payment_method' | 'fetching_payment_method' | 'error' | 'creating_subscription'

type PlanStatus = null | 'free' | 'active' | 'inactive'

export type PaymentMethod = {
  id: string
  card: {
    brand: string
    last4: string
    expMonth: string
    expYear: string
  }
} | null

type StatusChangeAction = {
  type: 'change_status'
  payload: PageStatus
}

type SetUpgradeIntent = {
  type: 'upgrade_intent'
  payload: boolean
}

type SetPaymentMethodAction = {
  type: 'set_payment_method'
  payload: PaymentMethod
}

type SetPlanStatus = {
  type: 'set_plan_status'
  payload: PlanStatus
}

type Action = StatusChangeAction | SetPaymentMethodAction | SetUpgradeIntent | SetPlanStatus

// Async action creators
export const fetchPaymentMethod = async (dispatch, adminEmail: string) => {
  const { stripeApi }: PicclesApiObject = picclesAPI()

  dispatch({ type: 'change_status', payload: 'fetching_payment_methods' })
  try {
    const { paymentMethods, customer } = await stripeApi.getPaymentMethods(adminEmail)
    // Find the default payment method
    const defaultPaymentMethod = paymentMethods.find((pm) => pm.id === customer.invoice_settings.default_payment_method)
    dispatch({ type: 'set_payment_method', payload: defaultPaymentMethod })
    dispatch({ type: 'change_status', payload: 'idle' })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'change_status', payload: 'error' })
  }
}

export const createStripeSubscription = async (
  dispatch,
  // adminEmail: string,
  // paymentMethodId: string,
  // period: EventType,
) => {
  // const { stripeApi }: PicclesApiObject = picclesAPI()

  dispatch({ type: 'change_status', payload: 'creating_subscription' })
  try {
    // const { status } = await stripeApi.upgradeCompanyPlan({
    //   paymentMethodId: paymentMethodId,
    //   email: adminEmail,
    //   period,
    // })
    // dispatch({ type: 'set_plan_status', payload: status })
    // dispatch({ type: 'change_status', payload: 'idle' })
    // dispatch({ type: 'upgrade_intent', payload: false })
    window.location.reload()
  } catch (err) {
    console.error(err)
    dispatch({ type: 'change_status', payload: 'error' })
  }
}

export const fetchSubsciptionStatus = async (dispatch, adminEmail: string) => {
  const { stripeApi }: PicclesApiObject = picclesAPI()

  try {
    const customer = await stripeApi.getCustomer(adminEmail)

    const hasActivePlan = customer?.subscriptions.data.find((sub) => sub.status === 'active')

    const status = hasActivePlan ? 'active' : 'free'

    // TODO work out how we can detect inactive plans

    dispatch({ type: 'set_plan_status', payload: status })
    dispatch({ type: 'change_status', payload: 'idle' })
  } catch (err) {
    console.error(err)
    dispatch({ type: 'change_status', payload: 'error' })
  }
}

// Prepare the initial state of your container
const initialState: State = {
  status: 'idle',
  paymentMethod: null,
  upgradeIntent: false,
  plan: null,
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'change_status':
      return {
        ...state,
        status: action.payload,
      }
    case 'set_payment_method':
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case 'set_plan_status':
      return {
        ...state,
        plan: action.payload,
      }
    case 'upgrade_intent':
      return {
        ...state,
        upgradeIntent: action.payload,
      }
    default:
      return state
  }
}

// Pass in your reducer and initialState into the createReducerContext
const [ProfilePageProvider, useProfilePageContext] = createReducerContext(reducer, initialState)

export { ProfilePageProvider, useProfilePageContext }
