/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex, Box } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Text, Heading3 } from '@lightspeed/flame/Text'
import { useBreakPoint } from 'hooks/useBreakPoint'
import useTranslations from 'hooks/useTranslations'
import { markTemplateJourneyAsRecentlyEdited } from 'api/journeys/markJourneyAsRecentlyEdited'
import { openLinkInNewTab } from 'api/helpers/openLinkInNewTab'
import { bounce } from 'appConstants'
import { useState } from 'react'
import { Template, TemplateJourney } from 'types'
import { downloadJourney } from 'api/journeys/downloadJourney'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    edit: 'Edit',
    projection: 'Projection',
    download: 'Download',
    moderation: 'Moderation',
    code: 'Code',
  },
  fr: {
    edit: 'Modifier',
    projection: 'Projection',
    download: 'Télécharger',
    moderation: 'Modération',
    code: 'Code',
  },
}

type Translations = typeof translations.en

type Props = {
  template: Template
  journey: TemplateJourney
  onEdit: () => void
}

export const TemplateJourneyCard: React.FC<Props> = ({ template, journey, onEdit }: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()
  const breakPoint: string = useBreakPoint()
  const [downloadActive] = useState<boolean>(false)

  const handleDownload = async () => {
    //TODO inplement download journey
    await downloadJourney(journey)
  }

  const { journeyCollectionPath, id } = template

  return (
    <Box mb={2}>
      <Card>
        <CardSection mx={-2} p={2}>
          <Flex>
            <Flex
              width={breakPoint !== 'sm' ? 1 / 4 : 2 / 4}
              px={4}
              flexDirection="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Heading3
                css={css`
                  line-height: 1.3rem;
                  padding-bottom: 0.8rem;
                `}
              >
                {journey.title ? journey.title : 'Untitled'}
              </Heading3>

              <Button
                variant="secondary"
                onClick={() => {
                  markTemplateJourneyAsRecentlyEdited(template.journeyCollectionPath, journey.id)
                  onEdit()
                }}
              >
                {labels.edit}
              </Button>
            </Flex>

            {breakPoint !== 'sm' && (
              <Flex width={1 / 4} px={[1, 2]} justifyContent="flex-end" flexDirection="column">
                <Flex alignItems="center">
                  <Text color="textDim" pr={1}>
                    {labels.code}:{' '}
                  </Text>

                  <Text color="textDim" fontWeight="bold">
                    {journey.code}
                  </Text>
                </Flex>
              </Flex>
            )}

            <Flex width={2 / 4} px={[1, 2]} flexDirection="column" alignItems="flex-end">
              <Button
                variant="secondary"
                m={1}
                onClick={() => {
                  openLinkInNewTab(`/t/projection/${journeyCollectionPath}/${id}/${journey.id}`)
                }}
              >
                {labels.projection}
              </Button>

              <Button
                variant="secondary"
                m={1}
                onClick={() => {
                  markTemplateJourneyAsRecentlyEdited(template.journeyCollectionPath, journey.id)
                  navigate(`/admin/moderate/${journeyCollectionPath}/${id}/${journey.id}`)
                }}
              >
                {labels.moderation}
              </Button>

              <Button
                variant="secondary"
                m={1}
                onClick={handleDownload}
                css={() => css`
                  position: relative;
                  text-align: center;
                  margin-left: auto;
                  display: flex;
                  flex-direction: row;
                  .dot {
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin-top: 12px;
                    margin-left: 3px;
                    margin-right: 3px;
                    background: #452768;
                    animation: ${downloadActive ? bounce : null} 1s ease infinite;

                    &:nth-of-type(2) {
                      animation-delay: -1.1s;
                    }

                    &:nth-of-type(3) {
                      animation-delay: -0.9s;
                    }
                  }
                `}
              >
                {downloadActive ? (
                  <div style={{ display: 'inline-flex' }}>
                    <Text>Downloading</Text>
                    <Text className="dot"></Text>
                    <Text className="dot"></Text>
                    <Text className="dot"></Text>
                  </div>
                ) : (
                  <Text>Download</Text>
                )}
              </Button>
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </Box>
  )
}
