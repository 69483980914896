import Firebase from 'firebase/app'

export const hasOngoingActivity = async (journeyId: string): Promise<boolean> => {
  try {
    /**
     * We cannot have two active activities ("started" AND "incomplete") in the same journey.
     * Therefore we begin by verifying that no other activity in this journey are active ("started" AND "incomplete").
     * If no other activities are active ("started" AND "incomplete"), we may proceed to start this one.
     */
    const journeyActivities = await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .get()
      .then((snapshot: Firebase.firestore.QuerySnapshot) => snapshot.docs.map((doc) => doc.data()))

    // Ensure NO activities are currently active (started AND incomplete).
    return journeyActivities.some((activity: any) => activity.isStarted && !activity.isComplete)
  } catch (error) {
    console.log('Error', error)
  }
}
