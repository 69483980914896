import React from 'react'
import { Flex, Image } from 'rebass'

type CrayonHeaderProps = {
  secondaryLogo?: string
  primaryLogo: string
}

export const CrayonHeader = ({ primaryLogo, secondaryLogo }: CrayonHeaderProps) => {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      pl={20}
      pr={20}
      alignItems="center"
      width={[1]}
      sx={{ height: '80px', minWidth: ['60vw', '40vw', '20vw'] }}
    >
      {primaryLogo && <Image src={primaryLogo} height="64px" />}
      {secondaryLogo && <Image src={secondaryLogo} height="64px" />}
    </Flex>
  )
}
