export const cancelSubscription = async (email: string, endpointUrl: string): Promise<void> => {
  try {
    await fetch(`${endpointUrl}/cancel`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        admin: email,
      }),
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}
