/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'
import { Text } from '@lightspeed/flame/Text'

import { Input } from '@lightspeed/flame/Input'
import { Checkbox } from '@lightspeed/flame/Checkbox'
import { FaTrash } from 'react-icons/fa'
import useTranslations from 'hooks/useTranslations'
import { Question, QuestionCategory, UpdateQuestionInputType } from 'types'
import { useState } from 'react'

const translations = {
  en: {
    placeholder: 'Sibling/ playroom/ patient questions go here.',
  },
  fr: {
    placeholder: 'Sibling/ playroom/ patient questions go here.',
  },
}

type Translations = typeof translations.en

type QuestionItemProps = {
  index?: number
  question?: Question
  questionCategories?: (keyof typeof QuestionCategory)[]
  onDeleteQuestion?: (questionId: string) => void
  updateQuestions?: ({ questionId, payload }: UpdateQuestionInputType) => void
}

export const QuestionItem: React.FC<QuestionItemProps> = ({
  index,
  question,
  questionCategories,
  onDeleteQuestion,
  updateQuestions,
}: QuestionItemProps): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const [title, setTitle] = useState(question.title)
  const [categories, setCategories] = useState(question.categories)

  const onTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    event.preventDefault()
    const { value, name }: EventTarget & HTMLInputElement = event.target
    const newTitle = { ...title }
    newTitle[name] = value
    setTitle(newTitle)
    updateQuestions({ questionId: question.id, payload: { categories, title: newTitle } })
  }

  const hanldeCategoryChange = (item: keyof typeof QuestionCategory): void => {
    let newCategories = [...categories]

    if (isChecked(item)) {
      newCategories = newCategories.filter((c) => c !== item)
    } else {
      newCategories = [...newCategories, item]
    }
    setCategories(newCategories)
    updateQuestions({ questionId: question.id, payload: { title, categories: newCategories } })
  }

  const isChecked = (item: any): boolean => {
    return categories.includes(item)
  }

  return (
    <Box>
      <Flex flexDirection={'row'}>
        <Flex width={1 / 16}>
          <Text>{index + 1}</Text>
        </Flex>
        <Flex width={1 / 2} mr={5}>
          <Input placeholder={labels.placeholder} name="en" value={title.en} onChange={onTitleChange}></Input>
        </Flex>
        <Flex width={1 / 2} mr={5}>
          <Input placeholder={labels.placeholder} name="es" value={title.es} onChange={onTitleChange}></Input>
        </Flex>
        <Flex justifyContent="center" width={1 / 2}>
          {questionCategories.map((category) => (
            <Flex mr={5} width={1 / 3} key={category}>
              <Checkbox
                name={category}
                checked={isChecked(category)}
                onChange={() => hanldeCategoryChange(category)}
              ></Checkbox>
            </Flex>
          ))}
          <Box key={'box' + index} sx={{ cursor: 'pointer', marginLeft: 'auto', color: 'background' }}>
            <FaTrash color={'black'} onClick={() => onDeleteQuestion(question.id)} />
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}
