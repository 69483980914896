/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react'
import get from 'lodash.get'

import { PiccleCanvasContainer as PiccleCanvas } from 'components'
import useWindowWidth from 'hooks/useWindowWidth'

type Props = {
  journeyId: string
  activityId: string
  picclesData?: { piccleId: string; description: string }[]
  onSelect?: (id: string, description: string) => void
}

const FluidPiccleGrid: React.FunctionComponent<Props> = ({
  journeyId,
  picclesData,
  onSelect,
  activityId,
}: Props): React.ReactElement => {
  const windowWidth: number = useWindowWidth()
  const [gridWidth, setGridWidth]: [string | number, Dispatch<SetStateAction<string | number>>] = useState(null)
  const [gridHeight, setGridHeight]: [string | number, Dispatch<SetStateAction<string | number>>] = useState(null)

  const piccleGridEl: React.MutableRefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
  // find the smallest square number which is bigger or equal to your num of squares.
  // Divide 100 by this square number to get your width for sqaures.
  const squareNumbers: number[] = [1, 4, 9, 16, 25, 36, 49, 64, 81, 100]
  // getSquareParent returns the nearest square number which is bigger or equal to your number
  const getSquareParentIndex: Function = (num: number): number =>
    squareNumbers.findIndex((squareNum: number): boolean => num <= squareNum) + 1
  const getSquareWidth: Function = (num: string): number => 100 / getSquareParentIndex(num)
  const getGridWidth: Function = (): string | number => gridWidth
  const getGridHeight: Function = (): string | number => gridHeight
  const getCanvasWidth: Function = (): number => Number(getGridWidth()) / getSquareParentIndex(picclesData.length)
  const getCanvasHeight: Function = (): number => Number(getGridHeight()) / getSquareParentIndex(picclesData.length)
  const getCanvasSize: Function = (): number => Math.min(getCanvasWidth(), getCanvasHeight())
  const getIsReadyForRender: Function = (): boolean => !!getGridWidth()

  useEffect(() => {
    setGridWidth(get(piccleGridEl, 'current.clientWidth'))
    setGridHeight(get(piccleGridEl, 'current.clientHeight'))
  }, [windowWidth, picclesData.length])

  const handleOnSelect: Function = (event: React.MouseEvent<HTMLDivElement>) => (
    piccledId: string,
    description: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    if (onSelect) {
      onSelect(piccledId, description)
    }
  }

  return (
    <div
      ref={piccleGridEl}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {getIsReadyForRender() && (
        <div
          style={{
            width: getGridWidth(),
            height: getGridHeight(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap',
            background: 'white',
            border: '2px solid #3434347F',
          }}
        >
          {picclesData.map((
            { piccleId, description }, // **************************************************
          ) => (
            <div
              key={`grid:${piccleId}`}
              css={css`
                height: ${getSquareWidth(picclesData.length)}%;
                width: ${getSquareWidth(picclesData.length)}%;
                position: relative;
              `}
            >
              <div
                onClick={(e) => handleOnSelect(e)(piccleId, description)}
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  cursor: pointer;
                  z-index: 2;
                  transition: all 0.2s;
                  border-radius: 2px;
                  &:hover {
                    background: rgba(0, 0, 0, 0.2);
                  }
                `}
              />
              <PiccleCanvas
                journeyId={journeyId}
                activityId={activityId}
                piccleId={piccleId}
                width={getCanvasSize()}
                height={getCanvasSize()}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FluidPiccleGrid
