import Firebase from 'firebase/app'
import debounce from 'lodash.debounce'

import { prepareDocForCreate } from '../helpers/firestoreHelpers'

type CanvasObject = {
  sequence: string
  height: number
  left: number
  stroke: string
  strokeWidth: number
  top: number
  width: number
  path: any[] | string
}
type Args = {
  payload: CanvasObject
  journeyId: string
  activityId: string
  piccleId: string
  svg: string
}

const updatePiccleSvg = debounce(({ journeyId, activityId, piccleId, svg }: Args) => {
  Firebase.firestore()
    .doc(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}`)
    .update({ svg })
}, 1000)

const updatePiccle = ({ payload, journeyId, activityId, piccleId, svg }: Args) => {
  // nested arrays are not allowed so let's stringify our data path
  payload.path = JSON.stringify(payload.path)

  const newStroke = Firebase.firestore()
    .collection(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}/strokes`)
    .doc()

  newStroke.set(prepareDocForCreate(payload))

  updatePiccleSvg({ journeyId, activityId, piccleId, svg, payload })

  return newStroke.id
}

export default updatePiccle
