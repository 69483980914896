import Firebase from 'firebase/app'
import { canvasObjConstantProperties } from 'appConstants'

type Args = {
  journeyCode: string
  activityId: string
  piccleId: string
  setError: (arg) => void
  setData: (arg) => void
  setLoading: (arg) => void
}

const rehydrateCanvasObjects = (canvasObjects) =>
  canvasObjects.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))

const getPiccleData = async ({
  journeyCode,
  activityId,
  piccleId,
  setError,
  setData,
  setLoading,
}: Args): Promise<void> => {
  setLoading(true)
  try {
    // First fetch the journeyId from code
    const journeyId = await Firebase.firestore()
      .collection('journeys')
      .where('code', '==', journeyCode)
      .get()
      .then(
        (snap: Firebase.firestore.QuerySnapshot): string =>
          snap.docs.map((doc: Firebase.firestore.QueryDocumentSnapshot) => doc.id)[0],
      )

    const data = rehydrateCanvasObjects(
      await Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .collection(`activities/${activityId}/piccles/${piccleId}/strokes`)
        .orderBy('createdOn', 'asc')
        .get()
        .then((snap: Firebase.firestore.QuerySnapshot) => snap.docs)
        .then((docs: Firebase.firestore.QueryDocumentSnapshot[]) =>
          docs.map((doc: Firebase.firestore.QueryDocumentSnapshot): {
            id: string
          } => ({ ...doc.data(), id: doc.id })),
        ),
    )
    setData(data)
    setLoading(false)
  } catch (error) {
    setError(error)
  }
}

export default getPiccleData
