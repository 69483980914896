/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex, Box } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Heading3 } from '@lightspeed/flame/Text'
import useTranslations from 'hooks/useTranslations'
import { Template } from 'types'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    heading: 'Template Journey',
    title: 'Crayon Initiative',
    createNew: 'Create New',
    edit: 'Edit',
  },
  fr: {
    heading: 'Template Journey',
    title: 'Crayon Initiative',
    createNew: 'Create New',
    edit: 'Edit',
  },
}

type Translations = typeof translations.en

type TemplateCardProps = {
  onClickCreate: () => void
  template: Template
}

export const TemplateCard: React.FC<TemplateCardProps> = ({
  template,
  onClickCreate,
}: TemplateCardProps): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()

  return (
    <Box mb={2}>
      <Card key={'CrayonCardView'}>
        <CardSection mx={-2} p={2}>
          <Flex>
            <Flex width={1 / 2} px={2} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
              <Heading3 m={1}>{template.name}</Heading3>
              <Button variant="secondary" m={1} onClick={() => navigate(`admin/template/edit/${template.id}`)}>
                {labels.edit}
              </Button>
            </Flex>
            <Flex width={1 / 2} px={2} flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
              <Button variant="secondary" m={1} onClick={onClickCreate}>
                {labels.createNew}
              </Button>
            </Flex>
          </Flex>
        </CardSection>
      </Card>
    </Box>
  )
}
