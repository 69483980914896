import Firebase from 'firebase/app'
import PQueue, { DefaultAddOptions } from 'p-queue'
import PriorityQueue from 'p-queue/dist/priority-queue'

const queue: PQueue<PriorityQueue, DefaultAddOptions> = new PQueue({ concurrency: 1 })

type Args = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
  strokeId: string
}

const undoPiccleStroke = async ({ journeyCollectionPath, journeyId, piccleId, strokeId }: Args): Promise<void> => {
  await queue.add(
    (): Promise<void> =>
      Firebase.firestore()
        .collection(journeyCollectionPath)
        .doc(journeyId)
        .collection(`piccles/${piccleId}/strokes/`)
        .doc(strokeId)
        .delete(),
  )
}

export default undoPiccleStroke
