import React from 'react'
import { AdminLayout } from 'components'
import JourneyListContainer from './JourneyListContainer'
import TemplateListContainer from './TemplateListContainer'
import TemplateJourneyListContainer from './TemplateJourneyListContainer'
import { GlobalActivitiesContainer as GlobalActivities } from 'components/molecules/GlobalActivities'
import { Box } from '@lightspeed/flame/Core'

type Props = {
  onCreateNewJourney: () => Promise<void>
}

const PageDashboard = ({ onCreateNewJourney }: Props) => {
  return (
    <AdminLayout>
      <JourneyListContainer onCreateNewJourney={onCreateNewJourney} />
      <TemplateJourneyListContainer />
      <Box mt={3} />
      <GlobalActivities />
      <TemplateListContainer />
    </AdminLayout>
  )
}

export default PageDashboard
