import { Subscription, EventType } from 'Pages/Admin/PageProfile/Plans'

export type UpgradePayload = {
  paymentMethodId: string
  email: string
  subscription: Subscription
  eventType: EventType
}

export type ChangePlanResults = {
  paymentMethodId: string
  email: string
  subscription: Subscription
}

const changePlan = async (data: ChangePlanResults, endpointUrl: string) => {
  try {
    const response = await fetch(`${endpointUrl}/change-plan`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data }),
    })
    return await response.json()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default changePlan
