import deleteCrayonJourney, { DeleteJourneyArgs } from './deleteCrayonJourney'
import updateCrayonJourney, { UpdateJourneyArgs } from './updateCrayonJourney'
import getCrayonJourney, { GetJourneyArgs } from './getCrayonJourney'
import createCrayonJourney from './createCrayonJourney'
import createQuestion, { CreateQuestionArguments } from './createQuestion'
import deleteQuestion, { DeleteQuestionArgs } from './deleteQuestion'
import updateQuestion, { UpdateQuestionArgs } from './updateQuestion'
import { setPiccleDone, isPiccleDone } from './piccles'
import getCrayonJourneyActivities, { GetJourneyActivitiesArgs } from './getCrayonJourneyActivities'
import toggleDisplayAll, { ToggleDisplayAllArgs } from './toggleDisplayAll'

export type CrayonJourneyActions = {
  getCrayonJourney: (getJourneyArgs: GetJourneyArgs) => Promise<any>
  deleteCrayonJourney: (deleteJourneyArguments: DeleteJourneyArgs) => Promise<void>
  updateCrayonJourney: (updateJourneyArguments: UpdateJourneyArgs) => Promise<any>
  createCrayonJourney: (data: any) => Promise<string | void>
  createQuestion: (args: CreateQuestionArguments) => Promise<string | void>
  deleteQuestion: (deleteQuestionArguments: DeleteQuestionArgs) => Promise<void>
  updateQuestion: (updateuestionArguments: UpdateQuestionArgs) => Promise<void>
  toggleDisplayAll: (toggleDisplayAllArgs: ToggleDisplayAllArgs) => Promise<any>
  getCrayonJourneyActivities: (args: GetJourneyActivitiesArgs) => Promise<any>
  setPiccleDone: (args: { journeyCollectionPath: string; piccleId: string; journeyId: string }) => Promise<any>
  isPiccleDone: (args: any) => Promise<any>
}

const crayonJourneyActions: CrayonJourneyActions = {
  getCrayonJourney,
  deleteCrayonJourney,
  updateCrayonJourney,
  createCrayonJourney,
  createQuestion,
  deleteQuestion,
  updateQuestion,
  toggleDisplayAll,
  getCrayonJourneyActivities,
  setPiccleDone,
  isPiccleDone,
}

export default crayonJourneyActions
