/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Image, Flex } from 'rebass'

import picclesLogo from 'assets/images/piccles_logo_white_hotdog.png'
import { LanguageToggle } from 'components/atoms/LanguageToggle'
import { useNavigate } from 'hooks/useNavigate'

export const SignUpNavBar: React.FunctionComponent = () => {
  const navigate = useNavigate()

  return (
    <Flex p={2} bg="secondarydark" justifyContent="space-between" alignItems="center" data-testid="SignUpNavBar">
      <Image
        onClick={() => navigate('https://www.piccles.com/')}
        src={picclesLogo}
        variant="avatar"
        my={1}
        css={css`
          height: 42px;
          cursor: pointer;
        `}
      />
      <div
        css={css`
          position: relative;
          height: 42px;
          width: 75px;
        `}
      >
        <LanguageToggle />
      </div>
    </Flex>
  )
}
export default SignUpNavBar
