import React from 'react'
import { Box } from '@lightspeed/flame/Core'

type Props<Option> = {
  options: Option[]
  selected: Option
  onPlanTypePicked: (value: Option) => void
}

const PlanSwitch = <T extends { label: string; value: any }>({ options, selected, onPlanTypePicked }: Props<T>) => {
  return (
    <Box style={{ padding: 5, backgroundColor: '#3A1256', borderRadius: 5 }}>
      {options.map((option) => (
        <button
          key={option.value}
          style={{
            border: 0,
            borderRadius: 5,
            padding: 5,
            color: selected === option ? '#000' : '#FFF',
            backgroundColor: selected === option ? '#CFCBD1' : '#3A1256',
          }}
          onClick={() => {
            onPlanTypePicked(option)
          }}
        >
          {option.label}
        </button>
      ))}
    </Box>
  )
}

export default PlanSwitch
