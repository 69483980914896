import Firebase from 'firebase/app'
import picclesApi, { crayonPicclesApi } from '..'
import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

export const markJourneyAsRecentlyEdited: Function = async (journeyId: string): Promise<void> => {
  try {
    const { journeyApi } = picclesApi()
    const journey: { [x: string]: any } = await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .get()
      .then((doc: Firebase.firestore.DocumentSnapshot): Firebase.firestore.DocumentData => ({ ...doc.data() }))

    const payload: { updatedOn: Firebase.firestore.Timestamp } = prepareDocForUpdate(journey)

    journeyApi.updateJourney({ journeyId, payload })
  } catch (err) {
    console.log(err)
  }
}

export const markTemplateJourneyAsRecentlyEdited: Function = async (
  templateCollectionPath,
  journeyId: string,
): Promise<void> => {
  try {
    const { crayonJourneyApi } = crayonPicclesApi()
    const journey: { [x: string]: any } = await Firebase.firestore()
      .collection(templateCollectionPath)
      .doc(journeyId)
      .get()
      .then((doc: Firebase.firestore.DocumentSnapshot): Firebase.firestore.DocumentData => ({ ...doc.data() }))

    const payload: { updatedOn: Firebase.firestore.Timestamp } = prepareDocForUpdate(journey)

    crayonJourneyApi.updateCrayonJourney({ journeyId, payload })
  } catch (err) {
    console.log(err)
  }
}
