import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForCreate } from '../helpers/firestoreHelpers'

type Args = {
  journeyCollectionPath: string
  journeyId: string
  questionId: string
}

const createPiccle = ({ journeyCollectionPath, journeyId, questionId }: Args): Promise<string | void> => {
  ReactGA.event({
    category: 'Crayon-Piccle',
    action: 'create piccle in crayob',
  })

  return Firebase.firestore()
    .collection(journeyCollectionPath)
    .doc(journeyId)
    .collection('piccles')
    .add(prepareDocForCreate({ done: false, questionId }))
    .then((doc: Firebase.firestore.DocumentReference) => doc.id)
    .catch((error) => {
      alert(`Whoops, couldn't create the piccle: ${error.message}`)
    })
}

export default createPiccle
