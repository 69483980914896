import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

type Args = {
  journeyId: string
  activityId: string
  piccleId: string
}

const deletePiccle = async ({ journeyId, activityId, piccleId }: Args): Promise<void> => {
  /**
   * We only allow the creator of the activity to delete piccles from that activity.
   * This is enforced at the level of firestore rules on firebase, so no need to do the check here.
   */

  ReactGA.event({
    category: 'Piccle',
    action: 'Delete piccle',
  })

  return Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .doc(activityId)
    .collection('piccles')
    .doc(piccleId)
    .update({
      deleted: true,
    })
    .then(() => {})
    .catch((error) => {
      alert(`Whoops, couldn't delete the piccle: ${error.message}`)
    })
}

export default deletePiccle
