import React, { ReactElement, FunctionComponent } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Text, Heading3 } from '@lightspeed/flame/Text'
import { Card } from '@lightspeed/flame/Card'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Flex } from 'rebass'
import { PillBadge } from '@lightspeed/flame/Badge'
import { useParams } from '@reach/router'

import useTranslations from 'hooks/useTranslations'
import { PROMPT_ACTIVITY, MOSAIC_ACTIVITY } from 'appConstants'
import { hasOngoingActivity } from 'api/journeys/hasOngoingActivity'
import picclesApi, { PicclesApiObject } from 'api'
import { openLinkInNewTab } from 'api/helpers/openLinkInNewTab'
import { Icon } from '@lightspeed/flame/Icon'

const translations = {
  en: {
    activeStatus: 'Active',
    activityType: 'Activity Type',
    prompt: 'Prompt',
    quickStart: 'Start activity',
  },
  fr: {
    activeStatus: 'Actif',
    activityType: 'Activity Type',
    prompt: 'Prompt',
    quickStart: "Démarrer l'activité",
  },
}

type Props = {
  title: string
  type: string
  id: string
  prompt: string
  isActive?: boolean
  moveCard?: (id: string, to: number) => void
  findCard?: (id: string) => { index: number }
  onEdit: (activityId) => void
  isEditing: boolean
}

export const ActivityCard: FunctionComponent<Props> = ({
  id,
  moveCard,
  findCard,
  title,
  prompt,
  type,
  onEdit,
  isEditing,
  isActive = false,
}: Props): ReactElement => {
  const { journeyId } = useParams()

  const labels = useTranslations<typeof translations.en>(translations)

  const originalIndex: number = findCard(id).index

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: 'card',
    canDrop: () => false,
    hover({ id: draggedId }: any) {
      if (draggedId !== id) {
        const { index: overIndex } = findCard(id)
        moveCard(draggedId, overIndex)
      }
    },
  })

  const getBgColor = (type) =>
    ({
      [MOSAIC_ACTIVITY]: 'wheat',
      [PROMPT_ACTIVITY]: 'DarkSeaGreen',
    }[type] || 'white')

  const opacity: number = isDragging ? 0 : 1

  const CenteredCardText: Function = (label: string, content: string): ReactElement => (
    <>
      <br />
      <Text
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        }}
      >
        <strong>{label}</strong>: {content}
      </Text>
    </>
  )

  const { activityApi, journeyApi }: PicclesApiObject = picclesApi()

  const handleQuickStart = async () => {
    try {
      const check = await hasOngoingActivity(journeyId)

      //end all activity if there is active one
      if (check) {
        await activityApi.endActivity({ journeyId })
      }

      //start current activity and start/complete all previous activities in a sequence array

      //update previous activities
      //1. get all previous activityids
      const { sequence }: { sequence: string[] } = await journeyApi.getJourney({ journeyId })

      //2. update docs of each activity
      for (const activityId of sequence) {
        //when hit the current activity in array, break the loop
        if (activityId === id) {
          break
        }
        //start and complete previous activity
        await activityApi.updateActivity({
          journeyId,
          activityId,
          payload: { isStarted: true, isComplete: true },
        })
      }

      //3. finally start the current activity
      await activityApi.startActivity({ journeyId, activityId: id })
      openLinkInNewTab(`/projection/${journeyId}`)
    } catch (error) {
      throw error.message
    }
  }

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        cursor: 'move',
        opacity,
      }}
    >
      <Card top bg={getBgColor(type)}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center" style={{ position: 'relative' }}>
          {isActive && (
            <PillBadge size="small" type="important" style={{ position: 'absolute', top: '4px', right: '4px' }}>
              {labels.activeStatus}
            </PillBadge>
          )}

          <Heading3 mt={2}>{title}</Heading3>

          <div
            style={{
              whiteSpace: 'nowrap',
              maxWidth: '100%',
              width: 'auto',
            }}
          >
            {CenteredCardText(labels.activityType, type)}
            {CenteredCardText(labels.prompt, prompt)}
          </div>

          <Button
            variant="secondary"
            my={2}
            onClick={() => {
              onEdit(id)
            }}
          >
            {isEditing ? 'Editing..' : 'Edit'}
          </Button>
          <Button
            mb={2}
            mx={2}
            variant="secondary"
            fill
            form="promptActivityForm"
            onClick={async () => {
              try {
                await handleQuickStart()
              } catch (error) {
                throw error.message
              }
            }}
          >
            {labels.quickStart}
            <Icon name="external-link" />
          </Button>
        </Flex>
      </Card>
    </div>
  )
}
