import Firebase from 'firebase/app'

type SetPiccleDoneArgs = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

/**
 * marks a piccle as done on the piccle doc.
 */
export const setPiccleDone = async ({
  journeyCollectionPath,
  piccleId,
  journeyId,
}: SetPiccleDoneArgs): Promise<void> => {
  const piccleRef = await Firebase.firestore().doc(`${journeyCollectionPath}/${journeyId}/piccles/${piccleId}`)
  await piccleRef.set({ done: true }, { merge: true })
}
