/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box } from '@lightspeed/flame/Core'
import { Image } from 'rebass'
import { Card } from '@lightspeed/flame/Card'
import { Text } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import useTranslations from 'hooks/useTranslations'
import { useState } from 'react'
import QRCode from 'qrcode.react'
import { Fragment } from 'react'
import { useQrModalState } from 'hooks/useQrModalState'
import { QrModalStateOpenAction } from 'Contexts/QrContext/actions'

import piccleLogo from 'assets/images/piccles-logo.png'

const translations = {
  en: {
    toJoin: 'Join at:',
    activityReady: 'Next Activity Ready',
    journeyComplete: 'All activities are complete',
    copyLink: 'Copy Link',
    copied: 'Copied to clipboard',
    enterCode: 'and enter code',
    qrCode: 'Scan to join:',
  },
  fr: {
    toJoin: 'Rejoindre à :',
    activityReady: "L'activité suivante est chargée",
    journeyComplete: 'Toutes les activités sont terminées',
    copyLink: 'Copier ce lien',
    copied: 'Copié dans le presse-papiers',
    enterCode: 'et entrez le code',
    qrCode: 'Scanner le code QR pour rejoindre',
  },
}

type Translations = typeof translations.en

type Props = {
  code: string
  url: string
  QRenabled: boolean
}

export const SidebarCrayonProjection: React.FunctionComponent<Props> = ({
  url,
  code,
  QRenabled,
}: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const [copied, setCopied] = useState<boolean>(false)

  const [, qrModalDispatch] = useQrModalState()

  const copyToClipboard: () => void = (): void => {
    navigator.clipboard.writeText(url).then((): void => setCopied(true))
  }

  return (
    <Card
      css={(theme) => css`
        background-image: linear-gradient(to top right, ${theme.colors.secondary}, ${theme.colors.secondarydark});
        width: 16.6667%;
        height: 100%;
        margin-right: ${theme.space[2]};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {/* showing the piccle logo for now */}
      <Box
        height="9rem"
        width="9rem"
        bg="background"
        css={(theme) => css`
          border: 10px solid ${theme.colors.text};
          border-radius: 50% !important;
          display: flex;
          align-items: center;
          justify-content: space-around;
        `}
      >
        <Image height="5.4rem" width="5.4rem" src={piccleLogo} />
      </Box>

      {QRenabled && (
        <Fragment>
          <Box
            css={css`
              width: 100%;
              text-align: center;
            `}
            data-cy="QRcode"
          >
            <Text
              key={labels.qrCode}
              my={2}
              fontSize="large"
              color="white"
              css={css`
                font-weight: 400;
                font-size: 2vw;
              `}
            >
              {labels.qrCode}
            </Text>
            <QRCode
              onClick={() => {
                qrModalDispatch(new QrModalStateOpenAction())
              }}
              size={168}
              value={url}
              css={{ cursor: 'pointer', borderStyle: 'solid', borderColor: '#fff' }}
            />
          </Box>
        </Fragment>
      )}

      {!QRenabled && (
        <Box
          p={1}
          paddingTop={0}
          css={css`
            width: 100%;
            text-align: center;
          `}
        >
          <Text
            key={labels.toJoin}
            my={2}
            fontSize="large"
            color="white"
            css={css`
              font-weight: 400;
              font-size: 2vw;
            `}
          >
            {labels.toJoin}
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="primary"
            css={css`
              font-weight: 900;
              font-size: 2vw;
            `}
          >
            <b>{window.location.host}</b>
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="white"
            css={css`
              font-size: 1.5vw;
            `}
          >
            {labels.enterCode}
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="primary"
            css={css`
              font-weight: 700;
              font-size: 2.4vw;
            `}
          >
            {code}
          </Text>
        </Box>
      )}
      <Box
        css={css`
          width: 100%;
          padding: 0.375rem;
        `}
      >
        <Button block fill size="large" variant="primary" onClick={copyToClipboard}>
          {copied ? labels.copied : labels.copyLink}
        </Button>
      </Box>
    </Card>
  )
}
