import { lightTheme } from '@lightspeed/flame/Core'
import colors from './colors'
import deepmerge from 'deepmerge'
import buttonVariants from './buttonVariants'

// Extending the Flame theme
export default {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    ...colors,
  },
  breakpoints: [...lightTheme.breakpoints, '1400px'],

  buttonVariants: deepmerge(lightTheme.buttonVariants, buttonVariants),
}
