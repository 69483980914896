/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { Flex } from 'rebass'
import { Heading2 } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { ClientLayout } from 'components/molecules/ClientLayout'
import { PiccleCanvasContainer as PiccleCanvas } from 'components/molecules/PiccleCanvas'
import { ShareSms } from 'components/atoms/ShareSms'
import { FaDownload } from 'react-icons/fa'
import { headerLarge } from 'appConstants'

import { saveSvgAsPng } from 'save-svg-as-png'
import { FunctionComponent, ReactElement } from 'react'
import { useNavigate } from 'hooks/useNavigate'
import { useTranslation } from 'react-i18next'
import { useJourneyData } from 'hooks/useJourneyData'

type Props = {
  piccleId: string
  activityId: string
  journeyCode: string
  journeyId: string
  labels: {
    message: (code: string) => string
  }
}

const PageShare: FunctionComponent<Props> = ({
  piccleId,
  activityId,
  journeyCode,
  journeyId,
  labels,
}: Props): ReactElement => {
  console.log(labels)

  const navigate = useNavigate()
  const [journeyData] = useJourneyData({ journeyId })

  const handleDownloadImage: (e: React.MouseEvent) => void = (e: React.MouseEvent): void => {
    e.preventDefault()

    const svg: SVGSVGElement = document.getElementsByTagName('svg')[0]

    saveSvgAsPng(svg, 'myPiccle.png')
  }

  const handleDrawAgain: () => void = (): void => {
    navigate(`/${journeyCode}`)
  }
  const { t } = useTranslation()

  return (
    <ClientLayout headerStyle={headerLarge} languages={journeyData && journeyData.languages}>
      <Flex
        data-testid="PageShare"
        height="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Heading2 color="text">{t('pageShare.congrats')}</Heading2>
        <div className="piccle-result-sync">
          <PiccleCanvas journeyId={journeyId} activityId={activityId} piccleId={piccleId} height={250} width={250} />
        </div>

        <Button
          css={css`
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background: #281d3d;
            color: white;
          `}
          onClick={handleDownloadImage}
        >
          <FaDownload
            css={css`
              path {
                fill: #fff;
              }
            `}
          />
        </Button>

        <ShareSms messageBody={labels.message(journeyCode)}>
          <Button
            css={css`
              background: #402c67;
              color: white;
              border-radius: 20px;
            `}
          >
            {t('pageShare.invite')}
          </Button>
        </ShareSms>
        <Button
          onClick={handleDrawAgain}
          css={css`
            background: #8fd71e;
            color: white;
            border-radius: 20px;
          `}
        >
          {t('pageShare.continue')}
        </Button>
      </Flex>
    </ClientLayout>
  )
}

export default PageShare
