import { Flex } from '@lightspeed/flame/Core'
import React, { Fragment } from 'react'
import Firebase from 'firebase/app'
import { JourneyData } from 'hooks/useJourneyData'
import { headerLarge } from 'appConstants'
import { Button } from '@lightspeed/flame/Button'
import { PiccleCanvasContainer as PiccleCanvas } from 'components/molecules/PiccleCanvas'
import createPiccle from 'api/piccles/createPiccle'
import { AsynchronousProjectionViewContainer } from 'components/molecules/AsynchronousProjection'
import { AsyncSharePageLayout } from 'components/molecules/AsyncSharePageLayout'
import { useNavigate } from 'hooks/useNavigate'
import { useTranslation } from 'react-i18next'
import { useLocale } from 'hooks/useChangeLocale'

// const translations = {
//   en: {
//     next: 'Next',
//     again: 'Again',
//     finish: 'Finish',
//     thankYou: 'Congratulations on unlocking your creativity today!',
//     startOver: 'Start Over',
//     done: 'You are all done!',
//   },
//   fr: {
//     next: 'Suivant',
//     again: 'Encore',
//     finish: 'Terminer',
//     thankYou: "Félicitations d'avoir épanoui votre créativité aujourd'hui!",
//     startOver: 'Recommencer',
//     done: 'Vous êtes arrivé(e) à la fin!',
//   },
// }

type PageNextAsyncDrawingProps = JourneyData & {
  piccleId: string
  activityId: string
  journeyId: string
  journeyCode: string
}

const PageNextAsyncDrawing = ({ journeyId, activityId, journeyCode, piccleId }: PageNextAsyncDrawingProps) => {
  const navigate = useNavigate()
  const { locale } = useLocale()
  const { t, i18n } = useTranslation()

  const [journey, setJourney] = React.useState(null)
  const [activity, setActivity] = React.useState(null)
  const [prompt, setPrompt] = React.useState<string>('')

  const getJourney = async (journeyId: string): Promise<void> => {
    try {
      const journeySnapshot = await Firebase.firestore()
        .collection('journeys')
        .doc(journeyId)
        .get()
      setJourney(journeySnapshot.data())
    } catch (error) {
      console.log(error)
    }
  }

  const getActivity = async () => {
    Firebase.firestore()
      .collection(`journeys/${journeyId}/activities`)
      .doc(activityId)
      .get()
      .then((activityDocument) => setActivity(activityDocument.data()))
      .catch(console.error)
  }

  React.useEffect(() => {
    if (activity) {
      setPrompt(activity.question[i18n.language] ?? '')
    } else {
      setPrompt('')
    }
  }, [activity, i18n.language])

  React.useEffect(() => {
    getJourney(journeyId)
    getActivity()
    // eslint-disable-next-line
  }, [journeyId])

  const nextActivityId = journey && journey.sequence[journey.sequence.indexOf(activityId) + 1]

  const handleDrawAgain = async () => {
    try {
      const newPiccleId = await createPiccle({ journeyId, activityId })
      navigate(`${window.location.origin}/${journeyCode}/${journeyId}/${activityId}/${newPiccleId}?locale=${locale}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNextDrawing = async () => {
    const nextActivityIndex = journey?.sequence.indexOf(activityId) + 1
    const nextActivityId = journey?.sequence[nextActivityIndex]

    try {
      const newPiccleId = await createPiccle({ journeyId, activityId: nextActivityId })
      navigate(
        `${window.location.origin}/${journeyCode}/${journeyId}/${nextActivityId}/${newPiccleId}?locale=${locale}`,
      )
    } catch (error) {
      console.log(error)
    }
  }

  const goToAsyncJourneyEndPage = () => {
    navigate(`${window.location.origin}/${journeyCode}/end?locale=${locale}`)
  }

  return (
    <AsyncSharePageLayout headerStyle={headerLarge} languages={activity && Object.keys(activity.question)}>
      {activity && <header color="#27173a">{prompt}</header>}

      <div className="piccle-result" data-testid="Piccle-Result">
        <PiccleCanvas journeyId={journeyId} activityId={activityId} piccleId={piccleId} height={150} width={150} />
      </div>

      <Flex
        data-testid="PageShare"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
        height="100%"
      >
        {nextActivityId ? (
          <Flex className="async-btn-group" justifyContent="space-around">
            <Button size="small" className="async-button" onClick={handleDrawAgain}>
              {t('asynchronous.again')}
            </Button>

            <Button size="small" className="async-button" onClick={handleNextDrawing}>
              {t('asynchronous.next')}
            </Button>
          </Flex>
        ) : (
          <Fragment>
            <Flex className="async-btn-group" justifyContent="space-around" alignItems="center">
              <Button size="small" className="async-button" onClick={handleDrawAgain}>
                {t('asynchronous.again')}
              </Button>

              <Button size="small" className="async-button" onClick={goToAsyncJourneyEndPage}>
                {t('asynchronous.finish')}
              </Button>
            </Flex>
          </Fragment>
        )}

        <AsynchronousProjectionViewContainer journeyId={journeyId} activityId={activityId} />
      </Flex>
    </AsyncSharePageLayout>
  )
}

export default PageNextAsyncDrawing
