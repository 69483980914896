import { useState } from 'react'
import { Heading2 } from '@lightspeed/flame/Text'
import { headerSmall } from 'appConstants'
import { ClientLayout } from 'components'
import { Image } from 'rebass'
import React, { useEffect } from 'react'
import finalAsyncImage from 'assets/images/async-end.png'
import finalAsyncImageWebP from 'assets/images/async-end.webp'
import { Button } from '@lightspeed/flame/Button'
import { Flex } from '@lightspeed/flame/Core'
import { useNavigate } from 'hooks/useNavigate'
import { useTranslation } from 'react-i18next'
import collectEmail from 'api/journeys/collectEmail'
import { toast } from 'react-toastify'
import useJourneyId from 'hooks/useJourneyId'
import { useJourneyData } from 'hooks/useJourneyData'
import { useLocale } from 'hooks/useChangeLocale'

export const AsyncJourneyEndPage = (props) => {
  const navigate = useNavigate()
  const { locale } = useLocale()

  const { t } = useTranslation()

  const [email, setEmail] = useState<string>('')

  const [isEntered, setIsEntered] = useState<boolean>(false)

  // get journey data
  const [id] = useJourneyId({ journeyCode: props.journeyCode })
  const [data] = useJourneyData({ journeyId: id })

  const [collectInfo, setCollectInfo] = useState<boolean>(false)

  useEffect(() => {
    data && setCollectInfo(data.collectInfo)
  }, [data])

  const startOver = () => {
    navigate(`${window.location.origin}/${props.journeyCode}?locale=${locale}`)
  }

  return (
    <>
      <ClientLayout headerStyle={headerSmall}>
        <div className="async-end-container">
          <h1>{t('asyncJourneyEndPage.done')}</h1>

          <picture>
            <source srcSet={finalAsyncImageWebP} type="image/webp" />
            <source srcSet={finalAsyncImage} type="image/png" />
            <Image src={finalAsyncImage} />
          </picture>

          {!isEntered && collectInfo && (
            <>
              <input
                style={{
                  padding: '10px 20px',
                  fontSize: 'medium',
                  width: '250px',
                  marginBottom: '10px',
                  borderRadius: '5px',
                }}
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                value={email}
                placeholder="bruce@gotham.com"
              />
              <button
                style={{
                  padding: '10px 20px',
                  border: 'none',
                  background: 'linear-gradient(to right, #1fddff, #ff4b1f)',
                  color: 'white',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '250px',
                  fontSize: 'large',
                }}
                onClick={async () => {
                  const validateEmail = (email) => {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return re.test(String(email).toLowerCase())
                  }
                  if (validateEmail(email)) {
                    await collectEmail({ journeyCode: props.journeyCode, email: email })
                    setIsEntered(true)
                    setEmail('')
                    return
                  }
                  //error toast if the email is not valid
                  toast.error('Please use a valid email address.', {
                    toastId: 'Email Not Valid',
                    autoClose: false,
                  })
                }}
              >
                {t('asyncJourneyEndPage.enterToWinBtn')}
              </button>

              <div style={{ margin: '20px 0', textAlign: 'center' }} color="text">
                {t('asyncJourneyEndPage.enterToWin')}
              </div>
            </>
          )}

          {(isEntered || !collectInfo) && (
            <Heading2 color="text" className="async-thankyou-header">
              {t('asyncJourneyEndPage.thankYou')}
            </Heading2>
          )}

          <Flex justifyContent="center" alignItems="center" width="100%">
            <Button
              className="async-button"
              style={{
                backgroundColor: '#f99000',
              }}
              onClick={startOver}
            >
              {t('asyncJourneyEndPage.startOver')}
            </Button>
          </Flex>
        </div>
      </ClientLayout>
    </>
  )
}
