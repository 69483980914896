import Firebase from 'firebase/app'

type SetPiccleDoneArgs = {
  piccleId: string
  activityId: string
  journeyId: string
}

/**
 * marks a piccle as done on the piccle doc.
 */
export const setPiccleDone = async ({ piccleId, activityId, journeyId }: SetPiccleDoneArgs): Promise<void> => {
  const piccleRef = await Firebase.firestore().doc(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}`)
  await piccleRef.set({ done: true }, { merge: true })
}
