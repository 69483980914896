import Firebase from 'firebase/app'

export type GetUserArguments = {
  userId: string
}

export type UserData = {
  firstName: string
  lastName: string
  role?: string
  email: string
  stripeCustomerId?: string
  createdBy: string
  signedUpOn: {
    _seconds: number
    _nanoseconds: number
  }
}

const getUser = async ({ userId }: GetUserArguments): Promise<UserData> => {
  try {
    const userRef = await Firebase.firestore()
      .collection('users')
      .doc(userId)
      .get()

    return userRef.data() as UserData
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getUser
