import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

type Args = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

const deletePiccle = async ({ journeyCollectionPath, journeyId, piccleId }: Args): Promise<void> => {
  ReactGA.event({
    category: 'Piccle',
    action: 'Delete Crayon piccle',
  })

  Firebase.firestore()
    .collection(journeyCollectionPath)
    .doc(journeyId)
    .collection('piccles')
    .doc(piccleId)
    .update({
      deleted: true,
    })
    .catch((error) => {
      alert(`Whoops, couldn't delete the piccle: ${error.message}`)
    })
}

export default deletePiccle
