import * as React from 'react'
import { Tooltip } from '@lightspeed/flame/Tooltip'
import { Icon } from '@lightspeed/flame/Icon'

type HelpboxProps = {
  info: string
}

export const Helpbox: React.FC<HelpboxProps> = ({ info }: HelpboxProps) => {
  return (
    <>
      &#160;
      <Tooltip content={info}>
        <Icon name="info" />
      </Tooltip>
    </>
  )
}
