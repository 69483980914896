import { CompanyData } from './getCompany'

export type GetCompanyByAdminArguments = string

const getCompanyByAdmin = async (adminEmail: GetCompanyByAdminArguments, endpointUrl: string): Promise<CompanyData> => {
  adminEmail = encodeURIComponent(adminEmail)
  try {
    const response = await fetch(`${endpointUrl}/admin/${adminEmail}`, { mode: 'cors' })
    if (response.status >= 400 && response.status <= 499) throw new Error('API Client Error')
    const data = response.json()
    return data
  } catch (error) {
    console.error('getCompanyByAdmin.ts: ', error)
    throw error
  }
}

export default getCompanyByAdmin
