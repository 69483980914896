export type PaymentMethodPayload = {
  paymentMethodId: string
  stripeCustomerId: string
}

const setDefaultPaymentMethod = async (
  { paymentMethodId, stripeCustomerId }: PaymentMethodPayload,
  endpointUrl: string,
): Promise<void> => {
  try {
    await fetch(`${endpointUrl}/set-default-payment-method`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId,
        stripeCustomerId,
      }),
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default setDefaultPaymentMethod
