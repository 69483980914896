/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import useTranslations from 'hooks/useTranslations'
import { Heading1, Heading2 } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Flex } from 'rebass'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    title: "You're done!",
    subtitle: 'Thanks for your participation',
    button: 'Continue to Draw',
  },
  fr: {
    title: 'Vous avez terminé!',
    subtitle: 'Merci pour votre participation',
    button: 'Continuer à dessiner',
  },
}

export const TerminalLayout = () => {
  const navigate = useNavigate()
  const labels = useTranslations<typeof translations.en>(translations)

  return (
    <div data-testid="TerminalLayout">
      <Flex flexDirection="column" justifyContent="space-between" height="75vh" alignItems="center">
        <Flex flexDirection="column" alignItems="center" py={5}>
          <Heading1 color="text"> {labels.title}</Heading1>
          <Heading2 color="text"> {labels.subtitle}</Heading2>
        </Flex>
        <Button
          onClick={() => navigate('/')}
          css={css`
            background: #8fd71e;
            color: white;
            border-radius: 20px;
          `}
        >
          {labels.button}
        </Button>
      </Flex>
    </div>
  )
}
