/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState } from 'react'
import { Flex, Box } from 'rebass'
import { Input } from '@lightspeed/flame/Input'
import { Heading1 } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'

import { ClientLayout } from 'components'
import { headerLarge } from 'appConstants'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  validation: string
  setValidation: (arg: string) => void
  handleGoToExperience: (experienceCode: string) => Promise<void>
}

const PageWelcome: React.FunctionComponent<Props> = ({
  handleGoToExperience,
  validation,
  setValidation,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [code, setCode] = useState<string>('')

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if ((event.target as HTMLInputElement).value.length > 0) {
      setValidation('')
    }
    setCode((event.target as HTMLInputElement).value.toUpperCase())
  }

  const handleSubmit = (event): void => {
    event.preventDefault()
    handleGoToExperience(code)
  }

  return (
    <ClientLayout headerStyle={headerLarge}>
      <Flex px={[2, 3, 4]} pt={5} justifyContent="center">
        <Flex
          flexDirection="column"
          alignItems="center"
          as="form"
          data-testid="codeForm"
          justifyContent="space-between"
          sx={{ minHeight: '50vh', minWidth: ['60vw', '40vw', '20vw'] }}
          onSubmit={handleSubmit}
        >
          <Heading1 marginBottom={2} color="text" fontWeight={400}>
            {t('welcome.welcome')}
          </Heading1>

          <Flex
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '80%', height: '125px' }}
          >
            <Box>
              <Input
                id="code"
                size="large"
                placeholder={t('welcome.enterCode')}
                onChange={handleOnChange}
                status={
                  validation && {
                    type: 'error',
                    message: t('welcome.validation'),
                  }
                }
                autoComplete="off"
                css={css`
                  border-radius: 20px;
                  text-align: center;
                  font-size: 1.3rem;
                `}
                value={code}
              />
            </Box>

            <Button
              block
              fill
              size="xlarge"
              variant="primary"
              type="submit"
              form="journey"
              css={css`
                margin-top: 20px;
              `}
              onClick={(event) => handleSubmit(event)}
            >
              {t('welcome.submit')}
            </Button>

            <Button
              block
              fill
              size="xlarge"
              variant="secondary"
              css={css`
                margin-top: 20px;
              `}
              onClick={() => navigate('/admin')}
            >
              {t('welcome.admin')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </ClientLayout>
  )
}

export default PageWelcome
