/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Box, Flex } from '@lightspeed/flame/Core'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { Heading3, Text } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { DashboardHeader } from 'components/molecules/DashboardHeader'
import useTranslations from 'hooks/useTranslations'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    projection: 'Join',
    publicPiccle: 'Public Piccles Activities',
    code: 'Code',
  },
  fr: {
    projection: 'Joignez-vous',
    publicPiccle: 'Activité de Piccles Publiques',
    code: 'Code',
  },
}

type CardProps = {
  journey: { id: string; title: string; code: string }
}

export const GlobalActivity = ({ journey }: CardProps) => {
  const labels = useTranslations<typeof translations.en>(translations)
  const navigate = useNavigate()

  return (
    <Card key={journey.id}>
      <CardSection mx={-2} p={2}>
        <Flex>
          <Flex width={2 / 4} px={2} flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <Heading3>{journey.title}</Heading3>
          </Flex>

          <Box width={1 / 4} px={[1, 2]}>
            <Flex alignItems="center" flexWrap="wrap">
              <Text color="textDim" pr={1}>
                {labels.code}:{' '}
              </Text>
              <Text color="textDim" fontWeight="bold">
                {journey.code}
              </Text>
            </Flex>
          </Box>

          <Flex width={1 / 4} px={2} flexDirection="column" alignItems="flex-end" justifyContent="flex-end">
            <Button variant="primary" m={1} onClick={() => navigate(`/projection/${journey.id}`)}>
              {labels.projection}
            </Button>
          </Flex>
        </Flex>
      </CardSection>
    </Card>
  )
}

type Props = {
  data: { id: string; title: string; code: string }[]
}

export const GlobalActivities = ({ data }: Props) => {
  const labels = useTranslations<typeof translations.en>(translations)

  return (
    <Box data-testid="GlobalActivities" mb={2}>
      <DashboardHeader title={labels.publicPiccle} />
      <Flex flexDirection="column" pt={2}>
        {data.map((journey) => (
          <GlobalActivity key={journey.id} journey={journey} />
        ))}
      </Flex>
    </Box>
  )
}
