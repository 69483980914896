import React from 'react'
import useTranslations from 'hooks/useTranslations'
import { Loading } from 'components/atoms/Loading'
import { Heading1, Heading2 } from '@lightspeed/flame/Text'
import { Button } from '@lightspeed/flame/Button'
import { Flex, Image } from 'rebass'
import asyncImage from 'assets/images/welcome-async.png'
import asyncImageWebP from 'assets/images/welcome-async.webp'

const translations = {
  en: {
    title: 'Just a moment',
    subtitle: 'we are setting your activity',
    welcome: (title: string) => `Welcome to the ${title}. Draw with us while you are here!`,
    start: 'Start',
  },
  fr: {
    title: 'Juste un moment',
    subtitle: 'nous établissons votre activité',
    welcome: (title: string) => `Bienvenue à ${title}. Dessinez avec nous pendant que vous êtes ici!`,
    start: 'Commencer',
  },
}

type InactiveLayoutProps = {
  asyncJourney?: boolean
  title?: string
  handleStartJourney?: VoidFunction
}

export const InactiveLayout = ({ asyncJourney = false, title = '', handleStartJourney }: InactiveLayoutProps) => {
  const labels = useTranslations<typeof translations.en>(translations)
  return (
    <div data-testid="InactiveLayout">
      <Flex flexDirection="column" alignItems="center" py={5} px={10}>
        {asyncJourney ? (
          <>
            <Heading1 className="async-welcome-header" marginBottom={10}>
              {labels.welcome(title)}
            </Heading1>

            <picture className="async-welcome-image">
              <source srcSet={asyncImageWebP} type="image/webp" />
              <source srcSet={asyncImage} type="image/png" />
              <Image src={asyncImage} />
            </picture>

            <Flex className="center-flex">
              <Button variant="primary" onClick={handleStartJourney} fill block p={1}>
                {labels.start}
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Heading1 color="text">{labels.title}</Heading1>
            <Heading2 color="text">{labels.subtitle}</Heading2>
          </>
        )}
      </Flex>
      {!asyncJourney && <Loading />}
    </div>
  )
}
