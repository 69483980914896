export enum QrModalActionType {
  OPEN = 'OPEN',
  CLOSE = 'Close',
}

export class QrModalStateOpenAction {
  readonly type = QrModalActionType.OPEN
}

export class QrModalStateCloseAction {
  readonly type = QrModalActionType.CLOSE
}

export type QrModalStateAction = QrModalStateOpenAction | QrModalStateCloseAction
