import Firebase from 'firebase/app'

import updateActivity from './updateActivity'

export type StartActivityArgs = {
  journeyId: string
  activityId: string
}

const startActivity = async ({ activityId, journeyId }: StartActivityArgs) => {
  /**
   * We cannot have two active activities ("started" AND "incomplete") in the same journey.
   * Therefore we begin by verifying that no other activity in this journey are active ("started" AND "incomplete").
   * If no other activities are active ("started" AND "incomplete"), we may proceed to start this one.
   */
  const journeyActivities = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => doc.data()))

  // Ensure NO activities are currently active (started AND incomplete).
  const isAllInactive = !journeyActivities.some((a: any) => a.isStarted && !a.isComplete)

  if (isAllInactive) {
    // If none of the activities are active we may start the desired activity
    return updateActivity({
      journeyId,
      activityId,
      payload: { isStarted: true, startedAt: Firebase.firestore.Timestamp.now(), isComplete: false },
    })
  }
  throw new Error('Attempted to start an activity while another is still active.')
}

export default startActivity
