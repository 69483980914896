/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Image, Flex } from 'rebass'

import picclesLogo from 'assets/images/piccles-logo.png'
import { LanguageToggle } from 'components/atoms/LanguageToggle'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  headerStyle: {
    height: string
    align: string
    marginTop: string
  }
  languages?: string[]
}

export const ClientNavbar: React.FunctionComponent<Props> = ({ headerStyle, languages }: Props): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Flex p={1} bg="white" justifyContent="center" alignItems={headerStyle.align}>
      <LanguageToggle languages={languages} />
      <Image className="navbar-logo" onClick={() => navigate('/')} src={picclesLogo} />
    </Flex>
  )
}
