import React from 'react'
import PageShare from './PageShare'
import useJourneyId from 'hooks/useJourneyId'
import useTranslations from 'hooks/useTranslations'
import { Loading, Error } from 'components'

const translations = {
  en: {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  fr: {
    message: (code) =>
      `Rejoignez-moi sur https://app.piccles.com/${code} et participez à une création artistique collaborative.`,
  },
  ko: {
    message: (code) => `한국어 https://app.piccles.com/${code} 한국어`,
  },
  es: {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  ja: {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  th: {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  zh: {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  'zh-hans': {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
  'zh-hant': {
    message: (code) => `Join me on https://app.piccles.com/${code} and participate in a collaborative work of art.`,
  },
}

const PageShareContainer = (props) => {
  const labels = useTranslations<typeof translations.en>(translations)
  const [journeyId, journeyIdLoading, journeyIdError] = useJourneyId({ journeyCode: props.journeyCode })

  return (
    <>
      {journeyIdError && <Error error={journeyIdError} />}
      {journeyIdLoading && <Loading />}
      {journeyId && (
        <PageShare
          journeyId={journeyId}
          journeyCode={props.journeyCode}
          piccleId={props.piccleId}
          activityId={props.activityId}
          labels={labels}
        />
      )}
    </>
  )
}

export default PageShareContainer
