/** @jsx jsx */
import { jsx } from '@emotion/core'
import Firebase from 'firebase/app'
import { Checkbox } from '@lightspeed/flame/Checkbox'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Text } from '@lightspeed/flame/Text'
import deletePiccle from 'api/piccles/deletePiccle'
import restartJourney from 'api/journeys/restartJourney'
import { TERMINAL } from 'appConstants'
import picclesApi from 'api'
import {
  AdminNavbar,
  ModerationActionModal,
  ModerationActivityCard,
  PiccleCanvasContainer as PiccleCanvas,
  ProjectionViewGridContainer as ProjectionViewGrid,
} from 'components'
import useTranslations from 'hooks/useTranslations'
import { Fragment, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { Box, Flex } from 'rebass'

const translations = {
  en: {
    moderationPanel: 'Moderation Panel',
    selectAPiccle: 'Select a piccle to open actions menu.',
    displayAll: 'Display All',
  },
  fr: {
    moderationPanel: 'Moderation Panel',
    selectAPiccle: 'Select a piccle to open actions menu.',
    displayAll: 'Affichez tout',
  },
}

type Translations = typeof translations.en

type Activity = {
  id: string
  type: string
  title: string
  isStarted: boolean
  isComplete: boolean
  journeyId: string
  continuous?: boolean
  promotedPiccle: string
  displayAll?: boolean
  question: { [key: string]: string }
}
export type Data = {
  title?: string
  code?: string
  journeyState: {
    status?: string
    previousActivity?: null | Activity
    currentActivity?: null | Activity
    nextActivity?: null | Activity
  }
}
type Props = {
  data: Data
  journeyId: string
}

const PageModeration: React.FunctionComponent<Props> = ({ data, journeyId }: Props): React.ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)
  const { journeyApi } = picclesApi()

  const [isActionModalOpen, setActionModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)
  const [selectedPiccleId, setSelectedPiccleId]: [string, Dispatch<SetStateAction<string>>] = useState<string>(null)
  const [selectedPiccleDescription, setSelectedPiccleDescription]: [
    string,
    Dispatch<SetStateAction<string>>,
  ] = useState<string>(null)

  // we don't show the display all button in terminal screen.
  const [showAllPiccles, setShowAllPiccles] = useState<boolean>(true)

  const handleDeletePiccle: () => void = async (): Promise<void> => {
    await deletePiccle({ journeyId, activityId: currentActivity.id, piccleId: selectedPiccleId })
    setActionModalOpen(false)
  }

  const { currentActivity, nextActivity, status }: Data['journeyState'] = data.journeyState

  const setDisplayAllValue = () => {
    if (status === 'pregame' || 'limbo') {
      // keeps compatibility with data model
      setShowAllPiccles(nextActivity?.displayAll ?? true)
    }

    if (status === 'active') {
      // keeps compatibility with data model
      setShowAllPiccles(currentActivity.displayAll ?? true)
    }
  }

  useEffect(() => {
    setDisplayAllValue()
  }, [status]) // eslint-disable-line

  const handleToggleDisplayAll = () => {
    journeyApi.toggleDisplayAll({ journeyId, value: !showAllPiccles })
    setShowAllPiccles(!showAllPiccles)
  }

  const handleSelectPiccle = async (piccleId: string) => {
    const piccleDoc = await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .doc(currentActivity.id)
      .collection('piccles')
      .doc(piccleId)
      .get()

    const { description = '' } = piccleDoc.data()

    setSelectedPiccleId(piccleId)
    setSelectedPiccleDescription(description)
    setActionModalOpen(true)
  }

  return (
    <Fragment>
      <Flex bg="muted" width="100vw" height="100vh" flexDirection="column">
        <AdminNavbar loading={false} company={null}>
          <Text fontSize={[20, 30, 40]} fontWeight="bold">
            {labels.moderationPanel}
          </Text>
        </AdminNavbar>

        <Flex flex={1} width="100vw">
          <Box backgroundColor="lemonchiffon" height="100%" width={1 / 5} p={2}>
            <Text my={2} fontSize={[10, 20, 30]} fontWeight="bold">
              {data.title}
            </Text>

            <Text mt={4} fontSize={[5, 15, 25]}>
              {`Journey Code: `}
            </Text>
            <Text fontWeight="bold" fontSize={[10, 20, 30]} color="darkgreen">
              {data.code}
            </Text>

            <Text my={4} fontSize={[10, 10, 15]}>
              {labels.selectAPiccle}
            </Text>

            {status !== 'terminal' ? (
              <Flex alignItems="center">
                <Text my={4} mr={1} fontSize={[10, 10, 15]}>
                  {labels.displayAll}
                </Text>
                <Checkbox checked={showAllPiccles} onChange={() => handleToggleDisplayAll()} />
              </Flex>
            ) : null}

            {currentActivity && <ModerationActivityCard current activityData={currentActivity} journeyId={journeyId} />}
            <Box my={2} />
            {nextActivity && (
              <ModerationActivityCard activityData={nextActivity} disabled={!!currentActivity} journeyId={journeyId} />
            )}
            {status === TERMINAL && <Button onClick={() => restartJourney({ journeyId })}>Restart journey</Button>}
          </Box>

          <Box width={4 / 5} height="100%" bg="white">
            <Box p={2} height="105vh">
              {currentActivity && (
                <ProjectionViewGrid
                  isModeration={true}
                  journeyId={journeyId}
                  activity={currentActivity}
                  handleOnSelect={handleSelectPiccle}
                />
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>

      {currentActivity && (
        <ModerationActionModal
          isModalOpen={isActionModalOpen}
          onClose={() => setActionModalOpen(false)}
          onDelete={handleDeletePiccle}
          currentPiccleId={selectedPiccleId}
          currentJourneyId={journeyId}
          data={currentActivity}
          description={selectedPiccleDescription}
        >
          <Box>
            <PiccleCanvas
              journeyId={journeyId}
              activityId={currentActivity.id}
              height={200}
              width={200}
              piccleId={selectedPiccleId}
            />
          </Box>
        </ModerationActionModal>
      )}
    </Fragment>
  )
}

export default PageModeration
