import React, { useEffect } from 'react'
import picclesApi, { PicclesApiObject } from 'api'
import PageProfile from './PageProfile'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { Loading } from 'components'
import { CompanyData } from 'api/companies/getCompany'
import { ProfilePageProvider } from './reducerContext'
import { Subscription } from './Plans'

const PageProfileContainer = () => {
  const { stripeApi, companyApi }: PicclesApiObject = picclesApi()

  const {
    user: { email },
  }: AuthenticationContextFields = useAuth()

  const [paymentMethods, setPaymentMethods] = React.useState(null)
  const [company, setCompany] = React.useState<CompanyData>(null)
  const [subscription, setSubscription] = React.useState<Subscription>(null)

  useEffect(() => {
    stripeApi.getPaymentMethods(email).then((res) => setPaymentMethods(res.paymentMethods))
    companyApi.getCompanyByUser(email).then(setCompany)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (company) {
      stripeApi.getCompanySubscription(company && company.stripeSubscriptionId).then((res) => setSubscription(res))
    }
  }, [company]) // eslint-disable-line

  return (
    <ProfilePageProvider>
      {!company && <Loading />}
      {company && (
        <PageProfile companyData={company} paymentMethods={paymentMethods} currentSubscription={subscription} />
      )}
    </ProfilePageProvider>
  )
}
export default PageProfileContainer
