import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

export type DeleteQuestionArgs = {
  templateId: string
  questionId: string
}

const deleteCrayonJourney = async ({ templateId, questionId }: DeleteQuestionArgs) => {
  // Google analytics
  ReactGA.event({
    category: 'Template-Question',
    action: 'Delete template question',
  })

  // delets the question document
  try {
    return await Firebase.firestore()
      .collection(`templates/${templateId}/questions`)
      .doc(questionId)
      .delete()
  } catch (e) {
    console.log(e)
  }
}

export default deleteCrayonJourney
