import React from 'react'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import useTranslations from 'hooks/useTranslations'
import Firebase from 'firebase/app'
import PageEditTemplateJourney from './PageEditTemplateJourney'
import { Loading, Error } from 'components'
import { crayonPicclesApi } from 'api'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { TemplateJourney } from 'types'
import { getUserId } from 'api/helpers/firestoreHelpers'
import createSponsor from 'api/journeys/createSponsor'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    createJourney: 'Create Journey',
    opening: 'Opening',
    closing: 'Closing',
    message: 'Message',
    openingMessagePlaceholder: 'Put your welcome message here',
    image: 'Image',
    logo: 'Logo',
    primarySponsor: 'Primary sponsor',
    secondarySponsor: 'Secondary sponsor',
    noSponsor: 'No Sponsor',
    hospital: 'Hospital',
    closingMessagePlaceholder: 'Put your closing message here',
    upload: 'Upload',
    done: 'Done',
    deleteJourney: `Delete this Piccle Journey?`,
  },
  fr: {
    createJourney: 'Modifier la Journey',
    opening: 'Opening',
    message: 'Message',
    closing: 'Closing',
    image: 'Image',
    logo: 'Logo',
    primarySponsor: 'Primary sponsor',
    secondarySponsor: 'Secondary sponsor',
    noSponsor: 'No Sponsor',
    hospital: 'Hospital',
    openingMessagePlaceholder: 'Put your welcome message here',
    closingMessagePlaceholder: 'Put your closing message here',
    upload: 'Upload',
    done: 'Fini',
    deleteJourney: `Supprimer la Piccle Journey?`,
  },
}

const PageEditTemplateJourneyContainer = (props) => {
  const navigate = useNavigate()
  const labels = useTranslations<typeof translations.en>(translations)
  const userUid = getUserId()
  const { crayonJourneyApi } = crayonPicclesApi()

  // Journey Data fetch
  const [journeyData, journeyLoading, journeyError]: [TemplateJourney, boolean, Error] = useDocumentData(
    Firebase.firestore().doc(`${props.templatePath}/${props.journeyId}`),
  )

  // Sponser Data fetch
  const [sponsorsData, sponsorsLoading, sponsorsError]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(`sponsors`)
      .where('createdBy', '==', userUid)
      .orderBy('createdOn', 'desc'),
    {
      idField: 'id',
    },
  )

  const handleUpdateJourney = (payload) => {
    return crayonJourneyApi.updateCrayonJourney({ journeyId: props.journeyId, payload })
  }

  const handleDeleteJourney = async () => {
    const confirmDelete = window.confirm(labels.deleteJourney)
    if (confirmDelete === true) {
      try {
        await crayonJourneyApi.deleteCrayonJourney({ journeyId: props.journeyId })
        navigate('/admin')
      } catch (e) {
        alert(e.message)
        console.error(e)
      }
    }
  }

  const handleCreateSponsor = async (sponsorData) => {
    return createSponsor(sponsorData)
  }

  const error = journeyError || sponsorsError
  const loading = journeyLoading || sponsorsLoading
  return (
    <DndProvider backend={HTML5Backend}>
      {error && <Error error={journeyError} />}
      {loading && <Loading />}
      {journeyData && sponsorsData && (
        <PageEditTemplateJourney
          onSubmit={handleUpdateJourney}
          values={journeyData}
          handleDeleteJourney={handleDeleteJourney}
          sponsorsData={sponsorsData}
          onCreateSponsor={handleCreateSponsor}
        />
      )}
    </DndProvider>
  )
}

export default PageEditTemplateJourneyContainer
