import Firebase from 'firebase/app'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { fabric } from 'fabric'
import memoize from 'micro-memoize'
import { deepEqual } from 'fast-equals'
import { Timestamp } from '@firebase/firestore-types'

type Args = {
  journeyId: string
  activityId: string
  piccleId: string
  height: number | string
  width: number | string
}

export type Stroke = {
  createdBy: string
  createdOn: Timestamp
  height: number
  left: number
  path: string
  stroke: string
  strokeWidth: number
  top: number
  width: number
}

// matches svg to strip off hardcoded width and height values
const SVGregex = new RegExp(/(<svg\s.+)width="\d+"\sheight="\d+"(.+)/)

const usePiccleSVG = ({ journeyId, activityId, piccleId }: Args) => {
  let [data, loading, error]: any = useDocumentData(
    Firebase.firestore().doc(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}`),
    { idField: 'id' },
  )

  return [data?.svg, loading, error]
}

export default usePiccleSVG

// -------------------------
// CANVAS TO SVG TRANSFORM
// -------------------------
const CANVAS_SIZE = 320

const canvasToSvg = ({ piccleId, height, width, canvasObjects }) => {
  const canvasId = `${piccleId}-${height}-${width}`

  const initialCanvasSize = width
  // Initialize the canvas element
  const canvas = new fabric.StaticCanvas(canvasId, {
    width: width,
    height: height,
  })
  canvas.setZoom(initialCanvasSize / CANVAS_SIZE)

  // Set some defaults on the canvas
  fabric.Object.prototype.transparentCorners = false

  // add the pre-existing canvas elements
  if (canvasObjects) {
    canvas.loadFromJSON({ objects: canvasObjects, backgroundColor: 'white' }, null)
  }

  // replaces the svg without the hardcoded height and width html attributes
  const svg = canvas.toSVG().replace(SVGregex, '$1$2')
  canvas.dispose()

  return svg
}

// Very important to memoize this function
export const memoizedCanvasToSvg = memoize(canvasToSvg, { isEqual: deepEqual, maxSize: 128 })
