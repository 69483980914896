import { JourneyCard, Loading, Error } from 'components'
import { Journey } from 'hooks/useJourneyData'
import Firebase from 'firebase/app'
import React from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Activity } from '../PageEditJourney/PageEditJourney'

type JourneyCardContainerProps = {
  journey: Journey
}

export const JourneyCardContainer = ({ journey }: JourneyCardContainerProps): JSX.Element => {
  const [activityData, loading, error]: [Activity[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .doc(`journeys/${journey.id}`)
      .collection('activities'),
    { idField: 'id' },
  )

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {activityData && <JourneyCard data={journey} activities={activityData} />}
    </>
  )
}
