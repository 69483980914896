import Firebase from 'firebase/app'

import picclesApi, { PicclesApiObject } from 'api'

type Args = {
  journeyId: string
}

const restartJourney = async ({ journeyId }: Args): Promise<any[]> => {
  const { activityApi }: PicclesApiObject = picclesApi()

  const journeyActivities = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .get()
    .then((snapshot: Firebase.firestore.QuerySnapshot): {
      id: string
    }[] =>
      snapshot.docs.map((doc: Firebase.firestore.QueryDocumentSnapshot): {
        id: string
      } => ({ ...doc.data(), id: doc.id })),
    )

  // Ensure ALL activities are ended
  const areAllActivitiesEnded: boolean = journeyActivities.every((a: any): boolean => a.isStarted && a.isComplete)

  if (areAllActivitiesEnded) {
    // Restart the journey by resetting isStarted and isEnded on all activities
    return Promise.all(
      journeyActivities.map((act: { id: string }) =>
        activityApi.updateActivity({
          journeyId,
          activityId: act.id,
          payload: { isStarted: false, isComplete: false },
        }),
      ),
    )
  }
  alert('Attempted to restart a journey with incomplete activities.')
  throw new Error('Attempted to restart a journey with incomplete activities.')
}

export default restartJourney
