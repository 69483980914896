import Firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Sponsor, SponsorsData, TemplateJourney } from 'types'

type Args = {
  journeyCollectionPath: string
  journeyId: string
}

export const useSponsorsData = ({ journeyCollectionPath, journeyId }: Args): [SponsorsData, boolean, Error] => {
  const [sponsors, setSponsors] = useState<{ [key: string]: Sponsor }>()
  const [sponsorsLoading, setSponsorsLoading] = useState<boolean>(false)
  const [sponsorsError, setSponsorsError] = useState<Error>()

  const [journey, journeyLoading, journeyError]: [
    TemplateJourney,
    boolean,
    Error,
  ] = useDocumentData(Firebase.firestore().doc(`${journeyCollectionPath}/${journeyId}`), { idField: 'id' })

  useEffect(() => {
    if (journey && journey.sponsors) {
      getSponsors()
    }
    // eslint-disable-next-line
  }, [journey])

  const getSponsors = async () => {
    setSponsorsLoading(true)
    try {
      const primarySponsor = await getSponsor(journey.sponsors['primary'])
      const secondarySponsor = await getSponsor(journey.sponsors['secondary'])
      const hospitalSponsor = await getSponsor(journey.sponsors['hospital'])

      setSponsors({ hospitalSponsor, primarySponsor, secondarySponsor })
    } catch (error) {
      setSponsorsError(error)
    }
    setSponsorsLoading(false)
  }

  const getSponsor = async (docId: string): Promise<Sponsor> => {
    const doc = await Firebase.firestore()
      .collection('sponsors')
      .doc(docId)
      .get()
    if (doc.exists) {
      return { ...doc.data(), id: doc.id } as Sponsor
    }
  }

  const loading: boolean = journeyLoading || sponsorsLoading
  const error: Error = journeyError || sponsorsError

  return [sponsors, loading, error]
}
