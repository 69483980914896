import React from 'react'
import { Select } from '@lightspeed/flame/Select'
import { Text } from '@lightspeed/flame/Text'
import { Flex } from 'rebass'

type Props = {
  options: { label: string; id: string }[]
  onChange: any
  label?: string
  name: string
  value: string
  key?: string
  required?: boolean
  defaultOption?: string
  helpbox?: JSX.Element
}

export const SelectDropdown: React.FunctionComponent<Props> = ({
  defaultOption,
  options,
  onChange,
  label,
  name,
  value,
  key,
  required,
  helpbox,
}: Props) => {
  return (
    <div
      style={{
        marginBottom: '10px',
      }}
    >
      <Flex>
        {label && (
          <Text size="small" fontWeight="bold" color="textHeading" mb={1}>
            {label}
          </Text>
        )}
        {helpbox}
      </Flex>

      <Select onChange={onChange} name={name} value={value} key={key} required={required}>
        {[
          <option key="uniqueKey01" value={defaultOption} hidden={!defaultOption}>
            {defaultOption || 'Select'}
          </option>,
          ...options.map((o) => (
            <option key={o.id} value={o.id}>
              {o.label}
            </option>
          )),
        ]}
      </Select>
    </div>
  )
}

export default SelectDropdown
