import { Error, Loading } from 'components'
import Firebase from 'firebase/app'
import { getJourneyState } from 'api/selectors'
import React from 'react'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import PageModeration, { Data } from './PageModeration'

const PageModerationContainer = (props) => {
  // Journey Data fetch
  const [journeyData, journeyLoading, journeyError]: [Data, boolean, Error] = useDocumentData(
    Firebase.firestore().doc(`journeys/${props.journeyId}`),
  )
  // Activity data fetch
  const [activityData, activityLoading, activityError]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .doc(`journeys/${props.journeyId}`)
      .collection('activities'),
    { idField: 'id' },
  )
  // Sponsors data fetch
  const [sponsorsData, sponsorsLoading, sponsorsError]: [
    any,
    boolean,
    Error,
  ] = useCollectionData(Firebase.firestore().collection(`sponsors`), { idField: 'id' })

  // DATA MERGING
  // If all the necessary data is here, proceed to merging data sets for consumption by Page.
  let data
  if (journeyData && activityData && sponsorsData) {
    // Start by spreading in the journey data.
    data = { ...journeyData }

    // Merge the journey sequence with the activities data in order to hydrate the sequence of IDs
    // coming from journey with the full activity data required by the Activity Cards in the stack.
    data.sequenceMap = data.sequence
      ? data.sequence.map((id: string) => ({
          id,
          ...activityData.find((a) => a.id === id),
        }))
      : []

    // Add sponsorsData to data
    data.sponsors = sponsorsData.map(({ id, name, imageUrl }) => ({ id, label: name, imageUrl }))

    // select the journey state and put on data
    data.journeyState = getJourneyState(data.sequenceMap)
  }

  // Merge all Loading and Error
  const loading = journeyLoading || activityLoading || sponsorsLoading
  const error = journeyError || activityError || sponsorsError

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {data && <PageModeration data={data} journeyId={props.journeyId} />}
    </>
  )
}

export default PageModerationContainer
