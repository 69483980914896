import getPaymentMethods, { PaymentMethods } from './getPaymentMethods'
import getSetupIntent, { SetupIntent } from './getSetupIntent'
import setDefaultPaymentMethod, { PaymentMethodPayload } from './setDefaultPaymentMethod'
import upgradeCompanyPlan, { UpgradePayload, UpgradeConfirmation } from './upgradeCompanyPlan'
import getCompanySubscription from './getCompanySubscription'
import getCustomer from './getCustomer'
import { cancelSubscription } from './cancelSubscription'
import changePlan, { ChangePlanResults } from './changePlan'

export type StripeActions = {
  getPaymentMethods: (email: string) => Promise<PaymentMethods>
  getSetupIntent: (email: string) => Promise<SetupIntent>
  setDefaultPaymentMethod: (data: PaymentMethodPayload) => Promise<void>
  upgradeCompanyPlan: (data: UpgradePayload) => Promise<UpgradeConfirmation>
  getCompanySubscription: (id: string) => Promise<any>
  getCustomer: (email: string) => Promise<any>
  cancelSubscription: (email: string) => Promise<void>
  changePlan: (data: ChangePlanResults) => Promise<void>
}

const stripeActions = (url: string): StripeActions => ({
  getPaymentMethods: (email: string) => getPaymentMethods(email, url),
  getSetupIntent: (email: string) => getSetupIntent(email, url),
  setDefaultPaymentMethod: (data: PaymentMethodPayload) => setDefaultPaymentMethod(data, url),
  upgradeCompanyPlan: (data: UpgradePayload) => upgradeCompanyPlan(data, url),
  getCompanySubscription: (id: string) => getCompanySubscription(id, url),
  getCustomer: (email: string) => getCustomer(email, url),
  cancelSubscription: (email: string) => cancelSubscription(email, url),
  changePlan: (data) => changePlan(data, url),
})

export default stripeActions
