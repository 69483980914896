import React from 'react'
import { useLocation, navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

export const useLocale = () => {
  const { i18n } = useTranslation()
  const location = useLocation()

  React.useEffect(() => {
    navigate(`${location.pathname}?locale=${i18n.language}`)
    // eslint-disable-next-line
  }, [i18n.language])

  const changeLocale = (newLocale: string) => {
    i18n.changeLanguage(newLocale)
  }

  return { locale: i18n.language, changeLocale }
}
