export const defaultColors: Array<string> = [
  '#222021',
  '#B80F0A',
  '#F9812A',
  '#FCE205',
  '#DE6fA1',
  '#B47EDE',
  '#2E1A47',
  '#003151',
  '#95C8D8',
  '#8ACF1F',
  '#98FB98',
  '#B9BBB6',
  '#613613',
  '#997950',
  '#FFFFFF',
]
