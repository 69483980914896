import Firebase from 'firebase/app'
import ReactGA from 'react-ga'
import journeyApi from '../journeys'

export type DeleteActivityArgs = {
  journeyId: string
  activityId: string
  sequence?: string[]
}
const deleteActivity = async ({ journeyId, activityId, sequence }: DeleteActivityArgs): Promise<any> => {
  /**
   * We prevent deleting an activity that is currently "Active" (isStarted && !isComplete)
   * So first we fetch the activity data to make sure it's not active and if it is, we gtfo.
   */
  const activity: Firebase.firestore.DocumentData = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .doc(activityId)
    .get()
    .then((doc: Firebase.firestore.DocumentSnapshot): Firebase.firestore.DocumentData => doc.data())

  // if "Active" gtfo!
  if (activity.isStarted && !activity.isComplete) {
    throw new Error('Activity is currently active, you may not delete an active activity')
  }

  ReactGA.event({
    category: 'Activity',
    action: 'Delete activity',
  })
  try {
    await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .doc(activityId)
      .delete()
      .then(() => {})
      .catch((error) => {
        alert(`Whoops, couldn't delete the activity: ${error.message}`)
      })

    // Update the journey sequence to remove the activity
    const newSequence: string[] = sequence ? sequence.filter((id) => id !== activityId) : []
    return journeyApi.updateJourney({ journeyId, payload: { sequence: newSequence } })
  } catch (e) {
    console.log(e)
  }
}

export default deleteActivity
