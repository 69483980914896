import React, { ReactElement, FunctionComponent } from 'react'
import { Flex, Box } from 'rebass'
import { DashboardHeader } from 'components/molecules/DashboardHeader'
import useTranslations from 'hooks/useTranslations'

const translations = {
  en: {
    heading: 'Template Journey',
    title: 'Crayon Initiative',
    createNew: 'Create New',
    edit: 'Edit',
  },
  fr: {
    heading: 'Template Journey',
    title: 'Crayon Initiative',
    createNew: 'Create New',
    edit: 'Edit',
  },
}

type Translations = typeof translations.en

type Props = {
  children?: React.ReactNode
}

const TemplateList: FunctionComponent<Props> = ({ children }: Props): ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)

  return (
    <Box>
      <DashboardHeader title={labels.heading} />
      <Flex flexDirection="column" pt={2}>
        {children}
      </Flex>
    </Box>
  )
}

export default TemplateList
