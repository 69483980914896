// a generic error page to show whenever something goes wrong in other views
import React from 'react'
import useTranslations from 'hooks/useTranslations'

const translations = {
  en: {
    title: 'Whoops',
    message: "Sorry, something went wrong. We're looking into it.",
  },
  fr: {
    title: 'Ouf',
    message: 'Désolé de l’inconvénient, nous allons regarder le problème sous peu.',
  },
}

type Props = {
  children?: React.ReactNode
  error?: any
}

export const Error = ({ error }: Props) => {
  const labels = useTranslations<typeof translations.en>(translations)
  console.log('\n%cerror', 'color:orange;font-weight:bold;', error, '\n\n')
  return (
    <div>
      <h1>{labels.title}</h1>
      <p>{labels.message}</p>
      <div style={{ fontFamily: 'monospace' }}>{error ? error.message : null}</div>
    </div>
  )
}
