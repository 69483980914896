import React, { useState, ReactElement, FunctionComponent } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Firebase from 'firebase/app'

import { getUserId } from 'api/helpers/firestoreHelpers'
import { Loading, Error, JourneyList } from 'components'
import { JourneyCardContainer } from './JourneyCardContainer'

type SortingOption = {
  label: string
  id: string
  field: string
  direction: 'asc' | 'desc'
}

type Props = {
  onCreateNewJourney: () => Promise<void>
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Newest', // used for SelectDropdown
    id: 'newest', // used for SelectDropdown
    field: 'createdOn', // used for firestore query
    direction: 'desc', // used for firestore query
  },
  {
    label: 'Oldest',
    id: 'oldest',
    field: 'createdOn',
    direction: 'asc',
  },
  {
    label: 'A to Z',
    id: 'atoz',
    field: 'title',
    direction: 'asc',
  },
  {
    label: 'Z to A',
    id: 'ztoa',
    field: 'title',
    direction: 'desc',
  },
  {
    label: 'Recent',
    id: 'recent',
    field: 'updatedOn',
    direction: 'desc',
  },
]

const JourneyListContainer: FunctionComponent<Props> = ({ onCreateNewJourney }: Props): ReactElement => {
  const userUid: string = getUserId()

  // State and handler for sort and data fetching
  const [sortOption, setSortOption]: [SortingOption, React.Dispatch<React.SetStateAction<SortingOption>>] = useState<
    SortingOption
  >(sortingOptions[4])

  const handleOnSort: (event: React.ChangeEvent<HTMLSelectElement>) => void = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const newSortOption: SortingOption = sortingOptions.find((o: SortingOption) => o.id === event.target.value)
    setSortOption(newSortOption)
  }

  const [data, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .where('createdBy', '==', userUid)
      .where('isDraft', '==', false)
      .where('isDeleted', '==', false)
      .orderBy(sortOption.field, sortOption.direction),
    { idField: 'id' },
  )

  // remove any draft or deleted journeys
  // const filterOutDraftandDeletedJourneys = (journeys) =>
  //   journeys.filter((journey) => !journey.draft && !journey.isDeleted)

  return (
    <JourneyList
      sortingOptions={sortingOptions}
      onClickCreate={onCreateNewJourney}
      onChangeSort={handleOnSort}
      sortBy={sortOption.id}
    >
      {loading && <Loading />}
      {error && <Error error={error} />}
      {data &&
        data.map((journey: any) => {
          return <JourneyCardContainer key={journey.id} journey={journey} />
        })}
    </JourneyList>
  )
}
export default JourneyListContainer
