import Firebase from 'firebase/app'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Question } from 'types'

type Args = {
  templateId: string
  questionId: string
}

const useQuestion = ({ templateId, questionId }: Args): [Question, boolean, Error] => {
  const [data, loading, error]: [Question, boolean, Error] = useDocumentData(
    Firebase.firestore()
      .collection('templates')
      .doc(templateId)
      .collection('questions')
      .doc(questionId),
    { idField: 'id' },
  )

  return [data, loading, error]
}

export default useQuestion
