import Firebase from 'firebase/app'

type IsPiccleDoneArgs = {
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

export const isPiccleDone = async ({
  journeyCollectionPath,
  piccleId,
  journeyId,
}: IsPiccleDoneArgs): Promise<boolean> => {
  const piccle = await Firebase.firestore()
    .doc(`${journeyCollectionPath}/${journeyId}/piccles/${piccleId}`)
    .get()

  return piccle.data().done ?? false
}
