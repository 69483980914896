import 'firebase/firestore'

export type WriteUserData = {
  uid: string
  firstName: string
  lastName: string
  role?: string
  email: string
  associatedCompany: string
}

const createUser = async (
  { uid, firstName, lastName, role, email, associatedCompany }: WriteUserData,
  endpointUrl: string,
) => {
  try {
    await fetch(endpointUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uid,
        firstName,
        lastName,
        email,
        createdBy: uid,
        role: role ? role : '',
        associatedCompany,
      }),
    })
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default createUser
