/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useRef, useEffect } from 'react'
import { DrawingCanvas } from 'components/molecules/DrawingCanvas'
import { BrushSizeSlider } from 'components/atoms/BrushSizeSlider'
import { Palette } from 'components/atoms/Palette'
import { Card } from '@lightspeed/flame/Card'
import { FaUndo } from 'react-icons/fa'
import { FiType } from 'react-icons/fi'
import { FaPaintBrush } from 'react-icons/fa'
import Firebase from 'firebase/app'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Flex, Box } from '@lightspeed/flame/Core'
import { Text } from '@lightspeed/flame/Text'
import { ClientLayout, DrawingDescription } from 'components'
import undoPiccleStroke from 'api/piccles/undoPiccleStroke'
import { headerSmall, flipStyles, flip } from 'appConstants'
import picclesApi from 'api'
import { useTranslation } from 'react-i18next'
import { downloadJourneyOnDone } from 'api/journeys/downloadJourneyOnDone'

type Props = {
  journeyId: string
  activityId: string
  currentActivity?: any
  piccleId: string
  canvasObjects?: any
  colors: string[]
  goToSharePage: VoidFunction
  prompt?: string
  handleUpdatePiccle: (payload) => Promise<void>
  drawingLimit: boolean
  journey?: any
  goToNextActivity: () => void
  nextActivityId: string
}
export const AsynchronousPicclesCanvas = ({
  journeyId,
  activityId,
  piccleId,
  canvasObjects,
  colors,
  handleUpdatePiccle,
  goToSharePage,
  currentActivity,
}: Props) => {
  const { t, i18n } = useTranslation()

  // State
  const [currentColor, setCurrentColor] = useState<string>(colors ? colors[0] : '#000000')
  const [brushSize, setBrushSize] = useState<number>(20)
  // use clientSideStrokes state for snappier undos
  const [clientSideStrokes, setClientSideStrokes] = useState<Array<any>>(canvasObjects)
  const [showCanvas, setShowCanvas] = useState<boolean>(true)
  const [hasFlipped, setHasFlipped] = useState<boolean>(false)
  const [boxSize, setBoxSize] = useState(null)
  const boxRef = useRef(null)

  // this is a temporary workaround for QDS to allow the prompt to be translated
  // admin must write english first then french, separated by a "/"

  // Handlers
  const handleUndo = async () => {
    try {
      await undoPiccleStroke({
        journeyId,
        activityId,
        piccleId,
        strokeId: clientSideStrokes[clientSideStrokes.length - 1].id,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const handlePiccleDone = async () => {
    const { companyApi } = picclesApi()
    companyApi.updateCompanyUsage({ journeyId })
    await downloadJourneyOnDone(journeyId, activityId, piccleId)
    goToSharePage()
  }
  const handleOnStroke = (newStroke) => {
    const newStrokes = [...clientSideStrokes, newStroke]
    setClientSideStrokes(newStrokes)
  }
  const handleChangeColor = (color) => {
    setCurrentColor(color.hex)
  }
  const toggleCanvas = () => {
    setShowCanvas(!showCanvas)
    setHasFlipped(true)
  }
  // handle the resize of the canvas container so we can pass the height to  DrawingDescription
  const setContainerSize = () => {
    const squareCanvasSize = Math.min(boxRef.current.clientHeight, boxRef.current.clientWidth)
    setBoxSize(squareCanvasSize)
  }
  // get canvasSize and intialize resize listeners on mount
  useEffect(() => {
    setContainerSize()
    window.addEventListener('resize', setContainerSize)
    return async () => {
      window.removeEventListener('resize', setContainerSize)
      try {
        const result = await Firebase.firestore()
          .collection(`journeys/${journeyId}/activities/${activityId}/piccles`)
          .doc(piccleId)
          .set(
            {
              done: true,
            },
            { merge: true },
          )
        return result
      } catch (error) {
        console.log(error)
      }
    }
  }, []) // eslint-disable-line
  return (
    <ClientLayout headerStyle={headerSmall} languages={currentActivity && Object.keys(currentActivity.question)}>
      <Flex flexDirection="row" alignItems="center" justifyContent="flex-start">
        <Button
          onClick={async () => {
            try {
              await handleUndo()
            } catch (error) {
              console.log(error)
            }
            setClientSideStrokes(clientSideStrokes.slice(0, -1))
          }}
          css={(theme) => css`
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: white;
            box-shadow: ${theme.shadows[1]};
          `}
        >
          <FaUndo />
        </Button>
        ​
        <Text width="100%" pl={3} pr={1} color="text">
          {currentActivity.question[i18n.language]}
        </Text>
      </Flex>
      <Box
        ref={boxRef}
        css={() => css`
          position: relative;
          flex: 1;
        `}
      >
        <div
          css={() => css`
            height: 100%;
            animation: ${hasFlipped && showCanvas ? flip : null} 1s linear 1;
          `}
          style={showCanvas ? flipStyles.drawShow : flipStyles.drawHide}
        >
          <DrawingCanvas
            journeyId={journeyId}
            activityId={activityId}
            piccleId={piccleId}
            canvasObjects={clientSideStrokes}
            brushSize={brushSize}
            currentColor={currentColor}
            onStroke={handleOnStroke}
          />
        </div>
        <Flex
          justifyContent="center"
          alignItems="center"
          css={() => css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: ${!showCanvas ? flip : null} 1s linear 1;
          `}
          style={showCanvas ? flipStyles.writehide : flipStyles.writeShow}
        >
          <DrawingDescription boxSize={boxSize} handleUpdatePiccle={handleUpdatePiccle} />
        </Flex>
        <Button
          onClick={toggleCanvas}
          css={(theme) => css`
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: ${showCanvas ? 'white' : theme.colors.secondary};
            box-shadow: ${theme.shadows[1]};
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 4;
            &:hover {
              background: ${showCanvas ? 'white' : theme.colors.secondary};
            }
          `}
        >
          {showCanvas ? (
            <FiType color={'black'} size="1.7em" style={{ fill: 'none' }} />
          ) : (
            <FaPaintBrush style={{ fill: 'white' }} />
          )}
        </Button>
      </Box>
      <Card
        css={(theme) => css`
          width: 90%;
          margin: 0 auto;
          border-radius: 0 0 2rem 2rem;
          padding-bottom: ${theme.space[2]};
        `}
      >
        <BrushSizeSlider color={currentColor} onChange={setBrushSize} brushSize={brushSize} />
        <Palette colors={colors} onChangeComplete={handleChangeColor} />
      </Card>
      ​
      <Button
        className="async-button"
        mt={2}
        css={css`
          float: right;
          border-radius: 1rem;
          align-self: flex-end;
        `}
        variant="primary"
        fill
        onClick={handlePiccleDone}
        disabled={!clientSideStrokes.length}
      >
        {t('pagePiccleDraw.submit')}
      </Button>
    </ClientLayout>
  )
}
