import { Box, Flex } from 'rebass'
import { CrayonButtonSmall, CrayonLayout, CrayonText } from 'components'
import { Question, QuestionTitle, SponsorsData } from 'types'
import React, { useRef, useState } from 'react'
/** @jsx jsx */
import { css, jsx } from '@emotion/core'

import { BrushSizeSlider } from 'components/atoms/BrushSizeSlider'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Card } from '@lightspeed/flame/Card'
import { DrawingCanvasCrayon } from 'components/molecules/DrawingCanvasCrayon'
import { FaUndo } from 'react-icons/fa'
import { Palette } from 'components/atoms/Palette'
import { Text } from '@lightspeed/flame/Text'
import { crayonPicclesApi } from 'api'
import createPiccle from 'api/crayon/createPiccle'
import undoPiccleStroke from 'api/crayon/undoPiccleStroke'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  journeyCollectionPath: string
  category: string
  templateId: string
  journeyId: string
  randomQuestion: Question
  currentQuestionIndex: string
  piccleId: string
  canvasObjects?: any
  language: string
  colors: string[]
  prompt: QuestionTitle
  goToSharePage: () => void
  sponsors: SponsorsData
  labels: {
    submit: string
    skip: string
    poweredBy: string
  }
}

const PageCrayonPiccleDraw = ({
  journeyCollectionPath,
  category,
  journeyId,
  randomQuestion,
  currentQuestionIndex,
  piccleId,
  canvasObjects,
  templateId,
  language,
  colors,
  prompt,
  goToSharePage,
  sponsors = {},
  labels,
}: Props) => {
  const navigate = useNavigate()
  // State
  const [currentColor, setCurrentColor] = useState<string>(colors ? colors[0] : '#000000')
  const [brushSize, setBrushSize] = useState<number>(10)

  // use clientSideStrokes state for snappier undos
  const [clientSideStrokes, setClientSideStrokes] = useState(canvasObjects)

  const { crayonJourneyApi } = crayonPicclesApi()

  const boxRef = useRef(null)

  const hasNextQuestion: boolean = parseInt(currentQuestionIndex) < 5

  // Handlers
  const handleUndo = async () => {
    try {
      await undoPiccleStroke({
        journeyCollectionPath,
        journeyId,
        piccleId,
        strokeId: clientSideStrokes[clientSideStrokes.length - 1].id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnStroke = (newStroke) => {
    const newStrokes = [...clientSideStrokes, newStroke]
    setClientSideStrokes(newStrokes)
  }
  const handleChangeColor = (color) => {
    setCurrentColor(color.hex)
  }

  const handleSkip = async () => {
    if (hasNextQuestion) {
      const newPiccleId = await createPiccle({
        journeyCollectionPath,
        journeyId,
        questionId: randomQuestion.id,
      })
      navigate(
        window.location.pathname
          .split('/')
          .slice(0, -3)
          .join('/') + `/${parseInt(currentQuestionIndex) + 1}/${randomQuestion.id}/${newPiccleId}`,
        { replace: true },
      ).then(() => window.location.reload())
    } else {
      navigate(`/t/${journeyCollectionPath}/${templateId}/${journeyId}/${language}/${category}/end`)
    }
  }

  const handleOnDone = () => {
    crayonJourneyApi.setPiccleDone({ journeyCollectionPath, piccleId, journeyId })
    goToSharePage()
  }

  return (
    <CrayonLayout
      showHeader
      primaryLogo={sponsors['primarySponsor'] && sponsors['primarySponsor'].imgUrl}
      secondaryLogo={sponsors['secondarySponsor'] && sponsors['secondarySponsor'].imgUrl}
    >
      <React.Fragment>
        <Flex flexDirection="row" alignItems="center" justifyContent="flex-start">
          <Text mb={3} width="100%" pl={3} pr={1} color="text">
            {prompt[language]}
          </Text>
        </Flex>
        <Box
          ref={boxRef}
          css={() => css`
            display: flex;
            position: relative;
            flex: 1;
            width: 90%;
            justify-content: center;
          `}
        >
          <DrawingCanvasCrayon
            journeyCollectionPath={journeyCollectionPath}
            journeyId={journeyId}
            piccleId={piccleId}
            canvasObjects={clientSideStrokes}
            brushSize={brushSize}
            currentColor={currentColor}
            onStroke={handleOnStroke}
          >
            <Button
              onClick={async () => {
                try {
                  await handleUndo()
                } catch (error) {
                  console.log(error)
                }
                setClientSideStrokes(clientSideStrokes.slice(0, -1))
              }}
              // marginLeft={['0', '10%', '20%']}
              css={(theme) => css`
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: white;
                box-shadow: ${theme.shadows[1]};
              `}
            >
              <FaUndo />
            </Button>
          </DrawingCanvasCrayon>
        </Box>
        <Card
          css={(theme) => css`
            width: 90%;
            margin: 0 auto;
            border-radius: 0 0 2rem 2rem;
            padding-bottom: ${theme.space[2]};
          `}
        >
          <BrushSizeSlider color={currentColor} onChange={setBrushSize} brushSize={brushSize} />
          <Palette colors={colors} onChangeComplete={handleChangeColor} />
        </Card>
        <Flex width="100vw" alignItems="center" justifyContent="space-around">
          <CrayonButtonSmall onClick={handleSkip} height="95px">
            <CrayonText
              text={labels.skip}
              styles={{
                color: '#FFF',
                fontSize: '17px',
                fontWeight: 500,
              }}
            />
          </CrayonButtonSmall>
          <Flex flexDirection="row" mt={25} mb={25}>
            <Text opacity={0.4} fontSize="16">
              {labels.poweredBy}
            </Text>
          </Flex>
          <CrayonButtonSmall onClick={handleOnDone} height="95px" disabled={!clientSideStrokes.length}>
            <CrayonText
              text={labels.submit}
              styles={{
                color: '#FFF',
                fontSize: '17px',
                fontWeight: 500,
              }}
            />
          </CrayonButtonSmall>
        </Flex>
      </React.Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonPiccleDraw
