import React, { FC } from 'react'
import { Flex, Text } from 'rebass'

type Props = {
  footerText: string
}

export const CrayonFooter: FC<Props> = ({ footerText }: Props) => {
  return (
    <Flex flexDirection="row" justifyContent="center" mb={25} style={{ backgroundColor: 'white', width: '100%' }}>
      <Text opacity={0.4} fontSize="16">
        {footerText}
      </Text>
    </Flex>
  )
}
