import { EventType, Subscription } from 'Pages/Admin/PageProfile/Plans'

export type UpgradePayload = {
  paymentMethodId: string
  email: string
  subscription: Subscription
  period: EventType
}

const getCompanySubscription = async (subscriptionId: string, endpointUrl: string): Promise<any> => {
  try {
    const response = await fetch(`${endpointUrl}/subscriptions/${subscriptionId}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getCompanySubscription
