import React from 'react'
import { Flex } from 'rebass'
import { Heading1 } from '@lightspeed/flame/Text'

import { SignUpFooter, SignUpNavBar } from 'components'
import useTranslations from 'hooks/useTranslations'

const translations = {
  en: {
    tagline: 'Start using Piccles!',
  },
  fr: {
    tagline: 'Start using Piccles!',
  },
}

type Translations = typeof translations.en

type AuthLayoutProps = {
  children: React.ReactNode
  overwrite?: {
    en: Translations
    fr: Translations
  }
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children, overwrite }): JSX.Element => {
  const labels: Translations = useTranslations<Translations>({ ...translations, ...overwrite })

  return (
    <>
      <SignUpNavBar />
      <Flex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bg="muted"
        width="100vw"
        sx={{ minHeight: 'calc(100vh - 136px)' }}
        paddingBottom="60px"
      >
        <Heading1
          color="#1B314B"
          lineHeight={[5, 6]}
          fontSize={[42, 60]}
          style={{
            textTransform: 'uppercase',
            textAlign: 'center',
            margin: '80px auto 60px',
            borderBottom: '16px solid #FAAD13',
            fontFamily: 'Hind, sans-serif',
          }}
        >
          {labels.tagline}
        </Heading1>
        {children}
      </Flex>
      <SignUpFooter />
    </>
  )
}
