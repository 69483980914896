import React from 'react'
import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Loading, Error } from 'components'
import PageEditTemplate from './PageEditTemplate'
import { Question, UpdateQuestionInputType } from 'types'
import { crayonPicclesApi } from 'api'

const PageEditTemplateContainer = (props) => {
  const { crayonJourneyApi } = crayonPicclesApi()

  const [questions, loading, error]: [Question[], boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(`templates/${props.templateId}/questions`)
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )

  const handleAddQuestion = async (payload: Pick<Question, 'categories' | 'title'>) => {
    const templateId = props.templateId
    try {
      const questionId = await crayonJourneyApi.createQuestion({ templateId, payload })
      return questionId
    } catch (error) {
      console.log('error creating new question', error)
    }
  }

  const handleUpdateQuestion = async ({ questionId, payload }: UpdateQuestionInputType) => {
    const templateId = props.templateId
    try {
      await crayonJourneyApi.updateQuestion({ templateId, questionId, payload })
    } catch (error) {
      console.log('error updateting question', error)
    }
  }

  const handleDeleteQuestion = async (questionId: string) => {
    const templateId = props.templateId
    try {
      await crayonJourneyApi.deleteQuestion({ templateId, questionId })
    } catch (error) {
      console.log('error deleting question', error)
    }
  }

  return (
    <>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {questions && (
        <PageEditTemplate
          existingQuestions={questions}
          onNewQuestionCreate={handleAddQuestion}
          onUpdateQuestion={handleUpdateQuestion}
          onDeleteQuestion={handleDeleteQuestion}
        />
      )}
    </>
  )
}

export default PageEditTemplateContainer
