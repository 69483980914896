/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box } from '@lightspeed/flame/Core'
import { Image } from 'rebass'
import { Card } from '@lightspeed/flame/Card'
import { Text } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Spinner } from '@lightspeed/flame/Spinner'
import { SidebarTimer as Timer } from '../../atoms/Timer'
import { PREGAME, LIMBO, TERMINAL, ACTIVE } from 'appConstants'
import { useState } from 'react'
import { MosaicActivity, PromptActivity } from 'hooks/useJourneyData'
import QRCode from 'qrcode.react'
import { Fragment } from 'react'
import { useQrModalState } from 'hooks/useQrModalState'
import { QrModalStateOpenAction } from 'Contexts/QrContext/actions'
import picclesApi from 'api'
import { JourneyState } from 'hooks/useJourneyData'
import { useTranslation } from 'react-i18next'
import { useLocale } from 'hooks/useChangeLocale'

type Props = {
  activity: MosaicActivity | PromptActivity
  code: string
  status: string
  logo: string
  sponsorsLoading: boolean
  QRenabled: boolean
  journeyId: string
  state: JourneyState
  journeySequence: string[]
}

export const SidebarEnterprise: React.FunctionComponent<Props> = ({
  status,
  activity,
  code,
  QRenabled,
  sponsorsLoading,
  logo,
  journeyId,
  state,
  journeySequence,
}: Props): JSX.Element => {
  const { locale } = useLocale()
  const { t } = useTranslation()
  const [copied, setCopied] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [, qrModalDispatch] = useQrModalState()
  const copyToClipboard: () => void = (): void => {
    const path: string = `${window.location.protocol}//${window.location.host}/${code}?locale=${locale}`
    navigator.clipboard.writeText(path).then((): void => setCopied(true))
  }

  const { journeyApi } = picclesApi()
  return (
    <Card
      data-testid="SidebarEnterprise"
      css={(theme) => css`
        background-image: linear-gradient(to top right, ${theme.colors.secondary}, ${theme.colors.secondarydark});
        width: 16.6667%;
        height: 100%;
        margin-right: ${theme.space[2]};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {/* Piccles Logo on Projection screen, can become a sponsor image. */}
      <Box
        height="9rem"
        width="9rem"
        bg="background"
        css={(theme) => css`
          border: 10px solid ${theme.colors.text};
          border-radius: 50% !important;
          display: flex;
          align-items: center;
          justify-content: space-around;
        `}
      >
        {sponsorsLoading ? <Spinner color="blue-200" /> : <Image height="5.4rem" width="5.4rem" src={logo} />}
      </Box>

      {QRenabled && (
        <Fragment>
          <Box
            css={css`
              width: 100%;
              text-align: center;
            `}
            data-cy="QRcode"
          >
            <Text
              key={t('sidebarEnterprise.qrCode') as string}
              my={2}
              fontSize="large"
              color="white"
              css={css`
                font-weight: 400;
                font-size: 2vw;
              `}
            >
              {t('sidebarEnterprise.qrCode')}
            </Text>
            <QRCode
              onClick={() => {
                qrModalDispatch(new QrModalStateOpenAction())
              }}
              size={168}
              value={`${window.location.protocol}//${window.location.host}/${code}?locale=${locale}`}
              css={{ cursor: 'pointer', borderStyle: 'solid', borderColor: '#fff' }}
            />
          </Box>
        </Fragment>
      )}

      {/* Instructions for joining the experience, will rotate from french to english. */}
      {!QRenabled && (
        <Box
          p={1}
          paddingTop={0}
          css={css`
            width: 100%;
            text-align: center;
          `}
        >
          <Text
            key={t('sidebarEnterprise.toJoin') as string}
            my={2}
            fontSize="large"
            color="white"
            css={css`
              font-weight: 400;
              font-size: 2vw;
            `}
          >
            {t('sidebarEnterprise.toJoin')}
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="primary"
            css={css`
              font-weight: 900;
              font-size: 2vw;
            `}
          >
            <b>{window.location.host}</b>
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="white"
            css={css`
              font-size: 1.5vw;
            `}
          >
            {t('sidebarEnterprise.enterCode')}
          </Text>
          <Text
            my={2}
            fontSize={28}
            color="primary"
            css={css`
              font-weight: 700;
              font-size: 2.4vw;
            `}
          >
            {code}
          </Text>
        </Box>
      )}

      {(status === PREGAME || status === LIMBO || status === TERMINAL) && (
        <Box
          p={1}
          css={(theme) => css`
            background: ${theme.colors.secondary};
            color: ${theme.colors.text};
            border-radius: 1rem;
            text-align: center;
          `}
        >
          <Text>
            {status === TERMINAL ? t('sidebarEnterprise.journeyComplete') : t('sidebarEnterprise.activityReady')}
          </Text>
        </Box>
      )}
      {/* Button to share app url with activity loaded  */}
      <Box
        css={css`
          width: 100%;
          padding: 0.375rem;
        `}
      >
        <Button data-cy="sidebar-link-copy-btn" block fill size="large" variant="primary" onClick={copyToClipboard}>
          {copied ? t('sidebarEnterprise.copied') : t('sidebarEnterprise.copyLink')}
        </Button>
        {/* Timer */}
      </Box>
      <Box
        css={css`
          display: flex;
          color: white;
          font-size: 3rem;
          visibility: ${loading && 'hidden'};
        `}
      >
        {state.previousActivity ? (
          <Text
            css={css`
              margin-right: 3rem;
              &:hover {
                cursor: pointer;
              }
            `}
            onClick={async () => {
              setLoading(true)
              await journeyApi.moveBackwardJourneyActivity({ journeyId, state })
              setLoading(false)
            }}
          >
            <span role="img" aria-label="backword_arrow">
              ←
            </span>
          </Text>
        ) : null}

        {state.nextActivity ? (
          <Text
            css={css`
              &:hover {
                cursor: pointer;
              }
            `}
            onClick={async () => {
              // updating firebase docs when actually moving forward
              setLoading(true)
              await journeyApi.moveForwardJourneyActivity({ journeyId, state })
              setLoading(false)
            }}
          >
            <span role="img" aria-label="forward_arrow">
              →
            </span>
          </Text>
        ) : null}
      </Box>
      {!state.nextActivity && (
        <Button
          onClick={async () => {
            setLoading(true)
            await journeyApi.moveForwardJourneyActivity({ journeyId, state, firstActivityId: journeySequence[0] })
            setLoading(false)
          }}
        >
          Restart journey
        </Button>
      )}
      {status === ACTIVE && activity.startedAt ? (
        <Timer startedAt={activity.startedAt} timer={activity.timer} />
      ) : (
        <Box />
      )}
    </Card>
  )
}
