import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

export type DeleteJourneyArgs = {
  journeyId: string
}

const deleteJourney = async ({ journeyId }: DeleteJourneyArgs) => {
  /**
   *  Deleting a Journey - the soft delete method.
   *
   *  The purpose of deleting a journey is two fold:
   *  1. Unclutter a user's dashboard if they please.
   *  2. Remove the code from the journey code namespace to make it available again.
   *
   *  How?
   *
   *  To delete a journey, we simply set `isDelete === true` on the journey document
   *  AND
   *  set the `code` property to blank('') to render the code available again.
   *
   */

  // Seeing as FREE tier journeys always have an active activity, I'll comment out the following which would prevent deleting an active journey until we establish a need for this.

  // // Get activities and make sure none are "active"
  // const activities = await Firebase.firestore()
  //   .collection('journeys')
  //   .doc(journeyId)
  //   .collection('activities')
  //   .get()
  //   .then((snap) => snap.docs)
  //   .then((docs) => docs.map((doc) => doc.data()))

  // // if any of the activities are "Active" we gtfo!
  // if (activities.some((act) => act.isStarted && !act.isComplete)) {
  //   throw new Error(
  //     'Journey contains a currently active activity, you may not delete a journey containing active activity',
  //   )
  // }

  // Google analytics
  ReactGA.event({
    category: 'Journey',
    action: 'Delete journey',
  })

  // Here is where we do the deed. Set `isDeleted === true` and remove code.
  try {
    return await Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .update({ isDeleted: true, code: '' })
  } catch (e) {
    console.log(e)
  }
}

export default deleteJourney
