const getCustomer = async (email: string, endpointUrl: string): Promise<any> => {
  try {
    const response = await fetch(`${endpointUrl}/${email}/get-customer`, { mode: 'cors' })
    if (response.status >= 400 && response.status <= 499) throw new Error('API Client Error')
    const data = response.json()
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default getCustomer
