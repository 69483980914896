import Firebase from 'firebase/app'

import { prepareDocForCreate } from '../helpers/firestoreHelpers'

type CanvasObject = {
  sequence: string
  height: number
  left: number
  stroke: string
  strokeWidth: number
  top: number
  width: number
  path: any[] | string
}
type Args = {
  payload: CanvasObject
  journeyCollectionPath: string
  journeyId: string
  piccleId: string
}

const createPiccleStroke = ({
  payload,
  journeyCollectionPath,
  journeyId,
  piccleId,
}: Args): Promise<Firebase.firestore.DocumentReference> => {
  // nested arrays are not allowed so let's stringify our data path
  payload.path = JSON.stringify(payload.path)

  return Firebase.firestore()
    .collection(`${journeyCollectionPath}/${journeyId}/piccles/${piccleId}/strokes`)
    .add(prepareDocForCreate(payload))
}

export default createPiccleStroke
