import createCompany, { WriteCompanyData } from './createCompany'
import getCompany, { GetCompanyArguments, CompanyData } from './getCompany'
import getCompanyByAdmin, { GetCompanyByAdminArguments } from './getCompanyByAdmin'
import updateCompanyUsage, { UpdateCompanyUsageArgs } from './updateCompanyUsage'

export type CompanyActions = {
  createCompany: (data: WriteCompanyData) => Promise<string>
  getCompany: (data: GetCompanyArguments) => Promise<CompanyData>
  getCompanyByUser: (data: GetCompanyByAdminArguments) => Promise<CompanyData>
  updateCompanyUsage: (args: UpdateCompanyUsageArgs) => Promise<boolean>
}

const companyActions = (url: string): CompanyActions => ({
  createCompany: (data: WriteCompanyData) => createCompany(data, url),
  getCompany: (data: GetCompanyArguments) => getCompany(data, url),
  getCompanyByUser: (data: GetCompanyByAdminArguments) => getCompanyByAdmin(data, url),
  updateCompanyUsage: (args: UpdateCompanyUsageArgs) => updateCompanyUsage(args),
})

export default companyActions
