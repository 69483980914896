// string representing all characters we accept for codes
export const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

// get a random character from the string
export const randomCharacter = (): string => possibleChars[Math.floor(Math.random() * possibleChars.length)]

export const generateRandomCode = (lenghtOfCode: number): string => {
  const randomCode: string[] = []
  for (let i = 0; i < lenghtOfCode; i++) {
    randomCode[i] = randomCharacter()
  }

  return randomCode.join('')
}

export default generateRandomCode
