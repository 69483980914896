// the main file in our front-end app
// create-react-app expects a file in src/index.js and loads everything from here

import 'firebase/auth'
import 'firebase/firestore'

import * as Sentry from '@sentry/browser'

import App from 'App'
import Firebase from 'firebase/app'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import config from 'config'
import './i18n'

// Require dotenv to load local .env file if it exists.
require('dotenv').config()

// Log the current NODE_ENV | REACT_APP_ENV
console.log('env', config.env)

// connect our app to firebase
Firebase.initializeApp(config.firebase)

if (!process.env.REACT_APP_SKIP_EMULATOR && config.env === 'development') {
  const db = Firebase.firestore()
  //@ts-ignore
  db.useEmulator('localhost', 8080)
  const auth = Firebase.auth()
  //@ts-ignore
  auth.useEmulator('http://localhost:9099')
  const functions = Firebase.functions()
  //@ts-ignore
  functions.useEmulator('http://localhost', 5002)
}

// Google Analytics
// https://github.com/react-ga/react-ga#api
ReactGA.initialize(config.google.analytics.trackingId)

// Sentry
if (config.env === 'production') {
  Sentry.init({
    dsn: config.sentry.dsn,
  })
}

/**
 * Render the App component to our document root with React after detecting if a user is already logged in.
 * Firebase enforces an observer pattern here to ensure user is not in an intermediate state.
 */

Firebase.auth().onAuthStateChanged(() => {
  ReactDOM.render(<App />, document.getElementById('root'))
})
