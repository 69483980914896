import React, { useEffect } from 'react'
import { Box, Flex } from 'rebass'
import useTranslations from 'hooks/useTranslations'
import { Text } from '@lightspeed/flame/Text'
import { PiccleButton as Button } from 'components/atoms/Button'
import { useAuth } from 'hooks/useAuth'
import { BillingForm } from '../BillingForm'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import config from 'config'
import { Elements } from '@stripe/react-stripe-js'
import { Loading } from 'components'
import { useProfilePageContext, fetchPaymentMethod } from 'Pages/Admin/PageProfile/reducerContext'

type Translations = typeof translations.en

const translations = {
  en: {
    changePaymentMethod: 'Change Payment Method',
    addPaymentMethod: 'Add a Payment Method',
  },
  fr: {
    changePaymentMethod: 'Changer la méthode de paiement',
    addPaymentMethod: 'Ajouter un moyen de paiement',
  },
}

const stripePromise: Promise<Stripe> = loadStripe(config.stripe.publicKey)

const PaymentMethods = () => {
  const labels: Translations = useTranslations<Translations>(translations)
  const { user } = useAuth()

  // Use profile page state context
  const {
    state: {
      status = 'status',
      paymentMethod = {
        id: 'string',
        card: {
          brand: 'string',
          last4: 'string',
          expMonth: 'string',
          expYear: 'string',
        },
      },
    },
    dispatch,
  } = useProfilePageContext()

  const onChangePaymentMethod = () => {
    fetchPaymentMethod(dispatch, user.email)
  }

  const onCancelChangePaymentMethod = () => {
    dispatch({ type: 'change_status', payload: 'idle' })
  }

  // On mount, fetch payment methods
  useEffect(() => {
    fetchPaymentMethod(dispatch, user.email)
    // eslint-disable-next-line
  }, [])

  const showPaymentMethod = (status === 'idle' && paymentMethod) || status === 'creating_subscription'

  return (
    <Box my={5}>
      {status === 'fetching_payment_method' && <Loading />}
      {showPaymentMethod && (
        <Box
          sx={{
            background: 'white',
            boxShadow: '0 7px 14px 0 rgba(49,49,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.08)',
            borderRadius: '4px',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              color: '#8898AA',
              fontWeight: '300',
              height: '40px',
              lineHeight: '40px',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Flex>
              <Text mr={3}>{paymentMethod.card.brand}</Text>
              <Box id="saved-card">**** **** **** {paymentMethod.card.last4}</Box>
              <Text ml={3}>{paymentMethod.card.expMonth}</Text>
              {'/'}
              <Text>{paymentMethod.card.expYear}</Text>
            </Flex>
          </Box>
        </Box>
      )}
      {status === 'change_payment_method' && (
        <Elements stripe={stripePromise}>
          <BillingForm
            onCancelChangePaymentMethod={onCancelChangePaymentMethod}
            onChangePaymentMethod={onChangePaymentMethod}
          />
        </Elements>
      )}
      {status === 'idle' && (
        <Button onClick={() => dispatch({ type: 'change_status', payload: 'change_payment_method' })}>
          {paymentMethod ? labels.changePaymentMethod : labels.addPaymentMethod}
        </Button>
      )}
    </Box>
  )
}

export default PaymentMethods
