import { useState, useEffect } from 'react'

const getDeviceConfig = (width) => {
  if (width < 720) {
    return 'sm'
  } else if (width >= 720 && width < 1024) {
    return 'md'
  } else {
    return 'lg'
  }
}

export const useBreakPoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth))

  useEffect(() => {
    const calcInnerWidth = () => setBrkPnt(getDeviceConfig(window.innerWidth))
    window.addEventListener('resize', calcInnerWidth)
    return () => {
      window.removeEventListener('resize', calcInnerWidth)
    }
  }, [])

  return brkPnt
}
