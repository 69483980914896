import React, { useState, Fragment } from 'react'
import { Card, CardSection, CardHeader, CardFooter } from '@lightspeed/flame/Card'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '@lightspeed/flame/Modal'
import { Text } from '@lightspeed/flame/Text'
import { PiccleCanvasContainer as PiccleCanvas } from 'components'
import picclesApi from 'api'

import useTranslations from 'hooks/useTranslations'
import { Activity } from 'Pages/Admin/PageEditJourney/PageEditJourney'
import { Flex } from '@lightspeed/flame/Core'
import { PiccleButton as Button } from 'components/atoms/Button'

const translations = {
  en: {
    type: 'type',
    confirm: 'Confirm',
    abort: 'abort',
    yes: 'yes',
    finish: 'Finish',
    start: 'Start',
    currentActivity: 'Current Activity',
    nextActivity: 'Next Activity',
    confirmStart: 'Are you sure you want to start this activity?',
    confirmFinish: 'Are you certain you want to END the current activity?',
    unpromote: 'Unpromote',
    currentPromotedPiccle: 'Current Promoted Piccle:',
  },
  fr: {
    type: 'type',
    confirm: 'Confirm',
    abort: 'abort',
    yes: 'yes',
    finish: 'Finish',
    start: 'Start',
    currentActivity: 'Current Activity',
    nextActivity: 'Next Activity',
    confirmStart: 'Are you sure you want to start this activity?',
    confirmFinish: 'Are you certain you want to END the current activity?',
    unpromote: 'Unpromote',
    currentPromotedPiccle: 'Current Promoted Piccle:',
  },
}

type Translations = typeof translations.en

type Props = {
  activityData: Activity
  disabled?: boolean
  current?: boolean
  journeyId: string
}

export const ModerationActivityCard: React.FunctionComponent<Props> = ({
  activityData,
  disabled,
  current,
  journeyId,
}: Props): React.ReactElement => {
  const { activityApi } = picclesApi()
  const labels: Translations = useTranslations<Translations>(translations)

  const [isModalOpen, setIsModalOpen]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(
    false,
  )
  const [loading, setLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false)
  const label: string = current ? labels.finish : labels.start
  const title: string = current ? labels.currentActivity : labels.nextActivity

  const confirmationText: string = current ? labels.confirmFinish : labels.confirmStart

  const handleOnConfirm = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> & React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): Promise<void> => {
    event.preventDefault()
    setLoading(true)
    if (current) {
      await activityApi.endActivity({ journeyId, activityId: activityData.id })
    } else {
      await activityApi.startActivity({ journeyId, activityId: activityData.id })
    }
    setIsModalOpen(false)
    setLoading(false)
  }

  const onClose: () => void = (): void => {
    setIsModalOpen(false)
  }

  const unpromote: (currentJourneyId: string, activityData: Activity) => Promise<any> = async (
    currentJourneyId: string,
    activityData: Activity,
  ): Promise<any> => {
    return activityApi.updateActivity({
      journeyId: currentJourneyId,
      activityId: activityData.id,
      payload: { ...activityData, promotedPiccle: '' },
    })
  }

  return (
    <Fragment>
      <Card>
        <CardHeader style={{ fontSize: '!important 2vw' }} title={title}></CardHeader>
        <CardSection>
          <Text fontSize={['small', 'normal', 'large']} textAlign="center">
            {activityData.prompt}
          </Text>

          <br />

          <Text fontSize={['small', 'normal', 'normal']} mt={1}>
            {`${activityData.type} ${labels.type}`}
          </Text>

          {activityData.promotedPiccle && (
            <Fragment>
              <br />
              <Text>{labels.currentPromotedPiccle}</Text>
              <PiccleCanvas
                journeyId={journeyId}
                activityId={activityData.id}
                height={100}
                width={100}
                piccleId={activityData.promotedPiccle}
              />
              <Button
                variant="danger"
                size="small"
                onClick={() => {
                  unpromote(journeyId, activityData)
                }}
              >
                {labels.unpromote}
              </Button>
            </Fragment>
          )}
        </CardSection>
        <CardFooter>
          <Button variant="primary" size="small" disabled={disabled} onClick={() => setIsModalOpen(true)}>
            {label}
          </Button>
        </CardFooter>
      </Card>

      <Modal isOpen={isModalOpen} onRequestClose={onClose}>
        <ModalHeader onCloseClicked={onClose}>{labels.confirm}</ModalHeader>

        <ModalBody>
          <Flex style={{ maxWidth: '20rem' }}>
            <Text>{confirmationText}</Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex width="100%" justifyContent="space-between">
            <Button variant="danger" onClick={onClose}>
              {labels.abort}
            </Button>

            <Button size="large" fill variant="secondary" onClick={handleOnConfirm} loading={loading}>
              {labels.yes}
            </Button>
          </Flex>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}
