/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect } from 'react'
import { JourneyData } from 'hooks/useJourneyData'
import { Box } from '@lightspeed/flame/Core'
import { Progress } from '@lightspeed/flame/Progress'
import { FaStopwatch } from 'react-icons/fa'
import { Text } from 'rebass'
import moment from 'moment'

export type Data = JourneyData
type Props = {
  timer: string
  startedAt: {
    seconds: number
  }
}

export const SidebarTimer = ({ startedAt, timer }: Props) => {
  const [count, setCount] = useState(Date.now() / 1000 - startedAt.seconds)
  const timerTotal = Number(timer) * 60

  let countDown = moment((timerTotal - count) * 1000).format('mm:ss')

  useEffect(() => {
    setInterval(() => setCount(Date.now() / 1000 - startedAt.seconds), 1000)
  }, []) // eslint-disable-line

  if (timerTotal - count < 0) {
    countDown = '00:00'
  }

  return (
    <Box
      width="80%"
      css={css`
        padding: 0.5rem 0.5rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <Progress
        type="determinate"
        current={count}
        total={timerTotal}
        css={css`
          overflow-x: hidden;
          height: 3rem;
          border-radius: 1.5rem;
          & > div {
            border-radius: 1.5rem;
          }
        `}
      />
      <Box
        py={1}
        px={3}
        css={(theme) => css`
          background: ${theme.colors.background};
          border-radius: 0 0 1rem 1rem;
          & > div {
            border-radius: 1rem;
          }
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.6rem;
        `}
      >
        <FaStopwatch />
        <div
          css={css`
            padding-right: 1rem;
          `}
        />
        <Text>{countDown}</Text>
      </Box>
    </Box>
  )
}
