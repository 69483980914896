/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { FunctionComponent } from 'react'
import useTranslations from 'hooks/useTranslations'
import { Box, Flex, Image } from 'rebass'

import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { CompanyData, SubscriptionStatus } from 'api/companies/getCompany'
import { Subscription } from 'Pages/Admin/PageProfile/Plans'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    profile: 'Profile',
    logout: 'Log out',
  },
  fr: {
    profile: 'Profil',
    logout: 'Se déconnecter',
  },
}

type Translations = typeof translations.en

type Props = {
  children?: React.ReactNode
  company?: CompanyData
  loading: boolean
}

const defaultCompany: CompanyData = {
  admin: 'user',
  createdBy: 'user',
  createdOn: {
    _seconds: 0,
    _nanoseconds: 0,
  },
  company: 'noCompany',
  trialEndsOn: {
    _seconds: 0,
    _nanoseconds: 0,
  },
  plan: SubscriptionStatus.NOT_SUBSCRIBED,
  stripeCustomerId: 'testStripeId',
  subscription: Subscription.TASTER,
  singleEventType: Subscription.TASTER,
}

export const AdminNavbar: FunctionComponent<Props> = ({
  children,
  company = defaultCompany,
  loading = true,
}: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()
  const { signout }: AuthenticationContextFields = useAuth()

  const handleLogout: VoidFunction = async (): Promise<void> => {
    const frontPage: string = '/admin'
    try {
      await signout()
      navigate(frontPage)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const onProfileClick: VoidFunction = (): void => {
    if (!company) {
      navigate('/admin')
      return
    }

    navigate('/admin/profile')
  }

  const onLogoClick: VoidFunction = (): void => {
    if (!company) {
      navigate('/admin')
      return
    }

    navigate('/admin')
  }

  return (
    <Flex
      height={['80px', '4rem', '5rem']}
      px={[0, 3, 6]}
      mx={[0, 8, '13%']}
      bg="white"
      alignItems="center"
      css={(theme) =>
        css`
          box-shadow: ${theme.shadows[3]};
        `
      }
    >
      {/* 
      This removes the logo from the navbar

      <Image
        onClick={onLogoClick}
        src={picclesLogo}
        variant="avatar"
        my={1}
        height="3.5rem"
        sx={{ transform: 'translateY(5px)', cursor: 'pointer' }}
      /> */}

      <Flex
        height="100%"
        justifyContent="center"
        alignItems="center"
        css={() =>
          css`
            cursor: pointer;
          `
        }
        onClick={onLogoClick}
      >
        <Image
          alt="Piccles Logo"
          height={['50%', '55%', '100%']}
          src="https://firebasestorage.googleapis.com/v0/b/piccles-dev.appspot.com/o/Piccles-logo.png?alt=media"
        />
      </Flex>

      <Box mx="auto" />
      {children}
      <Box mx="auto" />
      {!loading && (
        <img
          alt={company?.singleEventType ?? company?.subscription ?? Subscription.TASTER}
          height="100%"
          src={`https://firebasestorage.googleapis.com/v0/b/piccles-dev.appspot.com/o/${company?.singleEventType ??
            company?.subscription ??
            Subscription.TASTER}.png?alt=media`}
        />
      )}

      <Flex ml={2} className="navbar-links">
        <Flex
          justifyContent="center"
          alignItems="center"
          onClick={onProfileClick}
          p={1}
          css={(theme) =>
            css`
              font-weight: 700;
              color: ${theme.colors.primary};
              text-decoration: none;
              cursor: pointer;
            `
          }
        >
          {labels.profile}
        </Flex>

        <Flex
          p={1}
          justifyContent="center"
          alignItems="center"
          onClick={handleLogout}
          css={(theme) =>
            css`
              font-weight: 700;
              color: ${theme.colors.primary};
              text-decoration: none;
              cursor: pointer;
            `
          }
        >
          {labels.logout}
        </Flex>
      </Flex>
    </Flex>
  )
}
