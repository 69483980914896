import { navigate, NavigateOptions } from '@reach/router'
import { useTranslation } from 'react-i18next'

export const useNavigate = () => {
  const { i18n } = useTranslation()
  const navigateTo = (to: string, options?: NavigateOptions<{}>) => {
    const linkWithLang = i18n.language === 'en' ? to : `${to}?lang=${i18n.language}`
    return navigate(linkWithLang, options)
  }

  return navigateTo
}
