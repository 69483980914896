import ReactGA from 'react-ga'
import Firebase from 'firebase/app'

import updateActivity from './updateActivity'
import getActivityStatus, { ActivityStatus } from 'api/activities/getActivityStatus'

export type EndActivityArgs = {
  journeyId: string
  activityId?: string
}

const endActivity = async ({ journeyId, activityId }: EndActivityArgs): Promise<any> => {
  /**
   * We cannot end an activity if it isn't active ("started" AND "incomplete").
   * We Therefore we begin by verifying that this activity is the active one in the journey.
   * If so we may proceed to end this activity.
   */

  // end specific activity to move onto next activity on moderation
  if (activityId) {
    const activityStatus = await getActivityStatus({ journeyId, activityId })

    if (activityStatus === ActivityStatus.ACTIVE) {
      // If the activity is currently active we may end it
      ReactGA.event({
        category: 'Activity',
        action: 'End activity',
      })
      return updateActivity({ journeyId, activityId, payload: { isComplete: true } })
    }
  }

  // end every activity to start selected activity on edit page
  const journeyActivitiesId = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .get()
    .then((snapshot) => snapshot.docs.map((doc) => doc.id))

  let activeActivityExist = false

  //end any active activity(reset)
  for (const activityId of journeyActivitiesId) {
    const activityStatus = await getActivityStatus({ journeyId, activityId })

    if (activityStatus === ActivityStatus.ACTIVE) {
      // If the activity is currently active we may end it
      activeActivityExist = true
      ReactGA.event({
        category: 'Activity',
        action: 'End activity',
      })
      updateActivity({ journeyId, activityId, payload: { isStarted: false, isComplete: false } })
    }
  }

  if (!activeActivityExist) {
    alert(`Attempted to end an activity while it wasn't active.`)
    throw new Error(`Attempted to end an activity while it wasn't active.`)
  }

  return
}

export default endActivity
