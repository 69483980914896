import React, { memo } from 'react'
import './CrayonPiccleCanvas'

type Props = {
  svg: string
  width?: number
  height?: number
}

export default memo(({ svg }: Props) => <div dangerouslySetInnerHTML={{ __html: svg }} />)
