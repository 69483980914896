import { CrayonFooter, CrayonHeader } from 'components'

import { Flex } from 'rebass'
import React from 'react'
import { ReactElement } from 'react'
import { css } from '@emotion/core'
import useWindowHeight from 'hooks/useWindowHeight'

type CrayonLayoutProps = {
  primaryLogo?: string
  secondaryLogo?: string
  showHeader?: boolean
  showFooter?: boolean
  footerText?: string
  children: ReactElement
}

export const CrayonLayout = ({
  showHeader,
  showFooter,
  primaryLogo,
  secondaryLogo,
  children,
  footerText,
}: CrayonLayoutProps) => {
  const windowHeight: number = useWindowHeight()
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: center;
        width: 100vw;
        background-color: white;
        touch-action: pan-y pan-x;
        heigth: '100vh';
        overflow: 'auto';
      `}
      height={windowHeight ? windowHeight : '100vh'}
    >
      {showHeader && <CrayonHeader primaryLogo={primaryLogo} secondaryLogo={secondaryLogo} />}
      <Flex
        flexDirection="column"
        flex="1"
        justifyContent="flex-start"
        alignItems="center"
        style={{ backgroundColor: 'white', width: '100%' }}
      >
        {children}
      </Flex>
      {showFooter && <CrayonFooter footerText={footerText} />}
    </Flex>
  )
}
