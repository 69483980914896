import React, { FunctionComponent } from 'react'
import { Router } from '@reach/router'
import { Route } from 'Routes'
import PageDashboard from './PageDashboard'
import PageEditJourney from './PageEditJourney'
import PageModeration from './PageModeration'
import PageProfile from './PageProfile'
import { SignUpPage } from './SignUp'
import PageEditTemplateJourney from './PageEditTemplateJourney'
import PageEditTemplate from './PageEditTemplate'
import PageTemplateModeration from './PageTemplateModeration'
import { PageProfileEventsContainer } from './PageProfileEvents'

const Admin: FunctionComponent = (): JSX.Element => {
  return (
    <Router>
      <Route path="/" component={PageDashboard} />
      <Route path="/profile" component={PageProfile} />
      <Route path="/profile/events" component={PageProfileEventsContainer} />
      <Route path="/register" component={SignUpPage} />
      <Route path="/edit/:journeyId" component={PageEditJourney} />
      <Route path="/template/edit/:templateId" component={PageEditTemplate} />
      <Route path="/:templatePath/journey/:journeyId" component={PageEditTemplateJourney} />
      <Route path="/moderate/:journeyId" component={PageModeration} />
      <Route path="/moderate/:journeyCollectionPath/:templateId/:journeyId" component={PageTemplateModeration} />
    </Router>
  )
}

export default Admin
