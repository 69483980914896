import picclesApi from 'api'
import { JourneyState } from 'hooks/useJourneyData'

export type MoveBackwardJourneyActivity = {
  journeyId: string
  state: JourneyState
}

const moveBackwardJourneyActivity = async ({ journeyId, state }: MoveBackwardJourneyActivity): Promise<any> => {
  try {
    const { activityApi } = picclesApi()
    if (state.currentActivity && state.previousActivity) {
      await activityApi.updateActivity({
        journeyId,
        activityId: state.currentActivity.id,
        payload: { isStarted: false },
      })

      await activityApi.updateActivity({
        journeyId,
        activityId: state.previousActivity.id,
        payload: { isComplete: false },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

export default moveBackwardJourneyActivity
