import { Box, Flex, Image } from 'rebass'
import { CrayonButton, CrayonLayout, CrayonText } from 'components'
import { Question, SponsorsData } from 'types'

import { Fragment } from 'react'
import React from 'react'
import girlBike from 'assets/images/welcome-async.png'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import createPiccle from 'api/crayon/createPiccle'
import { useNavigate } from 'hooks/useNavigate'

type Props = {
  labels: {
    weldone: string
    startOver: string
    forMore: string
    visit: string
    poweredBy: string
  }
  closingImage: string
  closingMessage: string
  journeyCollectionPath: string
  randomQuestion: Question
  templateId: string
  journeyId: string
  language: string
  sponsors: SponsorsData
}

const PageCrayonJourneyEnd: React.FunctionComponent<Props> = ({
  labels,
  closingImage,
  closingMessage,
  journeyCollectionPath,
  randomQuestion,
  journeyId,
  sponsors = {},
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const startOver = async () => {
    const goToDrawPrompt = async () => {
      try {
        const piccleId = await createPiccle({
          journeyCollectionPath,
          journeyId,
          questionId: randomQuestion.id,
        })
        return piccleId
      } catch (error) {
        console.log(error)
      }
    }
    try {
      const piccleId = await goToDrawPrompt()
      navigate(
        window.location.pathname
          .split('/')
          .slice(0, -1)
          .join('/') + `/1/${randomQuestion.id}/${piccleId}`,
        { replace: true },
      )
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <CrayonLayout
      showHeader
      showFooter
      primaryLogo={sponsors['primarySponsor'] && sponsors['primarySponsor'].imgUrl}
      secondaryLogo={sponsors['secondarySponsor'] && sponsors['secondarySponsor'].imgUrl}
      footerText={labels.poweredBy}
    >
      <Fragment>
        {closingImage ? (
          <Image mt={25} src={closingImage} height="200px" width="200" />
        ) : (
          <Image mt={25} src={girlBike} height="200px" width="200" />
        )}
        <Box mt={45} mb={45}>
          <CrayonText
            text={closingMessage ? closingMessage : labels.weldone}
            styles={{
              fontFamily: 'RivalSlab-Medium',
              fontSize: '25px',
            }}
          />
        </Box>
        <CrayonButton onClick={() => startOver()}>
          <CrayonText text={labels.startOver} styles={{ color: '#FFF', fontSize: '22px', fontWeight: 500 }} />
        </CrayonButton>
        <Box mt={45}>
          <CrayonText text={labels.forMore} />
          <Flex>
            <CrayonText text={labels.visit} />
            <CrayonText
              text={
                <a
                  style={{
                    textDecoration: 'none',
                    marginLeft: 5,
                    color: '#76bc21',
                  }}
                  href="https://thecrayoninitiative.org"
                >
                  TheCrayonInitiative.org
                </a>
              }
            />
          </Flex>
        </Box>
      </Fragment>
    </CrayonLayout>
  )
}

export default PageCrayonJourneyEnd
