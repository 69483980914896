import React from 'react'

import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import { Error, Loading } from 'components'
import FluidPiccleGrid from './FluidPiccleGrid'

type Props = {
  journeyId: string
  onSelect?: (piccleId: string, description: string) => void
  activity: { id: string; continuous?: boolean; maxSquares?: string }
}

export const FluidPiccleGridContainer: React.FunctionComponent<Props> = ({
  journeyId,
  onSelect,
  activity,
}: Props): React.ReactElement => {
  const [data, loading, error]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection('journeys')
      .doc(journeyId)
      .collection('activities')
      .doc(activity.id)
      .collection('piccles')
      .where('hasStrokes', '==', true)
      .where('deleted', '==', false)
      .orderBy('createdOn', activity.continuous ? 'desc' : 'asc')
      .limit(activity.maxSquares ? Number(activity.maxSquares) : 100),
    { idField: 'id' },
  )

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {data && (
        <FluidPiccleGrid
          journeyId={journeyId}
          activityId={activity.id}
          picclesData={data.map(({ id, description }) => ({
            piccleId: id,
            description: description ? description : '',
          }))}
          onSelect={onSelect}
        />
      )}
    </>
  )
}
