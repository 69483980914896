import React from 'react'
import { Button, ButtonProps } from '@lightspeed/flame/Button'

export const PiccleButton: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const buttonProps: ButtonProps = {
    style: {
      borderRadius: '30px',
    },
    ...props,
  }
  return <Button data-testid="Button" {...buttonProps} />
}
