import React from 'react'
import { Button, ButtonProps } from '@lightspeed/flame/Button'

export const CrayonButtonSmall: React.FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  const buttonProps: ButtonProps = {
    style: props.style ?? {
      height: '45px',
      width: '95px',
      backgroundColor: '#1a508f',
      borderRadius: '5px',
      border: 0,
    },
    ...props,
  }
  return <Button fill variant="#1a508f" data-testid="Crayon-Button" {...buttonProps} />
}
