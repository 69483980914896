import firebase from 'firebase'

export enum JourneyType {
  SYNCHRONOUS = 'SYNCHRONOUS',
  ASYNCHRONOUS = 'ASYNCHRONOUS',
}

export enum CrayonActivityType {
  PROMPT = 'PROMPT',
}

export enum Language {
  English = 'en',
  Español = 'es',
}

export type CrayonTranslationObjectType = {
  [key in Language]: { [key: string]: string }
}

export enum QuestionCategory {
  Sibling = 'SIBLING',
  Parent = 'PARENT',
  Patient = 'PATIENT',
}

export type Template = {
  id: string
  name: string
  journeyCollectionPath: string
  users: string[]
  createdBy: string
  createdOn: firebase.firestore.Timestamp
  updatedBy?: string
  updatedOn?: firebase.firestore.Timestamp
}

export type QuestionTitle = {
  [key in Language]?: string
}

export type Question = {
  id: string
  title: QuestionTitle
  categories: (keyof typeof QuestionCategory)[]
  createdBy: string
  createdOn: firebase.firestore.Timestamp
  updatedBy?: string
  updatedOn?: firebase.firestore.Timestamp
}

export type UpdateQuestionInputType = {
  questionId: string
  payload: Pick<Question, 'title' | 'categories'>
}

export type TemplatePromotedPiccle = {
  questionId: string
  piccleId: string
}

export type TemplateJourney = {
  id: string
  title: string
  colors: string[]
  openingMessage: string
  openingImageUrl?: string
  closingMessage: string
  closingImageUrl?: string
  sponsors?: { [key in SponsorType]: string }
  code: string
  QRcode?: boolean
  isDeleted: boolean
  currentQuestion: string
  promotedPiccles: TemplatePromotedPiccle[]
  createdBy: string
  createdOn: firebase.firestore.Timestamp
  updatedBy?: string
  updatedOn?: firebase.firestore.Timestamp
}

export type TemplatePiccle = {
  id: string
  hasStrokes: boolean
  done: boolean
  strokes: Stroke[]
  questionId: string
  createdBy: string
  createdOn: firebase.firestore.Timestamp
}

export type Stroke = {
  id: string
  path: string
  heigth: number
  width: number
  left: number
  top: number
  stroke: string
  strokeWidth: number
  createdBy: string
  createdOn: firebase.firestore.Timestamp
}
export enum SponsorType {
  Primary = 'primary',
  Secondary = 'secondary',
  Hosppital = 'hospital',
}

export type Sponsor = {
  id: string
  name: string
  slug?: string
  imgUrl?: string
  type?: string
  createdBy: string
  createdOn: firebase.firestore.Timestamp
}

export type SponsorsData = {
  [key: string]: Sponsor
}
