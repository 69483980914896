import getActivity from './getActivity'

export type GetIsActivityEditableArgs = {
  activityId: string
  journeyId: string
}

/**
 * Will check if the displayAll flag exists on an activity.
 * this flags means we are in a more secured activity where all piccles must be approved before being displayed.
 * In those activities piccles can't be edited after submitted.
 * @param GetIsActivityEditableArgs  { activityId: string  journeyId: string }
 * @returns a boolean indicating if the piccles of this activity can be edited
 */
const getIsActivityEditable = async ({ activityId, journeyId }: GetIsActivityEditableArgs): Promise<boolean> => {
  const activity = await getActivity({ activityId, journeyId })
  // if we can't find the flag on the activity document we assume it's a regular activities where all piccles can be edited
  return activity.displayAll ?? true
}

export default getIsActivityEditable
