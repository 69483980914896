/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Card, CardSection, CardHeader, CardFooter } from '@lightspeed/flame/Card'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Text } from '@lightspeed/flame/Text'
import { crayonPicclesApi } from 'api'
import deletePiccle from 'api/crayon/deletePiccle'

import { AdminNavbar, CrayonPiccleCanvasContainer, CrayonProjection, TemplateModerationActionModal } from 'components'
import useTranslations from 'hooks/useTranslations'
import { Fragment, useState, Dispatch, SetStateAction, useEffect } from 'react'
import { Box, Flex } from 'rebass'
import { Question, TemplateJourney, TemplatePromotedPiccle } from 'types'

const translations = {
  en: {
    moderationPanel: 'Moderation Panel',
    selectAPiccle: 'Select a piccle to open actions menu.',
    currentPromotedPiccle: 'Current promoted piccle',
    unpromote: 'Unpromote',
  },
  fr: {
    moderationPanel: 'Moderation Panel',
    selectAPiccle: 'Select a piccle to open actions menu.',
    currentPromotedPiccle: 'current promoted piccle',
    unpromote: 'Unpromote',
  },
}

type Translations = typeof translations.en

type Props = {
  journeyCollectionPath: string
  questions: Question[]
  journey: TemplateJourney
}

const PageTemplateModeration: React.FunctionComponent<Props> = ({
  journeyCollectionPath,
  questions,
  journey,
}: Props): React.ReactElement => {
  const labels: Translations = useTranslations<Translations>(translations)
  const [isActionModalOpen, setActionModalOpen]: [boolean, Dispatch<SetStateAction<boolean>>] = useState<boolean>(false)
  const [selectedPiccleId, setSelectedPiccleId]: [string, Dispatch<SetStateAction<string>>] = useState<string>(null)
  const [currentQuestion, setCurrentQuestion]: [Question, Dispatch<SetStateAction<Question>>] = useState<Question>(null)

  const handleDeletePiccle: () => void = async (): Promise<void> => {
    if (getPromotedPiccle().piccleId === selectedPiccleId) {
      handleUnpromotePiccle()
    }
    await deletePiccle({ journeyCollectionPath, journeyId: journey.id, piccleId: selectedPiccleId })
    setActionModalOpen(false)
  }

  const { crayonJourneyApi } = crayonPicclesApi()
  const { promotedPiccles = [] } = journey

  useEffect(() => {
    //if no current question. set first one current
    let newCurrentQuestion: Question
    if (!journey.currentQuestion) {
      updateCurrentQuestion(questions[0].id)
    } else {
      newCurrentQuestion = questions.find((q) => q.id === journey.currentQuestion)
    }
    setCurrentQuestion(newCurrentQuestion)
    // eslint-disable-next-line
  }, [journey, questions])

  const updateCurrentQuestion = async (questionId: string): Promise<void> => {
    await crayonJourneyApi.updateCrayonJourney({ journeyId: journey.id, payload: { currentQuestion: questionId } })
  }

  const getCurrentQuestionIndex = (id: string): number => {
    return questions.findIndex((q) => q.id === id)
  }

  const handleSelectPiccle = async (piccleId: string) => {
    setSelectedPiccleId(piccleId)
    setActionModalOpen(true)
  }

  const getPromotedPiccle = (): TemplatePromotedPiccle => {
    return promotedPiccles.find((p) => p.questionId === currentQuestion.id)
  }

  const handleUnpromotePiccle: () => Promise<any> = async (): Promise<any> => {
    const newPromotedPiccles = promotedPiccles.filter((p) => p.questionId !== currentQuestion.id)
    return crayonJourneyApi.updateCrayonJourney({
      journeyId: journey.id,
      payload: { ...journey, promotedPiccles: newPromotedPiccles },
    })
  }

  return (
    <Fragment>
      <Flex bg="muted" width="100vw" height="100vh" flexDirection="column">
        <AdminNavbar loading={false} company={null}>
          <Text fontSize={[20, 30, 40]} fontWeight="bold">
            {labels.moderationPanel}
          </Text>
        </AdminNavbar>

        <Flex flex={1} width="100vw">
          <Box backgroundColor="lemonchiffon" height="100%" width={1 / 5} p={2}>
            <Text my={2} fontSize={[10, 20, 30]} fontWeight="bold">
              {journey.title}
            </Text>

            <Text mt={4} fontSize={[5, 15, 25]}>
              {`Journey Code: `}
            </Text>
            <Text fontWeight="bold" fontSize={[10, 20, 30]} color="darkgreen">
              {journey.code}
            </Text>

            <Text my={4} fontSize={[10, 10, 15]}>
              {labels.selectAPiccle}
            </Text>
            {currentQuestion && (
              <Card>
                <CardHeader style={{ fontSize: '!important 2vw' }} title="Current Question"></CardHeader>
                <CardSection>
                  <Text fontSize={['small', 'normal', 'large']} textAlign="center">
                    {currentQuestion.title.en}
                  </Text>

                  <br />

                  {getPromotedPiccle() && (
                    <Fragment>
                      <br />
                      <Text>{labels.currentPromotedPiccle}</Text>
                      <CrayonPiccleCanvasContainer
                        journeyCollectionPath={journeyCollectionPath}
                        piccleId={getPromotedPiccle().piccleId}
                        journeyId={journey.id}
                        height={100}
                        width={100}
                      />
                      <Button
                        variant="danger"
                        size="small"
                        onClick={() => {
                          handleUnpromotePiccle()
                        }}
                      >
                        {labels.unpromote}
                      </Button>
                    </Fragment>
                  )}
                </CardSection>
                <CardFooter>
                  <Flex justifyContent="space-around">
                    <Button
                      variant="primary"
                      size="small"
                      disabled={getCurrentQuestionIndex(currentQuestion.id) === 0}
                      onClick={() => {
                        updateCurrentQuestion(questions[getCurrentQuestionIndex(currentQuestion.id) - 1].id)
                      }}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      size="small"
                      disabled={getCurrentQuestionIndex(currentQuestion.id) + 1 === questions.length}
                      onClick={() => {
                        updateCurrentQuestion(questions[getCurrentQuestionIndex(currentQuestion.id) + 1].id)
                      }}
                    >
                      Next
                    </Button>
                  </Flex>
                </CardFooter>
              </Card>
            )}
            <Box my={2} />
          </Box>

          <Box width={4 / 5} height="100%" bg="white">
            <Box p={2} height="105vh">
              {journey.currentQuestion && (
                <CrayonProjection
                  isModeration={true}
                  journeyCollectionPath={journeyCollectionPath}
                  questionId={journey.currentQuestion}
                  journeyId={journey.id}
                  handleOnSelect={handleSelectPiccle}
                />
              )}
            </Box>
          </Box>
        </Flex>
      </Flex>
      <TemplateModerationActionModal
        isModalOpen={isActionModalOpen}
        onClose={() => setActionModalOpen(false)}
        onDelete={handleDeletePiccle}
        journey={journey}
        questionId={currentQuestion && currentQuestion.id}
        journeyCollectionPath={journeyCollectionPath}
        piccleId={selectedPiccleId}
      />
    </Fragment>
  )
}

export default PageTemplateModeration
