import Firebase from 'firebase/app'
import 'firebase/firestore'
import ReactGA from 'react-ga'
import slugify from 'slugify'

import { prepareDocForCreate } from '../helpers/firestoreHelpers'

type Data = {
  name: string
  imgUrl: string
}
const createSponsor = async (data) => {
  ReactGA.event({
    category: 'Sponsor',
    action: 'create sponsor',
  })

  data.slug = slugify(data.name, { lower: true })

  return Firebase.firestore()
    .collection('sponsors')
    .add(prepareDocForCreate(data))
    .then(() => {})
    .catch((error) => {
      alert(`Whoops, couldn't create the sponsor: ${error.message}`)
    })
}

export default createSponsor
