import React, { useState } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '@lightspeed/flame/Modal'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'
import { Box } from 'rebass'

import uploadImage from 'api/helpers/uploadImage'
import useTranslations from 'hooks/useTranslations'
import { Timestamp } from '@firebase/firestore-types'

const translations = {
  en: {
    cancel: 'Cancel',
    create: 'Create',
  },
  fr: {
    cancel: 'Cancel',
    create: 'Create',
  },
}

type Translations = typeof translations.en

type ReactHooks<T> = [T, React.Dispatch<React.SetStateAction<T>>]

type Sponsor = {
  name: string
  imgUrl: string
  slug?: string
  createdBy?: string
  createdOn?: Timestamp
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onCreate: (args: Sponsor) => void
}

export const CreateSponsorModal: React.FC<Props> = ({ isOpen, onClose, onCreate }: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)

  const [inputs, setInputs]: ReactHooks<Sponsor> = useState<Sponsor>({ name: '', imgUrl: '' })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist()
    setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }))
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLButtonElement> | React.FormEvent<HTMLAnchorElement>,
  ): Promise<void> => {
    event.preventDefault()
    event.stopPropagation()

    try {
      await onCreate(inputs)
      onClose()
    } catch (err) {
      console.log(err.message)
    }
  }

  const [isUploadingImage, setIsUploadingImage]: ReactHooks<boolean> = useState<boolean>(false)

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.persist()

    try {
      setIsUploadingImage(true)
      const imgUrl = await uploadImage(event.target.files[0])
      setInputs({ ...inputs, imgUrl })
      setIsUploadingImage(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <ModalHeader onCloseClicked={onClose}>Create New Sponsor</ModalHeader>

      <ModalBody>
        <Box as="form" id="sponsorForm" onSubmit={handleSubmit}>
          <Input
            required
            type="text"
            label="Sponsor Name"
            name="name"
            value={inputs.name}
            onChange={handleInputChange}
          />

          <Input
            type="file"
            label="Sponsor Image"
            name="picture"
            id="picture"
            accept="image/*"
            onChange={handleUploadImage}
          />
        </Box>
      </ModalBody>

      <ModalFooter style={{ textAlign: 'right' }}>
        <Button mr={2} onClick={onClose}>
          {labels.cancel}
        </Button>

        <Button form="sponsorForm" type="submit" variant="primary" onSubmit={handleSubmit} loading={isUploadingImage}>
          {labels.create}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
