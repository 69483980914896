import React, { useState, useEffect, ReactElement, FunctionComponent } from 'react'
import { useDrop } from 'react-dnd'
import update from 'immutability-helper'

import { ActivityCard } from 'components'
import { Activity } from 'Pages/Admin/PageEditJourney/PageEditJourney'

type Props = {
  activities: Activity[]
  onReorder: (arg) => any
  onClickEditActivity: (activityId: string) => void
  activityIdForEdit?: string
}

type Card = {
  id: string
  type: string
  title: string
  prompt?: string
  isStarted: boolean
  isComplete: boolean
  question?: { [key: string]: string }
}

export const ActivityStack: FunctionComponent<Props> = ({
  activities,
  onReorder,
  onClickEditActivity,
  activityIdForEdit,
}: Props): ReactElement => {
  const [cards, setCards] = useState<Card[]>(activities)

  useEffect(() => {
    setCards(activities)
  }, [activities])

  const moveCard: (id: string, to: number) => void = (id: string, atIndex: number): void => {
    const { card, index } = findCard(id)
    setCards(
      // TODO @nktizke: understand why this is necessary... don't want to rely on immutability-helper
      update(cards, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      }),
    )
  }

  const findCard: (id: string) => { card: Card; index: number } = (id: string): { card: Card; index: number } => {
    const card = cards.filter((c) => `${c.id}` === id)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }

  const [, drop] = useDrop({
    accept: 'card',
    drop: () => {
      onReorder(cards.map((card) => card.id))
    },
  })

  const getIsActive: Function = ({ isStarted, isComplete }: { isStarted: boolean; isComplete: boolean }): boolean =>
    isStarted && !isComplete

  return (
    <div ref={drop}>
      {cards.map((card) => (
        <ActivityCard
          title={card.title}
          type={card.type}
          key={card.id}
          id={card.id}
          //now the prompt is a question in english
          prompt={card.question['en']}
          moveCard={moveCard}
          findCard={findCard}
          onEdit={onClickEditActivity}
          isEditing={activityIdForEdit === card.id}
          isActive={getIsActive(card)}
        />
      ))}
    </div>
  )
}
