import * as React from 'react'
import { Box as RebassBox } from 'rebass'
import { Flex, Box } from '@lightspeed/flame/Core'
import { Text } from '@lightspeed/flame/Text'

export const PaymentMethodDisplay: React.FC<any> = ({ paymentMethod, onClick, selected }): JSX.Element => {
  return (
    <>
      {paymentMethod ? (
        <RebassBox
          sx={{
            background: 'white',
            boxShadow: '0 7px 14px 0 rgba(49,49,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.08)',
            borderRadius: '4px',
            marginBottom: '20px',
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          <RebassBox
            sx={{
              position: 'relative',
              color: '#8898AA',
              fontWeight: '300',
              height: '40px',
              lineHeight: '40px',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Flex>
              <Text mr={3}>{paymentMethod.card.brand}</Text>
              <Box id="saved-card">**** **** **** {paymentMethod.card.last4}</Box>
              <Text ml={3}>{paymentMethod.card.exp_month}</Text>
              {'/'}
              <Text>{paymentMethod.card.exp_year}</Text>
              {selected && <Text mx={3}>Selected</Text>}
            </Flex>
          </RebassBox>
        </RebassBox>
      ) : (
        <></>
      )}
    </>
  )
}
