import React, { SyntheticEvent, useState } from 'react'
import useTranslations from 'hooks/useTranslations'
import { Box, Flex } from '@lightspeed/flame/Core'
import { useAuth, AuthenticationContextFields } from 'hooks/useAuth'
import { useInput, Input as InputValue } from 'hooks/useInput'
import { Alert } from '@lightspeed/flame/Alert'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Input } from '@lightspeed/flame/Input'

const translations = {
  en: {
    enterYour: 'Enter your email',
    send: 'Send password reset email',
    weHaveSent: "We've sent a password reset email to ",
  },
  fr: {
    enterYour: 'Entrez votre courriel',
    send: 'Réinitialiser mon mot de passe.',
    weHaveSent: "Nous avons envoyé un courriel de réinitialisation à l'addresse ",
  },
}

type Translations = typeof translations.en

export const PasswordResetForm: React.FunctionComponent = (): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)

  const { sendPasswordResetEmail, error, loading }: AuthenticationContextFields = useAuth()
  const [success, setSuccess]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false)

  // Form state and handler
  const email: InputValue = useInput('')

  const handleSubmit = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault()

    try {
      await sendPasswordResetEmail({ email: email.value })
      setSuccess(true)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Box as="form" id="signup" onSubmit={handleSubmit} data-testid="PasswordResetForm">
      {error && (
        <Alert noCloseBtn mb={3} type="warning">
          {error}
        </Alert>
      )}
      {success ? (
        <Flex mx={-2} mb={3}>
          {labels.weHaveSent} {email.value}
        </Flex>
      ) : (
        <>
          <Flex mx={-2} mb={3}>
            <Box width="100%" px={2}>
              <Input {...email.bind} label={labels.enterYour} name="email" required autoComplete="off" />
            </Box>
          </Flex>
          <Button block fill variant="primary" type="submit" form="signup" value="Submit" loading={loading}>
            {labels.send}
          </Button>
        </>
      )}
    </Box>
  )
}
