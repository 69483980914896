import React, { useState, useEffect, useContext, createContext } from 'react'
import Firebase from 'firebase/app'
import picclesAPI, { PicclesApiObject } from 'api'
import 'firebase/auth'

export enum PiccleUserType {
  FREE = 'free',
  ENTERPRISE = 'enterprise',
}

type UserCredential = {
  email: string
  password: string
  firstName?: string
  lastName?: string
  companyName?: string
  role?: string
}

export type AuthenticationContextFields = {
  user: Firebase.User
  userType: PiccleUserType
  signin: ({ email, password }: UserCredential) => Promise<Firebase.User>
  signup: ({ email, password }: UserCredential) => Promise<void>
  signout: () => Promise<Firebase.User>
  confirmPasswordReset: (code: string, password: string) => Promise<boolean>
  sendPasswordResetEmail: ({ email }: { email: string }) => Promise<boolean>
  signInAnonymously: () => Promise<Firebase.User>
  error: string
  loading: boolean
  setError: React.Dispatch<React.SetStateAction<string>>
  googleSignup: () => Promise<void>
}

export const authContext: React.Context<AuthenticationContextFields> = createContext({
  user: null,
  userType: null,
  signin: null,
  signup: null,
  signout: null,
  confirmPasswordReset: null,
  sendPasswordResetEmail: null,
  signInAnonymously: null,
  error: null,
  loading: null,
  setError: null,
  googleSignup: null,
})

// Provider that wraps your app and makes auth object available to children that call useAuth().
export const ProvideAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth: any = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = (): AuthenticationContextFields => {
  return useContext(authContext)
}

// Provider hook that creates auth object and handles state
export const useProvideAuth = (): AuthenticationContextFields => {
  const [user, setUser]: [Firebase.User, React.Dispatch<React.SetStateAction<Firebase.User>>] = useState<Firebase.User>(
    Firebase.auth().currentUser,
  )
  const [userType, setUserType]: [PiccleUserType, React.Dispatch<React.SetStateAction<PiccleUserType>>] = useState<
    PiccleUserType
  >(null)

  const [loading, setLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState<boolean>(false)

  const [error, setError]: [string, React.Dispatch<React.SetStateAction<string>>] = useState<string>(null)

  const { userApi, companyApi }: PicclesApiObject = picclesAPI()

  // Get the user type inferred from the enterprise claim on the firebase auth token of the current user.
  const getUserType = async (user: Firebase.User): Promise<PiccleUserType> =>
    (await user.getIdTokenResult())?.claims?.enterprise ? PiccleUserType.ENTERPRISE : PiccleUserType.FREE

  const signin = async ({ email, password }: UserCredential): Promise<Firebase.User> => {
    try {
      setLoading(true)
      const { user } = await Firebase.auth().signInWithEmailAndPassword(email, password)
      setUser(user)
      setUserType(await getUserType(user))
      return user
    } catch (error) {
      if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/invalid-email' ||
        error.code === 'auth/user-not-found'
      ) {
        setError('Invalid Credentials')
      } else {
        setError(error.message)
      }
    } finally {
      setLoading(false)
    }
  }

  const signup = async ({ email, password, firstName, lastName, companyName, role }: UserCredential): Promise<void> => {
    try {
      setLoading(true)
      const displayName: string = `${firstName} ${lastName}`
      const { user }: Firebase.auth.UserCredential = await Firebase.auth().createUserWithEmailAndPassword(
        email,
        password,
      )
      await user.updateProfile({ displayName })
      setUser(user)
      const createdCompany: string = await companyApi.createCompany({
        companyName,
        admin: email,
        createdBy: user.uid,
        role: role,
        firstName,
        lastName,
        associatedUser: user.uid,
      })
      await userApi.createUser({
        uid: user.uid,
        firstName,
        lastName,
        email,
        role,
        associatedCompany: createdCompany,
      })
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const googleSignup = async (): Promise<void> => {
    const provider = new Firebase.auth.GoogleAuthProvider()
    try {
      const result = await Firebase.auth().signInWithPopup(provider)
      const user = result.user

      setUser(user)
      // check if the user has registered a company as a google user
      const { size } = await Firebase.firestore()
        .collection('companies')
        .where('company', '==', user.email)
        .get()

      // exit out if user has a google company already
      if (size) {
        return
      }
      const createdCompany: string = await companyApi.createCompany({
        companyName: user.email,
        admin: user.email,
        createdBy: user.uid,
        firstName: user.displayName,
        lastName: '',
        associatedUser: user.uid,
      })
      await userApi.createUser({
        uid: user.uid,
        firstName: user.uid,
        lastName: '',
        email: user.email,
        role: '',
        associatedCompany: createdCompany,
      })
      window.location.reload()
    } catch (error) {
      setError(error.message)
    }
  }

  const signout = async (): Promise<Firebase.User> => {
    try {
      setLoading(true)
      await Firebase.auth().signOut()
      setUser(null)
      setUserType(null)
      return user
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const sendPasswordResetEmail = async ({ email }: { email: string }): Promise<boolean> => {
    try {
      setLoading(true)
      await Firebase.auth().sendPasswordResetEmail(email)
      return true
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const confirmPasswordReset = async (code: string, password: string): Promise<boolean> => {
    try {
      setLoading(true)
      await Firebase.auth().confirmPasswordReset(code, password)
      return true
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const signInAnonymously = async (): Promise<Firebase.User> => {
    try {
      setLoading(true)
      const { user } = await Firebase.auth().signInAnonymously()
      setUser(user)
      return user
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  // Subscribe to user on mount because this sets state in the callback it will cause any
  // component that utilizes this hook to re-render with the latest auth object.
  useEffect(() => {
    setLoading(true)
    const unsubscribe = Firebase.auth().onAuthStateChanged(
      async (user: Firebase.User): Promise<void> => {
        if (user) {
          setUser(user)
          setUserType(await getUserType(user))
        } else {
          setUser(null)
        }
        setLoading(false)
      },
    )

    // Cleanup subscription on unmount
    return unsubscribe
  }, [])

  // Return the user object and auth methods
  return {
    user,
    userType,
    signin,
    signup,
    signout,
    confirmPasswordReset,
    sendPasswordResetEmail,
    signInAnonymously,
    error,
    loading,
    setError,
    googleSignup,
  }
}
