import React from 'react'
import { Flex } from 'rebass'
import { Card, CardSection } from '@lightspeed/flame/Card'
import { TextLink } from '@lightspeed/flame/Text'

import { SignupForm } from 'components'
import useTranslations from 'hooks/useTranslations'
import { AuthLayout } from 'components/molecules/AuthLayout'
import { useNavigate } from 'hooks/useNavigate'

const translations = {
  en: {
    signup: 'Looking to Signup?',
    login: 'I already have an account.',
    forgot: 'I forgot my password',
    tagline: 'Create to connect.',
  },
  fr: {
    signup: "S'enregistrer",
    login: "J'ai déjà un compte.",
    forgot: "J'ai oublier mon mot de passe.",
    tagline: 'Créer pour relier.',
  },
}

type Translations = typeof translations.en

const CenterLinksBox = ({ children }): JSX.Element => (
  <Flex flexDirection="column" mt={3} mb={3}>
    {children}
  </Flex>
)

export const SignUpPage: React.FunctionComponent = (): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)
  const navigate = useNavigate()

  const LoginLink = () => <TextLink onClick={() => navigate('/admin')}>{labels.login}</TextLink>

  return (
    <AuthLayout>
      <Card large style={{ zIndex: 1 }}>
        <CardSection>
          <>
            <SignupForm />
            <CenterLinksBox>
              <LoginLink />
            </CenterLinksBox>
          </>
        </CardSection>
      </Card>
    </AuthLayout>
  )
}
