import Firebase from 'firebase/app'

export type GetActivityArgs = {
  activityId: string
  journeyId: string
}

/**
 * Makes a DB call to get the details of an activity.
 * @param GetActivityArgs Object containing  activityId: string and journeyId: string
 * @returns an activity object with it's id
 */
const getActivity = async ({ activityId, journeyId }: GetActivityArgs): Promise<any> => {
  const activity = await Firebase.firestore()
    .doc(`journeys/${journeyId}/activities/${activityId}`)
    .get()

  return { ...activity.data(), id: activity.id }
}

export default getActivity
