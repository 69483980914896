/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from 'rebass'
import { Text } from '@lightspeed/flame/Text'

export const SignUpFooter: React.FunctionComponent = () => (
  <Flex data-testid="SignUpFooter" bg="secondarydark" alignItems="center" justifyContent="flex-start" padding={'20px'}>
    <Text size="small" color="rgba(255, 255, 255, 0.5)">
      &#169; Piccles
    </Text>
  </Flex>
)
