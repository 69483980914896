import Firebase from 'firebase/app'

export type GetJourneyActivitiesArgs = {
  journeyId: string
}

const getCrayonActivities = async ({ journeyId }: GetJourneyActivitiesArgs): Promise<any> => {
  const activities = await Firebase.firestore()
    .collection(`crayonJourneys/${journeyId}/activities`)
    .get()

  return activities.docs.map((activity) => ({ ...activity.data(), id: activity.id }))
}

export default getCrayonActivities
