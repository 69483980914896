import { CrayonTranslationObjectType, Question, SponsorsData } from 'types'
import { Error, Loading } from 'components'

import PageCrayonPiccleDraw from './PageCrayonPiccleDraw'
import React from 'react'
import { useJourneyData } from 'hooks/crayon/useJourneyData'
import { usePiccleDataOnce } from 'hooks/crayon/usePiccleData'
import useQuestion from 'hooks/crayon/useQuestion'
import useRandomQuestion from 'hooks/crayon/useRandomQuestion'
import { useSponsorsData } from 'hooks/crayon/useSponsorsData'
import { useNavigate } from 'hooks/useNavigate'

const translations: CrayonTranslationObjectType = {
  en: {
    submit: 'Done',
    skip: 'Skip',
    poweredBy: 'Powered by Piccles',
  },
  es: {
    submit: 'Hecho',
    skip: '¡Salta!',
    poweredBy: 'Energizado por Piccles',
  },
}

type Props = {
  journeyCollectionPath: string
  templateId: string
  journeyId: string
  questionId: string
  piccleId: string
  currentQuestionIndex: string
  language: string
  category: string
}

const PageCrayonPiccleDrawContainer: React.FunctionComponent<any> = ({
  journeyCollectionPath,
  templateId,
  journeyId,
  questionId,
  piccleId,
  currentQuestionIndex,
  category,
  language,
}: Props) => {
  const navigate = useNavigate()
  const labels = translations[language]

  const [journeyData, journeyLoading, journeyError] = useJourneyData({
    journeyCollectionPath,
    journeyId,
  })

  const [question, questionsLoading, questionsError]: [Question, boolean, Error] = useQuestion({
    templateId,
    questionId,
  })

  const [randomQuestion, randomQuestionLoading, randomQuestionError]: [Question, boolean, Error] = useRandomQuestion({
    templateId,
    category,
  })

  const [sponsors, sponsorsLoading, sponsorsError]: [SponsorsData, boolean, Error] = useSponsorsData({
    journeyCollectionPath,
    journeyId,
  })

  const [strokesData, strokesLoading, strokesError] = usePiccleDataOnce({
    journeyCollectionPath,
    journeyId,
    piccleId,
  })

  // Data Merging
  const error = journeyError || questionsError || randomQuestionError || strokesError || sponsorsError
  const loading = journeyLoading || questionsLoading || randomQuestionLoading || strokesLoading || sponsorsLoading

  const goToSharePage = (): void => {
    navigate(`${window.location}/share`)
  }

  return (
    <>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {journeyData && question && randomQuestion && strokesData && (
        <>
          <PageCrayonPiccleDraw
            journeyCollectionPath={journeyCollectionPath}
            category={category}
            templateId={templateId}
            journeyId={journeyId}
            randomQuestion={randomQuestion}
            currentQuestionIndex={currentQuestionIndex}
            canvasObjects={strokesData}
            piccleId={piccleId}
            colors={journeyData.colors}
            prompt={question.title}
            goToSharePage={goToSharePage}
            labels={labels}
            language={language}
            sponsors={sponsors}
          />
        </>
      )}
    </>
  )
}

export default PageCrayonPiccleDrawContainer
