import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForUpdate } from '../helpers/firestoreHelpers'

type Args = {
  journeyId: string
  activityId: string
  piccleId: string
  payload: any
}

const updatePiccle = ({ journeyId, activityId, piccleId, payload }: Args): Promise<any> => {
  ReactGA.event({
    category: 'Piccle',
    action: 'Update pctivity',
  })

  return Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .doc(activityId)
    .collection('piccles')
    .doc(piccleId)
    .update(prepareDocForUpdate(payload))
    .then(() => payload)
    .catch((error) => {
      alert(`Whoops, couldn't update the piccle: ${error.message}`)
    })
}

export default updatePiccle
