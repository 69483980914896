import Firebase from 'firebase/app'
import ReactGA from 'react-ga'

import { prepareDocForCreate } from '../helpers/firestoreHelpers'

type Args = {
  journeyId: string
  activityId: string
  meta?: { mosaicIndex: string }
}

const createPiccle = ({ journeyId, activityId, meta }: Args): Promise<string | void> => {
  ReactGA.event({
    category: 'Piccle',
    action: 'create piccle',
  })

  /**
   * meta is an optional vehicle for putting important information inside the piccle doc.
   * Example: the position of this piccle in a mosaic (mosaicIndex).
   */
  return Firebase.firestore()
    .collection(`journeys`)
    .doc(journeyId)
    .collection(`activities`)
    .doc(activityId)
    .collection('piccles')
    .add(prepareDocForCreate({ ...meta, done: false }))
    .then((doc: Firebase.firestore.DocumentReference) => doc.id)
    .catch((error) => {
      alert(`Whoops, couldn't create the piccle: ${error.message}`)
    })
}

export default createPiccle
