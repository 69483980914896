import { AuthenticationContextFields, useAuth } from 'hooks/useAuth'
import { Error, Loading } from 'components'
import {
  PageCrayonDrawingShare,
  PageCrayonJourneyEnd,
  PageCrayonJourneyStart,
  PageCrayonPiccleDraw,
  PageCrayonPickJourney,
  PageCrayonProjectionView,
  PageCrayonWelcome,
} from './CrayonPages'

import { AsyncJourneyEndPage } from './AsyncJourneyEndPage'
import { AsynchronousPicclesContainer } from 'components/molecules/AsynchronousPicclesCanvas/AsynchronousPicclesContainer'
import PageJourneyStatus from './PageJourneyStatus'
import PageNextAsyncDrawing from './PageNextAsyncDrawing'
import PagePiccleDraw from './PagePiccleDraw'
import PageProjectionView from './PageProjectionView'
import PageShare from './PageShare'
import PageWelcome from './PageWelcome'
import React from 'react'
import { Route } from 'Routes'
import { Router } from '@reach/router'

const Client: React.FC = (): JSX.Element => {
  const { user, signInAnonymously, loading, error }: AuthenticationContextFields = useAuth()

  if (error) {
    return <Error error={error} />
  }
  if (loading) {
    return <Loading />
  }
  if (!user) {
    signInAnonymously()
  }

  return (
    <Router>
      <Route path="/" component={PageWelcome} />
      <Route path="/:journeyCode" component={PageJourneyStatus} />
      <Route path="/:journeyCode/end" component={AsyncJourneyEndPage} />
      <Route path="/:journeyCode/:activityId/:piccleId/" component={PagePiccleDraw} />
      <Route path="/:journeyCode/:journeyId/:activityId/:piccleId" component={AsynchronousPicclesContainer} />
      <Route path="/:journeyCode/:journeyId/:activityId/:piccleId/share" component={PageNextAsyncDrawing} />
      <Route path="/:journeyCode/:activityId/:piccleId/share" component={PageShare} />
      <Route path="/projection/:journeyId" component={PageProjectionView} />

      {/* Custom Routes from Crayon */}
      <Route path="/t/:journeyCollectionPath/:templateId/:journeyId" component={PageCrayonWelcome} />
      <Route path="/t/:journeyCollectionPath/:templateId/:journeyId/:language" component={PageCrayonPickJourney} />
      <Route
        path="/t/:journeyCollectionPath/:templateId/:journeyId/:language/:category"
        component={PageCrayonJourneyStart}
      />
      <Route
        path="/t/:journeyCollectionPath/:templateId/:journeyId/:language/:category/:currentQuestionIndex/:questionId/:piccleId"
        component={PageCrayonPiccleDraw}
      />
      <Route
        path="/t/:journeyCollectionPath/:templateId/:journeyId/:language/:category/:currentQuestionIndex/:questionId/:piccleId/share"
        component={PageCrayonDrawingShare}
      />
      <Route
        path="/t/:journeyCollectionPath/:templateId/:journeyId/:language/:category/end"
        component={PageCrayonJourneyEnd}
      />
      <Route path="/t/projection/:journeyCollectionPath/:templateId/:journeyId" component={PageCrayonProjectionView} />
    </Router>
  )
}

export default Client
