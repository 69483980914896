/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from 'rebass'
import { ClientNavbar } from 'components'
import useWindowHeight from 'hooks/useWindowHeight'

type Props = {
  children?: React.ReactNode
  headerStyle: {
    height: string
    align: string
    marginTop: string
  }
  [key: string]: any
}

export const AsyncSharePageLayout: React.FunctionComponent<Props> = ({
  children,
  headerStyle,
  languages,
}: Props): JSX.Element => {
  const windowHeight: number = useWindowHeight()
  return (
    <Flex
      css={css`
        flex-direction: column;
        align-items: stretch;
        width: 100vw;
        border-radius: 20px;
        touch-action: pan-y pan-x;
      `}
      height={windowHeight ? windowHeight : '100vh'}
    >
      <ClientNavbar headerStyle={headerStyle} languages={languages} />
      <Flex flexDirection="column" alignItems="center" flex="1" px={1} py={1} data-testid="AsyncClientLayoutFlexBox">
        {children}
      </Flex>
    </Flex>
  )
}
