import React from 'react'
import PageCrayonWelcome from './PageCrayonWelcome'
import { Language } from 'types'
import { QuestionMemoize } from 'api/crayon/questionMemoization'
import { useNavigate } from 'hooks/useNavigate'

const PageCrayonWelcomeContainer: React.FC<any> = (): JSX.Element => {
  const navigate = useNavigate()
  const handleLanguagePicked = (language: string): void => {
    navigate(`${window.location}/${Language[language]}`)
  }

  QuestionMemoize.clear()

  return (
    <>
      <PageCrayonWelcome onLanguagePicked={handleLanguagePicked} />
    </>
  )
}

export default PageCrayonWelcomeContainer
