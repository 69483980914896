import Firebase from 'firebase/app'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { getJourneyState } from 'api/selectors'

type Activity = {
  id: string
  type: string
  title: string
  isStarted: boolean
  isComplete: boolean
  promotedPiccle?: string
  startedAt: {
    seconds: number
  }
  timer?: string
  question: { [key: string]: string }
}
export type PromptActivity = Activity & {
  prompt: string
  maxSquares: number
}
export type MosaicActivity = Activity & {
  numSquares: number
  imgUrl: string
}
export type Journey = {
  sequence: string[]
  title: string
  code: string
  id: string
  type: 'SYNCHRONOUS' | 'ASYNCHRONOUS'
  accessible?: boolean
  QRcode?: boolean
  languages?: string[]
  participants?: string[]
  collectInfo?: boolean
}
export type JourneyState = {
  status: string
  previousActivity: MosaicActivity | PromptActivity
  currentActivity: MosaicActivity | PromptActivity
  nextActivity: MosaicActivity | PromptActivity
}
export type JourneyData = Journey & {
  state: JourneyState
}

/**
 * useJourneyState subscribes a component to the current state of the journey by
 * watching the activities subcollection of a journey.
 * This is what allows the client side Journey Status Page to react to changes
 * made by moderation when stopping and starting activities.
 */
type Args = {
  journeyId: string
}
export const useJourneyState = ({ journeyId }: Args) => {
  const [journey, journeyLoading, journeyError]: [Journey, boolean, Error] = useDocumentData(
    Firebase.firestore().doc(`journeys/${journeyId}`),
    { idField: 'id' },
  )
  const [activities, activitiesLoading, activitiesError]: [
    Activity[],
    boolean,
    Error,
  ] = useCollectionData(Firebase.firestore().collection(`journeys/${journeyId}/activities`), { idField: 'id' })

  let journeyState
  if (journey && activities) {
    /**
     * We cannot rely on firebase ordering of activities subcollection as the sequence for the the journey.
     * The ordering truth is contained in journey.sequence. Map over this sequence and hydrate with activity
     * data before using the getJourneyState selector.
     */
    const orderedActivities = journey.sequence.map((id: string) => ({
      id,
      ...activities.find((a) => a.id === id),
    }))

    journeyState = getJourneyState(orderedActivities)
  }

  const loading: boolean = journeyLoading || activitiesLoading
  const error: Error = journeyError || activitiesError

  return [journeyState, loading, error]
}

/**
 * useJourneyData subscribes a component to the current state of the journey with useJourneyState
 * but additionally includes the journey meta data as well (title, code, etc...)
 */
export const useJourneyData = ({ journeyId }: Args): [JourneyData, boolean, Error] => {
  const [journeyState, journeyStateLoading, journeyStateError] = useJourneyState({ journeyId })
  const [journeyData, journeyDataLoading, journeyDataError]: [
    Activity[],
    boolean,
    Error,
  ] = useDocumentData(Firebase.firestore().doc(`journeys/${journeyId}`), { idField: 'id' })

  const loading: boolean = journeyStateLoading || journeyDataLoading
  const error: Error = journeyStateError || journeyDataError

  let data
  if (journeyState && journeyData) {
    data = { state: journeyState, ...journeyData }
  }

  return [data, loading, error]
}
