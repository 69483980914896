// the main routes of our app are defined here using @reach/router
import React, { FunctionComponent } from 'react'
import { Router, RouteComponentProps } from '@reach/router'
import Admin from 'Pages/Admin'
import Client from 'Pages/Client'

type Props = { component: FunctionComponent } & RouteComponentProps
const Route: FunctionComponent<Props> = ({ component: Component, ...rest }) => <Component {...rest} />

const Routes = () => (
  <Router>
    <Route path="/*" component={Client} />
    <Route path="/admin/*" component={Admin} />
  </Router>
)

export { Route }
export default Routes
