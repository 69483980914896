import React, { useState, useEffect } from 'react'

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import useTranslations from 'hooks/useTranslations'
import { Flex } from 'rebass'
import { PiccleButton as Button } from 'components/atoms/Button'
import { Text } from '@lightspeed/flame/Text'
import { defaultColors } from './defaultPalette'

import { SketchPicker } from 'react-color'
import nicePalettes from 'nice-color-palettes/500.json'

const translations = {
  en: {
    autogenerate: 'Auto generate',
    palette: 'Color palette',
    default: 'Default colors',
    clear: 'Clear colors',
  },
  fr: {
    autogenerate: 'Generer automatiquement',
    palette: 'Palette de couleurs',
    default: 'Couleurs par défault',
    clear: 'Effacer les couleurs',
  },
}

type Translations = typeof translations.en

type Props = {
  maxColors?: number
  onChange: (values) => void
  colors: string[]
}

export const PalettePicker: React.FunctionComponent<Props> = ({
  maxColors = 15,
  onChange,
  colors,
}: Props): JSX.Element => {
  const labels: Translations = useTranslations<Translations>(translations)

  const [isColorPickerOpen, setIsColorPickerOpen] = useState<boolean>()
  const [selectedColorId, setSelectedColorId] = useState<number>()

  const onColorClickHandler = (colorId: number): void => {
    setIsColorPickerOpen(true)
    setSelectedColorId(colorId)
  }

  const onColorRemoveHandler = (colorId): void => {
    onChange([...colors.slice(0, colorId), ...colors.slice(colorId + 1)])
  }

  const onColorAddHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault()

    if (colors.length < maxColors) {
      onChange([...colors, '#000'])
      onColorClickHandler(colors.length)
    }
  }

  const toggleColorPickerModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    setIsColorPickerOpen(!isColorPickerOpen)
    setSelectedColorId(isColorPickerOpen ? null : selectedColorId)
  }

  const changeColor = (color): void => {
    onChange([...colors.slice(0, selectedColorId), color.hex, ...colors.slice(selectedColorId + 1)])
  }

  const handleGeneratePalettes = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> & React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault()

    const numOfPalettes: number = 3
    // Randomly select 3 palettes from nicePalettes
    // Flatten all colors into 1 array
    // slice off the last color to stay under 15 colors in order to indicate to user he can still select.
    const randomPalettes: Array<string> = nicePalettes
      .sort(() => 0.5 - Math.random())
      .slice(0, numOfPalettes)
      .flat()
      .slice(0, -1)

    onChange(randomPalettes)
  }

  const defaultPalette = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> & React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault()
    onChange(defaultColors)
  }

  useEffect(() => {
    if (colors.length === 0) {
      onChange(defaultColors)
    }
  }, [colors.length, onChange])

  return (
    <div>
      <Text fontWeight="bold" size="small" color="textHeading">
        {labels.palette}
      </Text>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        data-testid="PalettePicker"
        justifyContent={['center', 'space-between']}
      >
        <div
          css={css`
            min-width: 250px;
            max-width: 250px;
          `}
        >
          {colors.map((color, i) => {
            const isSelected = selectedColorId === i
            return (
              <div
                key={color + i}
                css={css`
                  position: relative;
                  cursor: pointer;
                  background-color: ${color};
                  background-clip: content-box;
                  padding: 2px;
                  width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  margin: 4px 13px 0 0;
                  display: inline-block;
                  border: 1px solid #d5d5d5;
                  ${isSelected ? `border: 2px solid rgba(0, 0, 0, 0.6);` : ''}
                `}
                onClick={() => onColorClickHandler(i)}
              >
                {isColorPickerOpen &&
                  isSelected && [
                    <div
                      key="1"
                      css={css`
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                      `}
                      onClick={toggleColorPickerModal}
                    />,
                    <div
                      key="2"
                      css={css`
                        position: absolute;
                        bottom: 40px;
                        left: 15px;
                        width: 223px;
                        overflow: hidden;
                        padding: 2px;
                        z-index: 2;
                      `}
                    >
                      <SketchPicker color={colors[selectedColorId]} onChange={changeColor} />
                    </div>,
                  ]}
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                    font-weight: bold;
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    width: 16px;
                    height: 16px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    &::before {
                      color: rgba(0, 0, 0, 0.4);
                      content: '×';
                    }

                    ${isSelected ? `top: -2px; right: -2px;` : ''}
                  `}
                  onClick={() => onColorRemoveHandler(i)}
                />
              </div>
            )
          })}
          {colors.length < maxColors && (
            <div
              css={css`
                position: relative;
                cursor: pointer;
                background-clip: content-box;
                padding: 2px;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin: 4px 13px 0 0;
                display: inline-block;
                border: 1px solid #d5d5d5;
                &::before {
                  content: '＋';
                  font-size: 25px;
                  display: block;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              `}
              onClick={onColorAddHandler}
            />
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
          <Button variant="secondary" onClick={handleGeneratePalettes}>
            {labels.autogenerate}
          </Button>

          <Button variant="primary" disabled={colors === defaultColors} onClick={defaultPalette}>
            {labels.default}
          </Button>
        </div>
      </Flex>
    </div>
  )
}
