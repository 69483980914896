import React from 'react'
import { useJourneyData } from 'hooks/useJourneyData'
import PageNextAsyncDrawing from './PageNextAsyncDrawing'
import { Loading, Error } from 'components'

const PageShareContainer = (props) => {
  const [journeyData, journeyDataLoading, journeyDataError] = useJourneyData({ journeyId: props.journeyId })
  return (
    <>
      {journeyDataError && <Error error={journeyDataError} />}
      {journeyDataLoading && <Loading />}
      {journeyData && (
        <PageNextAsyncDrawing
          {...journeyData}
          journeyId={props.journeyId}
          journeyCode={journeyData.code}
          piccleId={props.piccleId}
          activityId={props.activityId}
        />
      )}
    </>
  )
}

export default PageShareContainer
