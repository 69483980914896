import Firebase from 'firebase/app'

export enum ActivityStatus {
  UNSTARTED = 'unstarted', // Corresponds to activity.isStarted === false
  ACTIVE = 'active', // Corresponds to activity.isStarted === true && activity.isComplete === false
  COMPLETE = 'complete', // Corresponds to activity.isComplete === true
}

type Args = {
  journeyId: string
  activityId: string
}

const getActivityStatus = async ({ journeyId, activityId }: Args): Promise<ActivityStatus> => {
  const activity = await Firebase.firestore()
    .collection('journeys')
    .doc(journeyId)
    .collection('activities')
    .doc(activityId)
    .get()
    .then((doc: Firebase.firestore.DocumentSnapshot) => doc.data())

  if (activity.isStarted === true && activity.isComplete === false) {
    return ActivityStatus.ACTIVE
  }
  if (activity.isStarted === false) {
    return ActivityStatus.UNSTARTED
  }
  if (activity.isComplete === true) {
    return ActivityStatus.COMPLETE
  }
}

export default getActivityStatus
