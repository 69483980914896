import { useState, useEffect } from 'react'
import getPiccleData from 'api/piccles/getPiccleData'
import Firebase from 'firebase/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { canvasObjConstantProperties } from 'appConstants'

type Args = {
  journeyCode: string
  activityId: string
  piccleId: string
}

export const usePiccleDataOnce = ({ journeyCode, activityId, piccleId }: Args) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    getPiccleData({ journeyCode, activityId, piccleId, setData, setLoading, setError })
  }, [activityId, piccleId]) // eslint-disable-line
  return [data, loading, error]
}

type Args2 = {
  journeyId: string
  activityId: string
  piccleId: string
}

const usePiccleData = ({ journeyId, activityId, piccleId }: Args2) => {
  let [data, loading, error]: [any, boolean, Error] = useCollectionData(
    Firebase.firestore()
      .collection(`journeys/${journeyId}/activities/${activityId}/piccles/${piccleId}/strokes`)
      .orderBy('createdOn', 'asc'),
    { idField: 'id' },
  )
  // Rehydrate the cavasObjects
  if (data) {
    data = data.map((obj) => ({ ...obj, ...canvasObjConstantProperties }))
  }

  return [data, loading, error]
}

export default usePiccleData
