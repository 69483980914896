import { Subscription, EventType } from 'Pages/Admin/PageProfile/Plans'

export type UpgradePayload = {
  paymentMethodId: string
  email: string
  subscription: Subscription
  eventType: EventType
  eventStartDate?: number
}

export type UpgradeConfirmation = {
  status: string
}

const upgradeCompanyPlan = async (
  { paymentMethodId, email, subscription, eventType, eventStartDate }: UpgradePayload,
  endpointUrl: string,
): Promise<UpgradeConfirmation> => {
  try {
    const response = await fetch(`${endpointUrl}/upgrade-plan`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentMethodId,
        adminEmail: email,
        subscription,
        eventType,
        eventStartDate,
      }),
    })
    const data = await response.json()
    return { status: data.status }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export default upgradeCompanyPlan
