import React, { Suspense, useEffect } from 'react'
import Firebase from 'firebase/app'
import { FirestoreProvider } from 'react-firestore'
import ReactGA from 'react-ga'
import ThemeProvider from 'theme/ThemeProvider'

import * as Sentry from '@sentry/browser'
import { Location, LocationProvider, useLocation } from '@reach/router'
import { FlameGlobalStyles } from '@lightspeed/flame/Core'
import './styles/index.scss'

import Routes from 'Routes'
import config from 'config'
import { ProvideAuth } from 'hooks/useAuth'
import QrProvider from 'Contexts/QrContext'

import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Loading } from 'components'

// Main App Wrapper, holds all top lvel wrappers and provider and renders Routes component (our entry into the app ui)
const App = () => (
  <Suspense fallback={Loading}>
    <ProvideAuth>
      <LocationProvider>
        <ThemeProvider>
          <FirestoreProvider firebase={Firebase}>
            <QrProvider>
              <SetInitialLanguage />
              <FlameGlobalStyles />
              <LocationChangeUtils />
              <Routes />
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </QrProvider>
          </FirestoreProvider>
        </ThemeProvider>
      </LocationProvider>
    </ProvideAuth>
  </Suspense>
)

// ErrorBoundary provides a shell that will catch any bubbling errors from its children and provide a sentry report option.
class ErrorBoundary extends React.Component {
  public state = { error: null }

  static getDeriveStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    if (config.env === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props
    if (error) {
      return (
        <button type="button" onClick={() => Sentry.showReportDialog()}>
          Report feedback
        </button>
      )
    }
    return children
  }
}

// LocationChangeUtils ensure we scroll to top on route change and that we capture page view analytics.
const LocationChangeUtils = () => (
  <Location>
    {({ location }) => {
      return (
        <React.Fragment>
          <ScrollToTop pathname={location.pathname} />
          <Analytics location={location} />
        </React.Fragment>
      )
    }}
  </Location>
)

// scroll to top on route change
const scrollTop = () => window.scrollTo(0, 0)
const ScrollToTop: React.FunctionComponent<{ pathname: string }> = ({ pathname }) => {
  useEffect(scrollTop, [pathname])
  return null
}

const SetInitialLanguage: React.FC = () => {
  const { href } = useLocation()
  const { i18n } = useTranslation()

  React.useEffect(() => {
    const params = new URL(href).searchParams
    const lang = params.get('locale')
    if (lang) {
      i18n.changeLanguage(lang)
    }
    // eslint-disable-next-line
  }, [])

  return null
}

// Track Google Analytics page view for every route
const Analytics: React.FunctionComponent<{ location: any }> = ({ location }) => {
  const page = location.pathname + location.search
  ReactGA.set({ page })
  ReactGA.pageview(page)
  return <></>
}

export default App
